import { Box } from '@chakra-ui/react'
import FarmerAnimalHusbandryInformation from '@ui/admin/organisms/farmer/forms/FarmerAnimalHusbandryInformation'
import FarmerCropsInformation from '@ui/admin/organisms/farmer/forms/FarmerCropsInformation'
import FarmerFamilyInformation from '@ui/admin/organisms/farmer/forms/FarmerFamilyInformation'
import FarmerFarmsInformation from '@ui/admin/organisms/farmer/forms/FarmerFarmsInformation'
import FarmerPersonalInformation from '@ui/admin/organisms/farmer/forms/FarmerPersonalInformation'
import Header from '@ui/common/molecules/Header'
import Stepper from '@ui/common/molecules/Stepper'
import { useState } from 'react'
import { MdOutlineBookmarkAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'

const Registration = () => {
  const params = useParams()
  const [step, setStep] = useState<number>(1)
  const [farmerId, setFarmerId] = useState<string | undefined>(params?.id)

  const stepsName = ['व्यक्तिगत जानकारी', 'परिवारको जानकारी', 'खेतको जानकारी', 'अन्नबालिको जानकारी', 'पशुपालन']
  return (
    <Box>
      <Header buttonIcon={<MdOutlineBookmarkAdd />} header={'कृषक दर्ता'} />
      <Box>
        <Stepper step={step} totalStep={5} stepsName={stepsName} />
        <Box border='1px' borderColor={'gray.100'} p='4' mt='2'>
          {step === 1 ? (
            <FarmerPersonalInformation step={step} setStep={setStep} farmerId={farmerId} setFarmerId={setFarmerId} />
          ) : step === 2 ? (
            <FarmerFamilyInformation step={step} setStep={setStep} farmerId={farmerId} />
          ) : step === 3 ? (
            <FarmerFarmsInformation step={step} setStep={setStep} farmerId={farmerId} />
          ) : step === 4 ? (
            <FarmerCropsInformation step={step} setStep={setStep} farmerId={farmerId} />
          ) : (
            <FarmerAnimalHusbandryInformation step={step} setStep={setStep} farmerId={farmerId} />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default Registration
