import { NEPALI_REGEX } from '@regex/index'
import * as yup from 'yup'

export const personalInformationSchema = yup.object({
  firstName: yup.object().shape({
    en: yup.string().required('First name is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'पहिलो नाम देवानगरिमा लेख्नुहोस').required('पहिलो नाम आवश्यक छ ।'),
  }),
  lastName: yup.object().shape({
    en: yup.string().required('Last name is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'थर देवानगरिमा लेख्नुहोस').required('थर आवश्यक छ ।'),
  }),
  gender: yup.string().required('कृपया लिङ्ग छान्नुहोस'),
  dateOfBirth: yup.string().required('कृपया जन्म मिति हाल्नुहोस'),
  academicQualification: yup.string().required('कृपया शैक्षिक योग्यता छान्नुहोस'),
  literateLevel: yup
    .string()
    .when('academicQualification', {
      is: 'LITERATE',
      then: yup.string().required('कृपया साक्षर योग्यता छान्नुहोस'),
    })
    .nullable(),
  ward: yup
    .number()
    .min(1, '१-१४ सम्म मान्य छ')
    .max(14, '१-१४ सम्म मान्य छ')
    .required('वडा नम्बर छान्नुहोस')
    .typeError('१-१४ सम्म मान्य छ'),
  village: yup.string().required('गाँउको नाम लेख्नुहोस'),
  farmVillage: yup.string().required('कृषि कार्य गरेको गाँउको नाम लेख्नुहोस'),
  farmWard: yup
    .number()
    .min(1, '१-१४ सम्म मान्य छ')
    .max(14, '१-१४ सम्म मान्य छ')
    .required('वडा नम्बर छान्नुहोस')
    .typeError('१-१४ सम्म मान्य छ'),
  citizenshipNumber: yup.string().required('नागरिकता नम्बर लेख्नुहोस'),
  citizenshipIssuedDistrict: yup.string().required('नागरिकता जारि जिल्ला लेख्नुहोस'),
  agricultureArea: yup.string().required('कृषि कार्यको क्षेत्र छान्नुहोस'),
  agricultureSubArea: yup.string().required('कृषि कार्यको उपक्षेत्र लेख्नुहोस'),
  isOwnLand: yup.string().required('कृषि कार्यको उपक्षेत्र लेख्नुहोस'),
  landArea: yup
    .object()
    .when('isOwnLand', {
      is: 'YES',
      then: yup.object().shape({
        biggha: yup.string().required('जग्गाको क्षेत्रफल बिघा लेख्नुहोस'),
        katha: yup.string().required('जग्गाको क्षेत्रफल कठ्ठा लेख्नुहोस'),
        dhur: yup.string().required('जग्गाको क्षेत्रफल धुरमा लेख्नुहोस'),
      }),
    })
    .nullable(),
  yearlyTurnOver: yup.object().shape({
    agriculture: yup.string().required('कृषि पेशाबाट हुने आम्दानी रु. मा लेख्नुहोस'),
    nonAgriculture: yup.string().required('गैर कृषि पेशाबाट हुने आम्दानी रु. मा लेख्नुहोस'),
  }),
  averageMonthEngageOnAgriculture: yup.string().required('बार्षिक औसत महिना कृषि कार्यमा संलग्न समय छान्नुहोस'),
})

export const familyInformationSchema = yup.object({
  totalFamilyMember: yup.object().shape({
    male: yup.string().required('परिवारको पुरुषको संख्या लेख्नुहोस'),
    female: yup.string().required('परिवारको महिलाको संख्या लेख्नुहोस'),
  }),
  involveInAgriculture: yup.object().shape({
    male: yup.string().required('कृषि कार्यमा संलग्न पुरुषको संख्या लेख्नुहोस'),
    female: yup.string().required('कृषि कार्यमा संलग्न महिलाको संख्या लेख्नुहोस'),
  }),
  year: yup.string().required('कृषि पेशा वा व्यावसायमा संलग्न भएको वर्ष लेख्नुहोस'),
})

export const familyDetailsWhoInvolveInAgricultureSchema = yup.object({
  familyDetailsWhoInvolveInAgriculture: yup.object().shape({
    relation: yup.string().required('किसान संगको नाता लेख्नुहोस'),
    fullname: yup.string().required('पुरा नाम लेख्नुहोस'),
    age: yup.number().required('उमेर (बर्षमा) लेख्नुहोस').typeError('उमेर (बर्षमा) लेख्नुहोस'),
    gender: yup.string().required('लिङ्ग छान्नुहोस'),
    workingArea: yup.string().required('कृषि कार्यको क्षेत्र छान्नुहोस'),
    citizenshipNumber: yup.string().required('नागरिकता प्रमाण पत्र नम्बर लेख्नुहोस'),
    phoneNumber: yup.string().required('फोन नम्बर लेख्नुहोस'),
  }),
})

export const farmsInformationSchema = yup.object({
  isRegistedFarmer: yup.string().required('कृषक दर्ता नम्बर छ वा छैन छान्नुहोस'),
  farmerRegistrationNumber: yup
    .string()
    .when('isRegistedFarmer', {
      is: 'YES',
      then: yup.string().required('कृषक दर्ता नम्बर लेख्नुहोस'),
    })
    .nullable(),
  farmerDetails: yup
    .object()
    .when('isRegistedFarmer', {
      is: 'NO',
      then: yup.object().shape({
        name: yup.string().required('कृषकको नाम लेख्नुहोस'),
        phoneNumber: yup.string().required('फोन न. लेख्नुहोस'),
        address: yup.string().required('ठेगना लेख्नुहोस'),
        contractDate: yup.string().required('सम्झौता मिति लेख्नुहोस'),
        contractEndDate: yup.string().required('सम्झौता समाप्त मिति लेख्नुहोस'),
        citizenshipNumber: yup.string().required('नागारिकता नम्बर लेख्नुहोस'),
        currentAddress: yup.string().required('हालको ठेगना लेख्नुहोस'),
      }),
    })
    .nullable(),
})

export const landOwnershipInformationSchema = yup.object({
  landOwnershipInformation: yup.object().shape({
    landInformation: yup.object().shape({
      ownership: yup.string().required('स्वामित्व छान्नुहोस'),
      landType: yup.string().required('जमिनको किसिम छान्नुहोस'),
      area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
    }),
    irregation: yup.object().shape({
      isAvaiableIrregation: yup
        .string()
        .required('सिंचाई उपलब्धता छ वा छैन छान्नुहोस')
        .typeError('सिंचाई उपलब्धता छ वा छैन छान्नुहोस'),
      irrigatedArea: yup.string().required('सिंचित क्षेत्रफल लेख्नुहोस'),
      irrigationSouce: yup.string().required('सिंचाईको स्रोत छान्नुहोस'),
    }),
  }),
})

export const agricultureDetailsSchema = yup.object({
  agricultureDetails: yup.object().shape({
    address: yup.string().required('ठेगाना लेख्नुहोस'),
    area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
    isAvaiableIrregation: yup
      .string()
      .required('सिंचाई उपलब्धता छ वा छैन छान्नुहोस')
      .typeError('सिंचाई उपलब्धता छ वा छैन छान्नुहोस'),
    categoryOfAgriculture: yup.string().required('कृषि कार्यको क्षेत्र लेख्नुहोस'),
    subCategoryOfAgriculture: yup.string().required('कृषि कार्यको उपक्षेत्र लेख्नुहोस'),
    ownership: yup.string().required('सिंचाईको स्रोत छान्नुहोस'),
    ownershipInformationIfLandIsOnContract: yup.string().optional(),
  }),
})

export const contractDetailsSchema = yup.object({
  contractDetails: yup.object().shape({
    landInformation: yup.object().shape({
      ownership: yup.string().required('स्वामित्व छान्नुहोस'),
      landType: yup.string().required('जमिनको किसिम छान्नुहोस'),
      area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
    }),
    irregation: yup.object().shape({
      isAvaiableIrregation: yup.string().required('सिंचाई उपलब्धता छान्नुहोस'),
      irrigatedArea: yup.string().required('सिंचित क्षेत्रफल लेख्नुहोस'),
      irrigationSouce: yup.string().required('सिंचाईको स्रोत छान्नुहोस'),
    }),
  }),
})

export const cropsSchema = yup.object({})

export const foodCropsSchema = yup.object({
  foodCrops: yup.object().shape({
    name: yup.string().required('बालीको नाम लेख्नुहोस'),
    area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
    productionQuantity: yup
      .number()
      .required('उत्पादन परिमाण (के.जि) मा लेख्नुहोस')
      .typeError('उत्पादन परिमाण (के.जि) मा लेख्नुहोस'),
  }),
})

export const fruitsCropsSchema = yup.object({
  fruitsCrops: yup.object().shape({
    name: yup.string().required('बालीको नाम लेख्नुहोस'),
    plantCount: yup.number().required('बोटको संख्या लेख्नुहोस').typeError('बोटको संख्या लेख्नुहोस'),
    area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
    productionQuantity: yup
      .number()
      .required('उत्पादन परिमाण (के.जि) मा लेख्नुहोस')
      .typeError('उत्पादन परिमाण (के.जि) मा लेख्नुहोस'),
  }),
})

export const seedsSchema = yup.object({
  seeds: yup.object().shape({
    crops: yup.string().required('बालीको नाम लेख्नुहोस'),
    type: yup.string().required('बालीको जात लेख्नुहोस'),
    area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
    productionQuantity: yup
      .number()
      .required('उत्पादन परिमाण (के.जि) मा लेख्नुहोस')
      .typeError('उत्पादन परिमाण (के.जि) मा लेख्नुहोस'),
  }),
})

export const mushroomSchema = yup.object({
  mushroom: yup.object().shape({
    type: yup.string().required('च्याउको जात लेख्नुहोस'),
    tunnelCount: yup.number().required('टनेल संख्या लेख्नुहोस').typeError('टनेल संख्या लेख्नुहोस'),
    area: yup.string().required('प्लाष्टिकघरको क्षेत्रफल लेख्नुहोस'),
    productionQuantity: yup
      .number()
      .required('उत्पादन परिमाण (के.जि) मा लेख्नुहोस')
      .typeError('उत्पादन परिमाण (के.जि) मा लेख्नुहोस'),
  }),
})

export const silkSchema = yup.object({
  silk: yup.object().shape({
    area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
    speciesOfInsects: yup.string().required('रेशम कीराको जात लेख्नुहोस'),
    insectsCount: yup.number().required('किरा संख्या लेख्नुहोस').typeError('किरा संख्या लेख्नुहोस'),
  }),
})

export const beeSchema = yup.object({
  bee: yup.object().shape({
    type: yup.string().required('मौरीको जात लेख्नुहोस'),
    numberOfHives: yup.number().required('घारको संख्या लेख्नुहोस').typeError('घारको संख्या लेख्नुहोस'),
    productionQuantity: yup
      .number()
      .required('उत्पादन परिमाण (के.जि) मा लेख्नुहोस')
      .typeError('उत्पादन परिमाण (के.जि) मा लेख्नुहोस'),
  }),
})

export const animalHusbandrySchema = yup.object({
  cattleRearing: yup.object().shape({
    local: yup.object().shape({
      count: yup.string().required('स्थानीय गाईको संख्या लेख्नुहोस'),
      annualAverageMilkingMonths: yup.string().required('बार्षिक औसत कति महिना दुध दिन्छ लेख्नुहोस'),
      LitersOfMilkProductionPerDay: yup.string().required('दैनिक दुध उत्पादन लिटरमा लेख्नुहोस'),
    }),
    advance: yup.object().shape({
      count: yup.string().required('उन्नत गाईको संख्या लेख्नुहोस'),
      annualAverageMilkingMonths: yup.string().required('बार्षिक औसत कति महिना दुध दिन्छ लेख्नुहोस'),
      LitersOfMilkProductionPerDay: yup.string().required('दैनिक दुध उत्पादन लिटरमा लेख्नुहोस'),
    }),
  }),
  buffaloRearing: yup.object().shape({
    local: yup.object().shape({
      count: yup.string().required('स्थानीय भैसिको संख्या लेख्नुहोस'),
      annualAverageMilkingMonths: yup.string().required('बार्षिक औसत कति महिना दुध दिन्छ लेख्नुहोस'),
      LitersOfMilkProductionPerDay: yup.string().required('दैनिक दुध उत्पादन लिटरमा लेख्नुहोस'),
    }),
    advance: yup.object().shape({
      count: yup.string().required('उन्नत भैसिको संख्या लेख्नुहोस'),
      annualAverageMilkingMonths: yup.string().required('बार्षिक औसत कति महिना दुध दिन्छ लेख्नुहोस'),
      LitersOfMilkProductionPerDay: yup.string().required('दैनिक दुध उत्पादन लिटरमा लेख्नुहोस'),
    }),
  }),
  yakRearing: yup.object().shape({
    chauri: yup.object().shape({
      count: yup.string().required('चौरीको संख्या लेख्नुहोस'),
      annualAverageMilkingMonths: yup.string().required('बार्षिक औसत कति महिना दुध दिन्छ लेख्नुहोस'),
      LitersOfMilkProductionPerDay: yup.string().required('दैनिक दुध उत्पादन लिटरमा लेख्नुहोस'),
    }),
    yak: yup.object().shape({
      count: yup.string().required('याकको संख्या लेख्नुहोस'),
      annualAverageMilkingMonths: yup.string().required('बार्षिक औसत कति महिना दुध दिन्छ लेख्नुहोस'),
      LitersOfMilkProductionPerDay: yup.string().required('दैनिक दुध उत्पादन लिटरमा लेख्नुहोस'),
    }),
  }),
  bullOrQueue: yup.object().shape({
    bull: yup.object().shape({
      count: yup.string().required('गोरुको संख्या लेख्नुहोस'),
    }),
    queue: yup.object().shape({
      count: yup.string().required('रांगाको संख्या लेख्नुहोस'),
    }),
  }),
  sheepGoatMountainGoat: yup.object().shape({
    sheep: yup.object().shape({
      ewe: yup.string().required('माउको संख्या लेख्नुहोस'),
      ram: yup.string().required('खसिको संख्या लेख्नुहोस'),
      castratedSheep: yup.string().required('बोकाको संख्या लेख्नुहोस'),
      lamp: yup.string().required('पाठा/पाठिको संख्या लेख्नुहोस'),
    }),
    goat: yup.object().shape({
      sheGoat: yup.string().required('माउको संख्या लेख्नुहोस'),
      goat: yup.string().required('खसिको संख्या लेख्नुहोस'),
      billyGoat: yup.string().required('बोकाको संख्या लेख्नुहोस'),
      kid: yup.string().required('पाठा/पाठिको संख्या लेख्नुहोस'),
    }),
    mountainGoat: yup.object().shape({
      sheGoat: yup.string().required('माउको संख्या लेख्नुहोस'),
      goat: yup.string().required('खसिको संख्या लेख्नुहोस'),
      castratedGoat: yup.string().required('बोकाको संख्या लेख्नुहोस'),
      kid: yup.string().required('पाठा/पाठिको संख्या लेख्नुहोस'),
    }),
  }),
  boarPigWildBoar: yup.object().shape({
    boar: yup.object().shape({
      sow: yup.string().required('माउको संख्या लेख्नुहोस'),
      boar: yup.string().required('खसिको संख्या लेख्नुहोस'),
      piglet: yup.string().required('बोकाको संख्या लेख्नुहोस'),
    }),
    pig: yup.object().shape({
      sow: yup.string().required('माउको संख्या लेख्नुहोस'),
      boar: yup.string().required('खसिको संख्या लेख्नुहोस'),
      piglet: yup.string().required('बोकाको संख्या लेख्नुहोस'),
    }),
    wildBoar: yup.object().shape({
      sow: yup.string().required('माउको संख्या लेख्नुहोस'),
      boar: yup.string().required('खसिको संख्या लेख्नुहोस'),
      piglet: yup.string().required('बोकाको संख्या लेख्नुहोस'),
    }),
  }),
  poultryFarming: yup.object().shape({
    boiler: yup.object().shape({
      isFarming: yup.string().required('छ वा छैन छान्नुहोस')?.typeError('छ वा छैन छान्नुहोस'),
      count: yup.string().when('isFarming', (isFarming: string, schema) => {
        if (isFarming === 'YES') {
          return schema.required('कुखुराको संख्या लेख्नुहोस')
        }
        return schema
      }),
      annualAverageEggProduction: yup.string().when('isFarming', (isFarming: string, schema) => {
        if (isFarming === 'YES') {
          return schema.required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस')
        }
        return schema
      }),
      annualAverageProduction: yup.string().when('isFarming', (isFarming: string, schema) => {
        if (isFarming === 'YES') {
          return schema.required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस')
        }
        return schema
      }),
    }),
    local: yup.object().shape({
      isFarming: yup.string().required('छ वा छैन छान्नुहोस')?.typeError('छ वा छैन छान्नुहोस'),
      count: yup.string().when('isFarming', (isFarming: string, schema) => {
        if (isFarming === 'YES') {
          return schema.required('कुखुराको संख्या लेख्नुहोस')
        }
        return schema
      }),
      annualAverageEggProduction: yup.string().when('isFarming', (isFarming: string, schema) => {
        if (isFarming === 'YES') {
          return schema.required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस')
        }
        return schema
      }),
      annualAverageProduction: yup.string().when('isFarming', (isFarming: string, schema) => {
        if (isFarming === 'YES') {
          return schema.required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस')
        }
        return schema
      }),
    }),
  }),
  birds: yup.object().shape({
    duck: yup.object().shape({
      count: yup.string().required('कुखुराको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
    battai: yup.object().shape({
      count: yup.string().required('बट्टाईको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
    ostrich: yup.object().shape({
      count: yup.string().required('अस्ट्रिचको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
    kalij: yup.object().shape({
      count: yup.string().required('कालिजको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
    turkey: yup.object().shape({
      count: yup.string().required('टर्कीको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
    laukat: yup.object().shape({
      count: yup.string().required('लौकाटको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
    parrot: yup.object().shape({
      count: yup.string().required('परेवाको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
    others: yup.object().shape({
      count: yup.string().required('अन्यको संख्या लेख्नुहोस'),
      annualAverageEggProduction: yup.string().required('वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस'),
      annualAverageProduction: yup.string().required('वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस'),
    }),
  }),
  fisheries: yup.object().shape({
    breed: yup.array().of(yup.string()).min(1, 'रोज्नुहोस्'),
    area: yup.string().required('पोखरीको क्षेत्रफल लेख्नुहोस'),
    count: yup.string().required('संख्या लेख्नुहोस'),
    productionPurpose: yup.string().required('उत्पादन प्रयोजन छान्नुहोस'),
    productioUnit: yup.string().required('उत्पादन एकाइ छान्नुहोस'),
    productionQuantity: yup.string().required('उत्पादन परिमाण (के.जि) मा लेख्नुहोस'),
  }),
  othersAnimalHusbandry: yup.object().shape({
    horse: yup.string().required('घोडाको संख्या लेख्नुहोस'),
    khachhad: yup.string().required('खच्चडको संख्या लेख्नुहोस'),
    donkey: yup.string().required('गधाको संख्या लेख्नुहोस'),
    rabbit: yup.string().required('खरायोको संख्या लेख्नुहोस'),
    dog: yup.string().required('कुकुरको संख्या लेख्नुहोस'),
    cat: yup.string().required('बिरालोको संख्या लेख्नुहोस'),
    others: yup.string().required('अन्यको संख्या लेख्नुहोस'),
  }),
  grassCrops: yup.object().shape({
    cold: yup.object().shape({
      breed: yup.string().required('जात लेख्नुहोस'),
      area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
      productionQuantity: yup.string().required('हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस'),
      seedProduction: yup.string().required('बिउ उत्पादन लेख्नुहोस'),
    }),
    yearly: yup.object().shape({
      breed: yup.string().required('जात लेख्नुहोस'),
      area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
      productionQuantity: yup.string().required('हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस'),
      seedProduction: yup.string().required('बिउ उत्पादन लेख्नुहोस'),
    }),
    allTime: yup.object().shape({
      breed: yup.string().required('जात लेख्नुहोस'),
      area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
      productionQuantity: yup.string().required('हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस'),
      seedProduction: yup.string().required('बिउ उत्पादन लेख्नुहोस'),
    }),
    grass: yup.object().shape({
      breed: yup.string().required('जात लेख्नुहोस'),
      area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
      productionQuantity: yup.string().required('हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस'),
      seedProduction: yup.string().required('बिउ उत्पादन लेख्नुहोस'),
    }),
    nursery: yup.object().shape({
      breed: yup.string().required('जात लेख्नुहोस'),
      area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
      productionQuantity: yup.string().required('हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस'),
      seedProduction: yup.string().required('बिउ उत्पादन लेख्नुहोस'),
    }),
  }),
  industryDetails: yup.object().shape({
    registrationNumber: yup.string().required('दर्ता नम्बर लेख्नुहोस'),
    registrationDate: yup.string().required('दर्ता मिति लेख्नुहोस'),
    name: yup.string().required('व्यावसाय / फर्म / उधमको नाम लेख्नुहोस'),
    registeredOffice: yup.string().required('दर्ता भएको कार्यालय लेख्नुहोस'),
    panVatNumber: yup.string().required('प्यान / भ्याट नं. लेख्नुहोस '),
    chairmanName: yup.string().required('अध्यक्ष/संचालकको नाम लेख्नुहोस'),
    type: yup.string().required('व्यावसायको किसिम छान्नुहोस'),
    memberCount: yup.string().required('सदस्य संख्या लेख्नुहोस'),
    annualJobCount: yup.string().required('बार्षिक रोजगारी सृजना संख्या लेख्नुहोस'),
  }),
})

export const machinerySchema = yup.object({
  machinery: yup.object().shape({
    name: yup.string().required('मेसिनरीको नाम लेख्नुहोस'),
    ownership: yup.string().required('स्वामित्व लेख्नुहोस'),
    capacity: yup.number().required('क्षमता लेख्नुहोस').typeError('क्षमता लेख्नुहोस'),
    count: yup.number().required('संख्या लेख्नुहोस').typeError('संख्या लेख्नुहोस'),
  }),
})

export const buildingSchema = yup.object({
  building: yup.object().shape({
    type: yup.string().required('संरचनाको प्रकार लेख्नुहोस'),
    count: yup.number().required('संख्या लेख्नुहोस').typeError('संख्या लेख्नुहोस'),
    area: yup.string().required('क्षेत्रफल लेख्नुहोस'),
  }),
})
