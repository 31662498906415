import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  useToast,
} from "@chakra-ui/react";
import endPoint from "@config/endpoint/news";
import { newsSchema } from "@config/schema/news/news.schema";
import newsLabel from "@data/localization/news";
import notificationLabel, {
  generateMessage,
} from "@data/localization/notification";
import utilsLabel from "@data/localization/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import useAPI from "@hooks/useApi";
import useLang from "@hooks/useLang";
import { IResponse } from "@interface/response.interface";
import NewsModel from "@models/news/news.model";
import NepaliInput from "@ui/common/atoms/NepaliInput";
import RequireSign from "@ui/common/atoms/RequireSign";
import TextEditor from "@ui/common/atoms/TexEditor";
import FileUpload from "@ui/common/molecules/FileUpload";
import Modal from "@ui/common/molecules/Modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

interface IProps {
  open: boolean;
  setOpen: (data: boolean) => void;
  values: IResponse<NewsModel[]> | null;
  setValues: (data: IResponse<NewsModel[]>) => void;
  selectedValue: NewsModel | null;
  setSelectedValue: (data: NewsModel | null) => void;
}

interface ICategory {
  id: string;
  title: {
    en: string;
    ne: string;
  };
  description: {
    en: string;
    ne: string;
  };
  media: string;
  category: any;
  isPublished: boolean;
}

const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props;
  const { post, put, get } = useAPI<NewsModel>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const [categoryList, setCategoryList] = useState<ICategory[] | null>(null);
  const { lang } = useLang();

  useEffect(() => {
    if (open) void handleFetchCategory();
  }, [open]);

  const handleFetchCategory = async () => {
    const res: any = await get(`${endPoint?.newsCategory}/all`, {
      search: "",
    });
    if (res?.data?.length > 0) {
      setCategoryList(res?.data);
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
    trigger,
  } = useForm<ICategory>({
    resolver: yupResolver(newsSchema),
    defaultValues: {
      title: {
        en: "",
        ne: "",
      },
      description: {
        en: "",
        ne: "",
      },
      category: "",
      media: "",
      isPublished: true,
    },
  });
  useEffect(() => {
    if (selectedValue) {
      reset({
        title: {
          en: selectedValue?.title?.en ?? "",
          ne: selectedValue?.title?.ne ?? "",
        },
        description: {
          en: selectedValue?.title?.en ?? "",
          ne: selectedValue?.title?.ne ?? "",
        },
        category: selectedValue?.category?.id ?? "",
      });
    }
  }, [selectedValue]);

  const onSubmit = async (data: ICategory) => {
    try {
      let res;
      if (selectedValue) {
        res = await put(endPoint?.news, {
          id: selectedValue?.id?.toString(),
          title: data.title,
          description: data.description,
          isPublished: data.isPublished,
          media: data.media,
          category: data?.category,
        });
      } else {
        res = await post(endPoint?.news, {
          title: data.title,
          description: data.description,
          isPublished: data.isPublished,
          media: data.media,
          category: data?.category,
        });
      }
      if (res?.data) {
        await queryClient.invalidateQueries("news");

        toast.closeAll();
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.news[lang],
            selectedValue
              ? notificationLabel?.updated[lang]
              : notificationLabel?.added[lang],
            lang
          ),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else if (res?.error) {
        toast.closeAll();
        toast({
          title: notificationLabel?.success[lang],
          description: res?.error?.message ?? notificationLabel?.added[lang],
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      console.log(err);
      toast.closeAll();
      toast({
        title: notificationLabel?.success[lang],
        description: err?.message ?? notificationLabel?.added[lang],
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setOpen(false);
      reset({
        title: {
          en: "",
        },
        media: "",
        category: "",
      });
      setSelectedValue(null);
    }
  };
  useEffect(() => {
    if (selectedValue) {
      setOpen(true);
    }
  }, [selectedValue]);

  return (
    <div>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        title="Add news"
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: "row",
                  base: "column",
                }}
                justifyContent="space-between"
                gap="5"
              >
                <FormControl isInvalid={errors.title?.en !== null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.title?.en != null ? "error" : ""}
                    type="text"
                    {...register("title.en")}
                    placeholder={"Enter title name"}
                  />
                  <FormErrorMessage>
                    {errors?.title?.en?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={"शिर्षक लेख्नुहोस |"}
                    handleChange={async (e: string) => {
                      setValue("title.ne", e);
                      if (e.length > 0) await trigger("title.ne");
                    }}
                    value={watch("title.ne")}
                  />
                  <FormErrorMessage>
                    {errors?.title?.ne?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex
                direction={{
                  base: "column",
                }}
                justifyContent="space-between"
                gap="5"
                mt="5"
              >
                <FormControl isInvalid={errors.description?.en != null}>
                  <FormLabel>
                    Description - english <RequireSign />
                  </FormLabel>
                  <TextEditor
                    height={300}
                    error={errors?.description?.en != null}
                    placeholder={"Enter news descriptions"}
                    handleChange={(e: string) => {
                      setValue("description.en", e);
                    }}
                    value={selectedValue?.description?.en ?? ""}
                  />
                  <FormErrorMessage>
                    {errors?.description?.en?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.description?.ne != null}>
                  <FormLabel>
                    विवरण - नेपाली <RequireSign />
                  </FormLabel>
                  <TextEditor
                    height={300}
                    error={errors?.description?.ne != null}
                    placeholder={"विवरण लेख्नुहोस |"}
                    handleChange={(e: string) => {
                      setValue("description.ne", e);
                    }}
                    value={selectedValue?.description?.ne ?? ""}
                  />
                  <FormErrorMessage>
                    {errors?.description?.ne?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex
                direction={{
                  md: "row",
                  base: "column",
                }}
                justifyContent="space-between"
                gap="5"
                mt="5"
              >
                <FormControl pt="5" isInvalid={errors.media != null}>
                  <FormLabel>
                    {newsLabel?.selectCoverImage[lang]} <RequireSign />
                  </FormLabel>
                  <FileUpload
                    selectFormat={`.png, .jpg, .jpeg`}
                    selectedId={selectedValue?.media?.id}
                    onChange={(data: string) => {
                      setValue("media", data);
                    }}
                    selectedFile={selectedValue?.media?.medias?.[0]?.name ?? ""}
                    relatedTo="News"
                  />
                  <FormErrorMessage>{errors?.media?.message}</FormErrorMessage>
                </FormControl>
                <FormControl pt="5" isInvalid={errors.media != null}>
                  <Flex my="4" alignItems="center">
                    <Checkbox
                      {...register("isPublished")}
                      defaultChecked={selectedValue?.isPublished ?? false}
                    >
                      {newsLabel?.isPublished[lang]}
                    </Checkbox>
                  </Flex>
                </FormControl>
              </Flex>

              <Box pt="5">
                <FormControl isInvalid={errors.category != null}>
                  <FormLabel>
                    {newsLabel?.selectNewsCategory[lang]} <RequireSign />
                    <FormErrorMessage>
                      {errors?.category?.message as string}
                    </FormErrorMessage>
                  </FormLabel>
                  <RadioGroup
                    defaultValue={selectedValue?.category?.id?.toString()}
                  >
                    <Flex wrap={"wrap"} gap="4">
                      {categoryList &&
                        categoryList?.length > 0 &&
                        categoryList?.map((item: any, index: number) => {
                          return (
                            <Radio
                              value={item?.id?.toString()}
                              {...register("category")}
                              key={index}
                              colorScheme="facebook"
                            >
                              {item?.title[lang]}
                            </Radio>
                          );
                        })}
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Flex my="5" gap="5" justifyContent={"flex-end"}>
                <Button
                  size="sm"
                  variant={"outline"}
                  colorScheme="red"
                  onClick={() => setOpen(false)}
                >
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size="sm"
                  variant={"primary"}
                >
                  {selectedValue
                    ? utilsLabel?.update[lang]
                    : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </div>
  );
};

export default Add;
