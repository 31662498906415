type Label = Record<string, Record<string, string>>

const labels: Label = {
  backendIsNotWorking: {
    en: 'Backend is not working !',
    ne: 'ब्याकएन्डले काम गरेको छैन !',
  },
  pleaseRestartBackendServer: {
    en: 'Please restart backend server',
    ne: 'कृपया ब्याकएन्ड सर्भर पुनः सुरु गर्नुहोस्',
  },
  noData: {
    en: 'No data',
    ne: 'कुनै डाटा छैन',
  },
  pleaseTryAgainLater: {
    en: 'Please try again later',
    ne: 'कृपया थप पछि पुन: प्रयास गर्नुहोस्',
  },
  goBack: {
    en: 'Go Back',
    ne: 'फिर्ता जानुहोस्',
  },
  welcome: {
    en: 'Welcome',
    ne: 'स्वागत छ',
  },
  roleLevel: {
    en: 'Role Level',
    ne: 'भूमिका स्तर',
  },
  logout: {
    en: 'Logout',
    ne: 'लगआउट',
  },
  help: {
    en: 'Help',
    ne: 'सहयोग',
  },
  add: {
    en: 'Add',
    ne: 'थप्नुहोस्',
  },
  submit: {
    en: 'Submit',
    ne: 'पेश गर्नुहोस्',
  },
  cancel: {
    en: 'Cancel',
    ne: 'रद्द गर्नुहोस्',
  },
  update: {
    en: 'Update',
    ne: 'अपडेट गर्नुहोस्',
  },
  submitting: {
    en: 'Submitting...',
    ne: 'पेश गर्दै...',
  },
  confirm: {
    en: 'Confirm',
    ne: 'पुष्टि गर्नुहोस्',
  },
  confirming: {
    en: 'Confirming...',
    ne: 'पुष्टि गर्दै...',
  },
  saveAndAddAnotherTopic: {
    en: 'Save and add another topic',
    ne: 'बचत गर्नुहोस् र अर्को विषय थप्नुहोस्',
  },
  addMore: {
    en: 'Add more',
    ne: 'थप थप्नुहोस्',
  },
  delete: {
    en: 'Delete',
    ne: 'मेटाउनुहोस्',
  },
}
export default labels
