import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import endPoint from "@config/endpoint/news";
import { newsCategory } from "@config/schema/news/newsCategory.schema";
import newsLabel from "@data/localization/news";
import notificationLabel, {
  generateMessage,
} from "@data/localization/notification";
import utilsLabel from "@data/localization/utils";
import { getMessage } from "@functions/generateMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import useAPI from "@hooks/useApi";
import useLang from "@hooks/useLang";
import { IResponse } from "@interface/response.interface";
import AgricultureType from "@models/agricultureType.model";
import NewsCategoryModel from "@models/news/category.model";
import NepaliInput from "@ui/common/atoms/NepaliInput";
import RequireSign from "@ui/common/atoms/RequireSign";
import Modal from "@ui/common/molecules/Modal";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

interface IProps {
  open: boolean;
  setOpen: (data: boolean) => void;
  values: IResponse<NewsCategoryModel[]> | null;
  setValues: (data: IResponse<NewsCategoryModel[]>) => void;
  selectedValue: NewsCategoryModel | null;
  setSelectedValue: (data: NewsCategoryModel | null) => void;
}

interface ICategory {
  id?: string;
  title: {
    en: string;
    ne: string;
  };
}

const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props;
  const { post, put } = useAPI<AgricultureType>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { lang } = useLang();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<ICategory>({
    resolver: yupResolver(newsCategory),
    defaultValues: {
      title: {
        en: "",
        ne: "",
      },
    },
    reValidateMode: "onSubmit",
  });
  useEffect(() => {
    if (selectedValue) {
      reset({
        title: {
          en: selectedValue?.title?.en ?? "",
          ne: selectedValue?.title?.ne ?? "",
        },
      });
    }
  }, [selectedValue]);

  const onSubmit = async (data: any) => {
    try {
      let res;
      if (selectedValue) {
        res = await put(endPoint?.newsCategory, {
          id: selectedValue?.id?.toString(),
          title: data.title,
          media: data.media,
          category: data.category,
        });
      } else {
        res = await post(endPoint?.newsCategory, {
          title: data.title,
          media: data.media,
          category: data.category,
        });
      }
      if (res?.data) {
        await queryClient.invalidateQueries("newsCategory");

        toast.closeAll();
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.newsCategory[lang],
            selectedValue
              ? notificationLabel?.updated[lang]
              : notificationLabel?.added[lang],
            lang
          ),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else if (res?.error) {
        toast.closeAll();
        toast({
          title: notificationLabel?.error[lang],
          description:
            res?.error?.message ??
            notificationLabel?.somethingWrongHappen[lang],
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      console.log(err);
      toast.closeAll();
      toast({
        title: notificationLabel?.error[lang],
        description:
          err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setOpen(false);
      reset({
        title: {
          en: "",
        },
      });
      setSelectedValue(null);
    }
  };
  useEffect(() => {
    if (selectedValue) {
      setOpen(true);
    }
  }, [selectedValue]);

  return (
    <div>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(newsLabel?.newsCategory[lang], "add", lang)}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: "row",
                  base: "column",
                }}
                justifyContent="space-between"
                gap="5"
              >
                <FormControl isInvalid={errors.title?.en != null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.title?.en != null ? "error" : ""}
                    type="text"
                    {...register("title.en")}
                    placeholder={"Enter title name"}
                  />
                  <FormErrorMessage>
                    {errors?.title?.en?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={"शिर्षक लेख्नुहोस |"}
                    handleChange={(e: string) => {
                      setValue("title.ne", e);
                    }}
                    value={watch("title.ne")}
                  />
                  <FormErrorMessage>
                    {errors?.title?.ne?.message}
                  </FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex my="5" gap="5" justifyContent={"flex-end"}>
                <Button
                  size="sm"
                  variant={"outline"}
                  colorScheme="red"
                  onClick={() => setOpen(false)}
                >
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size="sm"
                  variant={"primary"}
                >
                  {selectedValue
                    ? utilsLabel?.update[lang]
                    : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </div>
  );
};

export default Add;
