const HOME_LABELS = {
  home: {
    en: 'Home',
    ne: 'घर',
  },
  commercialAgriculture: {
    en: 'Commercial Agriculture',
    ne: 'व्यावसायिक कृषि',
  },
  news: {
    en: 'News',
    ne: 'समाचार',
  },
  videoGallery: {
    en: 'Video Gallery',
    ne: 'भिडियो ग्यालेरी',
  },
  imageGallery: {
    en: 'Image Gallery',
    ne: 'चित्र ग्यालेरी',
  },
};
export default HOME_LABELS as any;
