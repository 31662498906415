import Language from '@models/language.model'
import { plainToClass } from 'class-transformer'
import Member from './groupMember'
class AgricultureGroup {
  id: string
  groupName: Language
  establishDate: Date
  villageName: string
  description: string
  ward: number
  tole: string
  purpose: string
  population: {
    male: number
    female: number
    others: number
  }

  officeWard: number
  officeName: string
  createdAt: Date
  members: Member[]

  constructor(
    id: string,
    groupName: Language,
    establishDate: Date,
    villageName: string,
    description: string,
    ward: number,
    tole: string,
    purpose: string,
    population: {
      male: number
      female: number
      others: number
    },

    officeWard: number,
    officeName: string,
    createdAt: Date,
    members: Member[]
  ) {
    this.id = id
    this.groupName = groupName
    this.establishDate = establishDate
    this.villageName = villageName
    this.description = description
    this.ward = ward
    this.tole = tole
    this.purpose = purpose
    this.population = population
    this.officeWard = officeWard
    this.officeName = officeName
    this.createdAt = createdAt
    this.members = members
  }

  static plainToInstance(plainData: object): AgricultureGroup {
    return plainToClass(AgricultureGroup, plainData)
  }

  static plainToInstances(plainData: object[]): AgricultureGroup[] {
    return plainToClass(AgricultureGroup, plainData)
  }
}
export default AgricultureGroup
