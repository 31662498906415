import { Alert, AlertDescription, AlertIcon, AlertTitle, Box } from '@chakra-ui/react'
import labels from '@data/localization/utils'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { useEffect, useState } from 'react'

const Ping = () => {
  const { lang } = useLang()
  const [error, setError] = useState<boolean>(true)
  const { get } = useAPI()

  useEffect(() => {
    void checkBackend()
  }, [])

  const checkBackend = async () => {
    const response = await get('/ping')
    if (response) {
      setError(false)
    }
  }

  return (
    <Box>
      {error && (
        <Alert status='error' mt='2' mb='4'>
          <AlertIcon />
          <AlertTitle>{labels?.backendIsNotWorking[lang]}</AlertTitle>
          <AlertDescription>{labels?.pleaseRestartBackendServer[lang]}</AlertDescription>
        </Alert>
      )}
    </Box>
  )
}

export default Ping
