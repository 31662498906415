type Label = Record<string, Record<string, string>>

const labels: Label = {
  areYouSureToLogout: {
    en: 'Are you sure you want to logout?',
    ne: 'के तपाईं लगआउट गर्न चाहानुहुन्छ?',
  },
  areYouSureToDelete: {
    en: 'Are you sure you want to delete?',
    ne: 'के तपाईं हटाउन चाहानुहुन्छ?',
  },
}
export default labels
