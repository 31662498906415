import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  useToast,
} from '@chakra-ui/react'
import endpoint from '@config/endpoint/farmer'
import {
  beeSchema,
  cropsSchema,
  foodCropsSchema,
  fruitsCropsSchema,
  mushroomSchema,
  seedsSchema,
  silkSchema,
} from '@config/schema/farmer/Registration.schema'
import { handleDigitKeyPress } from '@functions/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import FarmerCropsInformation from '@models/farmer/farmerCropsInformation.model'
import RequireSign from '@ui/common/atoms/RequireSign'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiEdit } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'

interface IProps {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  farmerId: string | undefined
}

const FarmerCropsInformationForm = ({ step, setStep, farmerId }: IProps) => {
  const { post, patch, put, getById } = useAPI<FarmerCropsInformation>()
  const toast = useToast()
  const [selectedValue, setSelectedValue] = useState<FarmerCropsInformation | null>(null)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(cropsSchema),
    defaultValues: {
      foodCrops: {
        name: '',
        area: '',
        productionQuantity: '',
      },
      fruitsCrops: {
        name: '',
        plantCount: '',
        area: '',
        productionQuantity: '',
      },
      seeds: {
        crops: '',
        type: '',
        area: '',
        productionQuantity: '',
      },
      mushroom: {
        type: '',
        tunnelCount: '',
        area: '',
        productionQuantity: '',
      },
      silk: {
        area: '',
        speciesOfInsects: '',
        insectsCount: '',
      },
      bee: {
        type: '',
        numberOfHives: '',
        productionQuantity: '',
      },
    },
  })

  const onSubmit = async (data: any) => {
    try {
      let res
      if (selectedValue) {
        res = await put(endpoint?.farmerCropsInformation, {
          id: selectedValue?.id?.toString(),
          foodCrops: cropsDetails ?? undefined,
          fruitsCrops: fruitsDetails ?? undefined,
          seeds: seedsDetails ?? undefined,
          mushroom: mushroomDetails ?? undefined,
          silk: silkDetails ?? undefined,
          bee: beeDetails ?? undefined,
        })
      } else {
        res = await post(endpoint?.farmerCropsInformation, {
          farmerId,
          foodCrops: cropsDetails ?? undefined,
          fruitsCrops: fruitsDetails ?? undefined,
          seeds: seedsDetails ?? undefined,
          mushroom: mushroomDetails ?? undefined,
          silk: silkDetails ?? undefined,
          bee: beeDetails ?? undefined,
        })
      }
      if (res?.data) {
        toast.closeAll()
        toast({
          title: 'सफल भयो',
          description: `बाली उत्पादन विवरण सफलतापूर्वक ${selectedValue ? 'अपडेट' : 'सुरक्षित'} गरियो`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        setStep(step + 1)
        reset({
          foodCrops: {
            name: '',
            area: '',
            productionQuantity: '',
          },
          fruitsCrops: {
            name: '',
            plantCount: '',
            area: '',
            productionQuantity: '',
          },
          seeds: {
            crops: '',
            type: '',
            area: '',
            productionQuantity: '',
          },
          mushroom: {
            type: '',
            tunnelCount: '',
            area: '',
            productionQuantity: '',
          },
          silk: {
            area: '',
            speciesOfInsects: '',
            insectsCount: '',
          },
          bee: {
            type: '',
            numberOfHives: '',
            productionQuantity: '',
          },
        })
        setSelectedValue(null)
      } else {
        toast.closeAll()
        toast({
          title: 'गलत भयो',
          description: res?.error?.response?.data?.message ?? 'केहि गलत भयो',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: 'गलत भयो',
        description: err?.message ?? 'केहि गलत भयो',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  interface ICrops {
    name: string
    area: string
    productionQuantity: number
  }

  const [cropsDetails, setCropsDetails] = useState<ICrops[] | null>(null)
  const [selectedCropsDetails, setSelectedCropsDetails] = useState<ICrops | null>(null)

  const addCropsDetails = () => {
    const newCropsDetails = {
      name: watch('foodCrops.name'),
      area: watch('foodCrops.area'),
      productionQuantity: +watch('foodCrops.productionQuantity'),
    }

    if (cropsDetails) setCropsDetails([...cropsDetails, newCropsDetails])
    else setCropsDetails([newCropsDetails])

    reset({
      foodCrops: {
        name: '',
        area: '',
        productionQuantity: '',
      },
    })
  }

  const removeCropsDetails = (index: number) => {
    if (cropsDetails) {
      const data = cropsDetails?.filter((_, i: number) => i !== index)
      setCropsDetails(data)
    }
  }

  const editCropsDetails = (index: number) => {
    if (cropsDetails) {
      removeCropsDetails(index)
      const data = cropsDetails?.find((_, i: number) => i === index)
      if (data) setSelectedCropsDetails(data)
    }
  }

  useEffect(() => {
    if (selectedCropsDetails) {
      reset({
        foodCrops: {
          name: selectedCropsDetails.name,
          area: selectedCropsDetails.area,
          productionQuantity: selectedCropsDetails.productionQuantity?.toString(),
        },
      })
    }
  }, [selectedCropsDetails])

  interface IFruitsCrops {
    name: string
    plantCount: number
    area: string
    productionQuantity: number
  }

  const [fruitsDetails, setFruitsDetails] = useState<IFruitsCrops[] | null>(null)
  const [selectedFruitsDetails, setSelectedFruitsDetails] = useState<IFruitsCrops | null>(null)

  const addFruitsCrops = () => {
    const newFruitsCrops = {
      name: watch('fruitsCrops.name'),
      plantCount: +watch('fruitsCrops.plantCount'),
      area: watch('fruitsCrops.area'),
      productionQuantity: +watch('fruitsCrops.productionQuantity'),
    }

    if (fruitsDetails) setFruitsDetails([...fruitsDetails, newFruitsCrops])
    else setFruitsDetails([newFruitsCrops])

    reset({
      fruitsCrops: {
        name: '',
        plantCount: '',
        area: '',
        productionQuantity: '',
      },
    })
  }

  const removeFruitsCrops = (index: number) => {
    if (fruitsDetails) {
      const data = fruitsDetails?.filter((_, i: number) => i !== index)
      setFruitsDetails(data)
    }
  }

  const editFruitsCrops = (index: number) => {
    if (fruitsDetails) {
      removeFruitsCrops(index)
      const data = fruitsDetails?.find((_, i: number) => i === index)
      if (data) setSelectedFruitsDetails(data)
    }
  }

  useEffect(() => {
    if (selectedFruitsDetails) {
      reset({
        fruitsCrops: {
          name: selectedFruitsDetails.name,
          plantCount: selectedFruitsDetails.plantCount?.toString(),
          area: selectedFruitsDetails.area,
          productionQuantity: selectedFruitsDetails.productionQuantity?.toString(),
        },
      })
    }
  }, [selectedFruitsDetails])

  interface ISeeds {
    crops: string
    type: string
    area: string
    productionQuantity: number
  }

  const [seedsDetails, setSeedsDetails] = useState<ISeeds[] | null>(null)
  const [selectedSeedsDetails, setSelectedSeedsDetails] = useState<ISeeds | null>(null)

  const addSeedsDetails = () => {
    const newSeedsDetail = {
      crops: watch('seeds.crops'),
      type: watch('seeds.type'),
      area: watch('seeds.area'),
      productionQuantity: +watch('seeds.productionQuantity'),
    }

    if (seedsDetails) setSeedsDetails([...seedsDetails, newSeedsDetail])
    else setSeedsDetails([newSeedsDetail])

    reset({
      seeds: {
        crops: '',
        type: '',
        area: '',
        productionQuantity: '',
      },
    })
  }

  const removeSeedsDetails = (index: number) => {
    if (seedsDetails) {
      const data = seedsDetails?.filter((_, i: number) => i !== index)
      setSeedsDetails(data)
    }
  }

  const editSeedsDetails = (index: number) => {
    if (seedsDetails) {
      removeSeedsDetails(index)
      const data = seedsDetails?.find((_, i: number) => i === index)
      if (data) setSelectedSeedsDetails(data)
    }
  }

  useEffect(() => {
    if (selectedSeedsDetails) {
      reset({
        seeds: {
          crops: selectedSeedsDetails?.crops,
          type: selectedSeedsDetails?.type,
          area: selectedSeedsDetails?.area,
          productionQuantity: selectedSeedsDetails?.productionQuantity?.toString(),
        },
      })
    }
  }, [selectedSeedsDetails])

  interface IMushroom {
    type: string
    tunnelCount: number
    area: string
    productionQuantity: number
  }

  const [mushroomDetails, setMushroomDetails] = useState<IMushroom[] | null>(null)
  const [selectedMushroomDetails, setSelectedMushroomDetails] = useState<IMushroom | null>(null)

  const addMushroomDetails = () => {
    const newMushroomDetails = {
      type: watch('mushroom.type'),
      tunnelCount: +watch('mushroom.tunnelCount'),
      area: watch('mushroom.area'),
      productionQuantity: +watch('mushroom.productionQuantity'),
    }

    if (mushroomDetails) setMushroomDetails([...mushroomDetails, newMushroomDetails])
    else setMushroomDetails([newMushroomDetails])

    reset({
      mushroom: {
        type: '',
        tunnelCount: '',
        area: '',
        productionQuantity: '',
      },
    })
  }

  const removeMushroomDetails = (index: number) => {
    if (mushroomDetails) {
      const data = mushroomDetails?.filter((_, i: number) => i !== index)
      setMushroomDetails(data)
    }
  }

  const editMushroomDetails = (index: number) => {
    if (mushroomDetails) {
      removeMushroomDetails(index)
      const data = mushroomDetails?.find((_, i: number) => i === index)
      if (data) setSelectedMushroomDetails(data)
    }
  }

  useEffect(() => {
    if (selectedMushroomDetails) {
      reset({
        mushroom: {
          type: selectedMushroomDetails.type,
          tunnelCount: selectedMushroomDetails.tunnelCount?.toString(),
          area: selectedMushroomDetails.area,
          productionQuantity: selectedMushroomDetails.productionQuantity?.toString(),
        },
      })
    }
  }, [selectedMushroomDetails])

  interface Isilk {
    area: string
    speciesOfInsects: string
    insectsCount: number
  }

  const [silkDetails, setSilkDetails] = useState<Isilk[] | null>(null)
  const [selectedSilkDetails, setSelectedSilkDetails] = useState<Isilk | null>(null)

  const addSilkDetails = () => {
    const newSilkDetails = {
      area: watch('silk.area'),
      speciesOfInsects: watch('silk.speciesOfInsects'),
      insectsCount: +watch('silk.insectsCount'),
    }

    if (silkDetails) setSilkDetails([...silkDetails, newSilkDetails])
    else setSilkDetails([newSilkDetails])

    reset({
      silk: {
        area: '',
        speciesOfInsects: '',
        insectsCount: '',
      },
    })
  }

  const removeSilkDetails = (index: number) => {
    if (silkDetails) {
      const data = silkDetails?.filter((_, i: number) => i !== index)
      setSilkDetails(data)
    }
  }

  const editSilkDetails = (index: number) => {
    if (silkDetails) {
      removeSilkDetails(index)
      const data = silkDetails?.find((_, i: number) => i === index)
      if (data) setSelectedSilkDetails(data)
    }
  }

  useEffect(() => {
    if (selectedSilkDetails) {
      reset({
        silk: {
          area: selectedSilkDetails.area,
          speciesOfInsects: selectedSilkDetails.speciesOfInsects,
          insectsCount: selectedSilkDetails.insectsCount?.toString(),
        },
      })
    }
  }, [selectedSilkDetails])

  interface IBee {
    type: string
    numberOfHives: number
    productionQuantity: number
  }

  const [beeDetails, setBeeDetails] = useState<IBee[] | null>(null)
  const [selectedBeeDetails, setSelectedBeeDetails] = useState<IBee | null>(null)

  const addBeeDetails = () => {
    const newBeeDetails = {
      type: watch('bee.type'),
      numberOfHives: +watch('bee.numberOfHives'),
      productionQuantity: +watch('bee.productionQuantity'),
    }

    if (beeDetails) setBeeDetails([...beeDetails, newBeeDetails])
    else setBeeDetails([newBeeDetails])

    reset({
      bee: {
        type: '',
        numberOfHives: '',
        productionQuantity: '',
      },
    })
  }

  const removeBeeDetails = (index: number) => {
    if (beeDetails) {
      const data = beeDetails?.filter((_, i: number) => i !== index)
      setBeeDetails(data)
    }
  }

  const editBeeDetails = (index: number) => {
    if (beeDetails) {
      removeBeeDetails(index)
      const data = beeDetails?.find((_, i: number) => i === index)
      if (data) setSelectedBeeDetails(data)
    }
  }

  useEffect(() => {
    if (selectedBeeDetails) {
      reset({
        bee: {
          type: selectedBeeDetails.type,
          numberOfHives: selectedBeeDetails.numberOfHives?.toString(),
          productionQuantity: selectedBeeDetails.productionQuantity?.toString(),
        },
      })
    }
  }, [selectedBeeDetails])

  const getFarmerCropsInformation = async () => {
    try {
      const res = await getById(endpoint?.farmerCropsInformation + '/farmer', farmerId as string)
      if (res) {
        setSelectedValue(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (farmerId) {
      void getFarmerCropsInformation()
    }
  }, [])

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue?.foodCrops) setCropsDetails(selectedValue?.foodCrops)
      if (selectedValue?.fruitsCrops) setFruitsDetails(selectedValue?.fruitsCrops)
      if (selectedValue?.seeds) setSeedsDetails(selectedValue?.seeds)
      if (selectedValue?.mushroom) setMushroomDetails(selectedValue?.mushroom)
      if (selectedValue?.silk) setSilkDetails(selectedValue?.silk)
      if (selectedValue?.bee) setBeeDetails(selectedValue?.bee)
    }
  }, [selectedValue])

  // fake filler function
  const handleFakeFiller = async () => {
    setCropsDetails([
      { name: 'मकै', area: '231', productionQuantity: 100 },
      { name: 'धान', area: '91', productionQuantity: 212 },
    ])
    setFruitsDetails([
      {
        name: 'धान',
        plantCount: 231,
        area: '91',
        productionQuantity: 109,
      },
      {
        name: 'गहु',
        plantCount: 141,
        area: '123',
        productionQuantity: 1219,
      },
      {
        name: 'मकै',
        plantCount: 991,
        area: '123',
        productionQuantity: 9812,
      },
    ])
    setSeedsDetails([
      {
        crops: 'बिऊ',
        type: 'उन्नत',
        area: '1808',
        productionQuantity: 10123,
      },
      {
        crops: 'बेर्ना',
        type: 'उन्नत',
        area: '21312',
        productionQuantity: 112,
      },
      {
        crops: 'नर्सरी',
        type: 'घरेलु',
        area: '123',
        productionQuantity: 991,
      },
    ])
    setMushroomDetails([
      {
        type: 'घरेलु',
        tunnelCount: 122,
        area: '98',
        productionQuantity: 1329,
      },
      {
        type: 'उन्नत',
        tunnelCount: 412,
        area: '113',
        productionQuantity: 991,
      },
    ])
    setSilkDetails([
      {
        area: '981',
        speciesOfInsects: 'रेशम',
        insectsCount: 981,
      },
      {
        area: '87',
        speciesOfInsects: 'किरा',
        insectsCount: 145,
      },
    ])
    setBeeDetails([
      {
        type: 'उन्नत',
        numberOfHives: 19,
        productionQuantity: 100,
      },
      {
        type: 'काठे',
        numberOfHives: 349,
        productionQuantity: 180,
      },
    ])
  }

  return (
    <Box>
      {/* remove this fake filler on production */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleFakeFiller} variant={'outline'} size='md' colorScheme='purple'>
          Fake filler
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading pb='3' size='md' mt='2'>
          बाली उत्पादन विवरण
        </Heading>
        <Box>
          <Box p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <FormLabel>
              १. खाधन्न बाली, तरकारि बाली, दलहनबाली,औधोगीक बाली, मसलाबाली, पुष्पखेती <RequireSign />
            </FormLabel>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '50px',
                    }}
                  >
                    क्र.स.
                  </th>
                  <th>बालीको नाम</th>
                  <th>क्षेत्रफल</th>
                  <th>उत्पादन परिमाण (के.जि) मा</th>
                  <th
                    style={{
                      width: '150px',
                    }}
                  >
                    कार्य
                  </th>
                </tr>
              </thead>
              <tbody>
                {cropsDetails &&
                  cropsDetails?.length > 0 &&
                  cropsDetails?.map((i, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.name}</td>
                        <td>{i.area}</td>
                        <td>{i.productionQuantity}</td>
                        <td>
                          <Flex gap='4'>
                            <IconButton
                              colorScheme='telegram'
                              variant='outline'
                              size='xs'
                              aria-label='edit'
                              icon={<BiEdit />}
                              onClick={() => {
                                void editCropsDetails(index)
                              }}
                            />
                            <IconButton
                              colorScheme='red'
                              variant='outline'
                              size='xs'
                              aria-label='delete'
                              icon={<BsTrash />}
                              onClick={() => {
                                void removeCropsDetails(index)
                              }}
                            />
                          </Flex>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
                mt: 2,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.foodCrops?.name != null}>
                <FormLabel>
                  १.१. बालीको नाम <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.foodCrops?.name != null ? 'error' : ''}
                  type='text'
                  {...register('foodCrops.name')}
                  placeholder={'बालीको नाम लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.foodCrops?.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.foodCrops?.area != null}>
                <FormLabel>
                  १.२. क्षेत्रफल <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.foodCrops?.area != null ? 'error' : ''}
                  type='text'
                  {...register('foodCrops.area')}
                  placeholder={'क्षेत्रफल लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.foodCrops?.area?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.foodCrops?.productionQuantity != null}>
                <FormLabel>
                  १.३. उत्पादन परिमाण (के.जि) मा <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.foodCrops?.productionQuantity != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('foodCrops.productionQuantity')}
                  placeholder={'उत्पादन परिमाण (के.जि) मा लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.foodCrops?.productionQuantity?.message}</FormErrorMessage>
              </FormControl>
              <Box mt='8'>
                <Button
                  size='sm'
                  variant={'outline'}
                  colorScheme='facebook'
                  onClick={async () => {
                    try {
                      await foodCropsSchema.validate(getValues(), { abortEarly: false })
                      void addCropsDetails()
                    } catch (validationErrors: any) {
                      if (validationErrors?.inner?.length) {
                        validationErrors.inner.forEach((error: any) => {
                          setError(error.path, { type: 'manual', message: error.message })
                        })
                      } else {
                        setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                      }
                    }
                  }}
                >
                  सुरक्षित गर्नुहोस
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <FormLabel>
              २. फलफुल बाली <RequireSign />
            </FormLabel>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '50px',
                    }}
                  >
                    क्र.स.
                  </th>
                  <th>बालीको नाम</th>
                  <th>बोटको संख्या</th>
                  <th>क्षेत्रफल</th>
                  <th>उत्पादन परिमाण (के.जि) मा</th>
                  <th
                    style={{
                      width: '150px',
                    }}
                  >
                    कार्य
                  </th>
                </tr>
              </thead>
              <tbody>
                {fruitsDetails &&
                  fruitsDetails?.length > 0 &&
                  fruitsDetails?.map((i, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.name}</td>
                        <td>{i.plantCount}</td>
                        <td>{i.area}</td>
                        <td>{i.productionQuantity}</td>
                        <td>
                          <Flex gap='4'>
                            <IconButton
                              colorScheme='telegram'
                              variant='outline'
                              size='xs'
                              aria-label='edit'
                              icon={<BiEdit />}
                              onClick={() => {
                                void editFruitsCrops(index)
                              }}
                            />
                            <IconButton
                              colorScheme='red'
                              variant='outline'
                              size='xs'
                              aria-label='delete'
                              icon={<BsTrash />}
                              onClick={() => {
                                void removeFruitsCrops(index)
                              }}
                            />
                          </Flex>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
                mt: 5,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.fruitsCrops?.name != null}>
                <FormLabel>
                  २.१. बालीको नाम <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.fruitsCrops?.name != null ? 'error' : ''}
                  type='text'
                  {...register('fruitsCrops.name')}
                  placeholder={'बालीको नाम लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.fruitsCrops?.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.fruitsCrops?.plantCount != null}>
                <FormLabel>
                  २.२. बोटको संख्या <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.fruitsCrops?.plantCount != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('fruitsCrops.plantCount')}
                  placeholder={'बोटको संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.fruitsCrops?.plantCount?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.fruitsCrops?.area != null}>
                <FormLabel>
                  २.३. क्षेत्रफल <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.fruitsCrops?.area != null ? 'error' : ''}
                  type='text'
                  {...register('fruitsCrops.area')}
                  placeholder={'क्षेत्रफल लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.fruitsCrops?.area?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.fruitsCrops?.productionQuantity != null}>
                <FormLabel>
                  २.४. उत्पादन परिमाण (के.जि) मा <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.fruitsCrops?.productionQuantity != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('fruitsCrops.productionQuantity')}
                  placeholder={'उत्पादन परिमाण (के.जि) मा लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.fruitsCrops?.productionQuantity?.message}</FormErrorMessage>
              </FormControl>
              <Box mt='8'>
                <Button
                  size='sm'
                  variant={'outline'}
                  colorScheme='facebook'
                  onClick={async () => {
                    try {
                      await fruitsCropsSchema.validate(getValues(), { abortEarly: false })
                      void addFruitsCrops()
                    } catch (validationErrors: any) {
                      if (validationErrors?.inner?.length) {
                        validationErrors.inner.forEach((error: any) => {
                          setError(error.path, { type: 'manual', message: error.message })
                        })
                      } else {
                        setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                      }
                    }
                  }}
                >
                  सुरक्षित गर्नुहोस
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <FormLabel>
              ३. बिउ / बेर्ना / नर्सरी <RequireSign />
            </FormLabel>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '50px',
                    }}
                  >
                    क्र.स.
                  </th>
                  <th>बाली</th>
                  <th>जात</th>
                  <th>क्षेत्रफल</th>
                  <th>उत्पादन परिमाण (के.जि) मा</th>
                  <th
                    style={{
                      width: '150px',
                    }}
                  >
                    कार्य
                  </th>
                </tr>
              </thead>
              <tbody>
                {seedsDetails &&
                  seedsDetails?.length > 0 &&
                  seedsDetails?.map((i, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.crops}</td>
                        <td>{i.type}</td>
                        <td>{i.area}</td>
                        <td>{i.productionQuantity}</td>
                        <td>
                          <Flex gap='4'>
                            <IconButton
                              colorScheme='telegram'
                              variant='outline'
                              size='xs'
                              aria-label='edit'
                              icon={<BiEdit />}
                              onClick={() => {
                                void editSeedsDetails(index)
                              }}
                            />
                            <IconButton
                              colorScheme='red'
                              variant='outline'
                              size='xs'
                              aria-label='delete'
                              icon={<BsTrash />}
                              onClick={() => {
                                void removeSeedsDetails(index)
                              }}
                            />
                          </Flex>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
                mt: 2,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.seeds?.crops != null}>
                <FormLabel>
                  ३.१. बाली <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.seeds?.crops != null ? 'error' : ''}
                  type='text'
                  {...register('seeds.crops')}
                  placeholder={'बालीको नाम लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.seeds?.crops?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.seeds?.type != null}>
                <FormLabel>
                  ३.२. जात <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.seeds?.type != null ? 'error' : ''}
                  type='text'
                  {...register('seeds.type')}
                  placeholder={'जात लेख्नुहोस'}
                />
                {/* <FormErrorMessage>{errors?.seeds?.type?.message}</FormErrorMessage> */}
              </FormControl>
              <FormControl isInvalid={errors?.seeds?.area != null}>
                <FormLabel>
                  ३.३. क्षेत्रफल <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.seeds?.area != null ? 'error' : ''}
                  type='text'
                  {...register('seeds.area')}
                  placeholder={'क्षेत्रफल लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.seeds?.area?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.seeds?.productionQuantity != null}>
                <FormLabel>
                  ३.४. उत्पादन परिमाण (के.जि) मा <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.seeds?.productionQuantity != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('seeds.productionQuantity')}
                  placeholder={'उत्पादन परिमाण (के.जि) मा लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.seeds?.productionQuantity?.message}</FormErrorMessage>
              </FormControl>
              <Box mt='8'>
                <Button
                  size='sm'
                  variant={'outline'}
                  colorScheme='facebook'
                  onClick={async () => {
                    try {
                      await seedsSchema.validate(getValues(), { abortEarly: false })
                      void addSeedsDetails()
                    } catch (validationErrors: any) {
                      if (validationErrors?.inner?.length) {
                        validationErrors.inner.forEach((error: any) => {
                          setError(error.path, { type: 'manual', message: error.message })
                        })
                      } else {
                        setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                      }
                    }
                  }}
                >
                  सुरक्षित गर्नुहोस
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <FormLabel>
              ४. च्याउ <RequireSign />
            </FormLabel>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '50px',
                    }}
                  >
                    क्र.स.
                  </th>
                  <th>च्याउको जात</th>
                  <th>टनेल संख्या</th>
                  <th>प्लाष्टिकघर क्षेत्रफल</th>
                  <th>उत्पादन परिमाण (के.जि) मा</th>
                  <th
                    style={{
                      width: '150px',
                    }}
                  >
                    कार्य
                  </th>
                </tr>
              </thead>
              <tbody>
                {mushroomDetails &&
                  mushroomDetails?.length > 0 &&
                  mushroomDetails?.map((i, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.type}</td>
                        <td>{i.tunnelCount}</td>
                        <td>{i.area}</td>
                        <td>{i.productionQuantity}</td>
                        <td>
                          <Flex gap='4'>
                            <IconButton
                              colorScheme='telegram'
                              variant='outline'
                              size='xs'
                              aria-label='edit'
                              icon={<BiEdit />}
                              onClick={() => {
                                void editMushroomDetails(index)
                              }}
                            />
                            <IconButton
                              colorScheme='red'
                              variant='outline'
                              size='xs'
                              aria-label='delete'
                              icon={<BsTrash />}
                              onClick={() => {
                                void removeMushroomDetails(index)
                              }}
                            />
                          </Flex>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
                mt: 2,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.mushroom?.type != null}>
                <FormLabel>
                  ४.१. च्याउको जात <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.mushroom?.type != null ? 'error' : ''}
                  type='text'
                  {...register('mushroom.type')}
                  placeholder={'च्याउको जात लेख्नुहोस'}
                />
                {/* <FormErrorMessage>{errors?.mushroom?.type?.message}</FormErrorMessage> */}
              </FormControl>
              <FormControl isInvalid={errors?.mushroom?.tunnelCount != null}>
                <FormLabel>
                  ४.२. टनेल संख्या <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.mushroom?.tunnelCount != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('mushroom.tunnelCount')}
                  placeholder={'टनेल संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.mushroom?.tunnelCount?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.mushroom?.area != null}>
                <FormLabel>
                  ४.३. प्लाष्टिकघर क्षेत्रफल <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.mushroom?.area != null ? 'error' : ''}
                  type='text'
                  {...register('mushroom.area')}
                  placeholder={'प्लाष्टिकघरको क्षेत्रफल लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.mushroom?.area?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.mushroom?.productionQuantity != null}>
                <FormLabel>
                  ४.४. उत्पादन परिमाण (के.जि) मा <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.mushroom?.productionQuantity != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('mushroom.productionQuantity')}
                  placeholder={'उत्पादन परिमाण (के.जि) मा लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.mushroom?.productionQuantity?.message}</FormErrorMessage>
              </FormControl>
              <Box mt='8'>
                <Button
                  size='sm'
                  variant={'outline'}
                  colorScheme='facebook'
                  onClick={async () => {
                    try {
                      await mushroomSchema.validate(getValues(), { abortEarly: false })
                      void addMushroomDetails()
                    } catch (validationErrors: any) {
                      if (validationErrors?.inner?.length) {
                        validationErrors.inner.forEach((error: any) => {
                          setError(error.path, { type: 'manual', message: error.message })
                        })
                      } else {
                        setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                      }
                    }
                  }}
                >
                  सुरक्षित गर्नुहोस
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <FormLabel>
              ५. रेशम <RequireSign />
            </FormLabel>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '50px',
                    }}
                  >
                    क्र.स.
                  </th>
                  <th>किम्बु लगाईएको क्षेत्रफल</th>
                  <th>रेशम कीराको जात</th>
                  <th>किरा संख्या</th>
                  <th
                    style={{
                      width: '150px',
                    }}
                  >
                    कार्य
                  </th>
                </tr>
              </thead>
              <tbody>
                {silkDetails &&
                  silkDetails?.length > 0 &&
                  silkDetails?.map((i, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.area}</td>
                        <td>{i.speciesOfInsects}</td>
                        <td>{i.insectsCount}</td>
                        <td>
                          <Flex gap='4'>
                            <IconButton
                              colorScheme='telegram'
                              variant='outline'
                              size='xs'
                              aria-label='edit'
                              icon={<BiEdit />}
                              onClick={() => {
                                void editSilkDetails(index)
                              }}
                            />
                            <IconButton
                              colorScheme='red'
                              variant='outline'
                              size='xs'
                              aria-label='delete'
                              icon={<BsTrash />}
                              onClick={() => {
                                void removeSilkDetails(index)
                              }}
                            />
                          </Flex>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(5, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
                mt: 2,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.silk?.area != null}>
                <FormLabel>
                  ५.१. किम्बु लगाईएको क्षेत्रफल <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.silk?.area != null ? 'error' : ''}
                  type='text'
                  {...register('silk.area')}
                  placeholder={'किम्बु लगाईएको क्षेत्रफल लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.silk?.area?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.silk?.speciesOfInsects != null}>
                <FormLabel>
                  ५.२. रेशम कीराको जात
                  <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.silk?.speciesOfInsects != null ? 'error' : ''}
                  type='text'
                  {...register('silk.speciesOfInsects')}
                  placeholder={'रेशम कीराको जात लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.silk?.speciesOfInsects?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.silk?.insectsCount != null}>
                <FormLabel>
                  ५.३. किरा संख्या <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.silk?.insectsCount != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('silk.insectsCount')}
                  placeholder={'किरा संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.silk?.insectsCount?.message}</FormErrorMessage>
              </FormControl>
              <Box mt='8'>
                <Button
                  size='sm'
                  variant={'outline'}
                  colorScheme='facebook'
                  onClick={async () => {
                    try {
                      await silkSchema.validate(getValues(), { abortEarly: false })
                      void addSilkDetails()
                    } catch (validationErrors: any) {
                      if (validationErrors?.inner?.length) {
                        validationErrors.inner.forEach((error: any) => {
                          setError(error.path, { type: 'manual', message: error.message })
                        })
                      } else {
                        setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                      }
                    }
                  }}
                >
                  सुरक्षित गर्नुहोस
                </Button>
              </Box>
            </Box>
          </Box>
          <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <FormLabel>
              ६. मौरी <RequireSign />
            </FormLabel>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      width: '50px',
                    }}
                  >
                    क्र.स.
                  </th>
                  <th>मौरीको जात</th>
                  <th>घारको संख्या</th>
                  <th>उत्पादन परिमाण (के.जि) मा (मह /घार / गोला / रोयल / जेली / प्रोपेलिस आदी)</th>
                  <th
                    style={{
                      width: '150px',
                    }}
                  >
                    कार्य
                  </th>
                </tr>
              </thead>
              <tbody>
                {beeDetails &&
                  beeDetails?.length > 0 &&
                  beeDetails?.map((i, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{i.type}</td>
                        <td>{i.numberOfHives}</td>
                        <td>{i.productionQuantity}</td>
                        <td>
                          <Flex gap='4'>
                            <IconButton
                              colorScheme='telegram'
                              variant='outline'
                              size='xs'
                              aria-label='edit'
                              icon={<BiEdit />}
                              onClick={() => {
                                void editBeeDetails(index)
                              }}
                            />
                            <IconButton
                              colorScheme='red'
                              variant='outline'
                              size='xs'
                              aria-label='delete'
                              icon={<BsTrash />}
                              onClick={() => {
                                void removeBeeDetails(index)
                              }}
                            />
                          </Flex>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
                mt: 2,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.bee?.type != null}>
                <FormLabel>
                  ६.१. मौरीको जात <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.bee?.type != null ? 'error' : ''}
                  type='text'
                  {...register('bee.type')}
                  placeholder={'मौरीको जात लेख्नुहोस'}
                />
                {/* <FormErrorMessage>{errors?.bee?.type?.message}</FormErrorMessage> */}
              </FormControl>
              <FormControl isInvalid={errors?.bee?.numberOfHives != null}>
                <FormLabel>
                  ६.२. घारको संख्या
                  <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.bee?.numberOfHives != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('bee.numberOfHives')}
                  placeholder={'घारको संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.bee?.numberOfHives?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.bee?.productionQuantity != null}>
                <FormLabel>
                  ६.३. उत्पादन परिमाण (के.जि) मा <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.bee?.productionQuantity != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('bee.productionQuantity')}
                  placeholder={'उत्पादन परिमाण (के.जि) मा लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.bee?.productionQuantity?.message}</FormErrorMessage>
              </FormControl>
              <Box mt='8'>
                <Button
                  size='sm'
                  variant={'outline'}
                  colorScheme='facebook'
                  onClick={async () => {
                    try {
                      await beeSchema.validate(getValues(), { abortEarly: false })
                      void addBeeDetails()
                    } catch (validationErrors: any) {
                      if (validationErrors?.inner?.length) {
                        validationErrors.inner.forEach((error: any) => {
                          setError(error.path, { type: 'manual', message: error.message })
                        })
                      } else {
                        setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                      }
                    }
                  }}
                >
                  सुरक्षित गर्नुहोस
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '4',
            mt: '5',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            size='sm'
            variant='outline'
            type='button'
            onClick={() => {
              setStep(step - 1)
            }}
            colorScheme='telegram'
          >
            पछाडि फर्कनुहोस
          </Button>
          <Button size='sm' variant='primary' type='submit' isLoading={isSubmitting} loadingText='Submitting'>
            {farmerId && selectedValue ? 'अपडेट गरि अर्को पृष्ठमा जानुहोस' : 'अर्को पृष्ठमा जानुहोस'}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default FarmerCropsInformationForm
