const SIDEBAR_LABELS = {
  search: {
    en: 'Search',
    ne: 'खोज्नुहोस्',
  },
  searchPlaceholder: {
    en: 'Search menu items',
    ne: 'मेनु आइटमहरू खोज्नुहोस्',
  },
  menuItems: {
    en: 'Menu Items',
    ne: 'मेनु आइटमहरू',
  },
  dashboard: {
    en: 'Dashboard',
    ne: 'ड्यासबोर्ड',
  },
  help: {
    en: 'Help',
    ne: 'सहयोग',
  },
  settings: {
    en: 'Settings',
    ne: 'सेटिङहरू',
  },
  carousel: {
    en: 'Carousel',
    ne: 'क्यारोजल',
  },
  changePassword: {
    en: 'Change Password',
    ne: 'पासवर्ड परिवर्तन गर्नुहोस्',
  },
  logout: {
    en: 'Logout',
    ne: 'बाहिर निस्कनुहोस्',
  },
  phidimNagarpalika: {
    en: 'Phidim Nagarpalika',
    ne: 'फिदिम नगरपालिका',
  },
  phidimNagarpalikaAddress: {
    en: 'City Executive Office',
    ne: ' नगर कार्यपालिकाको कार्यालय',
  },
  serviceDeliveryApp: {
    en: 'Service Delivery App',
    ne: 'सेवा डिलिभरी अनुप्रयोग',
  },
  category: {
    en: 'Category',
    ne: 'श्रेणी',
  },
  agricultureType: {
    en: 'Agriculture Type',
    ne: 'कृषि प्रकार',
  },
  agriculture: {
    en: 'Agriculture',
    ne: 'कृषि',
  },
  agricultureName: {
    en: 'Agriculture Name',
    ne: 'कृषि नाम',
  },
  agricultureDetails: {
    en: 'Agriculture Details',
    ne: 'कृषि विवरण',
  },
  imageGallery: {
    en: 'Image Gallery',
    ne: 'चित्र ग्यालरी',
  },
  imageAlbum: {
    en: 'Album',
    ne: 'चित्र एल्बम',
  },
  image: {
    en: 'Image',
    ne: 'चित्र',
  },
  // imageGallery: {
  //   en: 'Image Gallery',
  //   ne: 'चित्र ग्यालरी',
  // },
  videoGallery: {
    en: 'Video Gallery',
    ne: 'भिडियो ग्यालरी',
  },
  playlist: {
    en: 'Playlist',
    ne: 'प्लेलिस्ट',
  },
  videos: {
    en: 'Videos',
    ne: 'भिडियोहरू',
  },
  news: {
    en: 'News',
    ne: 'समाचार',
  },
  farmer: {
    en: 'Farmer',
    ne: 'कृषक',
  },
  registration: {
    en: 'Registration',
    ne: 'पंजीकरण',
  },
  farmerList: {
    en: 'Farmer List',
    ne: 'कृषक सूची',
  },
  agricultureGroup: {
    en: 'Agriculture Group',
    ne: 'कृषक समुह',
  },
}

export default SIDEBAR_LABELS as any
