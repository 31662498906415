import Language from '@models/language.model'
import { plainToClass } from 'class-transformer'

class Videos {
  id?: string
  title: Language
  description: Language
  videoUrl: string
  status: boolean
  createdAt: Date
  playlist?: any

  constructor(
    id: string,
    title: Language,
    description: Language,
    videoUrl: string,
    status: boolean,
    createdAt: Date,
    playlist?: any
  ) {
    this.id = id
    this.title = title
    this.description = description
    this.videoUrl = videoUrl
    this.status = status
    this.createdAt = createdAt
    this.playlist = playlist
  }

  static plainToInstance(plainData: object): Videos {
    return plainToClass(Videos, plainData)
  }

  static plainToInstances(plainData: object[]): Videos[] {
    return plainToClass(Videos, plainData)
  }
}
export default Videos
