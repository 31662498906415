// react-icon
import { Box, Button, Flex } from '@chakra-ui/react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <div className='notFound_section mt-5'>
        {/* <Flex gap='2'>
          <div className='notFound_title'>4</div>
          <div className='notFound_title'>0</div>
          <div className='notFound_title'>4</div>
        </Flex> */}

        <div className='pageNotFound_text'>
          <span className='primary_color'> oops !</span> page not found
        </div>
        <div className='pageNotFound_description'>
          The Page you are looking for might have been removed had its name changed or is temporarily unavailable
        </div>
        <div className='pt-4'></div>
        {/* <Button variant='primary' onClick={() => navigate(-1)}>
          <IoArrowBack /> &nbsp; Go Back
        </Button> */}
      </div>
    </Box>
  )
}

export default PageNotFound
