import {
  Box,
  Button,
  Card,
  Flex,
  FormLabel,
  IconButton,
  Input,
  ListItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import endPoint from "@config/endpoint/agriculture";
import { agricultureDetailsSchema } from "@config/schema/agricultureDetails";
import agricultureLabel from "@data/localization/agriculture";
import notificationLabel, {
  generateMessage,
} from "@data/localization/notification";
import tableLabel from "@data/localization/table";
import utilsLabel from "@data/localization/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import useAPI from "@hooks/useApi";
import useLang from "@hooks/useLang";
import AgricultureName from "@models/agricultureName.model";
import RequireSign from "@ui/common/atoms/RequireSign";
import Modal from "@ui/common/molecules/Modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiEdit, BiShowAlt } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";

interface IProps {
  open: boolean;
  setOpen: (data: boolean) => void;
  values: AgricultureName | null;
  setValues: (data: AgricultureName) => void;
}

interface ITableData {
  columnName: string;
  values: string;
}

const Add = (props: IProps) => {
  const { open, setOpen, values } = props;
  const { post, patch, put } = useAPI<any>();
  const toast = useToast();
  const [selectTableData, setSelectTableData] = useState<ITableData[] | null>(
    null
  );
  const [openShowModal, setOpenShowModal] = useState<boolean>(false);
  const [detailsValues, setDetailsValues] = useState<ITableData[] | null>(null);
  const [columnName, setColumnName] = useState<string>("");
  const [columnValues, setColumnValues] = useState<string>("");
  const { lang } = useLang();

  const {
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(agricultureDetailsSchema),
    defaultValues: {
      values: [
        {
          columnName: "",
          values: "",
        },
      ],
      agricultureName: "",
    },
    reValidateMode: "onChange",
  });

  const data = getValues();
  useEffect(() => {
    reset({
      ...data,
      agricultureName: values?.id,
    });
    if (values?.details?.values && values?.details?.values.length > 0)
      setDetailsValues(values?.details?.values);
  }, [values]);

  const onSubmit = async (data: any) => {
    try {
      let res;
      if (values?.details) {
        data.id = values?.details?.id?.toString();
        delete data.agricultureName;
        res = await put(endPoint.agricultureDetails, data);
      } else {
        res = await post(endPoint.agricultureDetails, data);
      }
      if (res?.data !== null) {
        toast?.closeAll();
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.agricultureDetails[lang],
            values?.details?.values
              ? notificationLabel?.updated[lang]
              : notificationLabel?.added[lang],
            lang
          ),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast?.closeAll();
        toast({
          title: notificationLabel?.success[lang],
          description:
            res?.error?.response?.data?.message ??
            notificationLabel?.somethingWrongHappen[lang],
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      toast?.closeAll();
      toast({
        title: notificationLabel?.success[lang],
        description:
          err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setOpen(false);
    }
  };

  const handleRemove = (index: number) => {
    if (detailsValues && detailsValues?.length > 0) {
      detailsValues.splice(index, 1);
      setDetailsValues([...detailsValues]);
    }
  };

  const handleSelect = (index: number) => {
    if (index > -1 && detailsValues && detailsValues?.length > 0) {
      const data = detailsValues[index];
      setSelectTableData([data]);
    }
  };

  const handleEdit = (index: number) => {
    if (index > -1 && detailsValues && detailsValues?.length > 0) {
      const data = detailsValues[index];
      setColumnName(data?.columnName);
      setColumnValues(data?.values);
      handleRemove(index);
    }
  };

  // function to add details
  const handleAddDetails = () => {
    if (columnName && columnValues) {
      const data = {
        columnName,
        values: columnValues,
      };

      // append data into setDetailsValues state
      if (detailsValues && detailsValues?.length > 0) {
        setDetailsValues([...detailsValues, data]);
      } else {
        setDetailsValues([data]);
      }
      // clear column name and values
      setColumnName("");
      setColumnValues("");
    }
  };

  useEffect(() => {
    if (detailsValues) {
      setValue("values", detailsValues);
    }
  }, [detailsValues]);

  useEffect(() => {
    if (open) {
      setDetailsValues(null);
    }
  }, [open]);

  return (
    <Box>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        title={`${values?.title?.ne} - ${values?.title?.en}`}
        data={
          <Box>
            <Box pb="5">
              <Text
                fontSize={"14px"}
                fontWeight="400"
                textDecoration="underline"
                color="gray.600"
              >
                {agricultureLabel?.suggestedTopic[lang]}
              </Text>
              <UnorderedList
                sx={{
                  listStyleType: "disc",
                  color: "gray.600",
                  li: {
                    fontSize: "14px",
                  },
                }}
              >
                <ListItem>परिचय - Introduction</ListItem>
                <ListItem>
                  उत्पती र खेती बिस्तार - Production and Distribution
                </ListItem>
                <ListItem>हावापानी र माटो - Water and Soil</ListItem>
                <ListItem>जातहरु - Crops</ListItem>
                <ListItem>प्रयोग र महत्व - Uses and Importance</ListItem>
                <ListItem>
                  जग्गाको तयारी र बिरुवा रोप्ने - Preparation of land and sowing
                </ListItem>
                <ListItem>बिरुवा उत्पादन - Production of crops</ListItem>
                <ListItem>
                  मलखाद सिंचाई र बगैचा बेबस्थापन - Harvesting and
                  post-harvesting management
                </ListItem>
              </UnorderedList>
            </Box>

            {detailsValues && detailsValues?.length > 0 && (
              <TableContainer mb="5">
                <Table
                  size="sm"
                  sx={{
                    borderRadius: "1px",
                    border: "1px",
                    borderColor: "gray.100",
                    thead: {
                      th: {
                        border: "1px solid #c7c7c7",
                        "&:last-of-child": {
                          borderLeft: "none",
                        },
                      },
                    },
                    tbody: {
                      td: {
                        border: "1px solid #c7c7c7",
                        "&:last-of-child": {
                          borderLeft: "none",
                        },
                      },
                    },
                  }}
                >
                  <Thead
                    background="gray.200"
                    height="35px"
                    border="1px"
                    borderColor="gray.100"
                    textTransform="capitalize"
                  >
                    <Tr
                      sx={{
                        th: {
                          fontSize: "14px",
                          textTransform: "capitalize",
                          pt: 2,
                          fontWeight: "500",
                          color: "black",
                        },
                      }}
                    >
                      <Th width="30px">{tableLabel?.sn[lang]}</Th>
                      <Th width="25%">{tableLabel?.topic[lang]}</Th>
                      <Th width="50%">{tableLabel?.description[lang]}</Th>
                      <Th textAlign="center" width="150px">
                        {tableLabel?.action[lang]}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {detailsValues.map((value, index) => (
                      <Tr key={index}>
                        <Td>{index + 1}.</Td>
                        <Td>{value.columnName}</Td>
                        <Td>{value.values?.substring(0, 75)}...</Td>
                        <Td>
                          <Flex
                            gap="3"
                            justifyContent={"center"}
                            sx={{
                              button: {
                                width: "25px",
                                height: "25px",
                                padding: "0px",
                              },
                            }}
                          >
                            <IconButton
                              size="sm"
                              colorScheme="green"
                              variant="outline"
                              aria-label="show"
                              icon={<BiShowAlt />}
                              onClick={() => {
                                handleSelect(index);
                                setOpenShowModal(true);
                              }}
                            />
                            <IconButton
                              size="sm"
                              colorScheme="telegram"
                              variant="outline"
                              aria-label="show"
                              icon={<BiEdit />}
                              onClick={() => {
                                handleEdit(index);
                              }}
                            />
                            <IconButton
                              size="sm"
                              colorScheme="red"
                              variant="outline"
                              aria-label="show"
                              onClick={() => {
                                handleRemove(index);
                              }}
                              icon={<BsTrash />}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              <Card
                borderRadius={"1px"}
                sx={{
                  padding: "3",
                  boxShadow: "0px 0px 2px 1px #c7c7c7",
                }}
                shadow="sm"
              >
                <Box>
                  <Flex gap="2">
                    <FormLabel
                      sx={{
                        whiteSpace: "nowrap",
                        marginY: "auto",
                        fontSize: "14px",
                      }}
                    >
                      {agricultureLabel?.topic[lang]} <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      sx={{
                        height: "27px",
                      }}
                      type="text"
                      name="columnName"
                      value={columnName}
                      onChange={(e) => {
                        setColumnName(e.target.value);
                      }}
                      placeholder={agricultureLabel?.enterNameOfTopic[lang]}
                    />
                  </Flex>
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                      paddingTop: "2px",
                    }}
                  >
                    {errors?.values?.[0]?.columnName?.message}
                  </span>
                  <FormLabel pt="4" fontSize={"14px"}>
                    {agricultureLabel?.description[lang]} <RequireSign />
                  </FormLabel>

                  <Textarea
                    height="300px"
                    name="values"
                    value={columnValues}
                    onChange={(e) => {
                      setColumnValues(e.target.value);
                    }}
                    placeholder={agricultureLabel?.enterDetailsAboutTopic[lang]}
                    size="sm"
                  />
                  <span
                    style={{
                      color: "red",
                      paddingTop: "2px",
                      fontSize: "12px",
                    }}
                  >
                    {errors?.values?.[0]?.values?.message}
                  </span>
                </Box>

                <Flex pt="2" justifyContent={"flex-end"}>
                  <Button
                    size="sm"
                    variant={"outline"}
                    colorScheme="teal"
                    onClick={() => {
                      handleAddDetails();
                    }}
                  >
                    {utilsLabel?.saveAndAddAnotherTopic[lang]}
                  </Button>
                </Flex>
                <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    paddingTop: "2px",
                  }}
                >
                  {errors?.values?.message}
                </span>
              </Card>

              <Flex my="5" gap="5" justifyContent={"flex-end"}>
                <Button
                  size="sm"
                  variant={"outline"}
                  colorScheme="red"
                  onClick={() => setOpen(false)}
                >
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size="sm"
                  variant={"primary"}
                >
                  {values?.details !== null
                    ? utilsLabel?.update[lang]
                    : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />

      <Modal
        isOpen={openShowModal}
        setOpen={setOpenShowModal}
        title={`${values?.title?.ne} - ${values?.title?.en}`}
        data={
          <Box>
            <Box>
              <Text fontWeight={"500"} fontSize={"17px"}>
                {agricultureLabel?.topic[lang]}{" "}
              </Text>
              {`${selectTableData?.[0]?.columnName}`}
            </Box>
            <Text pt="4" fontWeight={"500"} fontSize={"17px"}>
              {agricultureLabel?.description[lang]}
            </Text>
            {`${selectTableData?.[0]?.values}`}
          </Box>
        }
      ></Modal>
    </Box>
  );
};

export default Add;
