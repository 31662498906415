import AdminTemplate from '@ui/admin/templates/AdminTemplate'
import AdminAuthTemplate from '@ui/admin/templates/AuthTemplate'
import { Route, Routes } from 'react-router-dom'
import { AdminProtectedRoute } from './ProtectedRoute'
import { AdminAgricultureRoute, AdminAuthRoute, UserAgricultureRoute } from './Routes'

function App() {
  return (
    <div className='App'>
      <Routes>
        {/* user route */}
        {UserAgricultureRoute &&
          UserAgricultureRoute?.length > 0 &&
          UserAgricultureRoute.map((route, index) => {
            return (
              <Route path={route?.path} element={route?.element} key={index}>
                {route?.children &&
                  route?.children?.length > 0 &&
                  route?.children.map((child, index) => {
                    return <Route path={child?.path} element={child?.element} key={index} />
                  })}
              </Route>
            )
          })}

        {/* admin route */}
        <Route
          path='admin-dashboard'
          element={
            <AdminProtectedRoute>
              <AdminTemplate />
            </AdminProtectedRoute>
          }
        >
          {AdminAgricultureRoute &&
            AdminAgricultureRoute?.length > 0 &&
            AdminAgricultureRoute.map((route, index) => {
              return (
                <Route path={route?.path} element={route?.element} key={index}>
                  {route?.children &&
                    route?.children?.length > 0 &&
                    route?.children.map((child, index) => {
                      return <Route path={child?.path} element={child?.element} key={index} />
                    })}
                </Route>
              )
            })}
        </Route>

        {/* admin auth route */}
        <Route path='auth' element={<AdminAuthTemplate />}>
          {AdminAuthRoute &&
            AdminAuthRoute?.length > 0 &&
            AdminAuthRoute.map((route, index) => {
              return (
                <Route path={route?.path} element={route?.element} key={index}>
                  {route?.children &&
                    route?.children?.length > 0 &&
                    route?.children.map((child, index) => {
                      return <Route path={child?.path} element={child?.element} key={index} />
                    })}
                </Route>
              )
            })}
        </Route>
      </Routes>
    </div>
  )
}

export default App
