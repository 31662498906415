import { Box, Button, Container, Grid, Heading, Image, Stack, Text } from '@chakra-ui/react'
import useAPI from '@hooks/useApi'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

interface ICommercialAgriculture {
  id: string
  title: {
    en: string
    ne: string
  }
  media: {
    medias: Array<{
      name: string
    }>
  }
}

interface CardProps {
  heading: string
  image: string
  id: string
}

const Card = ({ heading, image, id }: CardProps) => {
  const url = heading.replace(/ /g, '-').toLowerCase()
  return (
    <Box w={'full'} borderWidth='1px' borderRadius='lg' overflow='hidden' p={5}>
      <Stack align={'start'} spacing={2}>
        <Image mb='2' opacity={0.75} width={'100%'} height='100px' objectFit={'contain'} src={image}></Image>
        <Box mt={2} pb='2'>
          <Heading size='md'>{heading}</Heading>
        </Box>
        <Link
          to={`/commercial-agriculture/${url}`}
          state={{
            id,
          }}
        >
          <Button variant={'outline'} colorScheme={'blue'} p='0' size={'sm'}>
            Learn more
          </Button>
        </Link>
      </Stack>
    </Box>
  )
}

const CommercialAgriculturePage = () => {
  const { get, getById } = useAPI()
  const [commercialAgricultureId, setCommercialAgricultureId] = useState<string | null>(null)
  const [values, setValues] = useState<ICommercialAgriculture[] | null>(null)

  const getCommercialAgricultureId = async () => {
    const res = (await get('/agriculture/category?search=Commercial Agriculture')) as any
    if (res?.data?.length > 0) {
      setCommercialAgricultureId(res.data[0].id)
    }
  }

  const handleFetch = async () => {
    const res = (await getById('/agriculture/category/', commercialAgricultureId ?? '')) as any
    if (res?.agricultureType) {
      setValues(res?.agricultureType)
    }
  }

  useEffect(() => {
    void getCommercialAgricultureId()
  }, [])

  useEffect(() => {
    if (commercialAgricultureId) {
      void handleFetch()
    }
  }, [commercialAgricultureId])

  return (
    <Box py={4}>
      <Stack spacing={4} as={Container}>
        <Heading pt='1' fontSize={{ base: '2xl', sm: '3xl' }} fontWeight={'bold'} textTransform='capitalize'>
          Commercial Agriculture
        </Heading>
        <Text color={'gray.600'} fontSize={{ base: 'sm', sm: 'lg' }}>
          Commercial agriculture refers to the large-scale production of crops and livestock for sale in the market,
          with the goal of generating profit.
        </Text>
      </Stack>
      <Container>
        <Grid templateColumns={{ lg: 'repeat(4,1fr)', md: 'repeat(4,1fr)', base: 'repeat(1,1fr)' }} gap='5' mt='5'>
          {values &&
            values?.length > 0 &&
            values.map((item, index: number) => (
              <Card key={index} heading={item?.title?.en} image={item?.media?.medias[0]?.name} id={item?.id} />
            ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default CommercialAgriculturePage
