import * as yup from 'yup'
import { NEPALI_REGEX } from '../../regex/index'

export const carouselSchema = yup.object({
  title: yup.object().shape({
    en: yup.string().required('Name is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'देवानगरिमा लेख्नुहोस').required('नाम आवश्यक छ ।'),
  }),
  // media: yup.string().required('Please upload cover image'),
})
