import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react'
import endpoint from '@config/endpoint/farmer'
import {
  agricultureDetailsSchema,
  contractDetailsSchema,
  farmsInformationSchema,
  landOwnershipInformationSchema,
} from '@config/schema/farmer/Registration.schema'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import FarmerFarmsInformation from '@models/farmer/farmerFarmsInformation.model'
import RequireSign from '@ui/common/atoms/RequireSign'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiEdit } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'

interface IProps {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  farmerId: string | undefined
}

const FarmerFarmsInformationForms = ({ step, setStep, farmerId }: IProps) => {
  const { post, patch, put, getById } = useAPI<FarmerFarmsInformation>()
  const toast = useToast()
  const [selectedValue, setSelectedValue] = useState<FarmerFarmsInformation | null>(null)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(farmsInformationSchema),
    defaultValues: {
      landOwnershipInformation: {
        landInformation: {
          ownership: '',
          landType: '',
          area: '',
        },
        irregation: {
          isAvaiableIrregation: '',
          irrigatedArea: '',
          irrigationSouce: '',
        },
      },
      agricultureDetails: {
        address: '',
        area: '',
        isAvaiableIrregation: '',
        categoryOfAgriculture: '',
        subCategoryOfAgriculture: '',
        ownership: '',
        ownershipInformationIfLandIsOnContract: '',
      },
      contractDetails: {
        landInformation: {
          ownership: '',
          landType: '',
          area: '',
        },
        irregation: {
          isAvaiableIrregation: '',
          irrigatedArea: '',
          irrigationSouce: '',
        },
      },
      isRegistedFarmer: '',
      farmerRegistrationNumber: '',
      farmerDetails: {
        name: '',
        phoneNumber: '',
        address: '',
        contractDate: '',
        contractEndDate: '',
        citizenshipNumber: '',
        currentAddress: '',
      },
    },
  })

  const onSubmit = async (data: any) => {
    try {
      let res
      if (selectedValue) {
        res = await put(endpoint?.farmerFarmsInformation, {
          id: selectedValue?.id?.toString(),
          landOwnershipInformation: permanentLandInformation ?? undefined,
          agricultureDetails: agricultureDetails ?? undefined,
          contractDetails: contractDetails ?? undefined,
          isRegistedFarmer: data?.isRegistedFarmer === 'YES',
          farmerRegistrationNumber: data?.isRegistedFarmer === 'YES' ? data?.farmerRegistrationNumber : undefined,
          farmerDetail:
            data?.isRegistedFarmer === 'YES'
              ? undefined
              : {
                  name: data?.farmerDetails?.name,
                  phoneNumber: data?.farmerDetails?.phoneNumber,
                  address: data?.farmerDetails?.address,
                  contractDate: data?.farmerDetails?.contractDate,
                  contractEndDate: data?.farmerDetails?.contractDate,
                  citizenshipNumber: data?.farmerDetails?.contractEndDate,
                  currentAddress: data?.farmerDetails?.currentAddress,
                },
        })
      } else {
        res = await post(endpoint?.farmerFarmsInformation, {
          farmerId,
          landOwnershipInformation: permanentLandInformation ?? undefined,
          agricultureDetails: agricultureDetails ?? undefined,
          contractDetails: contractDetails ?? undefined,
          isRegistedFarmer: data?.isRegistedFarmer === 'YES',
          farmerRegistrationNumber: data?.isRegistedFarmer === 'YES' ? data?.farmerRegistrationNumber : undefined,
          farmerDetail:
            data?.isRegistedFarmer === 'YES'
              ? undefined
              : {
                  name: data?.farmerDetails?.name,
                  phoneNumber: data?.farmerDetails?.phoneNumber,
                  address: data?.farmerDetails?.address,
                  contractDate: data?.farmerDetails?.contractDate,
                  contractEndDate: data?.farmerDetails?.contractDate,
                  citizenshipNumber: data?.farmerDetails?.contractEndDate,
                  currentAddress: data?.farmerDetails?.currentAddress,
                },
        })
      }
      if (res?.data) {
        toast.closeAll()
        toast({
          title: 'सफल भयो',
          description: `फार्म विवरण सफलतापूर्वक ${selectedValue ? 'अपडेट' : 'सुरक्षित'} गरियो`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        setStep(step + 1)
        reset({
          landOwnershipInformation: {
            landInformation: {
              ownership: '',
              landType: '',
              area: '',
            },
            irregation: {
              isAvaiableIrregation: '',
              irrigatedArea: '',
              irrigationSouce: '',
            },
          },
          agricultureDetails: {
            address: '',
            area: '',
            isAvaiableIrregation: '',
            categoryOfAgriculture: '',
            subCategoryOfAgriculture: '',
            ownership: '',
            ownershipInformationIfLandIsOnContract: '',
          },
          contractDetails: {
            landInformation: {
              ownership: '',
              landType: '',
              area: '',
            },
            irregation: {
              isAvaiableIrregation: '',
              irrigatedArea: '',
              irrigationSouce: '',
            },
          },
          isRegistedFarmer: '',
          farmerRegistrationNumber: '',
          farmerDetails: {
            name: '',
            phoneNumber: '',
            address: '',
            contractDate: '',
            contractEndDate: '',
            citizenshipNumber: '',
            currentAddress: '',
          },
        })
      } else {
        toast.closeAll()
        toast({
          title: 'गलत भयो',
          description: res?.error?.response?.data?.message ?? 'केहि गलत भयो',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: 'गलत भयो',
        description: err?.message ?? 'केहि गलत भयो',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }
  interface IPermanentLandInformation {
    landOwnershipInformation: {
      landInformation: {
        ownership: string
        landType: string
        area: string
      }
      irregation: {
        isAvaiableIrregation: boolean
        irrigatedArea: string
        irrigationSouce: string
      }
    }
  }

  interface IAgricultureDetails {
    address: string
    area: string
    isAvaiableIrregation: boolean
    categoryOfAgriculture: string
    subCategoryOfAgriculture: string
    ownership: string
    ownershipInformationIfLandIsOnContract: string
  }

  interface IContractDetails {
    contractDetails: {
      landInformation: {
        ownership: string
        landType: string
        area: string
      }
      irregation: {
        isAvaiableIrregation: boolean
        irrigatedArea: string
        irrigationSouce: string
      }
    }
  }

  const [permanentLandInformation, setPermanentLandInformation] = useState<IPermanentLandInformation[] | null>(null)
  const [selectedPermanentLandInformation, setSelectedPermanentLandInformation] =
    useState<IPermanentLandInformation | null>(null)

  const addPermanentLandInformation = async () => {
    const newPermanentLandInformation: IPermanentLandInformation = {
      landOwnershipInformation: {
        landInformation: {
          ownership: watch('landOwnershipInformation.landInformation.ownership'),
          landType: watch('landOwnershipInformation.landInformation.landType'),
          area: watch('landOwnershipInformation.landInformation.area'),
        },
        irregation: {
          isAvaiableIrregation: watch('landOwnershipInformation.irregation.isAvaiableIrregation') === 'YES',
          irrigatedArea: watch('landOwnershipInformation.irregation.irrigatedArea'),
          irrigationSouce: watch('landOwnershipInformation.irregation.irrigationSouce'),
        },
      },
    }

    if (permanentLandInformation)
      setPermanentLandInformation([...permanentLandInformation, newPermanentLandInformation])
    else setPermanentLandInformation([newPermanentLandInformation])

    reset({
      landOwnershipInformation: {
        landInformation: {
          ownership: '',
          landType: '',
          area: '',
        },
        irregation: {
          isAvaiableIrregation: '',
          irrigatedArea: '',
          irrigationSouce: '',
        },
      },
    })
  }

  const editPermanentLandInformation = async (index: number) => {
    if (permanentLandInformation) {
      deletePermanentLandInformation(index)
      const data = permanentLandInformation?.find((_, i: number) => i === index)
      if (data) setSelectedPermanentLandInformation(data)
    }
  }

  const deletePermanentLandInformation = (index: number) => {
    if (permanentLandInformation) {
      const temp = [...permanentLandInformation]
      temp.splice(index, 1)
      setPermanentLandInformation(temp)
    }
  }

  const [agricultureDetails, setAgricultureDetails] = useState<IAgricultureDetails[] | null>(null)
  const [selectedAgricultureDetails, setSelectedAgricultureDetails] = useState<IAgricultureDetails | null>(null)

  const addAgricultureDetails = async () => {
    const newAgricultureDetails: IAgricultureDetails = {
      address: watch('agricultureDetails.address'),
      area: watch('agricultureDetails.area'),
      isAvaiableIrregation: watch('agricultureDetails.isAvaiableIrregation') === 'YES',
      categoryOfAgriculture: watch('agricultureDetails.categoryOfAgriculture'),
      subCategoryOfAgriculture: watch('agricultureDetails.subCategoryOfAgriculture'),
      ownership: watch('agricultureDetails.ownership'),
      ownershipInformationIfLandIsOnContract: watch('agricultureDetails.ownershipInformationIfLandIsOnContract'),
    }

    if (agricultureDetails) setAgricultureDetails([...agricultureDetails, newAgricultureDetails])
    else setAgricultureDetails([newAgricultureDetails])

    reset({
      agricultureDetails: {
        address: '',
        area: '',
        isAvaiableIrregation: '',
        categoryOfAgriculture: '',
        subCategoryOfAgriculture: '',
        ownership: '',
        ownershipInformationIfLandIsOnContract: '',
      },
    })
  }

  const editAgricultureDetails = async (index: number) => {
    if (agricultureDetails) {
      deleteAgricultureDetails(index)
      const data = agricultureDetails?.find((_, i: number) => i === index)
      if (data) setSelectedAgricultureDetails(data)
    }
  }

  const deleteAgricultureDetails = (index: number) => {
    if (agricultureDetails) {
      const temp = [...agricultureDetails]
      temp.splice(index, 1)
      setAgricultureDetails(temp)
    }
  }

  const [contractDetails, setContractDetails] = useState<IContractDetails[] | null>(null)
  const [selectedContractDetails, setSelectedContractDetails] = useState<IContractDetails | null>(null)

  const addContractDetails = async () => {
    const newContractDetails: IContractDetails = {
      contractDetails: {
        landInformation: {
          ownership: watch('contractDetails.landInformation.ownership'),
          landType: watch('contractDetails.landInformation.landType'),
          area: watch('contractDetails.landInformation.area'),
        },
        irregation: {
          isAvaiableIrregation: watch('contractDetails.irregation.isAvaiableIrregation') === 'YES',
          irrigatedArea: watch('contractDetails.irregation.irrigatedArea'),
          irrigationSouce: watch('contractDetails.irregation.irrigationSouce'),
        },
      },
    }

    if (contractDetails) setContractDetails([...contractDetails, newContractDetails])
    else setContractDetails([newContractDetails])

    reset({
      contractDetails: {
        landInformation: {
          ownership: '',
          landType: '',
          area: '',
        },
        irregation: {
          isAvaiableIrregation: '',
          irrigatedArea: '',
          irrigationSouce: '',
        },
      },
    })
  }

  const editContractDetails = async (index: number) => {
    if (contractDetails) {
      deleteContractDetails(index)
      const data = contractDetails?.find((_, i: number) => i === index)
      if (data) setSelectedContractDetails(data)
    }
  }

  const deleteContractDetails = (index: number) => {
    if (contractDetails) {
      const temp = [...contractDetails]
      temp.splice(index, 1)
      setContractDetails(temp)
    }
  }

  useEffect(() => {
    if (selectedPermanentLandInformation) {
      reset({
        landOwnershipInformation: {
          landInformation: {
            ownership: selectedPermanentLandInformation?.landOwnershipInformation?.landInformation?.ownership,
            landType: selectedPermanentLandInformation?.landOwnershipInformation?.landInformation?.landType,
            area: selectedPermanentLandInformation?.landOwnershipInformation?.landInformation?.area,
          },
          irregation: {
            isAvaiableIrregation: selectedPermanentLandInformation?.landOwnershipInformation?.irregation
              .isAvaiableIrregation
              ? 'YES'
              : 'NO',
            irrigatedArea: selectedPermanentLandInformation?.landOwnershipInformation?.irregation?.irrigatedArea,
            irrigationSouce: selectedPermanentLandInformation?.landOwnershipInformation?.irregation?.irrigationSouce,
          },
        },
      })
    }
  }, [selectedPermanentLandInformation])

  useEffect(() => {
    if (selectedAgricultureDetails) {
      reset({
        agricultureDetails: {
          address: selectedAgricultureDetails?.address,
          area: selectedAgricultureDetails?.area,
          isAvaiableIrregation: selectedAgricultureDetails?.isAvaiableIrregation ? 'YES' : 'NO',
          categoryOfAgriculture: selectedAgricultureDetails?.categoryOfAgriculture,
          subCategoryOfAgriculture: selectedAgricultureDetails?.subCategoryOfAgriculture,
          ownership: selectedAgricultureDetails?.ownership,
          ownershipInformationIfLandIsOnContract: selectedAgricultureDetails?.ownershipInformationIfLandIsOnContract,
        },
      })
    }
  }, [selectedAgricultureDetails])

  useEffect(() => {
    if (selectedContractDetails) {
      reset({
        contractDetails: {
          landInformation: {
            ownership: selectedContractDetails?.contractDetails?.landInformation?.ownership,
            landType: selectedContractDetails?.contractDetails?.landInformation?.landType,
            area: selectedContractDetails?.contractDetails?.landInformation?.area,
          },
          irregation: {
            isAvaiableIrregation: selectedContractDetails?.contractDetails?.irregation?.isAvaiableIrregation
              ? 'YES'
              : 'NO',
            irrigatedArea: selectedContractDetails?.contractDetails?.irregation?.irrigatedArea,
            irrigationSouce: selectedContractDetails?.contractDetails?.irregation?.irrigationSouce,
          },
        },
      })
    }
  }, [selectedContractDetails])

  const getFarmerFarmsInformation = async () => {
    try {
      const res = await getById(endpoint?.farmerFarmsInformation + '/farmer', farmerId as string)
      if (res) {
        setSelectedValue(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (farmerId) {
      void getFarmerFarmsInformation()
    }
  }, [])

  useEffect(() => {
    if (selectedValue) {
      if (selectedValue?.landOwnershipInformation) setPermanentLandInformation(selectedValue?.landOwnershipInformation)
      if (selectedValue?.agricultureDetails) setAgricultureDetails(selectedValue?.agricultureDetails)
      if (selectedValue?.contractDetails) setContractDetails(selectedValue?.contractDetails)
      reset({
        isRegistedFarmer: selectedValue?.isRegistedFarmer ? 'YES' : 'NO',
        farmerRegistrationNumber: selectedValue?.farmerRegistrationNumber,
        farmerDetails: {
          name: selectedValue?.farmerDetail?.name,
          phoneNumber: selectedValue?.farmerDetail?.phoneNumber,
          address: selectedValue?.farmerDetail?.address,
          contractDate: selectedValue?.farmerDetail?.contractDate,
          contractEndDate: selectedValue?.farmerDetail?.contractEndDate,
          citizenshipNumber: selectedValue?.farmerDetail?.citizenshipNumber,
          currentAddress: selectedValue?.farmerDetail?.currentAddress,
        },
      })
    }
  }, [selectedValue])

  // fake filler function
  const handleFakeFiller = async () => {
    reset({
      isRegistedFarmer: 'No',
      farmerRegistrationNumber: undefined,
      farmerDetails: {
        name: 'राज रावत',
        phoneNumber: '9850828328',
        address: 'Pokhara-09, Nepal',
        contractDate: '2023-01-12',
        contractEndDate: '2025-01-11',
        citizenshipNumber: '011123-123-11',
        currentAddress: 'Kathmandu - Nepal',
      },
    })
    setPermanentLandInformation([
      {
        landOwnershipInformation: {
          landInformation: {
            ownership: 'आफ्नो',
            landType: 'खेत',
            area: '100',
          },
          irregation: {
            isAvaiableIrregation: true,
            irrigatedArea: '120',
            irrigationSouce: 'सिंचाई कुलो',
          },
        },
      },
    ])
    setAgricultureDetails([
      {
        address: 'Kathmandu',
        area: '129',
        isAvaiableIrregation: true,
        categoryOfAgriculture: 'खाध्यबालि',
        subCategoryOfAgriculture: 'धान',
        ownership: 'आफ्नो',
        ownershipInformationIfLandIsOnContract: 'प्रमेश कार्कि',
      },
    ])
    setContractDetails([
      {
        contractDetails: {
          landInformation: {
            ownership: 'आफ्नो',
            landType: 'मलिलो',
            area: '12398',
          },
          irregation: {
            isAvaiableIrregation: true,
            irrigatedArea: '125',
            irrigationSouce: 'सिंचाई कुलो',
          },
        },
      },
    ])
  }

  return (
    <Box>
      {/* remove this fake filler on production */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleFakeFiller} variant={'outline'} size='md' colorScheme='purple'>
          Fake filler
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading pb='3' size='md' mt='2'>
          फार्मको विवरण
        </Heading>

        <Box p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
          <FormLabel>
            १. जमिन स्वामित्वको विवरण (स्थायी ठेगानामा रहेको) <RequireSign />
          </FormLabel>
          <table>
            <thead>
              <tr>
                <th
                  rowSpan={2}
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  जग्गाको विवरण
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  सिंचाई विवरण
                </th>
                <th
                  rowSpan={2}
                  style={{
                    textAlign: 'center',
                    width: '150px',
                  }}
                >
                  कार्य
                </th>
              </tr>
              <tr>
                <th>स्वामित्व (जग्गा धनी)</th>
                <th>जमिनको किसिम</th>
                <th>क्षेत्रफल</th>
                <th>सिंचाई उपलब्धता</th>
                <th>सिंचाई क्षेत्रफल</th>
                <th>सिंचाई स्रोत</th>
              </tr>
            </thead>
            <tbody>
              {permanentLandInformation &&
                permanentLandInformation?.length > 0 &&
                permanentLandInformation?.map((i, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{i.landOwnershipInformation.landInformation.ownership}</td>
                      <td>{i.landOwnershipInformation.landInformation.landType}</td>
                      <td>{i.landOwnershipInformation.landInformation.area}</td>
                      <td>{i.landOwnershipInformation.irregation.isAvaiableIrregation ? 'छ' : 'छैन'}</td>
                      <td>{i.landOwnershipInformation.irregation.irrigatedArea}</td>
                      <td>{i.landOwnershipInformation.irregation.irrigationSouce}</td>
                      <td>
                        <Flex gap='4'>
                          <IconButton
                            colorScheme='telegram'
                            variant='outline'
                            size='xs'
                            aria-label='edit'
                            icon={<BiEdit />}
                            onClick={() => {
                              void editPermanentLandInformation(index)
                            }}
                          />
                          <IconButton
                            colorScheme='red'
                            variant='outline'
                            size='xs'
                            aria-label='delete'
                            icon={<BsTrash />}
                            onClick={() => {
                              deletePermanentLandInformation(index)
                            }}
                          />
                        </Flex>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>

          <Box mt='2' p='4' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 3,
              }}
            >
              <Box>
                <FormLabel>
                  १.१. जग्गाको विवरण <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      md: 'repeat(3, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      base: 'repeat(1, 1fr)',
                    },
                    gap: 3,
                  }}
                >
                  <FormControl isInvalid={errors.landOwnershipInformation?.landInformation?.ownership != null}>
                    <FormLabel>
                      १.१.१. स्वामित्व (जग्गा धनी) <RequireSign />
                    </FormLabel>
                    <Select
                      size='sm'
                      {...register(`landOwnershipInformation.landInformation.ownership`)}
                      placeholder='स्वामित्व (जग्गा धनी) छान्नुहोस'
                    >
                      <option value={'आफ्नो'}>आफ्नो</option>
                      <option value={'परिवारको नाममा'}>परिवारको नाममा</option>
                      <option value={'ऐलानी / पर्ती'}>ऐलानी / पर्ती</option>
                    </Select>
                    <FormErrorMessage>
                      {errors?.landOwnershipInformation?.landInformation?.ownership?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.landOwnershipInformation?.landInformation?.landType != null}>
                    <FormLabel>
                      १.१.२. जमिनको किसिम <RequireSign />
                    </FormLabel>
                    <Select
                      size='sm'
                      {...register(`landOwnershipInformation.landInformation.landType`)}
                      placeholder='जमिनको किसिम छान्नुहोस'
                    >
                      <option value={'पाखो बारी'}>पाखो बारी</option>
                      <option value={'खेत'}>खेत</option>
                    </Select>
                    <FormErrorMessage>
                      {errors?.landOwnershipInformation?.landInformation?.landType?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.landOwnershipInformation?.landInformation?.area != null}>
                    <FormLabel>
                      १.१.३. क्षेत्रफल (वर्ग मिटर) <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.landOwnershipInformation?.landInformation?.area != null ? 'error' : ''}
                      type='text'
                      {...register('landOwnershipInformation.landInformation.area')}
                      placeholder={'क्षेत्रफल लेख्नुहोस'}
                    />
                    <FormErrorMessage>
                      {errors?.landOwnershipInformation?.landInformation?.area?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <FormLabel>
                  १.२.सिंचाई विवरण <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      md: 'repeat(3, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      base: 'repeat(1, 1fr)',
                    },
                    gap: 3,
                  }}
                >
                  <FormControl isInvalid={errors.landOwnershipInformation?.irregation?.isAvaiableIrregation != null}>
                    <FormLabel>
                      १.२.१. सिंचाई उपलब्धता <RequireSign />
                    </FormLabel>
                    <RadioGroup
                      mt='2'
                      defaultValue={
                        selectedPermanentLandInformation?.landOwnershipInformation?.irregation?.isAvaiableIrregation
                          ? 'YES'
                          : 'NO'
                      }
                    >
                      <Radio {...register('landOwnershipInformation.irregation.isAvaiableIrregation')} value={'YES'}>
                        छ &nbsp; &nbsp;
                      </Radio>
                      <Radio {...register('landOwnershipInformation.irregation.isAvaiableIrregation')} value={'NO'}>
                        छैन
                      </Radio>
                    </RadioGroup>
                    <FormErrorMessage>
                      {errors?.landOwnershipInformation?.irregation?.isAvaiableIrregation?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.landOwnershipInformation?.irregation?.irrigatedArea != null}>
                    <FormLabel>
                      १.२.२. सिंचाई क्षेत्रफल (वर्ग मिटर) <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.landOwnershipInformation?.irregation?.irrigatedArea != null ? 'error' : ''}
                      type='text'
                      {...register('landOwnershipInformation.irregation.irrigatedArea')}
                      placeholder={'सिंचाई क्षेत्रफल लेख्नुहोस'}
                    />
                    <FormErrorMessage>
                      {errors?.landOwnershipInformation?.irregation?.irrigatedArea?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.landOwnershipInformation?.irregation?.irrigationSouce != null}>
                    <FormLabel>
                      १.२.३. सिंचाई स्रोत <RequireSign />
                    </FormLabel>
                    <Select
                      size='sm'
                      {...register(`landOwnershipInformation.irregation.irrigationSouce`)}
                      placeholder='सिंचाई स्रोत छान्नुहोस'
                    >
                      <option value={'पोखरी/रिजरभायर'}>पोखरी/रिजरभायर</option>
                      <option value={'सिंचाई कुलो'}>सिंचाई कुलो</option>
                      <option value={'भुमिगत सिंचाई (प्यालो टुयबेल तथा डिपर टुयबेल)'}>
                        भुमिगत सिंचाई (प्यालो टुयबेल तथा डिपर टुयबेल)
                      </option>
                      <option value={'आकासे पानी संकलन'}>आकासे पानी संकलन</option>
                    </Select>

                    <FormErrorMessage>
                      {errors?.landOwnershipInformation?.irregation?.irrigationSouce?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Flex justifyContent={'flex-end'} mt='5'>
              <Button
                size='sm'
                variant={'outline'}
                colorScheme='facebook'
                onClick={async () => {
                  try {
                    await landOwnershipInformationSchema.validate(getValues(), { abortEarly: false })
                    void addPermanentLandInformation()
                  } catch (validationErrors: any) {
                    if (validationErrors?.inner?.length) {
                      validationErrors.inner.forEach((error: any) => {
                        setError(error.path, { type: 'manual', message: error.message })
                      })
                    } else {
                      setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                    }
                  }
                }}
              >
                सुरक्षित गर्नुहोस
              </Button>
            </Flex>
          </Box>
        </Box>
        <Text pt='4'>
          <i>
            नोट : समावेश गरिएको ऐलानी / पर्ती जग्गाको विवरण कृषि तथ्याङं सङ्कलन प्रायोजनको लागी मात्र प्रयोग हुनेछ ।
            कानुनी प्रायोजनको लागी यो विवरण वैध हुने छैन ।
          </i>
        </Text>
        <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
          २. एक भन्दा बढी स्थानीय तहमा कृषि फार्म गरिएको भए सो को विवरण
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '12px',
                  }}
                >
                  क्र.स.
                </th>
                <th>ठेगाना</th>
                <th>क्षेत्रफल</th>
                <th>सिंचाई उपलब्धता</th>
                <th>कृषि कार्यको क्षेत्र</th>
                <th>कृषि कार्यको उपलब्धता</th>
                <th>स्वामित्व</th>
                <th>करार भए जग्गा धनीको विवरण</th>
                <th
                  style={{
                    textAlign: 'center',
                    width: '50px',
                  }}
                >
                  कार्य
                </th>
              </tr>
            </thead>
            <tbody>
              {agricultureDetails &&
                agricultureDetails?.length > 0 &&
                agricultureDetails?.map((i, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{i.address}</td>
                      <td>{i.area}</td>
                      <td>{i.isAvaiableIrregation ? 'छ' : 'छैन'}</td>
                      <td>{i.categoryOfAgriculture}</td>
                      <td>{i.subCategoryOfAgriculture}</td>
                      <td>{i.ownership}</td>
                      <td>{i.ownershipInformationIfLandIsOnContract}</td>
                      <td>
                        <Flex gap='4'>
                          <IconButton
                            colorScheme='telegram'
                            variant='outline'
                            size='xs'
                            aria-label='edit'
                            icon={<BiEdit />}
                            onClick={() => {
                              void editAgricultureDetails(index)
                            }}
                          />
                          <IconButton
                            colorScheme='red'
                            variant='outline'
                            size='xs'
                            aria-label='delete'
                            icon={<BsTrash />}
                            onClick={() => {
                              deleteAgricultureDetails(index)
                            }}
                          />
                        </Flex>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                lg: 'repeat(6, 1fr)',
                md: 'repeat(3, 1fr)',
                base: 'repeat(1, 1fr)',
              },
              gap: 3,
              mt: 2,
            }}
            p='4'
            border='1px'
            borderColor='gray.200'
            borderRadius={'2px'}
          >
            <FormControl isInvalid={errors.agricultureDetails?.address != null}>
              <FormLabel>
                २.१. ठेगाना <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.agricultureDetails?.address != null ? 'error' : ''}
                type='text'
                {...register('agricultureDetails.address')}
                placeholder={'ठेगाना लेख्नुहोस'}
              />
              <FormErrorMessage>{errors?.agricultureDetails?.address?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureDetails?.area != null}>
              <FormLabel>
                २.२. क्षेत्रफल <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.agricultureDetails?.area != null ? 'error' : ''}
                type='text'
                {...register('agricultureDetails.area')}
                placeholder={'क्षेत्रफल लेख्नुहोस'}
              />
              <FormErrorMessage>{errors?.agricultureDetails?.area?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureDetails?.isAvaiableIrregation != null}>
              <FormLabel>
                २.३. सिंचाई उपलब्धता <RequireSign />
              </FormLabel>
              <RadioGroup mt='2' defaultValue='YES'>
                <Radio {...register('agricultureDetails.isAvaiableIrregation')} value={'YES'}>
                  छ &nbsp; &nbsp;
                </Radio>
                <Radio {...register('agricultureDetails.isAvaiableIrregation')} value={'NO'}>
                  छैन
                </Radio>
              </RadioGroup>
              <FormErrorMessage>{errors?.agricultureDetails?.isAvaiableIrregation?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureDetails?.categoryOfAgriculture != null}>
              <FormLabel>
                २.४. कृषि कार्यको क्षेत्र <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.agricultureDetails?.categoryOfAgriculture != null ? 'error' : ''}
                type='text'
                {...register('agricultureDetails.categoryOfAgriculture')}
                placeholder={'कृषि कार्यको क्षेत्र लेख्नुहोस'}
              />
              <FormErrorMessage>{errors?.agricultureDetails?.categoryOfAgriculture?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureDetails?.subCategoryOfAgriculture != null}>
              <FormLabel>
                २.५. कृषि कार्यको उपक्षेत्र <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.agricultureDetails?.subCategoryOfAgriculture != null ? 'error' : ''}
                type='text'
                {...register('agricultureDetails.subCategoryOfAgriculture')}
                placeholder={'कृषि कार्यको उपक्षेत्र लेख्नुहोस'}
              />
              <FormErrorMessage>{errors?.agricultureDetails?.subCategoryOfAgriculture?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureDetails?.ownership != null}>
              <FormLabel>
                २.६. स्वामित्व <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.agricultureDetails?.ownership != null ? 'error' : ''}
                type='text'
                {...register('agricultureDetails.ownership')}
                placeholder={'स्वामित्व लेख्नुहोस'}
              />
              <FormErrorMessage>{errors?.agricultureDetails?.ownership?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureDetails?.ownershipInformationIfLandIsOnContract != null}>
              <FormLabel>
                २.७. करार भए जग्गा धनीको विवरण <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.agricultureDetails?.ownershipInformationIfLandIsOnContract != null ? 'error' : ''}
                type='text'
                {...register('agricultureDetails.ownershipInformationIfLandIsOnContract')}
                placeholder={'करार भए जग्गा धनीको विवरण लेख्नुहोस'}
              />
              <FormErrorMessage>
                {errors?.agricultureDetails?.ownershipInformationIfLandIsOnContract?.message}
              </FormErrorMessage>
            </FormControl>
            <Flex mt='8'>
              <Button
                size='sm'
                variant={'outline'}
                colorScheme='facebook'
                onClick={async () => {
                  try {
                    await agricultureDetailsSchema.validate(getValues(), { abortEarly: false })
                    void addAgricultureDetails()
                  } catch (validationErrors: any) {
                    if (validationErrors?.inner?.length) {
                      validationErrors.inner.forEach((error: any) => {
                        setError(error.path, { type: 'manual', message: error.message })
                      })
                    } else {
                      setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                    }
                  }
                }}
              >
                सुरक्षित गर्नुहोस
              </Button>
            </Flex>
          </Box>
        </Box>

        <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
          <FormLabel>
            ३. अन्य व्यक्तिलाई जग्गा करार/भाडामा लगाएको भए सोको विवरण <RequireSign />
          </FormLabel>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '12px',
                  }}
                  rowSpan={2}
                >
                  क्र.स.
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                  colSpan={3}
                >
                  जग्गाको विवरण
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                  colSpan={3}
                >
                  सिंचाई विवरण
                </th>
                <th
                  style={{
                    textAlign: 'center',
                    width: '50px',
                  }}
                  rowSpan={2}
                >
                  कार्य
                </th>
              </tr>
              <tr>
                <th>स्वामित्व</th>
                <th>जमिनको किसिम</th>
                <th>क्षेत्रफल</th>
                <th>सिंचाई उपलब्धता</th>
                <th>सिंचाई क्षेत्रफल</th>
                <th>सिंचाईको स्रोत</th>
              </tr>
            </thead>
            <tbody>
              {contractDetails &&
                contractDetails?.length > 0 &&
                contractDetails?.map((i, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{i.contractDetails.landInformation.ownership}</td>
                      <td>{i.contractDetails.landInformation.landType}</td>
                      <td>{i.contractDetails.landInformation.area}</td>
                      <td>{i.contractDetails.irregation.isAvaiableIrregation ? 'छ' : 'छैन'}</td>
                      <td>{i.contractDetails.irregation.irrigatedArea}</td>
                      <td>{i.contractDetails.irregation.irrigationSouce}</td>
                      <td>
                        <Flex gap='4'>
                          <IconButton
                            colorScheme='telegram'
                            variant='outline'
                            size='xs'
                            aria-label='edit'
                            icon={<BiEdit />}
                            onClick={() => {
                              void editContractDetails(index)
                            }}
                          />
                          <IconButton
                            colorScheme='red'
                            variant='outline'
                            size='xs'
                            aria-label='delete'
                            icon={<BsTrash />}
                            onClick={() => {
                              deleteContractDetails(index)
                            }}
                          />
                        </Flex>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <Box mt='2' p='4' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 3,
              }}
            >
              <Box>
                <FormLabel>
                  ३.१. जग्गाको विवरण <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      md: 'repeat(3, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      base: 'repeat(1, 1fr)',
                    },
                    gap: 3,
                  }}
                >
                  <FormControl isInvalid={errors?.contractDetails?.landInformation?.ownership != null}>
                    <FormLabel>
                      ३.१.१. स्वामित्व (जग्गा धनी) <RequireSign />
                    </FormLabel>
                    <Select
                      size='sm'
                      {...register(`contractDetails.landInformation.ownership`)}
                      placeholder='स्वामित्व (जग्गा धनी) छान्नुहोस'
                    >
                      <option value={'आफ्नो'}>आफ्नो</option>
                      <option value={'परिवारको नाममा'}>परिवारको नाममा</option>
                      <option value={'ऐलानी / पर्ती'}>ऐलानी / पर्ती</option>
                    </Select>
                    <FormErrorMessage>{errors?.contractDetails?.landInformation?.ownership?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.contractDetails?.landInformation?.area != null}>
                    <FormLabel>
                      ३.१.२. जमिनको किसिम <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.contractDetails?.landInformation?.landType != null ? 'error' : ''}
                      type='text'
                      {...register(`contractDetails.landInformation.landType`)}
                      placeholder={'जमिनको किसिम लेख्नुहोस'}
                    />
                    <FormErrorMessage>
                      {errors?.landOwnershipInformation?.landInformation?.landType?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.contractDetails?.landInformation?.area != null}>
                    <FormLabel>
                      ३.१.३. क्षेत्रफल <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.contractDetails?.landInformation?.area != null ? 'error' : ''}
                      type='text'
                      {...register('contractDetails.landInformation.area')}
                      placeholder={'क्षेत्रफल लेख्नुहोस'}
                    />
                    <FormErrorMessage>{errors?.contractDetails?.landInformation?.area?.message}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <FormLabel>
                  ३.२. सिंचाई विवरण <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      md: 'repeat(3, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      base: 'repeat(1, 1fr)',
                    },
                    gap: 3,
                  }}
                >
                  <FormControl isInvalid={errors.contractDetails?.irregation?.isAvaiableIrregation != null}>
                    <FormLabel>
                      ३.२.१. सिंचाई उपलब्धता <RequireSign />
                    </FormLabel>
                    <RadioGroup
                      mt='2'
                      defaultValue={
                        selectedContractDetails?.contractDetails?.irregation?.isAvaiableIrregation ? 'YES' : 'NO'
                      }
                    >
                      <Radio {...register('contractDetails.irregation.isAvaiableIrregation')} value={'YES'}>
                        छ &nbsp; &nbsp;
                      </Radio>
                      <Radio {...register('contractDetails.irregation.isAvaiableIrregation')} value={'NO'}>
                        छैन
                      </Radio>
                    </RadioGroup>
                    <FormErrorMessage>
                      {errors?.contractDetails?.irregation?.isAvaiableIrregation?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.contractDetails?.irregation?.irrigatedArea != null}>
                    <FormLabel>
                      ३.२.२. सिंचाई क्षेत्रफल <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.contractDetails?.irregation?.irrigatedArea != null ? 'error' : ''}
                      type='text'
                      {...register('contractDetails.irregation.irrigatedArea')}
                      placeholder={'सिंचाई क्षेत्रफल लेख्नुहोस'}
                    />
                    <FormErrorMessage>{errors?.contractDetails?.irregation?.irrigatedArea?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.contractDetails?.irregation?.irrigationSouce != null}>
                    <FormLabel>
                      ३.२.३. सिंचाई स्रोत <RequireSign />
                    </FormLabel>
                    <Select
                      size='sm'
                      {...register(`contractDetails.irregation.irrigationSouce`)}
                      placeholder='सिंचाई स्रोत छान्नुहोस'
                    >
                      <option value={'पोखरी/रिजरभायर'}>पोखरी/रिजरभायर</option>
                      <option value={'सिंचाई कुलो'}>सिंचाई कुलो</option>
                      <option value={'भुमिगत सिंचाई (प्यालो टुयबेल तथा डिपर टुयबेल॰'}>
                        भुमिगत सिंचाई (प्यालो टुयबेल तथा डिपर टुयबेल)
                      </option>
                      <option value={'आकासे पानी संकलन'}>आकासे पानी संकलन</option>
                    </Select>

                    <FormErrorMessage>{errors?.contractDetails?.irregation?.irrigationSouce?.message}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Flex justifyContent={'flex-end'} mt='5'>
              <Button
                size='sm'
                variant={'outline'}
                colorScheme='facebook'
                onClick={async () => {
                  try {
                    await contractDetailsSchema.validate(getValues(), { abortEarly: false })
                    void addContractDetails()
                  } catch (validationErrors: any) {
                    if (validationErrors?.inner?.length) {
                      validationErrors.inner.forEach((error: any) => {
                        setError(error.path, { type: 'manual', message: error.message })
                      })
                    } else {
                      setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                    }
                  }
                }}
              >
                सुरक्षित गर्नुहोस
              </Button>
            </Flex>
          </Box>
        </Box>

        <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
          <FormLabel>
            ४. कृषकको विवरण <RequireSign />
          </FormLabel>
          <FormControl isInvalid={errors?.contractDetails?.landInformation?.ownership != null}>
            <FormLabel>
              ४.१. कृषक दर्ता नम्बर छ ? <RequireSign />
            </FormLabel>
            <RadioGroup mt='2' defaultValue={selectedValue?.isRegistedFarmer ? 'YES' : 'NO'}>
              <Radio {...register('isRegistedFarmer')} value={'YES'}>
                छ &nbsp; &nbsp;
              </Radio>
              <Radio {...register('isRegistedFarmer')} value={'NO'}>
                छैन
              </Radio>
            </RadioGroup>
            <FormErrorMessage>{errors?.contractDetails?.landInformation?.ownership?.message}</FormErrorMessage>
          </FormControl>

          {watch('isRegistedFarmer') === 'YES' ? (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6,1fr)',
                  md: 'repeat(3,1fr)',
                  sm: 'repeat(2,1fr)',
                  base: 'repeat(1,1fr)',
                },
                gap: 3,
                mt: 2,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.farmerRegistrationNumber != null}>
                <FormLabel>
                  ४.१.१. कृषक दर्ता नम्बर <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerRegistrationNumber != null ? 'error' : ''}
                  type='text'
                  {...register('farmerRegistrationNumber')}
                  placeholder={'कृषक दर्ता नम्बर लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.farmerRegistrationNumber?.message}</FormErrorMessage>
              </FormControl>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6,1fr)',
                  md: 'repeat(3,1fr)',
                  sm: 'repeat(2,1fr)',
                  base: 'repeat(1,1fr)',
                },
                gap: 3,
                mt: 2,
              }}
              p='4'
              border='1px'
              borderColor='gray.200'
              borderRadius={'2px'}
            >
              <FormControl isInvalid={errors?.farmerDetails?.name != null}>
                <FormLabel>
                  ४.१.१. नाम <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerDetails?.name != null ? 'error' : ''}
                  type='text'
                  {...register('farmerDetails.name')}
                  placeholder={'नाम लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.farmerDetails?.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.farmerDetails?.phoneNumber != null}>
                <FormLabel>
                  ४.१.२. फोन नम्बर <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerDetails?.phoneNumber != null ? 'error' : ''}
                  type='text'
                  {...register('farmerDetails.phoneNumber')}
                  placeholder={'फोन नम्बर लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.farmerDetails?.phoneNumber?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.farmerDetails?.address != null}>
                <FormLabel>
                  ४.१.३. ठेगाना <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerDetails?.address != null ? 'error' : ''}
                  type='text'
                  {...register('farmerDetails.address')}
                  placeholder={'ठेगाना लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.farmerDetails?.address?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.farmerDetails?.contractDate != null}>
                <FormLabel>
                  ४.१.४. सम्झौता मिति <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerDetails?.contractDate != null ? 'error' : ''}
                  type='date'
                  {...register('farmerDetails.contractDate')}
                />
                <FormErrorMessage>{errors?.farmerDetails?.contractDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.farmerDetails?.contractEndDate != null}>
                <FormLabel>
                  ४.१.५. सम्झौता समाप्त हुने मिति <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerDetails?.contractEndDate != null ? 'error' : ''}
                  type='date'
                  {...register('farmerDetails.contractEndDate')}
                />
                <FormErrorMessage>{errors?.farmerDetails?.contractEndDate?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.farmerDetails?.citizenshipNumber != null}>
                <FormLabel>
                  ४.१.६. नागरिकता प्रमाण पत्र नम्बर <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerDetails?.citizenshipNumber != null ? 'error' : ''}
                  type='text'
                  {...register('farmerDetails.citizenshipNumber')}
                  placeholder='नागरिकता प्रमाण पत्र नम्बर लेख्नुहोस'
                />
                <FormErrorMessage>{errors?.farmerDetails?.citizenshipNumber?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.farmerDetails?.currentAddress != null}>
                <FormLabel>
                  ४.१.७. हालको ठेगाना <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.farmerDetails?.currentAddress != null ? 'error' : ''}
                  type='text'
                  {...register('farmerDetails.currentAddress')}
                  placeholder='हालको ठेगाना लेख्नुहोस'
                />
                <FormErrorMessage>{errors?.farmerDetails?.currentAddress?.message}</FormErrorMessage>
              </FormControl>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '4',
            mt: '5',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            size='sm'
            variant='outline'
            type='button'
            onClick={() => {
              setStep(step - 1)
            }}
            colorScheme='telegram'
          >
            पछाडि फर्कनुहोस
          </Button>
          <Button size='sm' variant='primary' type='submit' isLoading={isSubmitting} loadingText='Submitting'>
            {farmerId && selectedValue ? 'अपडेट गरि अर्को पृष्ठमा जानुहोस' : 'अर्को पृष्ठमा जानुहोस'}
          </Button>
        </Box>
      </form>
    </Box>
  )
}
export default FarmerFarmsInformationForms
