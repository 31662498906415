import { Box, Flex, IconButton, useToast } from '@chakra-ui/react'
import endpoint from '@config/endpoint/farmer'
import farmerLabel from '@data/localization/farmer'
import tableLabel from '@data/localization/table'
import { getMessage } from '@functions/generateMessage'
import tableSnGenerate from '@functions/tableSnGenerate'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import FarmerModel from '@models/farmer/farmer.model'
import { createColumnHelper } from '@tanstack/react-table'
import { IPagination } from '@type/pagination.types'
import ModalBox from '@ui/common/molecules/Modal'
import Table from '@ui/common/organisms/Table'
import { useEffect, useState } from 'react'
import { BiEdit, BiShowAlt } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import ShowFarmerInformation from './ShowFarmerInformation'

interface IProps {
  values: IResponse<FarmerModel[]> | null
  setValues: (data: IResponse<FarmerModel[]>) => void
  loading: boolean
  setLoading: (data: boolean) => void
  pagination: IPagination
  setPagination: (data: IPagination) => void
}

const FarmerList = (props: IProps) => {
  const { values, loading, pagination, setPagination } = props
  const { lang } = useLang()
  const { remove, getById } = useAPI()
  const [open, setOpen] = useState<boolean>(false)
  const [openShow, setOpenShow] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [id, setId] = useState<string | null>(null)
  const [selectedData, setSelectedData] = useState<FarmerModel | null>(null)
  const [submit, setSubmitting] = useState<boolean>(false)
  const toast = useToast()
  const navigate = useNavigate()

  const queryClient = useQueryClient()

  const handleDelete = async () => {
    try {
      setSubmitting(true)
      const res = await remove(`${endpoint?.farmer}/${id}`)
      if (res === true) {
        await queryClient.invalidateQueries('farmer')
        toast.closeAll()
        toast({
          title: 'सफल भयो',
          description: `कृषक विवरण सफलतापूर्वक हटाईयो`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast.closeAll()
        toast({
          title: 'गलत भयो',
          description: res ?? 'केहि गलत भयो',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      toast.closeAll()
      toast({
        title: 'गलत भयो',
        description: err?.message ?? 'केहि गलत भयो',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      setConfirm(false)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (confirm) {
      void handleDelete()
      setConfirm(false)
    }
  }, [confirm])

  const handleFetchSelectedData = async (id: string) => {
    try {
      const res = await getById(endpoint?.farmer, id)
      if (res) {
        setSelectedData(res as FarmerModel)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('SN', {
      header: () => <span>{tableLabel?.sn[lang]}</span>,
      size: 50,
      cell: (info: any) => {
        return <span> {tableSnGenerate(info.row.index, pagination?.currentPage, pagination?.perPage)}</span>
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>{tableLabel?.farmerName[lang]}</span>,
      size: 350,
      cell: (info: any) => {
        return (
          <span>
            {info?.row?.original?.farmerPersonalInformation?.firstName?.[lang]}{' '}
            {info?.row?.original?.farmerPersonalInformation?.middleName?.[lang]
              ? ' ' + info?.row?.original?.farmerPersonalInformation?.middleName?.[lang] + ' '
              : ''}
            {info?.row?.original?.farmerPersonalInformation?.lastName?.[lang]}
          </span>
        )
      },
    }),
    columnHelper.accessor('citizenship', {
      id: 'citizenship',
      header: () => <span>{tableLabel?.citizenshipNumber[lang]} </span>,
      size: 200,
      cell: (info: any) => {
        return <span>{info?.row?.original?.farmerPersonalInformation?.citizenshipNumber}</span>
      },
    }),
    columnHelper.accessor('gender', {
      id: 'gender',
      header: () => <span>{tableLabel?.gender[lang]}</span>,
      size: 200,
      cell: (info: any) => {
        return (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {info?.row?.original?.farmerPersonalInformation?.gender?.toLowerCase()}
          </span>
        )
      },
    }),
    columnHelper.accessor('ward', {
      id: 'ward',
      header: () => <span>{tableLabel?.wardNo[lang]}</span>,
      size: 100,
      cell: (info: any) => {
        return <span>{info?.row?.original?.farmerPersonalInformation?.ward}</span>
      },
    }),
    columnHelper.accessor('toll', {
      id: 'toll',
      header: () => <span>{tableLabel?.tollName[lang]}</span>,
      size: 150,
      cell: (info: any) => {
        return <span>{info?.row?.original?.farmerPersonalInformation?.village}</span>
      },
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {tableLabel?.action[lang]}
        </span>
      ),
      size: 100,
      cell: (info: any) => {
        return (
          <Flex gap='3' justifyContent='center'>
            <IconButton
              colorScheme='green'
              variant='outline'
              aria-label='show'
              icon={<BiShowAlt />}
              onClick={() => {
                setOpenShow(true)
                void handleFetchSelectedData(info?.row?.original?.id)
              }}
            />
            <IconButton
              colorScheme='telegram'
              variant='outline'
              aria-label='edit'
              icon={<BiEdit />}
              onClick={() => {
                navigate(`/admin-dashboard/farmer/registration/${info?.row?.original?.id}`)
              }}
            />
            <IconButton
              colorScheme='red'
              variant='outline'
              aria-label='delete'
              icon={<BsTrash />}
              onClick={() => {
                setOpen(true)
                setId(info?.row?.original?.id)
              }}
            />
          </Flex>
        )
      },
    }),
  ]

  return (
    <Box>
      <Table
        title={getMessage(farmerLabel?.farmer[lang], 'list', lang)}
        columns={columns}
        rowData={values?.data ?? []}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
      />
      {/* Delete Model */}
      <ModalBox
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(farmerLabel?.farmer[lang], 'delete', lang)}
        type='delete'
        confirm={confirm}
        setConfirm={setConfirm}
        size='sm'
        submitting={submit}
      />
      {/* Show Modal */}
      <ModalBox
        size='lg'
        isOpen={openShow}
        setOpen={setOpenShow}
        title={farmerLabel?.farmer[lang]}
        data={<ShowFarmerInformation farmer={selectedData}></ShowFarmerInformation>}
      />
    </Box>
  )
}

export default FarmerList
