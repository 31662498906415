import { Box } from '@chakra-ui/react'
import endPoint from '@config/endpoint/news'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import NewsCategoryModel from '@models/news/category.model'
import { IPagination } from '@type/pagination.types'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

const NewsCategory = () => {
  const navigate = useNavigate()
  const { data, loading, get } = useAPI<NewsCategoryModel[] | null>()
  const [values, setValues] = useState<NewsCategoryModel[] | null>(null)
  const [mounted, setMounted] = useState(false)
  const { lang } = useLang()
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    perPage: 20,
    searchTerm: '',
    total: undefined,
    totalPages: 1,
    refreshTable: false,
  })

  useEffect(() => {
    if (data?.data) {
      setValues((prevValues) => {
        return prevValues ? [...prevValues, ...(data?.data as any)] : data.data
      })
    }
  }, [data?.data])

  const handleFetch = async () => {
    await get(endPoint?.newsCategory, {
      perPage: pagination?.perPage,
      page: pagination?.currentPage,
      search: pagination?.searchTerm,
    })
  }
  useQuery('newsCategory', handleFetch, {
    refetchIntervalInBackground: false,
  })

  useEffect(() => {
    if (mounted) {
      if (pagination?.currentPage > 1 && pagination?.currentPage <= pagination?.totalPages) void handleFetch()
    } else {
      setMounted(true)
    }
  }, [pagination?.currentPage])

  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.pagination?.total,
      totalPages: data?.pagination?.totalPages ?? 1,
    })
  }, [data])

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem',
      }}
    >
      {loading
        ? 'Loading'
        : values?.map((item, index: number) => (
            <Box
              sx={{
                background: 'gray.100',
                paddingY: '.45rem',
                paddingX: '1rem',
                borderRadius: '3px',
                transition: '.3s ease',
                _hover: {
                  background: 'gray.200',
                  cursor: 'pointer',
                },
              }}
              key={index}
              onClick={() => {
                navigate(`/news/?q=${item?.title?.en}`)
              }}
            >
              {item.title?.[lang]}
            </Box>
          ))}
    </Box>
  )
}

export default NewsCategory
