import Language from '@models/language.model'
import { plainToClass } from 'class-transformer'
import Videos from './videos.model'

class Playlist {
  id?: string
  title: any
  description: Language
  createdAt: Date
  status: boolean
  videos?: Videos[]

  constructor(id: string, title: any, createdAt: Date, description: Language, status: boolean, videos?: Videos[]) {
    this.id = id
    this.title = title
    this.createdAt = createdAt
    this.description = description
    this.status = status
    this.videos = videos
  }

  static plainToInstance(plainData: object): Playlist {
    return plainToClass(Playlist, plainData)
  }

  static plainToInstances(plainData: object[]): Playlist[] {
    return plainToClass(Playlist, plainData)
  }
}
export default Playlist
