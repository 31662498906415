import { Box } from "@chakra-ui/react";
import endpoint from "@config/endpoint/agriculture";
import agricultureLabel, { getMessage } from "@data/localization/agriculture";
import utilsLabels from "@data/localization/utils";
import useAPI from "@hooks/useApi";
import useLang from "@hooks/useLang";
import { IResponse } from "@interface/response.interface";
import AgricultureCategory from "@models/agricultureCategory.model";
import { IPagination } from "@type/pagination.types";
import Add from "@ui/admin/organisms/category/Add";
import Show from "@ui/admin/organisms/category/Show";
import Header from "@ui/common/molecules/Header";
import { useEffect, useState } from "react";
import { MdOutlineBookmarkAdd } from "react-icons/md";
import { useQuery } from "react-query";

const Category = () => {
  const { data, loading, setLoading, get } = useAPI<
    AgricultureCategory[] | null
  >();
  const [open, setOpen] = useState<boolean>(false);
  const [values, setValues] = useState<IResponse<AgricultureCategory[]> | null>(
    null
  );
  const [selectedValue, setSelectedValue] =
    useState<AgricultureCategory | null>(null);
  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    perPage: 20,
    searchTerm: "",
    total: undefined,
    totalPages: 1,
    refreshTable: false,
  });
  const [mounted, setMounted] = useState(false);
  const { lang } = useLang();

  // fetch data
  const handleFetch = async () => {
    await get(endpoint?.agricultureCategory, {
      perPage: pagination?.perPage,
      page: pagination?.currentPage,
      search: pagination?.searchTerm,
    });
  };

  useQuery("myData", handleFetch, {
    refetchIntervalInBackground: false,
  });

  useEffect(() => {
    if (data?.data) {
      setValues({
        ...data,
        data: AgricultureCategory?.plainToInstances(data?.data),
        pagination: data?.pagination,
      });
      if (data?.pagination)
        setPagination({
          ...pagination,
          currentPage: data?.pagination?.currentPage ?? 1,
          perPage: data?.pagination?.perPage ?? 20,
          total: data?.pagination?.total ?? undefined,
          totalPages: data?.pagination?.totalPages ?? undefined,
        });
    }
  }, [data]);

  // refresh
  useEffect(() => {
    if (pagination?.refreshTable) {
      void handleFetch();
      setPagination({ ...pagination, refreshTable: false });
    }
  }, [pagination?.refreshTable]);

  // pagination
  useEffect(() => {
    if (mounted) {
      if (pagination?.currentPage === values?.pagination?.currentPage) return;
      void handleFetch();
    } else {
      setMounted(true);
    }
  }, [pagination?.currentPage]);

  // row per page
  useEffect(() => {
    if (mounted) {
      if (pagination?.perPage === values?.pagination?.perPage) return;
      void handleFetch();
    } else {
      setMounted(true);
    }
  }, [pagination?.perPage]);

  let timer: any;

  // search
  useEffect(() => {
    if (mounted) {
      if (pagination?.searchTerm === values?.pagination?.searchTerm) return;
      timer = setTimeout(() => {
        void handleFetch();
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setMounted(true);
    }
  }, [pagination?.searchTerm]);

  return (
    <Box>
      <Header
        buttonText={utilsLabels?.add[lang]}
        buttonIcon={<MdOutlineBookmarkAdd />}
        header={getMessage(
          agricultureLabel?.agricultureCategory[lang],
          "add",
          lang
        )}
        setOpen={setOpen}
      />
      <Add
        values={values}
        setValues={setValues}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        open={open}
        setOpen={setOpen}
      />
      <Show
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setLoading={setLoading}
        values={values}
        setValues={setValues}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
      />
    </Box>
  );
};

export default Category;
