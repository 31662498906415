import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Spacer, useToast } from '@chakra-ui/react'
import notificationLabel from '@data/localization/notification'
import utilsLabel from '@data/localization/utils'
import EncryptDecrypt from '@functions/EncryptDecrypt'
import useAuth from '@hooks/useAuth'
import useLang from '@hooks/useLang'
import LanguageToggle from '@ui/common/molecules/LanguageToggle'
import ModalBox from '@ui/common/molecules/Modal'
import { Colors } from '@utils/Colors'
import { useEffect, useState } from 'react'
import { BiHelpCircle } from 'react-icons/bi'
import { HiOutlineChevronDown } from 'react-icons/hi'
import { MdOutlineLogout } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const { lang } = useLang()
  const { user, setUser } = useAuth()

  const [open, setOpen] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const navigate = useNavigate()
  const toast = useToast()

  const handleLogout = () => {
    setUser(null)

    toast.closeAll()
    toast({
      title: notificationLabel?.success[lang],
      description: notificationLabel?.logoutSuccessfully[lang],
      status: 'success',
      isClosable: true,
      duration: 5000,
    })
    localStorage.removeItem('service_delivery_agriculture_token')
    localStorage.removeItem('service_delivery_agriculture_user')

    navigate('/auth/admin', { replace: true })
  }

  useEffect(() => {
    if (confirm) {
      handleLogout()
      setConfirm(false)
    }
  }, [confirm])

  return (
    <Box py='1' pl='2' pr='1' bg={Colors?.dashboardColor} width={'100%'}>
      <Flex
        direction='row'
        flexWrap={{
          md: 'nowrap',
          base: 'wrap',
        }}
        justifyContent='space-between'
        alignItems='center'
        gap='5'
      >
        <Box
          textTransform='capitalize'
          display={{
            md: 'block',
            base: 'none',
          }}
          color='white'
        >
          {utilsLabel?.welcome[lang]} : &nbsp;
          <span
            style={{
              textTransform: 'capitalize',
              fontWeight: '500',
            }}
          >
            {user?.email}
          </span>{' '}
          &nbsp; &nbsp; {utilsLabel?.roleLevel[lang]} :
          <span
            style={{
              textTransform: 'capitalize',
              fontWeight: '500',
            }}
          >
            &nbsp; {user?.role?.replaceAll('_', ' ')?.toLowerCase()}
          </span>
        </Box>
        <Spacer />
        <LanguageToggle />

        <Menu>
          <MenuButton size='sm' as={Button} rightIcon={<HiOutlineChevronDown />}>
            <Flex alignItems='center'>
              <span
                style={{
                  textTransform: 'capitalize',
                  width: '100%',
                }}
              >
                {user?.role?.replaceAll('_', ' ')?.toLowerCase()}
              </span>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem icon={<BiHelpCircle />}>{utilsLabel?.help[lang]}</MenuItem>
            <MenuItem
              icon={<MdOutlineLogout />}
              onClick={() => {
                setOpen(true)
              }}
            >
              {utilsLabel?.logout[lang]}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <ModalBox
        isOpen={open}
        setOpen={setOpen}
        title='Logout'
        type='logout'
        data=''
        size='sm'
        confirm={confirm}
        setConfirm={setConfirm}
      />
    </Box>
  )
}

export default Navbar
