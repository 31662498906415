import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Icon,
  IconProps,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import image from '@config/constant/image'
import { BiSupport } from 'react-icons/bi'

export default function Hero() {
  return (
    <Container>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack spacing={{ base: 5, md: 10 }}>
          <Heading lineHeight={1.1}>
            <Text fontSize={'40px'}>Cultivate a Sustainable Future,</Text>
            <Text fontSize={'30px'} pt='1'>
              Join the Agricultural Revolution Today!
            </Text>
          </Heading>
          <Text fontSize={'14px'} color={'gray.500'}>
            Welcome to our agricultural community, where we're passionate about promoting sustainable farming practices
            and nurturing a healthier planet. Discover our comprehensive resources and join us in cultivating a brighter
            future for generations to come.!
          </Text>
          <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              variant='primary'
              _hover={{ bg: 'pink.300' }}
            >
              Get started
            </Button>
            <Button
              rounded={'full'}
              size={'lg'}
              fontWeight={'normal'}
              px={6}
              leftIcon={<BiSupport color={'gray.300'} />}
            >
              Live Chat
            </Button>
          </Stack>
        </Stack>
        <Flex justify={'center'} align={'center'} position={'relative'} w={'full'}>
          <Blob
            w={'100%'}
            h={'100%'}
            position={'absolute'}
            top={'-20%'}
            left={0}
            zIndex={-1}
            color={useColorModeValue('red.50', 'red.400')}
          />
          <Box
            position={'relative'}
            height={'400px'}
            rounded={'2xl'}
            boxShadow={'2xl'}
            width={'full'}
            overflow={'hidden'}
          >
            <Image
              opacity={0.75}
              alt={'Hero Image'}
              fit={'cover'}
              align={'center'}
              w={'100%'}
              h={'100%'}
              src={image.hero}
            />
          </Box>
        </Flex>
      </Stack>
    </Container>
  )
}

export const Blob = (props: IconProps) => {
  return (
    <Icon width={'100%'} viewBox='0 0 578 440' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z'
        fill='currentColor'
      />
    </Icon>
  )
}
