import { Box, Button, Container, Grid, Heading, Image, Stack } from '@chakra-ui/react'
import useAPI from '@hooks/useApi'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

interface CardProps {
  heading: string
  image: string
  id: string
}

interface ICommercialAgriculture {
  id: string
  title: {
    en: string
    ne: string
  }
  media: {
    medias: Array<{
      name: string
    }>
  }
  agricultureName: Array<{
    id: string
    title: {
      en: string
      ne: string
    }
    media: {
      medias: Array<{
        name: string
      }>
    }
  }>
}

const Card = ({ heading, image, id }: CardProps) => {
  const url = heading.replaceAll(' ', '-')
  return (
    <Box w={'full'} borderWidth='1px' borderRadius='lg' overflow='hidden' p={5}>
      <Stack align={'start'} spacing={2}>
        <Image mb='2' opacity={0.65} width={'100%'} height='100px' objectFit={'contain'} src={image}></Image>
        <Box mt={2} pb='2'>
          <Heading size='md'>{heading}</Heading>
        </Box>
        <Link
          to={`${url}`}
          state={{
            id,
          }}
        >
          <Button variant={'outline'} colorScheme={'blue'} p='0' size={'sm'}>
            Learn more
          </Button>
        </Link>
      </Stack>
    </Box>
  )
}

const CommercialAgriculture = () => {
  const location = useLocation()
  const heading = location.pathname.split('/').slice(-1)[0].replaceAll('-', ' ')
  const id = location.state.id

  const { getById } = useAPI()

  const [values, setValues] = useState<ICommercialAgriculture | null>(null)

  const handleFetch = async () => {
    const res = (await getById('/agriculture/type', id)) as any
    if (res) {
      setValues(res)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [])

  return (
    <Box py='5'>
      <Container>
        <Heading fontSize={{ base: '1xl', sm: '1xl' }} fontWeight={'bold'}>
          Commercial Agriculture
        </Heading>
        <Heading pt='1' fontSize={{ base: '2xl', sm: '3xl' }} fontWeight={'bold'} textTransform='capitalize'>
          {heading}
        </Heading>

        <Image
          my='2'
          borderRadius={'2px'}
          opacity='.7'
          src={values?.media?.medias?.[0]?.name}
          width='100%'
          height={{
            base: '200px',
            sm: '200px',
            md: '300px',
            lg: '350px',
          }}
          objectFit={'contain'}
          alt='image'
        />

        <Grid templateColumns={{ lg: 'repeat(4,1fr)', md: 'repeat(4,1fr)', base: 'repeat(1,1fr)' }} gap='5' mt='5'>
          {values?.agricultureName &&
            values?.agricultureName?.length > 0 &&
            values?.agricultureName?.map((item, index: number) => {
              return <Card key={index} heading={item?.title?.en} image={item?.media?.medias[0]?.name} id={item?.id} />
            })}
        </Grid>
      </Container>
    </Box>
  )
}

export default CommercialAgriculture
