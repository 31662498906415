import { AxiosBaseInstance } from "@api/index";
import pagination from "@config/constant/pagination";
import { IPagination } from "@type/pagination.types";
import { useState } from "react";

interface IGetParameter {
  perPage?: number;
  page?: number;
  search?: string;
  queryName?: string;
  queryValue?: string;
}

interface IPaginatedResponse<T> {
  data: T;
  pagination: IPagination;
}

const useAPI = <T>() => {
  const [dataById, setDataById] = useState<T | null>(null);
  const [data, setData] = useState<IPaginatedResponse<T> | null>(null);
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const get = async (endPoint: string, args?: IGetParameter): Promise<T> => {
    setLoading(true);
    let response;
    try {
      if (args)
        endPoint = `${endPoint}/?search=${args?.search}&perPage=${
          args?.perPage ?? pagination?.page
        }&page=${args?.page}&${args?.queryName}=${args?.queryValue}`;
      else endPoint = `${endPoint}`;

      response = await AxiosBaseInstance.get(endPoint);

      setData(response?.data?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

    return response?.data?.data;
  };

  const getById = async (endPoint: string, id: string): Promise<T> => {
    setLoading(true);
    let response;
    try {
      endPoint = `${endPoint}/${id}`;
      response = await AxiosBaseInstance.get(endPoint);
      setDataById(response?.data?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

    return response?.data?.data;
  };

  return { data, error, loading, setLoading, get, getById, dataById };
};

export default useAPI;
