const AUTH_LABELS = {
  governmentOfNepal: {
    en: 'Government of Nepal',
    ne: 'नेपाल सरकार',
  },
  nagarpalikaServiceDeliveryApp: {
    en: 'Nagarpalika Service Delivery App',
    ne: 'नगरपालिका सेवा डिलिभरी एप्प',
  },
  nagarpalikaName: {
    en: 'Koshi Province,Nepal',
    ne: 'कोशि प्रदेश,नेपाल',
  },
  phidimMunicipality : {
    en : 'Phidim Municipality',
    ne : 'फिदिम नगरपालिका'
  },
  email: {
    en: 'Email address',
    ne: 'इमेल ठेगाना',
  },
  emailPlaceholder: {
    en: 'Enter your email address',
    ne: 'आफ्नो इमेल ठेगाना लेख्नुहोस्',
  },
  mobileNumber: {
    en: 'Mobile Number',
    ne: 'मोबाइल नम्बर',
  },
  mobileNumberPlaceholder: {
    en: 'Enter your Mobile Number',
    ne: 'आफ्नो मोबाईल नं. लेख्नुहोस्',
  },
  password: {
    en: 'Password',
    ne: 'पासवर्ड',
  },
  newPassword: {
    en: 'New Password',
    ne: 'नयाँ पासवर्ड',
  },
  oldPassword: {
    en: 'Old Password',
    ne: 'पुरानो पासवर्ड',
  },
  oldPasswordPlaceholder: {
    en: 'Enter your old password',
    ne: 'आफ्नो पुरानो पासवर्ड लेख्नुहोस्',
  },
  newPasswordPlaceholder: {
    en: 'Enter your new password',
    ne: 'आफ्नो नयाँ पासवर्ड लेख्नुहोस्',
  },
  confirmPassword: {
    en: 'Confirm Password',
    ne: 'पासवर्ड पुष्टि गर्नुहोस्',
  },
  otpVerification: {
    en: 'OTP Verification',
    ne: 'OTP प्रमाणिकरण गर्नुहोस्',
  },
  enterOtp: {
    en: 'Enter OTP',
    ne: 'OTP लेख्नुहोस्',
  },
  passwordPlaceholder: {
    en: 'Enter your password',
    ne: 'आफ्नो पासवर्ड लेख्नुहोस्',
  },
  confirmPasswordPlaceholder: {
    en: 'Enter your password again',
    ne: 'आफ्नो पासवर्ड लेख्नुहोस्',
  },
  rememberMe: {
    en: 'Remember Password',
    ne: 'पासवर्ड याद राख्नुहोस्',
  },
  forgotPassword: {
    en: 'Forgot Password ?',
    ne: 'पासवर्ड भुल्नु भयो ?',
  },
  login: {
    en: 'Login',
    ne: 'लग इन',
  },
  register: {
    en: 'Register',
    ne: 'रजिस्टर',
  },
  dontHaveAnAccount: {
    en: "Don't have an account ?",
    ne: 'खाता छैन ?',
  },
  alreadyHaveAnAccount: {
    en: ' Already have an account ?',
    ne: 'खाता छ ?',
  },
  firstName: {
    en: 'First Name',
    ne: 'पहिलो नाम  ',
  },
  middleName: {
    en: 'Middle Name',
    ne: 'मध्य नाम  ',
  },
  lastName: {
    en: 'Last Name',
    ne: 'थर ',
  },
  submit: {
    en: 'Submit',
    ne: 'पेश गर्नुहोस्',
  },
  resetPassword: {
    en: 'Reset Password',
    ne: 'पासवर्ड रिसेट गर्नुहोस्',
  },
  backtoLogin: {
    en: 'Back to Login',
    ne: 'लग इनमा फर्कनुहोस्',
  },
  applicantRegistration: {
    en: 'Applicant Registration',
    ne: 'आवेदक रजिस्ट्रेसन',
  },

  adminRegistation: {
    en: 'Admin Registration',
    ne: 'व्यवस्थापक रजिस्ट्रेसन',
  },
  applicantLogin: {
    en: 'Applicant Login',
    ne: 'आवेदक लग इन',
  },
  adminLogin: {
    en: 'Admin Login',
    ne: 'व्यवस्थापक लग इन',
  },
  role: {
    en: 'Role',
    ne: 'भूमिका',
  },
  roleLevel: {
    en: 'Role Level',
    ne: 'भूमिका स्तर',
  },
  emailOrMobileNumber: {
    en: 'Email/Mobile Number',
    ne: 'इमेल वा मोबाइल नम्बर',
  },
  emailOrMobileNumberPlaceholder: {
    en: 'Enter your Email address or Mobile Number',
    ne: 'आफ्नो इमेल ठेगाना वा मोबाइल नम्बर हाल्नुहोस',
  },
  firstNamePlaceholder: {
    en: 'Enter your First Name',
    ne: 'आफ्नो पहिलो नाम लेख्नुहोस्',
  },
  middleNamePlaceholder: {
    en: 'Enter your Middle Name',
    ne: 'आफ्नो मध्य नाम लेख्नुहोस्',
  },
  lastNamePlaceholder: {
    en: 'Enter your Last Name',
    ne: 'आफ्नो थर लेख्नुहोस्',
  },
  verifyYourEmail: {
    en: 'Verify Your Email Address',
    ne: 'आफ्नो इमेल ठेगाना प्रमाणित गर्नुहोस्',
  },
  verify: {
    en: 'Verify',
    ne: 'प्रमाणित गर्नुहोस्',
  },
  verifying: {
    en: 'Verifying...',
    ne: 'प्रमाणित गर्दै...',
  },
  logging: {
    en: 'Logging...',
    ne: 'लग इन गर्दै...',
  },

  roleLevelPlaceholder: {
    en: 'select role Level',
    ne: 'भूमिका स्तर छान्नुहोस्',
  },
  rolePlaceholder: {
    en: 'select role',
    ne: 'भूमिका छान्नुहोस्',
  },
  updatePassword: {
    en: 'Update Password',
    ne: 'पासवर्ड अपडेट गर्नुहोस्',
  },
  ward: {
    en: 'Admin Ward Number',
    ne: 'प्रशासक वार्ड नम्बर',
  },
  superAdminRegistation: {
    en: 'Super Admin Registration',
    ne: 'सुपर व्यवस्थापक रजिस्ट्रेसन',
  },
  permission: {
    en: 'Permission',
    ne: 'अनुमति',
  },
  cancel: {
    en: 'Cancel',
    ne: 'रद्द गर्नुहोस्',
  },
}
export default AUTH_LABELS as any
