import { Box, Flex, IconButton, useToast } from '@chakra-ui/react'
import endPoint from '@config/endpoint/imageGallery'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import tableLabel from '@data/localization/table'
import videoGalleryLabel from '@data/localization/videoGallery'
import { getMessage } from '@functions/generateMessage'
import tableSnGenerate from '@functions/tableSnGenerate'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { ISimpleTable } from '@interface/global.interface'
import { IResponse } from '@interface/response.interface'
import ImageGalleryModel from '@models/imageGallery/imageGallery.model'
import { createColumnHelper } from '@tanstack/react-table'
import { IPagination } from '@type/pagination.types'
import ModalBox from '@ui/common/molecules/Modal'
import SimpleTable from '@ui/common/molecules/SimpleTable'
import Table from '@ui/common/organisms/Table'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { BiEdit, BiShowAlt } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import { useQueryClient } from 'react-query'
interface IProps {
  values: IResponse<ImageGalleryModel[]> | null
  setValues: (data: IResponse<ImageGalleryModel[]>) => void
  loading: boolean
  setLoading: (data: boolean) => void
  pagination: IPagination
  setPagination: (data: IPagination) => void
  selectedValue: ImageGalleryModel | null
  setSelectedValue: (data: ImageGalleryModel | null) => void
}

const Show = (props: IProps) => {
  const { values, pagination, setPagination, loading, setSelectedValue } = props
  const { remove } = useAPI()
  const { lang } = useLang()
  const toast = useToast()
  const [open, setOpen] = useState<boolean>(false)
  const [openShow, setOpenShow] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [id, setId] = useState<string | null>(null)
  const [selectedData, setSelectedData] = useState<ISimpleTable[] | null>(null)
  const [submit, setSubmitting] = useState<boolean>(false)

  const queryClient = useQueryClient()

  // function to delete
  const handleDelete = async () => {
    try {
      setSubmitting(true)
      const res = await remove(`${endPoint?.gallery}/${id}`)
      if (res === true) {
        await queryClient.invalidateQueries('videos')
        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(notificationLabel?.videos[lang], notificationLabel?.delete[lang], lang),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      setConfirm(false)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (confirm) {
      void handleDelete()
      setConfirm(false)
    }
  }, [confirm])

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('SN', {
      header: () => <span>{tableLabel?.sn[lang]}</span>,
      size: 10,
      cell: (info: any) => {
        return <span>{tableSnGenerate(info.row.index, pagination?.currentPage, pagination?.perPage)}</span>
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>{tableLabel?.title[lang]}</span>,
      size: 200,
      cell: (info: any) => {
        return <span>{info?.row?.original?.title?.[lang].substring(0, 75)}..</span>
      },
    }),

    columnHelper.accessor('album', {
      id: 'album',
      header: () => <span>{tableLabel?.album[lang]}</span>,
      size: 200,
      cell: (info: any) => {
        return <span>{info?.row?.original?.album?.title?.[lang]}</span>
      },
    }),
    columnHelper.accessor('publishDate', {
      id: 'publishDate',
      header: () => <span>{tableLabel?.publishedAt[lang]}</span>,
      size: 200,
      cell: (info: any) => {
        return (
          <span>
            {info?.row?.original?.createdAt ? moment(info?.row?.original?.createdAt).format('YYYY-MM-DD') : ''}
          </span>
        )
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => <span>{tableLabel?.status[lang]}</span>,
      size: 100,
      cell: (info: any) => {
        return <span>{info?.row?.original?.status ? 'Published' : 'Unpublished'}</span>
      },
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {tableLabel?.action[lang]}
        </span>
      ),
      size: 100,
      cell: (info: any) => {
        console.log(info?.row?.original?.videoUrl)
        return (
          <Flex gap='3' justifyContent={'center'}>
            <IconButton
              colorScheme='green'
              variant='outline'
              aria-label='show'
              icon={<BiShowAlt />}
              onClick={() => {
                console.log(info?.original)
                setOpenShow(true)
                setSelectedData([
                  {
                    title: 'Title - english',
                    description: `${info?.row?.original?.title?.en} `,
                  },
                  {
                    title: 'Title - nepali',
                    description: `${info?.row?.original?.title?.ne}`,
                  },
                  {
                    title: 'Album',
                    description: `${info?.row?.original?.album?.title?.en} - ${info?.row?.original?.album?.title?.ne}`,
                  },
                  {
                    title: 'Status',
                    description: `${info?.row?.original?.status ? 'Published' : 'Unpublished'}`,
                  },
                  {
                    title: 'Published at',
                    description: `${
                      info?.row?.original?.createdAt ? moment(info?.row?.original?.createdAt).format('YYYY-MM-DD') : ''
                    }`,
                  },
                ])
              }}
            />
            <IconButton
              colorScheme='telegram'
              variant='outline'
              aria-label='edit'
              icon={<BiEdit />}
              onClick={() => {
                setSelectedValue(info?.row?.original)
              }}
            />
            <IconButton
              colorScheme='red'
              variant='outline'
              aria-label='delete'
              icon={<BsTrash />}
              onClick={() => {
                setOpen(true)
                setId(info?.row?.original?.id)
              }}
            />
          </Flex>
        )
      },
    }),
  ]

  return (
    <Box>
      <Table
        title={getMessage(videoGalleryLabel?.videos[lang], 'list', lang)}
        columns={columns}
        rowData={values?.data ?? []}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
      />
      {/* Delete Model */}
      <ModalBox
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(videoGalleryLabel?.videos[lang], 'delete', lang)}
        type='delete'
        confirm={confirm}
        setConfirm={setConfirm}
        submitting={submit}
        size='sm'
      />
      {/* Show Modal */}
      <ModalBox
        isOpen={openShow}
        setOpen={setOpenShow}
        title={videoGalleryLabel?.gallery[lang]}
        data={<SimpleTable data={selectedData ?? []} />}
      />
    </Box>
  )
}

export default Show
