import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  useToast,
} from '@chakra-ui/react'
import endPoint from '@config/endpoint/agriculture'
import { typeSchema } from '@config/schema/type.schema'
import agricultureLabel, { getMessage } from '@data/localization/agriculture'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import utilsLabel from '@data/localization/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import AgricultureType from '@models/agricultureType.model'
import NepaliInput from '@ui/common/atoms/NepaliInput'
import RequireSign from '@ui/common/atoms/RequireSign'
import FileUpload from '@ui/common/molecules/FileUpload'
import Modal from '@ui/common/molecules/Modal'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

interface IProps {
  open: boolean
  setOpen: (data: boolean) => void
  values: IResponse<AgricultureType[]> | null
  setValues: (data: IResponse<AgricultureType[]>) => void
  selectedValue: AgricultureType | null
  setSelectedValue: (data: AgricultureType | null) => void
}

interface ICategory {
  id: string
  title: {
    en: string
    ne: string
  }
}

const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props
  const { post, patch, put, get } = useAPI<AgricultureType>()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [categoryList, setCategoryList] = useState<ICategory[] | null>(null)
  const { lang } = useLang()

  useEffect(() => {
    if (open) void handleFetchCategory()
  }, [open])

  const handleFetchCategory = async () => {
    const res: any = await get(`${endPoint?.agricultureCategory}/all`)
    if (res?.data?.length > 0) {
      setCategoryList(res?.data)
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(typeSchema),
    defaultValues: {
      title: {
        en: '',
        ne: '',
      },
      category: '',
      media: '',
    },
  })
  useEffect(() => {
    if (selectedValue) {
      reset({
        title: {
          en: selectedValue?.title?.en ?? '',
          ne: selectedValue?.title?.ne ?? '',
        },
        category: selectedValue?.category?.id ?? '',
      })
    }
  }, [selectedValue])

  const onSubmit = async (data: any) => {
    try {
      let res
      if (selectedValue) {
        res = await put(endPoint?.agricultureType, {
          id: selectedValue?.id?.toString(),
          title: data.title,
          media: data.media,
          category: data.category,
        })
      } else {
        res = await post(endPoint?.agricultureType, {
          title: data.title,
          media: data.media,
          category: data.category,
        })
      }
      if (res?.data) {
        await queryClient.invalidateQueries('agricultureType')
        setSelectedValue(null)
        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.agricultureType[lang],
            selectedValue ? notificationLabel?.updated[lang] : notificationLabel?.added[lang],
            lang
          ),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else if (res?.error) {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res?.error?.response?.data?.message ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      reset({
        title: {
          en: '',
        },
        media: '',
        category: '',
      })
      setSelectedValue(null)
    }
  }
  useEffect(() => {
    if (selectedValue) {
      setOpen(true)
    }
  }, [selectedValue])

  return (
    <div>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        onCloseComplete={() => {
          setOpen(false)
          setSelectedValue(null)
          reset({
            title: {
              en: '',
            },
            media: '',
            category: '',
          })
        }}
        title={getMessage(agricultureLabel?.agricultureType[lang], 'add', lang)}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
              >
                <FormControl isInvalid={errors.title?.en != null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size='sm'
                    variant={errors?.title?.en != null ? 'error' : ''}
                    type='text'
                    {...register('title.en')}
                    placeholder={'Enter title name'}
                  />
                  <FormErrorMessage>{errors?.title?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={'शिर्षक लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('title.ne', e)
                    }}
                    value={watch('title.ne')}
                  />
                  <FormErrorMessage>{errors?.title?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
              <FormControl pt='5' isInvalid={errors.media != null}>
                <FormLabel>
                  {agricultureLabel?.selectCoverImage[lang]} <RequireSign />
                </FormLabel>
                <FileUpload
                  selectFormat={`.png, .jpg, .jpeg`}
                  selectedId={selectedValue?.media?.id}
                  onChange={(data: string) => {
                    setValue('media', data)
                  }}
                  selectedFile={selectedValue?.media?.medias?.[0]?.name ?? ''}
                  relatedTo='Agriculture'
                />
                <FormErrorMessage>{errors?.media?.message}</FormErrorMessage>
              </FormControl>
              <Box pt='5'>
                <FormControl isInvalid={errors.category != null}>
                  <FormLabel>
                    {agricultureLabel?.selectAgricultureCategory[lang]} <RequireSign />
                    <FormErrorMessage>{errors?.category?.message}</FormErrorMessage>
                  </FormLabel>
                  <RadioGroup defaultValue={selectedValue?.category?.id?.toString()}>
                    <Flex wrap={'wrap'} gap='4'>
                      {categoryList &&
                        categoryList?.length > 0 &&
                        categoryList?.map((item: any, index: number) => {
                          return (
                            <Radio
                              value={item?.id?.toString()}
                              {...register('category')}
                              key={index}
                              colorScheme='facebook'
                            >
                              {item?.title?.[lang]}
                            </Radio>
                          )
                        })}
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Flex my='5' gap='5' justifyContent={'flex-end'}>
                <Button
                  size='sm'
                  variant={'outline'}
                  colorScheme='red'
                  onClick={() => {
                    setOpen(false)
                    setSelectedValue(null)
                    reset({
                      title: {
                        en: '',
                      },
                      media: '',
                      category: '',
                    })
                  }}
                >
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type='submit'
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size='sm'
                  variant={'primary'}
                >
                  {selectedValue ? utilsLabel?.update[lang] : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </div>
  )
}

export default Add
