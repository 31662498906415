import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import modelLabel from '@data/localization/model'
import utilsLabel from '@data/localization/utils'
import useLang from '@hooks/useLang'

interface IProps {
  isOpen: boolean
  setOpen: (data: boolean) => void
  title: string
  data?: any
  size?: 'sm' | 'md' | 'lg'
  type?: 'logout' | 'delete'
  confirm?: boolean
  submitting?: boolean
  setSubmitting?: (data: boolean) => void
  setConfirm?: (data: boolean) => void
  onCloseComplete?: () => void
}

const ModalBox = (props: IProps) => {
  const { isOpen, title, setOpen, size, setConfirm, type, data, submitting, onCloseComplete } = props
  console.log('🚀 ~ file: Modal.tsx:32 ~ ModalBox ~ data:', data)
  const { onClose } = useDisclosure()
  const { lang } = useLang()

  return (
    <Modal onCloseComplete={onCloseComplete} onClose={onClose} isOpen={isOpen} scrollBehavior={'inside'}>
      <ModalOverlay />
      <ModalContent
        minWidth={{
          lg: size === 'sm' ? '40vw' : size === 'lg' ? '80vw' : '60vw',
          md: size === 'lg' ? '98vw' : '80vw',
          base: '98vw',
        }}
      >
        <ModalHeader fontSize={'15px'}>{title}</ModalHeader>
        <ModalCloseButton
          onClick={() => setOpen(false)}
          _focus={{
            outline: 'none',
            boxShadow: 'none',
          }}
        />
        <ModalBody>
          {type === 'logout' ? (
            <div>{modelLabel?.areYouSureToLogout[lang]}</div>
          ) : type === 'delete' ? (
            <div>{modelLabel?.areYouSureToDelete[lang]}</div>
          ) : (
            data
          )}
        </ModalBody>
        {type === 'delete' || type === 'logout' ? (
          <ModalFooter
            sx={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            <Button size='sm' colorScheme={'red'} variant='outline' onClick={() => setOpen(false)}>
              {utilsLabel?.cancel[lang]}
            </Button>
            <Button
              size='sm'
              variant='primary'
              isLoading={submitting}
              loadingText={utilsLabel?.confirming[lang]}
              onClick={() => {
                if (setConfirm !== undefined) setConfirm(true)
              }}
            >
              {utilsLabel?.confirm[lang]}
            </Button>
          </ModalFooter>
        ) : (
          ''
        )}
      </ModalContent>
    </Modal>
  )
}

ModalBox.defaultProps = {
  submitting: false,
}

export default ModalBox
