import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  useToast,
} from '@chakra-ui/react'
import endpoint from '@config/endpoint/agriculture'
import { agricultureNameSchema } from '@config/schema/agricultureName'
import agricultureLabel, { getMessage } from '@data/localization/agriculture'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import utilsLabel from '@data/localization/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import AgricultureName from '@models/agricultureName.model'
import NepaliInput from '@ui/common/atoms/NepaliInput'
import RequireSign from '@ui/common/atoms/RequireSign'
import FileUpload from '@ui/common/molecules/FileUpload'
import Modal from '@ui/common/molecules/Modal'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

interface IProps {
  open: boolean
  setOpen: (data: boolean) => void
  values: IResponse<AgricultureName[]> | null
  setValues: (data: IResponse<AgricultureName[]>) => void
  selectedValue: AgricultureName | null
  setSelectedValue: (data: AgricultureName | null) => void
}

interface IAgricultureType {
  id: string
  title: {
    en: string
    ne: string
  }
}

const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props
  const { post, patch, put, get } = useAPI<AgricultureName>()
  const [agricultureType, setAgricultureType] = useState<IAgricultureType[] | null>(null)
  const toast = useToast()
  const queryClient = useQueryClient()
  const { lang } = useLang()

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(agricultureNameSchema),
    defaultValues: {
      title: {
        en: '',
        ne: '',
      },
      media: '',
      agricultureType: '',
    },
  })

  useEffect(() => {
    if (selectedValue) {
      reset({
        title: {
          en: selectedValue?.title?.en ?? '',
          ne: selectedValue?.title?.ne ?? '',
        },
      })
    }
  }, [selectedValue])

  const handleFetchCategory = async () => {
    const res: any = await get(`${endpoint?.agricultureType}/all`)
    if (res?.data?.length > 0) {
      setAgricultureType(res?.data)
    }
  }
  useEffect(() => {
    if (open) void handleFetchCategory()
  }, [open])

  const onSubmit = async (data: any) => {
    try {
      let res
      if (selectedValue) {
        res = await put(endpoint?.agricultureName, {
          id: selectedValue?.id?.toString(),
          title: data.title,
          media: data.media,
          agricultureType: data.agricultureType?.toString(),
        })
      } else {
        res = await post(endpoint?.agricultureName, {
          title: data.title,
          media: data.media,
          agricultureType: data.agricultureType?.toString(),
        })
      }
      if (res?.data) {
        await queryClient.invalidateQueries('myData')

        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.agricultureName[lang],
            selectedValue ? notificationLabel?.updated[lang] : notificationLabel?.added[lang],
            lang
          ),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else if (res?.error) {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res?.error?.response?.data?.message ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      reset({
        title: {
          en: '',
        },
        media: '',
        agricultureType: '',
      })
      setSelectedValue(null)
    }
  }

  useEffect(() => {
    if (selectedValue) {
      setOpen(true)
    }
  }, [selectedValue])

  return (
    <Box>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        onCloseComplete={() => {
          setSelectedValue(null)
          reset({
            title: {
              en: '',
              ne: '',
            },
            media: '',
            agricultureType: '',
          })
        }}
        title={getMessage(agricultureLabel?.agricultureName[lang], 'add', lang)}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
              >
                <FormControl isInvalid={errors.title?.en != null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size='sm'
                    variant={errors?.title?.en != null ? 'error' : ''}
                    type='text'
                    {...register('title.en')}
                    placeholder={'Enter title name'}
                  />
                  <FormErrorMessage>{errors?.title?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={'शिर्षक लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('title.ne', e)
                    }}
                    value={watch('title.ne')}
                  />
                  <FormErrorMessage>{errors?.title?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
              <FormControl pt='5' isInvalid={errors.media != null}>
                <FormLabel>
                  {agricultureLabel?.selectCoverImage[lang]} <RequireSign />
                </FormLabel>
                <FileUpload
                  selectFormat={`.png, .jpg, .jpeg`}
                  selectedId={selectedValue?.media?.id}
                  onChange={(data: string) => {
                    setValue('media', data)
                  }}
                  selectedFile={selectedValue?.media?.medias?.[0]?.name ?? ''}
                  relatedTo='Agriculture'
                />
                <FormErrorMessage>{errors?.media?.message}</FormErrorMessage>
              </FormControl>

              <Box pt='5'>
                <FormControl isInvalid={errors.agricultureType != null}>
                  <FormLabel>
                    {agricultureLabel?.selectAgricultureType[lang]} <RequireSign />
                    <FormErrorMessage>{errors?.agricultureType?.message}</FormErrorMessage>
                  </FormLabel>
                  <RadioGroup defaultValue={selectedValue?.agricultureType?.id?.toString()}>
                    <Flex wrap={'wrap'} gap='4'>
                      {agricultureType &&
                        agricultureType?.length > 0 &&
                        agricultureType?.map((item: any, index: number) => {
                          return (
                            <Radio
                              value={item?.id?.toString()}
                              {...register('agricultureType')}
                              key={index}
                              colorScheme='facebook'
                            >
                              {item?.title[lang]}
                            </Radio>
                          )
                        })}
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Flex my='5' gap='5' justifyContent={'flex-end'}>
                <Button size='sm' variant={'outline'} colorScheme='red' onClick={() => setOpen(false)}>
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type='submit'
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size='sm'
                  variant={'primary'}
                >
                  {selectedValue ? utilsLabel?.update[lang] : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </Box>
  )
}

export default Add
