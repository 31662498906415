import {
  Box,
  Button,
  Table as CTable,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import TABLE_LABELS from '@data/localization/table'
import useLang from '@hooks/useLang'
import { rankItem } from '@tanstack/match-sorter-utils'
import { ColumnDef, FilterFn, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { IPagination } from '@type/pagination.types'
import NoDataFound from '@ui/common/molecules/NoDataFound'
import { Colors } from '@utils/Colors'
import { memo, useEffect, useRef, useState } from 'react'
import { BsPrinter, BsSearch, BsThreeDots } from 'react-icons/bs'
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from 'react-icons/hi'
import { MdClose } from 'react-icons/md'
import { TbDatabaseExport, TbRefresh } from 'react-icons/tb'
import * as XLSX from 'xlsx'

interface IProps {
  // eslint-disable-next-line
  columns: ColumnDef<any>[]
  rowData: any
  pagination: IPagination
  setPagination: any
  title?: string
  showSearchBar?: boolean
  showTools?: boolean
  loading?: boolean
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const saveAsExcelFile = (buffer: any, fileName: string) => {
  const data = new Blob([buffer], { type: 'application/octet-stream' })
  const url = window.URL.createObjectURL(data)
  const link = document.createElement('a')
  link.href = url
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(url)
}

const Table = (props: IProps) => {
  const { lang } = useLang()
  const { columns, title, rowData, pagination, setPagination, showSearchBar, showTools, loading } = props
  const [data, setData] = useState(() => [...(rowData ?? [])])
  const [goToPage, setGoToPage] = useState<number>(pagination?.currentPage ?? 1)
  const printRef = useRef<HTMLDivElement>(null)
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
  })

  useEffect(() => {
    setData(rowData)
  }, [rowData])

  useEffect(() => {
    void setGoToPage(pagination?.currentPage)
  }, [pagination?.currentPage])

  const searchFn = (searchItem: string) => {
    setPagination({ ...pagination, searchTerm: searchItem })
  }

  const nextFn = () => {
    if (pagination?.currentPage === pagination?.totalPages) return
    let tempcurrentPage = pagination.currentPage
    tempcurrentPage = tempcurrentPage + 1
    setPagination({ ...pagination, currentPage: tempcurrentPage })
  }

  const prevFn = () => {
    if (pagination?.currentPage === 1) return
    let tempcurrentPage = pagination.currentPage
    tempcurrentPage = tempcurrentPage - 1
    setPagination({ ...pagination, currentPage: tempcurrentPage })
  }

  const handlePage = (page: number) => {
    if (pagination?.currentPage === page) return
    setPagination({ ...pagination, currentPage: page })
  }

  const handlePerPage = (perPage: number) => {
    setPagination({ ...pagination, perPage })
  }

  const handleResetSearch = () => {
    setPagination({ ...pagination, searchTerm: '', refreshTable: true })
  }

  const refreshData = () => {
    setPagination({
      ...pagination,
      refreshTable: true,
    })
  }

  const handlePrint = () => {
    const printContent = printRef?.current?.innerHTML ?? ''
    const originalContent = document.body.innerHTML
    document.body.innerHTML = printContent
    window.print()
    document.body.innerHTML = originalContent
  }

  return (
    <Box>
      <Box pt='4' pb='1' my='2' borderColor='gray.200'>
        <Flex
          direction={{
            lg: 'row',
            base: 'column',
          }}
          gap='5'
          alignItems='center'
          justify='space-between'
          px='2px'
        >
          {showSearchBar && (
            <Box display={'flex'} alignItems='center'>
              <InputGroup size='sm'>
                <Input
                  pr='4.5rem'
                  size={'sm'}
                  maxWidth='300'
                  onChange={(e) => searchFn(String(e.target.value))}
                  value={pagination?.searchTerm}
                  placeholder={TABLE_LABELS?.search[lang]}
                />
                <InputRightElement cursor={'pointer'} width='2rem'>
                  {pagination?.searchTerm?.length === 0 ? (
                    <BsSearch />
                  ) : (
                    <MdClose color='red' onClick={handleResetSearch} size='16' fontSize={'16'} />
                  )}
                </InputRightElement>
              </InputGroup>
            </Box>
          )}

          {showTools && (
            <Flex
              gap='3'
              sx={{
                svg: {
                  mb: '1px',
                },
              }}
            >
              <Button size='sm' onClick={refreshData} variant='primary'>
                <TbRefresh /> &nbsp; <span>{TABLE_LABELS?.refresh[lang]}</span>
              </Button>

              <Button
                size='sm'
                variant='primary'
                onClick={() => {
                  handlePrint()
                }}
              >
                <BsPrinter /> &nbsp; <span>{TABLE_LABELS?.print[lang]}</span>
              </Button>

              <Button
                onClick={async () => {
                  const worksheet = XLSX.utils.table_to_sheet(document.querySelector('table'))
                  const workbook = XLSX.utils.book_new()
                  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
                  const excelBuffer = await XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
                  saveAsExcelFile(excelBuffer, `${title}.xlsx`)
                }}
                size='sm'
                variant='primary'
              >
                <TbDatabaseExport /> &nbsp; <span>{TABLE_LABELS?.exportFile[lang]}</span>
              </Button>
            </Flex>
          )}
        </Flex>
      </Box>

      <TableContainer border='1px solid #bdbdbd' ref={printRef} overflowY='auto' height='76vh'>
        <CTable
          sx={{
            thead: {
              tr: {
                th: {
                  textTransform: 'capitalize',
                  paddingTop: '15px',
                  fontWeight: '500',
                  color: 'white',
                  background: '#255d88',
                },
              },
            },
            tbody: {
              tr: {
                td: {
                  border: '1px solid #ebebeb',
                  // set border right none for first child
                  '&:first-of-child': {
                    borderRight: 'none',
                  },
                  // set list child border left none
                  '&:last-of-child': {
                    borderLeft: 'none',
                  },
                  paddingY: '8px',
                  span: {
                    fontSize: '14px',
                  },
                  button: {
                    fontSize: '10px',
                    height: '25px',
                    width: '25px',
                    padding: '0px',
                  },
                },
              },
            },
          }}
        >
          <Thead border='1px solid #1c4c70' maxHeight='30px' position='sticky' top='0px' zIndex='2'>
            {table.getHeaderGroups().map((headerGroup, index: number) => (
              <Tr key={index}>
                {headerGroup.headers.map((header, index: number) => {
                  return (
                    <Th
                      key={index}
                      style={{
                        width: header.column.getSize(),
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {loading ? (
              <Tr>
                <Td colSpan={100}>
                  <Flex
                    sx={{
                      height: '76vh',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Spinner thickness='3px' speed='0.3s' emptyColor='gray.200' color={Colors.primaryColor} size='xl' />
                    <Text pt='3' fontSize={'14px'} color='gray'>
                      Loading.. Please wait...
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            ) : rowData?.length > 0 ? (
              table.getRowModel().rows.map((row, index) => (
                <Tr key={index}>
                  {row.getVisibleCells().map((cell, index) => (
                    <Td key={index}>
                      <span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={100}>
                  <Flex
                    sx={{
                      height: '68vh',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <NoDataFound />
                    <Box
                      onClick={refreshData}
                      background={Colors.primaryColor}
                      color='white'
                      paddingY='5px'
                      paddingX='20px'
                      fontSize='13px'
                      borderRadius='2px'
                      cursor='pointer'
                      display='flex'
                      alignItems='center'
                      gap='2'
                    >
                      <TbRefresh /> {TABLE_LABELS?.refresh[lang]}
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            )}
          </Tbody>
        </CTable>
      </TableContainer>

      <Box border='1px solid #bdbdbd' borderTop={'none'}>
        <Flex
          gap='5'
          px='4'
          py='3'
          borderBottom='1px'
          borderColor='gray.200'
          background='gray.100'
          justifyContent={'space-between'}
          alignItems='center'
        >
          <Text fontSize={'13px'}>{title}</Text>

          {pagination?.total && pagination?.total > 0 ? (
            <Flex justifyContent={'flex-end'} flexWrap='wrap' alignItems={'center'} gap='10'>
              <Box>
                <Flex
                  fontSize='15px'
                  alignItems='center'
                  mr={'2'}
                  sx={{
                    fontSize: '13px',
                  }}
                >
                  {TABLE_LABELS?.rowPerPage[lang]}
                  <Select
                    ml={'2'}
                    value={pagination.perPage}
                    onChange={(e) => handlePerPage(Number(e.target.value))}
                    size='xs'
                    width='60px'
                    fontSize='15px'
                    sx={{
                      outline: 'none',
                    }}
                    _focus={{
                      boxShadow: 'none',
                    }}
                    _hover={{
                      cursor: 'pointer',
                    }}
                  >
                    {[20, 30, 40, 50].map((pageSize, index) => (
                      <option key={index} value={pageSize} style={{ fontSize: '15px' }}>
                        {pageSize}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </Box>
              <Box>
                {pagination?.total ? (
                  <Text
                    sx={{
                      fontSize: '13px',
                    }}
                  >
                    {TABLE_LABELS?.totalData[lang]} : {pagination?.total}
                  </Text>
                ) : null}
              </Box>
              <Flex gap='2' alignItems={'center'}>
                <Text fontSize={'13px'}>{TABLE_LABELS?.gotoPage[lang]} : </Text>
                <form action=''>
                  <Input
                    sx={{
                      height: '22px',
                      width: '40px',
                      borderRadius: '1px',
                      textAlign: 'center',
                      fontSize: '13px',
                      outline: '1px solid #c2c2c2',
                      _focus: {
                        boxShadow: 'none',
                      },
                    }}
                    type='number'
                    min={1}
                    max={pagination?.totalPages ?? 1}
                    onChange={(e) => {
                      setGoToPage(parseInt(e.target.value))
                    }}
                    value={goToPage}
                  />
                  <input
                    onClick={(e) => {
                      e.preventDefault()
                      pagination?.totalPages && goToPage <= pagination?.totalPages && handlePage(goToPage)
                    }}
                    type='submit'
                    style={{
                      display: 'none',
                    }}
                  />
                </form>
              </Flex>
              <Flex
                gap='2'
                sx={{
                  button: {
                    fontSize: '13px',
                    height: '25px',
                    width: '25px',
                    _disabled: {
                      cursor: 'not-allowed',
                    },
                  },
                }}
              >
                <IconButton
                  sx={{
                    padding: '0px',
                  }}
                  isDisabled={pagination?.currentPage === 1}
                  variant='outline'
                  colorScheme='#255d88'
                  aria-label='previous'
                  onClick={prevFn}
                  size='sm'
                  icon={<HiOutlineArrowNarrowLeft color='#255d88' />}
                />
                {pagination?.totalPages &&
                  [...Array(pagination?.totalPages)].map((_, index) => {
                    const pageNumber = index + 1
                    if (
                      pageNumber === 1 ||
                      pageNumber === pagination?.totalPages ||
                      (pageNumber >= pagination?.currentPage - 1 && pageNumber <= pagination?.currentPage + 1)
                    ) {
                      return (
                        <Button
                          sx={{
                            padding: '0px',
                          }}
                          key={pageNumber}
                          size='sm'
                          variant={pageNumber === pagination?.currentPage ? 'primary' : 'outline'}
                          colorScheme='#255d88'
                          onClick={() => handlePage(pageNumber)}
                        >
                          {pageNumber}
                        </Button>
                      )
                    } else if (
                      (pageNumber === pagination?.currentPage - 2 && pageNumber > 1) ||
                      (pageNumber === pagination?.currentPage + 2 && pageNumber < pagination?.totalPages)
                    ) {
                      return (
                        <IconButton
                          sx={{
                            padding: '0px',
                          }}
                          key={pageNumber}
                          variant='outline'
                          colorScheme='#255d88'
                          aria-label='previous'
                          size='sm'
                          icon={<BsThreeDots color='#255d88' />}
                        />
                      )
                    }
                    return null
                  })}
                <IconButton
                  sx={{
                    padding: '0px',
                  }}
                  isDisabled={pagination?.currentPage === pagination?.totalPages}
                  variant='outline'
                  colorScheme='#255d88'
                  aria-label='next'
                  onClick={nextFn}
                  size='sm'
                  icon={<HiOutlineArrowNarrowRight color='#255d88' />}
                />
              </Flex>
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </Box>
  )
}

Table.defaultProps = {
  showSearchBar: true,
  showTools: true,
  loading: true,
}
export default memo(Table)
