import { Box, Center, Heading, Text } from '@chakra-ui/react'
import useLang from '@hooks/useLang'
import Modal from '@ui/common/molecules/Modal'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { BsCalendar2DateFill } from 'react-icons/bs'

interface IProps {
  videoUrl: string
  title: any
  publishedAt: Date
  description: any
}

const VideoCard = (props: IProps) => {
  const { videoUrl, title, publishedAt, description } = props
  const { lang } = useLang()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [embedUrl, setEmbedUrl] = useState<string | null>(null)

  useEffect(() => {
    if (videoUrl) void makeEmbedUrl(videoUrl)
  }, [videoUrl])

  const makeEmbedUrl = async (videoId: string) => {
    try {
      const url = new URL(videoId)
      const videoID = url.pathname.substr(1)

      setEmbedUrl(`https://www.youtube.com/embed/${videoID}`)
    } catch (r) {
      setEmbedUrl(`https://www.youtube.com/embed/${videoId}`)
    }
  }

  return (
    <Box>
      <Box
        w={'full'}
        borderWidth='1px'
        borderRadius='lg'
        overflow='hidden'
        p={5}
        onClick={() => {
          setIsOpen(true)
        }}
      >
        {embedUrl ? (
          <iframe
            width='100%'
            height='215'
            src={embedUrl}
            frameBorder='0'
            allowFullScreen
            style={{
              opacity: '.75',
            }}
          />
        ) : (
          <Center height='215px'> Preview not available</Center>
        )}

        <Heading fontSize='16px' lineHeight={'24px'} pt='4' color={'gray.600'}>
          {title?.[lang]}
        </Heading>
        <Text
          pt='2'
          sx={{
            display: 'flex',
            gap: '2',
            svg: {
              color: 'gray.500',
              mt: '1px',
            },
          }}
        >
          <BsCalendar2DateFill /> {moment(publishedAt).format('LL')}
        </Text>
      </Box>
      <Modal
        isOpen={isOpen}
        setOpen={setIsOpen}
        title={title?.[lang]}
        data={
          <Box>
            <Text>{description?.[lang]}</Text>
            <Text
              pt='2'
              sx={{
                display: 'flex',
                gap: '2',
                svg: {
                  color: 'gray.500',
                  mt: '1px',
                },
              }}
            >
              <BsCalendar2DateFill /> {moment(publishedAt).format('LL')}
            </Text>
            {embedUrl ? (
              <iframe
                width='100%'
                height='415'
                src={embedUrl}
                frameBorder='0'
                allowFullScreen
                style={{
                  opacity: '.75',
                }}
              />
            ) : (
              <Center height='215px'> Preview not available</Center>
            )}
          </Box>
        }
      />
    </Box>
  )
}

export default VideoCard
