import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import moment from "moment";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

interface INewsCard {
  id: string;
  title: any;
  description: any;
  createdAt: Date;
  image: string;
  category: string;
}

const NewsCard = (props: INewsCard) => {
  const { id, title, description, createdAt, image, category } = props;
  const navigate = useNavigate();
  return (
    <Card
      direction={{ base: "column", sm: "row" }}
      overflow="hidden"
      variant="outline"
    >
      <Image
        objectFit="cover"
        maxW={{ base: "100%", sm: "200px" }}
        src={image}
        alt="Caffe Latte"
      />

      <Stack>
        <CardBody>
          <Heading size="md">{title}</Heading>
          <Text
            fontSize={"12px"}
            sx={{
              display: "flex",
              gap: "5",
              pt: "3",
              color: "gray.600",
              pb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2",
                pt: "3",
              }}
            >
              <AiOutlineClockCircle />
              {moment(createdAt)?.format("LLL")}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "2",
                pt: "3",
              }}
            >
              <BiCategory /> {category}
            </Box>
          </Text>
          <Text
            pt="2"
            dangerouslySetInnerHTML={{
              __html: description?.slice(0, 200) + " ...",
            }}
          />
        </CardBody>

        <CardFooter pt="1">
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              navigate(`/news/${id}`, {
                state: {
                  id,
                  title,
                  description,
                  createdAt,
                  image,
                  category,
                },
              });
            }}
          >
            Read more
          </Button>
        </CardFooter>
      </Stack>
    </Card>
  );
};

export default NewsCard;
