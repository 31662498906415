type Label = Record<string, Record<string, string>>

const labels: Label = {
  agricultureCategory: {
    en: 'Agriculture category',
    ne: 'कृषि श्रेणी',
  },
  agricultureType: {
    en: 'Agriculture type',
    ne: 'कृषि प्रकार',
  },
  agricultureName: {
    en: 'Agriculture name',
    ne: 'कृषिको नाम',
  },

  suggestedTopic: {
    en: 'Suggested topic',
    ne: 'सुझाव दिएको विषय',
  },
  farmerGroup: {
    en: 'Farmer group',
    ne: 'कृषक समूह',
  },

  // forms fields
  selectCoverImage: {
    en: 'Select cover image',
    ne: 'कवर तस्बिर छान्नुहोस्',
  },
  selectAgricultureType: {
    en: 'Select agriculture type',
    ne: 'कृषि प्रकार छान्नुहोस्',
  },
  selectAgricultureCategory: {
    en: 'Select agriculture category',
    ne: 'कृषि श्रेणी छान्नुहोस्',
  },
  add: {
    en: 'Add',
    ne: 'थप्नुहोस्',
  },
  enterNameOfTopic: {
    en: 'Enter name of topic',
    ne: 'शीर्षकको नाम लेख्नुहोस्',
  },
  enterDetailsAboutTopic: {
    en: 'Enter details about topic',
    ne: 'विषयको विवरण लेख्नुहोस्',
  },
  description: {
    en: 'Description',
    ne: 'विवरण',
  },
  topic: {
    en: 'Topic',
    ne: 'शीर्षक',
  },
}
export default labels

interface IType {
  add: 'add'
  delete: 'delete'
  list: 'list'
}

const messages: any = {
  en: {
    add: (message: string) => `Add ${message}`,
    delete: (message: string) => `Delete ${message}`,
    list: (message: string) => `List ${message}`,
  },
  ne: {
    add: (message: string) => `${message} थप्नुहोस`,
    delete: (message: string) => `${message} मेटाउनुहोस`,
    list: (message: string) => `${message} सूची`,
  },
}

export const getMessage = (message: string, type: keyof IType, lang: any) => {
  return messages[lang][type](message)
}
