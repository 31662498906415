import Language from '@models/language.model'
import { plainToClass } from 'class-transformer'

class Carousel {
  id: string
  title: any
  isPublished: boolean
  createdAt: Date
  media: any

  constructor(id: string, title: Language, media: any, isPublished: boolean, createdAt: Date) {
    this.id = id
    this.title = title
    this.media = media
    this.isPublished = isPublished
    this.createdAt = createdAt
  }

  static plainToInstance(plainData: object): Carousel {
    return plainToClass(Carousel, plainData)
  }

  static plainToInstances(plainData: object[]): Carousel[] {
    return plainToClass(Carousel, plainData)
  }
}
export default Carousel
