import { Box, Container, Heading } from '@chakra-ui/react';
import label from '@data/localization/videoGallery';
import useLang from '@hooks/useLang';
import ImageGalleryList from '@ui/user/organisms/ImageGallery';

const ImageGalleryUser = () => {
  const { lang } = useLang();

  return (
    <Box py="4">
      <Container>
        <Heading
          fontSize={{ base: '2xl', sm: '3xl' }}
          fontWeight={'bold'}
          textTransform="capitalize"
        >
          {label?.imageGallery?.[lang]}
        </Heading>

        <Box pt="5">
          <ImageGalleryList />
        </Box>
      </Container>
    </Box>
  );
};

export default ImageGalleryUser;
