import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Textarea,
  useToast,
  IconButton,
} from '@chakra-ui/react'
import endPoint from '@config/endpoint/imageGallery'
import { BsFillTrashFill } from 'react-icons/bs'
import { imageGallerySchema } from '@config/schema/imageAlbum.schema'
import { videoSchema } from '@config/schema/videoGallery.schema'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import utilsLabel from '@data/localization/utils'
import videoGalleryLabel from '@data/localization/videoGallery'
import imageAlbumLabel from '@data/localization/imageAlbum'
import { getMessage } from '@functions/generateMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import ImageAlbumModel from '@models/imageGallery/imageAlbum.model'
import ImageGalleryModel from '@models/imageGallery/imageGallery.model'
import { IPagination } from '@type/pagination.types'
import NepaliInput from '@ui/common/atoms/NepaliInput'
import RequireSign from '@ui/common/atoms/RequireSign'
import Modal from '@ui/common/molecules/Modal'
import { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'
import useUpload from '@hooks/useUpload'

interface IProps {
  open: boolean
  setOpen: (data: boolean) => void
  values: IResponse<ImageGalleryModel[]> | null
  setValues: (data: IResponse<ImageGalleryModel[]>) => void
  selectedValue: ImageGalleryModel | null
  setSelectedValue: (data: ImageGalleryModel | null) => void
}
const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props
  const { post, put } = useAPI<ImageGalleryModel>()
  const { updateMedia, uploadMedia } = useUpload()
  const { data: albumList, get } = useAPI<ImageAlbumModel[] | null>()

  const toast = useToast()
  const queryClient = useQueryClient()
  const { lang } = useLang()
  const [pagination] = useState<IPagination>({
    currentPage: 1,
    perPage: 20,
    searchTerm: '',
    total: undefined,
    totalPages: 1,
    refreshTable: false,
  })
  const handleFetch = async () => {
    await get(endPoint.album, {
      perPage: pagination?.perPage,
      page: pagination?.currentPage,
      search: pagination?.searchTerm,
      queryName: 'videos',
      queryValue: 'false',
    })
  }

  useQuery('image-album', handleFetch, {
    refetchIntervalInBackground: false,
    enabled: false,
  })

  const handleFetchData = () => {
    void queryClient.prefetchQuery('imageAlbumData', handleFetch)
  }

  useEffect(() => {
    if (open) void handleFetchData()
  }, [open])

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(imageGallerySchema),
    defaultValues: {
      title: {
        en: '',
        ne: '',
      },
      status: '',
      album: '',
      images: [{}],
    },
    reValidateMode: 'onSubmit',
  })

  const {
    fields: fieldsFiles,
    append: appendFile,
    remove: removeFile,
  } = useFieldArray({
    control,
    name: 'images',
  })

  useEffect(() => {
    removeFile(0)
  }, [removeFile])

  useEffect(() => {
    if (selectedValue) {
      reset({
        title: {
          en: selectedValue?.title?.en ?? '',
          ne: selectedValue?.title?.ne ?? '',
        },
        status: selectedValue?.status ? '1' : '0',
        album: selectedValue?.album?.id ?? '',
      })
    }
  }, [selectedValue])

  const onSubmit = async (data: any) => {
    try {
      let res

      if (selectedValue) {
        data.id = selectedValue?.id
        res = await put(endPoint.gallery, data)
      } else {
        const image = await uploadMedia(data.images)
        data.mediaGroup = image.data.data.id
        delete data?.images
        res = await post(endPoint.gallery, data)
      }
      if (res?.data) {
        await queryClient.invalidateQueries('videos')

        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.videoGallery[lang],
            selectedValue ? notificationLabel?.updated[lang] : notificationLabel?.added[lang],
            lang
          ),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        setOpen(false)
        reset({
          title: {
            en: '',
          },
          status: '',
        })
        setSelectedValue(null)
      } else if (res?.error) {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res?.error?.response?.data?.message ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    if (selectedValue) {
      setOpen(true)
    }
  }, [selectedValue])

  return (
    <div>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        onCloseComplete={() => {
          setSelectedValue(null)
          reset({
            title: {
              en: '',
              ne: '',
            },
            status: '',
            album: '',
            images: [{}],
          })
        }}
        title={getMessage(videoGalleryLabel?.videoGallery[lang], 'add', lang)}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
              >
                <FormControl isInvalid={errors.title?.en != null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size='sm'
                    variant={errors?.title?.en != null ? 'error' : ''}
                    type='text'
                    {...register('title.en')}
                    placeholder={'Enter title name'}
                  />
                  <FormErrorMessage>{errors?.title?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={'शिर्षक लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('title.ne', e)
                    }}
                    value={watch('title.ne')}
                  />
                  <FormErrorMessage>{errors?.title?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
                pt='5'
              >
                <FormControl isInvalid={errors.album != null}>
                  <FormLabel>
                    {imageAlbumLabel?.album[lang]} <RequireSign />
                    <FormErrorMessage>{errors?.album?.message}</FormErrorMessage>
                  </FormLabel>
                  <Select placeholder='select album' {...register('album')}>
                    {albumList?.data &&
                      albumList?.data?.length > 0 &&
                      albumList?.data?.map((value, index) => {
                        return (
                          <option key={index} value={value.id as number}>
                            {value.title?.[lang]}
                          </option>
                        )
                      })}
                  </Select>
                </FormControl>
                <FormControl isInvalid={errors.status != null}>
                  <FormLabel>
                    {videoGalleryLabel?.status[lang]} <RequireSign />
                    <FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
                  </FormLabel>
                  <RadioGroup defaultValue={selectedValue?.status ? '1' : '0'}>
                    <Flex wrap={'wrap'} gap='4'>
                      <Radio value={'1'} {...register('status')} colorScheme='facebook'>
                        {videoGalleryLabel?.published[lang]}
                      </Radio>
                      <Radio value={'0'} {...register('status')} colorScheme='facebook'>
                        {videoGalleryLabel?.unpublished[lang]}
                      </Radio>
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </Flex>

              <Box pt={3}>
                <FormLabel> {imageAlbumLabel?.images?.[lang]} </FormLabel>

                <Box mb={4}>
                  {fieldsFiles?.map((files, index) => (
                    <div key={files.id}>
                      <Flex alignItems={'center'} gap={3}>
                        <FormControl isInvalid={errors.images?.[index] != null}>
                          <Input
                            size='sm'
                            variant={errors?.images?.[index] != null ? 'error' : 'filled'}
                            type='file'
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (e.target.files?.[0]) {
                                setValue(`images.${index}`, e.target.files?.[0])
                              }
                            }}
                          />
                          <FormErrorMessage>{errors.images?.[index]?.message}</FormErrorMessage>
                        </FormControl>
                        <Box width='fit-content' my='3' display='flex' alignItems='center' justifyContent='flex-end'>
                          <IconButton
                            size='sm'
                            variant='outline'
                            onClick={() => removeFile(index)}
                            colorScheme='red'
                            aria-label='delete'
                            icon={<BsFillTrashFill />}
                          />
                        </Box>
                      </Flex>
                    </div>
                  ))}
                </Box>
                <Button my='2' variant='outline' colorScheme='blue' size='sm' onClick={() => appendFile({})}>
                  Add File
                </Button>
              </Box>

              <Flex my='5' gap='5' justifyContent={'flex-end'}>
                <Button size='sm' variant={'outline'} colorScheme='red' onClick={() => setOpen(false)}>
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type='submit'
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size='sm'
                  variant={'primary'}
                >
                  {selectedValue ? utilsLabel?.update[lang] : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </div>
  )
}

export default Add
