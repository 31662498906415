import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
import endPoint from '@config/endpoint/imageGallery';
import useAPI from '@hooks/useUserHook';
import useLang from '@hooks/useLang';
import ImageGalleryModel from '@models/imageGallery/imageGallery.model';
import { IPagination } from '@type/pagination.types';
import { useEffect, useState } from 'react';
import { IoSearchSharp } from 'react-icons/io5';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import VideoCard from '../atoms/VideoCard';
import { AxiosBaseInstance } from '@api/index';
import Language from '@models/language.model';

interface IProps {
  buttonAction: 'pagination' | 'link';
}

const ImageGalleryList = (props: IProps) => {
  const { buttonAction } = props;
  const { data, loading, get } = useAPI<ImageGalleryModel[] | null>();
  const [values, setValues] = useState<ImageGalleryModel[] | null>(null);
  const [mounted, setMounted] = useState(false);
  const { lang } = useLang();

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    perPage: 8,
    searchTerm: '',
    total: undefined,
    totalPages: 1,
    refreshTable: false,
  });

  const handleFetch = async () => {
    const res = (await AxiosBaseInstance.get(`${endPoint.gallery}`)) as any;
    setValues(res?.data?.data?.data);
    console.log(res.data, 'haha');
  };
  useQuery('imageGallery', handleFetch, {
    refetchIntervalInBackground: false,
  });

  const handleNextPage = () => {
    setPagination({
      ...pagination,
      currentPage: pagination?.currentPage + 1,
    });
  };

  const handlePreviousPage = () => {
    setPagination({
      ...pagination,
      currentPage: pagination?.currentPage - 1,
    });
  };

  useEffect(() => {
    if (mounted) {
      if (
        pagination?.currentPage > 1 &&
        pagination?.currentPage <= pagination?.totalPages
      )
        void handleFetch();
    } else {
      setMounted(true);
    }
  }, [pagination?.currentPage]);

  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.pagination?.total,
      totalPages: data?.pagination?.totalPages ?? 1,
    });
  }, [data]);

  // search
  let timer: any;
  useEffect(() => {
    if (mounted) {
      timer = setTimeout(() => {
        void handleFetch();
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setMounted(true);
    }
  }, [pagination?.searchTerm]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {pagination?.searchTerm?.length > 0 && (
            <Text pt="4" display={'flex'} gap="2" alignItems={'center'}>
              <IoSearchSharp size={'18px'} />
              <Text pt="2px" display={'flex'} gap="3">
                Search for
                <Text color="green" textDecoration={'underline'}>
                  {pagination?.searchTerm}{' '}
                </Text>
              </Text>
            </Text>
          )}
        </Box>
        <Box
          mb={{ sm: 2, base: 10 }}
          width={{
            lg: '20vw',
            md: '30vw',
            sm: '50vw',
            base: '98vw',
          }}
        >
          <FormControl pt="3" w="100%">
            <FormLabel>Search here</FormLabel>
            <Input
              size="sm"
              type="text"
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  searchTerm: e.target.value,
                });
              }}
              placeholder={'Enter search text here..'}
            />
          </FormControl>
        </Box>
      </Box>
      {loading ? (
        <Center height={'40vh'}>Loading...</Center>
      ) : (
        <Box>
          {values?.map((item, index: number) => {
            return (
              <Box key={index} pb="10">
                <Heading pb="2" fontSize={'2xl'}>
                  {item.title?.[lang as keyof Language]}
                </Heading>
                <Grid
                  templateColumns={{
                    lg: 'repeat(4,1fr)',
                    md: 'repeat(2,1fr)',
                    base: 'repeat(1,1fr)',
                  }}
                  gap="5"
                  mt="2"
                >
                  <Flex gap={'20px'}>
                    {item.mediaGroup.medias.map((value, ind) => {
                      return <Image src={value.name} key={ind} />;
                    })}
                  </Flex>
                </Grid>
              </Box>
            );
          })}
          {values && values?.length < 1 && (
            <Text color={'red'}>No Images found</Text>
          )}

          <Box pt="5">
            {buttonAction === 'link' ? (
              <Flex my="5" justifyContent={'center'} alignItems="center">
                <Link to="video-gallery">
                  <Button variant={'primary'}>Show All</Button>
                </Link>
              </Flex>
            ) : (
              <Flex justifyContent={'flex-end'} gap="5" mt="5">
                {pagination?.currentPage !== 1 && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      handlePreviousPage();
                    }}
                  >
                    Previous
                  </Button>
                )}
                {pagination?.currentPage !== pagination?.totalPages &&
                  pagination?.totalPages > 1 && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        handleNextPage();
                      }}
                    >
                      Next
                    </Button>
                  )}
              </Flex>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
// set default props
ImageGalleryList.defaultProps = {
  buttonAction: 'pagination',
};

export default ImageGalleryList;
