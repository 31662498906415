import { Outlet } from 'react-router-dom';
// user
import CommercialAgriculture from '@ui/user/pages/CommercialAgriculture';
import CommercialAgriculturePage from '@ui/user/pages/CommercialAgriculturePage';
import Details from '@ui/user/pages/Details';
import Home from '@ui/user/pages/index';
import UserNews from '@ui/user/pages/News';
import UserNewsRead from '@ui/user/pages/NewsDetails';
import VideoGalleryUser from '@ui/user/pages/VideoGallery';
import UserTemplate from '@ui/user/templates/userTemplate';
// admin
// admin - auth
import AdminLogin from '@ui/admin/organisms/auth/Login';
// admin - dashboard
import AgricultureGroup from '@ui/admin/pages/AgricultureGroup';
import AgricultureName from '@ui/admin/pages/AgricultureName';
import Analytics from '@ui/admin/pages/Analytics';
import CarouselSetting from '@ui/admin/pages/Carousel';
import Category from '@ui/admin/pages/Category';
import FarmerList from '@ui/admin/pages/farmer/FarmerList';
import FarmerRegistration from '@ui/admin/pages/farmer/Registration';
import Types from '@ui/admin/pages/Types';
import Playlist from '@ui/admin/pages/videoGallery/Playlist';
// utils
import News from '@ui/admin/pages/news/News';
import NewsCategory from '@ui/admin/pages/news/NewsCategory';
import Videos from '@ui/admin/pages/videoGallery/Videos';
import PageNotFound from '@ui/common/pages/PageNotFound';
import ImageAlbum from '@ui/admin/pages/imageAlbum/ImageAlbum';
import ImageGallery from '@ui/admin/pages/imageAlbum/ImageGallery';
import ImageGalleryUser from '@ui/user/pages/ImageGallery';

export const UserAgricultureRoute = [
  {
    path: '',
    element: <UserTemplate />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'commercial-agriculture',
        element: <CommercialAgriculturePage />,
      },
      {
        path: 'commercial-agriculture/:id',
        element: <CommercialAgriculture />,
      },
      {
        path: 'commercial-agriculture/:id/:name',
        element: <Details />,
      },
      {
        path: 'news',
        element: <UserNews />,
      },
      {
        path: 'news/:id',
        element: <UserNewsRead />,
      },
      {
        path: 'video-gallery',
        element: <VideoGalleryUser />,
      },
      {
        path: 'image-gallery',
        element: <ImageGalleryUser />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];

export const AdminAgricultureRoute = [
  {
    path: '',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Analytics />,
      },
      {
        path: 'agriculture/type',
        element: <Types />,
      },
      {
        path: 'agriculture/category',
        element: <Category />,
      },
      {
        path: 'agriculture/name',
        element: <AgricultureName />,
      },
      {
        path: 'agriculture/group',
        element: <AgricultureGroup />,
      },
      {
        path: 'video-playlist',
        element: <Playlist />,
      },
      {
        path: 'videos',
        element: <Videos />,
      },
      {
        path: 'news',
        element: <News />,
      },
      {
        path: 'image-album',
        element: <ImageAlbum />,
      },
      {
        path: 'image-gallery',
        element: <ImageGallery />,
      },
      {
        path: 'news/category',
        element: <NewsCategory />,
      },
      {
        path: 'setting/carousel',
        element: <CarouselSetting />,
      },
      {
        path: 'farmer/registration/:id?',
        element: <FarmerRegistration />,
      },
      {
        path: 'farmer/list',
        element: <FarmerList />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];

export const AdminAuthRoute = [
  {
    path: 'admin',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <AdminLogin />,
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];
