import { Input, Textarea } from '@chakra-ui/react'
import { nepaliUnicodes } from '@config/constant/unicode'
import React, { useEffect, useState } from 'react'

interface IProps {
  handleChange: Function
  error?: boolean
  multiline?: boolean
  placeholder: string
  value: string
}

const NepaliInput: React.FC<IProps> = (props) => {
  const handleChange = (val: string) => {
    console.log('changed')
    props.handleChange(
      Array.from(val, (c: string) => {
        if (nepaliUnicodes?.[c]) {
          return nepaliUnicodes[c]
        }
        return c
      }).join('')
    )
  }

  return (
    <>
      {props.multiline ? (
        <Textarea
          placeholder={props.placeholder}
          onChange={(e: any) => {
            handleChange(e.target.value)
          }}
          size='sm'
          value={props.value}
        />
      ) : (
        <Input
          size={'sm'}
          type={'text'}
          value={props.value}
          variant={props?.error === true ? 'error' : ''}
          placeholder={props.placeholder}
          onChange={(e) => {
            handleChange(e.target.value)
          }}
        />
      )}
    </>
  )
}

NepaliInput.defaultProps = {
  multiline: false,
  error: false,
  value: '',
}

export default NepaliInput
