import {
  Box,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Text,
} from '@chakra-ui/react';
import imageFile from '@config/constant/image';
import SIDEBAR_LABELS from '@data/localization/sidebar';
import useLang from '@hooks/useLang';
import useWindowDimensions from '@hooks/useWindowDimension';
import SidebarItem from '@ui/admin/molecules/SidebarItem';
import { Colors } from '@utils/Colors';
import { useEffect, useMemo, useState } from 'react';
import {
  AiOutlineReconciliation,
  AiOutlineSetting,
  AiOutlineVideoCameraAdd,
} from 'react-icons/ai';
import { BiCategoryAlt, BiSearchAlt } from 'react-icons/bi';
import { BsNewspaper, BsSliders } from 'react-icons/bs';
import { CgNametag } from 'react-icons/cg';
import { HiOutlineUsers } from 'react-icons/hi';
import {
  MdAgriculture,
  MdOutlineAgriculture,
  MdOutlineChecklist,
  MdOutlineDashboardCustomize,
  MdOutlineFileCopy,
} from 'react-icons/md';
import { RiListSettingsFill } from 'react-icons/ri';
import { TbReportSearch } from 'react-icons/tb';
import { TfiMenu } from 'react-icons/tfi';
import { useLocation, useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const { lang } = useLang();
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const MENU_ITEMS = [
    {
      title: SIDEBAR_LABELS?.dashboard,
      link: '/admin-dashboard',
      icon: <MdOutlineDashboardCustomize />,
      roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
    },
    {
      title: SIDEBAR_LABELS?.agriculture,
      link: '#',
      icon: <MdAgriculture />,
      roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
      subMenu: [
        {
          title: SIDEBAR_LABELS?.category,
          link: '/admin-dashboard/agriculture/category',
          icon: <RiListSettingsFill />,
        },
        {
          title: SIDEBAR_LABELS?.agricultureType,
          link: '/admin-dashboard/agriculture/type',
          icon: <MdOutlineChecklist />,
        },
        {
          title: SIDEBAR_LABELS?.agricultureName,
          link: '/admin-dashboard/agriculture/name',
          icon: <CgNametag />,
        },
        {
          title: SIDEBAR_LABELS?.agricultureGroup,
          link: '/admin-dashboard/agriculture/group',
          icon: <HiOutlineUsers />,
        },
      ],
    },
    {
      title: SIDEBAR_LABELS?.videoGallery,
      link: '#',
      icon: <AiOutlineVideoCameraAdd />,
      roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
      subMenu: [
        {
          title: SIDEBAR_LABELS?.playlist,
          link: '/admin-dashboard/video-playlist',
          icon: <MdOutlineFileCopy />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT'],
        },
        {
          title: SIDEBAR_LABELS?.videos,
          link: '/admin-dashboard/videos',
          icon: <BiCategoryAlt />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT'],
        },
      ],
    },
    {
      title: SIDEBAR_LABELS?.imageGallery,
      link: '#',
      icon: <AiOutlineVideoCameraAdd />,
      roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
      subMenu: [
        {
          title: SIDEBAR_LABELS?.imageAlbum,
          link: '/admin-dashboard/image-album',
          icon: <MdOutlineFileCopy />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
        },
        {
          title: SIDEBAR_LABELS?.image,
          link: '/admin-dashboard/image-gallery',
          icon: <BiCategoryAlt />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
        },
      ],
    },
    {
      title: SIDEBAR_LABELS?.news,
      link: '#',
      icon: <BsNewspaper />,
      roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
      subMenu: [
        {
          title: SIDEBAR_LABELS?.news,
          link: '/admin-dashboard/news',
          icon: <MdOutlineFileCopy />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
        },
        {
          title: SIDEBAR_LABELS?.category,
          link: '/admin-dashboard/news/category',
          icon: <BiCategoryAlt />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
        },
      ],
    },
    {
      title: SIDEBAR_LABELS?.settings,
      link: '#',
      icon: <AiOutlineSetting />,
      roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
      subMenu: [
        {
          title: SIDEBAR_LABELS?.carousel,
          link: '/admin-dashboard/setting/carousel',
          icon: <BsSliders />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
        },
      ],
    },
    {
      title: SIDEBAR_LABELS?.farmer,
      link: '#',
      icon: <MdOutlineAgriculture />,
      roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
      subMenu: [
        {
          title: SIDEBAR_LABELS?.registration,
          link: '/admin-dashboard/farmer/registration',
          icon: <AiOutlineReconciliation />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
        },
        {
          title: SIDEBAR_LABELS?.farmerList,
          link: '/admin-dashboard/farmer/list',
          icon: <TbReportSearch />,
          roleLevel: ['SUDO_ADMIN', 'SUPER_ADMIN', 'SUDO_ADMIN_ALT', 'ADMIN'],
        },
      ],
    },
  ];
  const { width } = useWindowDimensions();
  const [minimize, setMinimize] = useState(
    sessionStorage.getItem('sidebar') === 'true'
  );
  const [sidebarWidth, setSidebarWidth] = useState<number>(350);
  const [show, setShow] = useState(true);
  let [sidebarItems] = useState(MENU_ITEMS);
  const [searchText, setSearchText] = useState<string>();

  // handle to show
  const handleToggle = () => {
    if (width >= 767) {
      setSidebarWidth(minimize ? 350 : 50);
      setMinimize(!minimize);
      sessionStorage.setItem('sidebar', 'true');
    } else {
      setShow(!show);
    }
  };

  useEffect(() => {
    if (width <= 767) {
      setShow(false);
      setMinimize(false);
    } else {
      if (minimize) setSidebarWidth(50);
      else setMinimize(false);
      setShow(true);
      setSidebarWidth(!minimize ? 350 : 50);
    }
  }, [width]);

  sidebarItems = useMemo(() => {
    if (searchText != null) {
      sidebarItems = sidebarItems.filter((el: any) => {
        const regex = new RegExp(searchText.trim(), 'ig');
        if (el?.subMenu?.length > 0) {
          return el?.subMenu.some((el: any) => regex.test(el.title.en))
            ? el.subMenu
            : null;
        }
        return el.title.en.match(regex);
      });
    }
    return sidebarItems;
  }, [searchText]);

  useEffect(() => {
    if (width <= 767) {
      setShow(false);
    }
  }, [location]);

  return (
    <Box
      height={{
        base: 'auto',
        md: '100vh',
      }}
      bg={Colors?.dashboardColor}
      width={{
        base: '100%',
        md: `${sidebarWidth}px`,
      }}
      overflowX="hidden"
      zIndex={1}
    >
      <Box display="flex" alignItems="center" gap="2" p="2">
        {!minimize && (
          <>
            <Image
              width={{ base: '55px' }}
              objectFit="cover"
              src={imageFile?.logo ?? ''}
              alt="logo"
              cursor={'pointer'}
              onClick={() => {
                navigate('/');
              }}
            />
            <Text fontSize="md" color={'white'}>
              {SIDEBAR_LABELS?.phidimNagarpalika[lang]} <br />
              {SIDEBAR_LABELS?.serviceDeliveryApp[lang]}
            </Text>
            <Spacer />
          </>
        )}
        <Box
          sx={{
            svg: {
              fontSize: '14px',
            },
          }}
          ms={minimize ? '.5rem' : '0'}
          pt={minimize ? '.75rem' : '0'}
        >
          <TfiMenu
            cursor="pointer"
            color={'white'}
            onClick={() => {
              handleToggle();
            }}
          />
        </Box>
      </Box>

      {minimize && (
        <Box px="4" pt="5">
          <BiSearchAlt
            cursor="pointer"
            color={'white'}
            onClick={handleToggle}
          />
        </Box>
      )}
      {/* // search menu item */}
      {!minimize && show && (
        <Box px="4" mt="5">
          <Text as="small" color={'white'}>
            {SIDEBAR_LABELS?.search[lang]}
          </Text>
          <InputGroup size="sm">
            <Input
              variant="filled"
              placeholder={SIDEBAR_LABELS?.searchPlaceholder[lang]}
              outlineColor={'white'}
              border={'none'}
              bg={'white'}
              _focus={{
                background: 'white',
              }}
              size="sm"
              color={Colors.dashboardColor}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              _placeholder={{ color: 'gray.350' }}
            />
            <InputRightElement>
              <BiSearchAlt cursor="pointer" color={Colors.primaryColor} />
            </InputRightElement>
          </InputGroup>
        </Box>
      )}
      {show && (
        <Box pt="5">
          {!minimize && (
            <Text ps="4" as="small" color={'white'}>
              {SIDEBAR_LABELS?.menuItems[lang]}
            </Text>
          )}
          <Box
            style={{
              overflowY: 'auto',
              height: 'calc(100vh - 180px)',
            }}
          >
            {sidebarItems.map((item: any, index: number) => {
              return (
                <SidebarItem
                  key={index}
                  minimize={minimize}
                  title={item.title}
                  link={item.link}
                  icon={item.icon}
                  subMenu={item?.subMenu}
                  roleLevel={item?.roleLevel}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
