import { Avatar } from '@chakra-ui/react'
import imageFile from '@config/constant/image'
import useLang from '@hooks/useLang'

const LanguageToggle = () => {
  const { lang, setLang } = useLang()
  // function to switch language
  const switchLanguage = (e: any) => {
    setLang(lang === 'en' ? 'ne' : 'en')
  }
  return (
    <>
      <Avatar
        onClick={switchLanguage}
        p='2px'
        border={'1px solid #a5a5a5'}
        cursor='pointer'
        height='28px'
        width='28px'
        name='Ryan Florence'
        src={lang === 'en' ? imageFile?.USA_flag : imageFile?.Nepal_flag}
      />
    </>
  )
}

export default LanguageToggle
