import { Box, Container, Heading, Image } from '@chakra-ui/react'
import useAPI from '@hooks/useApi'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface ICommercialAgriculture {
  id: string
  title: {
    en: string
    ne: string
  }
  media: {
    medias: Array<{
      name: string
    }>
  }
  details: {
    id: string
    values: IValues[]
  }
}

interface IValues {
  columnName: string
  values: string
}

const Details = () => {
  const location = useLocation()
  const heading = location.pathname.split('/').slice(-1)[0].replaceAll('-', ' ')
  const subHeading = location.pathname.split('/').slice(-2)[0].replaceAll('-', ' ')
  const id = location.state.id

  const { getById } = useAPI()

  const [values, setValues] = useState<ICommercialAgriculture | null>(null)

  const handleFetch = async () => {
    const res = (await getById('/agriculture/name', id)) as any
    if (res) {
      setValues(res)
    }
  }

  useEffect(() => {
    void handleFetch()
  }, [])

  return (
    <Box py='5'>
      <Container>
        <Heading fontSize={{ base: '1xl', sm: '1xl' }} fontWeight={'bold'} textTransform='capitalize'>
          {subHeading}
        </Heading>
        <Heading pt='1' fontSize={{ base: '2xl', sm: '3xl' }} fontWeight={'bold'} textTransform='capitalize'>
          {heading}
        </Heading>
        <Image
          my='2'
          borderRadius={'2px'}
          opacity='.7'
          src={values?.media?.medias[0]?.name}
          width='100%'
          height={{
            base: '200px',
            sm: '200px',
            md: '300px',
            lg: '350px',
          }}
          objectFit={'contain'}
          alt='image'
        />
        {values?.details &&
          values?.details?.values?.length > 0 &&
          values?.details?.values?.map((item, index: number) => {
            return (
              <Box py='4' key={index}>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  fontSize={{ base: '2xl', sm: '2xl', md: '2xl' }}
                  color={'gray.600'}
                >
                  {index + 1}. {item?.columnName}
                </Heading>
                <Box py='2'>{item?.values}</Box>
              </Box>
            )
          })}
      </Container>
    </Box>
  )
}

export default Details
