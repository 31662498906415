import { Box, Container, Heading } from '@chakra-ui/react'
import CaptionCarousel from '@ui/user/organisms/Carousel'
import Feature from '@ui/user/organisms/Feature'
import Hero from '@ui/user/organisms/Hero'
import VideoGallery from '@ui/user/organisms/VideoGallery'

const index = () => {
  return (
    <Box>
      <CaptionCarousel />
      <Hero />
      <Feature />
      <Container py='5' mt='5'>
        <Heading fontSize={{ base: '2xl', sm: '3xl' }} textAlign='center' py='2' fontWeight={'bold'}>
          Video Gallery
        </Heading>
        <VideoGallery buttonAction='link' />
      </Container>
    </Box>
  )
}

export default index
