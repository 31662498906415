import { Box, Flex, IconButton, useToast } from '@chakra-ui/react'
import endPoint from '@config/endpoint/agriculture'
import agricultureLabel, { getMessage } from '@data/localization/agriculture'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import tableLabel from '@data/localization/table'
import tableSnGenerate from '@functions/tableSnGenerate'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { ISimpleTable } from '@interface/global.interface'
import { IResponse } from '@interface/response.interface'
import AgricultureName from '@models/agricultureName.model'
import { createColumnHelper } from '@tanstack/react-table'
import { IPagination } from '@type/pagination.types'
import ModalBox from '@ui/common/molecules/Modal'
import SimpleTable from '@ui/common/molecules/SimpleTable'
import Table from '@ui/common/organisms/Table'
import { useEffect, useState } from 'react'
import { BiEdit, BiMessageAltAdd, BiShowAlt } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import { useQueryClient } from 'react-query'
interface IProps {
  values: IResponse<AgricultureName[]> | null
  setValues: (data: IResponse<AgricultureName[]>) => void
  loading: boolean
  setLoading: (data: boolean) => void
  openDetails: boolean
  setOpenDetails: (data: boolean) => void
  pagination: IPagination
  setPagination: (data: IPagination) => void
  selectedValue: AgricultureName | null
  setSelectedValue: (data: AgricultureName | null) => void
  agricultureDetails: AgricultureName | null
  setAgricultureDetails: (data: AgricultureName | null) => void
}

const Show = (props: IProps) => {
  const { values, loading, pagination, setPagination, setSelectedValue, setOpenDetails, setAgricultureDetails } = props
  const { remove, getById } = useAPI()
  const [open, setOpen] = useState<boolean>(false)
  const [openShow, setOpenShow] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [id, setId] = useState<string | null>(null)
  const [selectedData, setSelectedData] = useState<ISimpleTable[] | null>(null)
  const toast = useToast()
  const [submit, setSubmitting] = useState<boolean>(false)
  const { lang } = useLang()

  const queryClient = useQueryClient()

  // function to delete
  const handleDelete = async () => {
    try {
      setSubmitting(true)
      const res = await remove(`${endPoint?.agricultureName}/${id}`)
      if (res === true) {
        await queryClient.invalidateQueries('myData')
        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(notificationLabel?.agricultureName[lang], notificationLabel?.delete[lang], lang),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      setConfirm(false)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (confirm) {
      void handleDelete()
      setConfirm(false)
    }
  }, [confirm])

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('SN', {
      header: () => <span>{tableLabel?.sn[lang]}</span>,
      size: 50,
      cell: (info: any) => {
        return <span> {tableSnGenerate(info.row.index, pagination?.currentPage, pagination?.perPage)}</span>
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>{tableLabel?.name_english[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.title?.en}</span>
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>{tableLabel?.name_nepali[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.title?.ne}</span>
      },
    }),
    columnHelper.accessor('agricultureType', {
      id: 'agricultureType',
      header: () => <span> {tableLabel?.agricultureType[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return (
          <span>
            {info?.row?.original?.agricultureType?.title?.ne} - {info?.row?.original?.agricultureType?.title?.en}
          </span>
        )
      },
    }),

    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {tableLabel?.action[lang]}
        </span>
      ),
      size: 100,
      cell: (info: any) => {
        return (
          <Flex gap='3' justifyContent='center'>
            <Box
              sx={{
                fontSize: '13px',
                borderRadius: '2px',
                border: '1px',
                borderColor: 'teal',
                paddingY: '5px',
                paddingTop: '10px',
                paddingX: '15px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                transition: '.3s ease',
                _hover: {
                  backgroundColor: 'gray.100',
                },
              }}
              onClick={async () => {
                setOpenDetails(true)
                const res = (await getById(endPoint.agricultureName, info?.row?.original?.id)) as AgricultureName
                setAgricultureDetails(res)
              }}
            >
              {tableLabel?.addDetails[lang]}
              &nbsp; <BiMessageAltAdd />
            </Box>
            <IconButton
              colorScheme='green'
              variant='outline'
              aria-label='show'
              icon={<BiShowAlt />}
              onClick={async () => {
                setOpenShow(true)
                const res = (await getById(endPoint.agricultureName, info?.row?.original?.id)) as AgricultureName

                const details = res?.details?.values?.map((item) => {
                  return {
                    title: item?.columnName,
                    description: item?.values,
                  }
                })
                const tempData: ISimpleTable[] = [
                  {
                    title: 'Name',
                    description: `${res?.title?.en} - ${res?.title?.ne}`,
                  },
                  {
                    title: 'Agriculture type',
                    description: `${res?.agricultureType?.title?.en} - ${res?.agricultureType?.title?.ne}`,
                  },
                  {
                    title: 'Agriculture category',
                    description: `${res?.agricultureType?.category?.title?.en} - ${res?.agricultureType?.category?.title?.ne}`,
                  },
                  {
                    title: 'Cover image',
                    type: 'image',
                    description: `${res?.media?.medias?.[0]?.name}`,
                  },
                ]

                if (details) {
                  setSelectedData([...tempData, ...details])
                } else {
                  setSelectedData(tempData)
                }
              }}
            />
            <IconButton
              colorScheme='telegram'
              variant='outline'
              aria-label='edit'
              icon={<BiEdit />}
              onClick={async () => {
                const res = (await getById(endPoint.agricultureName, info?.row?.original?.id)) as AgricultureName
                setSelectedValue(res)
              }}
            />
            <IconButton
              colorScheme='red'
              variant='outline'
              aria-label='delete'
              icon={<BsTrash />}
              onClick={() => {
                setOpen(true)
                setId(info?.row?.original?.id)
              }}
            />
          </Flex>
        )
      },
    }),
  ]
  return (
    <Box>
      <Table
        title={getMessage(agricultureLabel?.agricultureName[lang], 'list', lang)}
        columns={columns}
        rowData={values?.data ?? []}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
      />
      {/* Delete Model */}
      <ModalBox
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(agricultureLabel?.agricultureName[lang], 'delete', lang)}
        type='delete'
        confirm={confirm}
        setConfirm={setConfirm}
        size='sm'
        submitting={submit}
      />
      {/* Show Modal */}
      <ModalBox
        isOpen={openShow}
        setOpen={setOpenShow}
        title={agricultureLabel?.agricultureName[lang]}
        data={<SimpleTable data={selectedData ?? []} />}
      />
    </Box>
  )
}

export default Show
