import Language from '@models/language.model';
import Media from '@models/media.model';
import { plainToClass } from 'class-transformer';

class ImageGallery {
  id?: string;
  title: Language;
  mediaGroup: Media;
  status: boolean;
  album?: any;

  constructor(
    id: string,
    title: Language,
    videoUrl: string,
    status: boolean,
    mediaGroup: Media
  ) {
    this.id = id;
    this.title = title;
    this.status = status;
    this.mediaGroup = mediaGroup;
  }

  static plainToInstance(plainData: object): ImageGallery {
    return plainToClass(ImageGallery, plainData);
  }

  static plainToInstances(plainData: object[]): ImageGallery[] {
    return plainToClass(ImageGallery, plainData);
  }
}
export default ImageGallery;
