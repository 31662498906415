import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import EncryptDecrypt from '@functions/EncryptDecrypt'

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_AUTH_URL,
})

const authLink = setContext((_, { headers }) => {
  const { decrypt } = EncryptDecrypt
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('service_delivery_agriculture_token') ?? ''
  const accessToken = decrypt(token) ?? ''

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
})
