import {
  Box,
  Button,
  Card,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import endpoint from "@config/endpoint/agriculture";
import { agricultureGroupSchema } from "@config/schema/agricultureGroup";
import notificationLabel, {
  generateMessage,
} from "@data/localization/notification";
import utilsLabel from "@data/localization/utils";
import { handleDigitKeyPress } from "@functions/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import useAPI from "@hooks/useApi";
import useLang from "@hooks/useLang";
import { IResponse } from "@interface/response.interface";
import AgricultureGroup from "@models/agricultureGroup.model";
import NepaliInput from "@ui/common/atoms/NepaliInput";
import RequireSign from "@ui/common/atoms/RequireSign";
import Modal from "@ui/common/molecules/Modal";
import moment from "moment";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

interface IProps {
  open: boolean;
  setOpen: (data: boolean) => void;
  values: IResponse<AgricultureGroup[]> | null;
  setValues: (data: IResponse<AgricultureGroup[]>) => void;
  selectedValue: AgricultureGroup | null;
  setSelectedValue: (data: AgricultureGroup | null) => void;
}

const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props;
  const { post, patch, put } = useAPI<AgricultureGroup>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { lang } = useLang();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(agricultureGroupSchema),
    defaultValues: {
      groupName: {
        en: "",
        ne: "",
      },
      establishDate: "",
      villageName: "",
      description: "",
      ward: "",
      tole: "",
      population: {
        male: "",
        female: "",
        others: "",
      },
      purpose: "",
      officeWard: "",
      officeName: "",
      members: [
        {
          firstName: "",
          middleName: "",
          lastName: "",
          position: "",
          educationQualification: "",
          age: "",
          farmArea: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "members",
  });

  useEffect(() => {
    if (selectedValue) {
      reset({
        groupName: {
          en: selectedValue?.groupName?.en ?? "",
          ne: selectedValue?.groupName?.ne ?? "",
        },
        //@ts-ignore
        establishDate: selectedValue?.establishDate ?? "",
        villageName: selectedValue?.villageName ?? "",
        description: selectedValue?.description ?? "",
        //@ts-ignore
        ward: selectedValue?.ward ?? "",
        tole: selectedValue?.tole ?? "",
        population: {
          //@ts-ignore
          male: selectedValue?.population?.male ?? "",
          //@ts-ignore
          female: selectedValue?.population?.female ?? "",
          //@ts-ignore
          others: selectedValue?.population?.others ?? "",
        },
        purpose: selectedValue?.purpose ?? "",
        //@ts-ignore
        officeWard: selectedValue?.officeWard ?? "",
        officeName: selectedValue?.officeName ?? "",
        members: selectedValue?.members?.map((value: any) => {
          return {
            id: value?.id ?? "",
            firstName: value?.firstName ?? "",
            middleName: value?.middleName ?? "",
            lastName: value?.lastName ?? "",
            position: value?.position ?? "",
            educationQualification: value?.educationQualification ?? "",
            age: value?.age ?? "",
            farmArea: value?.farmArea ?? "",
          };
        }) ?? [
          {
            firstName: "",
            middleName: "",
            lastName: "",
            position: "",
            educationQualification: "",
            age: "",
            farmArea: "",
          },
        ],
      });
    }
  }, [selectedValue]);

  const onSubmit = async (data: any) => {
    const tempData: any = data;
    tempData.population.total =
      tempData.population.male +
      tempData.population.female +
      tempData.population.others;
    tempData.establishDate = moment(tempData.establishDate).format(
      "YYYY-MM-DD"
    );
    try {
      let res;
      if (selectedValue) {
        tempData.id = selectedValue?.id;
        res = await put(endpoint?.AgricultureGroup, tempData);
      } else {
        tempData.members.forEach((value: any) => {
          delete value.id;
        });

        res = await post(endpoint?.AgricultureGroup, tempData);
      }
      if (res?.data && res?.data?.status !== false) {
        await queryClient.invalidateQueries("myData");
        toast.closeAll();
        reset();
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.farmerGroup[lang],
            selectedValue
              ? notificationLabel?.updated[lang]
              : notificationLabel?.added[lang],
            lang
          ),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setOpen(false);
        reset({});
        setSelectedValue(null);
      } else if (!res?.data?.status) {
        toast.closeAll();
        toast({
          title: notificationLabel?.error[lang],
          description:
            res?.error?.response?.data?.message ??
            notificationLabel?.somethingWrongHappen[lang],
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      console.log(err);
      toast.closeAll();
      toast({
        title: notificationLabel?.error[lang],
        description:
          err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (selectedValue) {
      setOpen(true);
    }
  }, [selectedValue]);

  return (
    <Box>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        title="कृषक समुह"
        size="lg"
        onCloseComplete={() => {
          setSelectedValue(null);
          reset({
            groupName: {
              en: "",
              ne: "",
            },
            establishDate: "",
            villageName: "",
            description: "",
            ward: "",
            tole: "",
            population: {
              male: "",
              female: "",
              others: "",
            },
            purpose: "",
            officeWard: "",
            officeName: "",
            members: [
              {
                firstName: "",
                middleName: "",
                lastName: "",
                position: "",
                educationQualification: "",
                age: "",
                farmArea: "",
              },
            ],
          });
        }}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Text mb="2" fontSize={"17px"} fontWeight={"bold"}>
                  कृषक समुहको विवरण
                </Text>
                <Grid
                  gap={5}
                  mb="4"
                  templateColumns={{
                    lg: "repeat(4,1fr)",
                    md: "repeat(3,1fr)",
                    sm: "repeat(2,1fr)",
                    base: "repeat(1,1fr)",
                  }}
                >
                  <FormControl isInvalid={errors.groupName?.en != null}>
                    <FormLabel>
                      Farmer group name - english <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={errors?.groupName?.en != null ? "error" : ""}
                      type="text"
                      {...register("groupName.en")}
                      placeholder={"Enter farmer group name"}
                    />
                    <FormErrorMessage>
                      {errors?.groupName?.en?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.groupName?.ne != null}>
                    <FormLabel>
                      कृषक समुहको नाम - नेपाली <RequireSign />
                    </FormLabel>
                    <NepaliInput
                      error={errors?.groupName?.ne != null}
                      placeholder={"कृषक समुहको नाम लेख्नुहोस "}
                      handleChange={(e: string) => {
                        setValue("groupName.ne", e);
                        clearErrors("groupName.ne");
                      }}
                      value={watch("groupName.ne")}
                    />
                    <FormErrorMessage>
                      {errors?.groupName?.ne?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.establishDate != null}>
                    <FormLabel>
                      कृषक समुहको स्थापना वर्ष <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={errors?.establishDate != null ? "error" : ""}
                      type="date"
                      {...register("establishDate")}
                      placeholder={"स्थापना वर्ष लेख्नुहोस "}
                    />
                    <FormErrorMessage>
                      {errors?.establishDate?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.villageName != null}>
                    <FormLabel>
                      कृषक समुहको गाउँपालिकाको नाम <RequireSign />
                    </FormLabel>
                    <NepaliInput
                      error={errors?.villageName != null}
                      placeholder={"कृषक समुहको नाम लेख्नुहोस "}
                      handleChange={(e: string) => {
                        setValue("villageName", e);
                        clearErrors("villageName");
                      }}
                      value={watch("villageName")}
                    />

                    <FormErrorMessage>
                      {errors?.villageName?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.ward != null}>
                    <FormLabel>
                      कृषक समुहको वडा नं <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={errors?.ward != null ? "error" : ""}
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("ward", {
                        valueAsNumber: true,
                      })}
                      placeholder={"वडा नं लेख्नुहोस "}
                    />
                    <FormErrorMessage>{errors?.ward?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.tole != null}>
                    <FormLabel>
                      कृषक समुहको टोलको नाम <RequireSign />
                    </FormLabel>
                    <NepaliInput
                      error={errors?.tole != null}
                      placeholder={"कृषक समुहको नाम लेख्नुहोस "}
                      handleChange={(e: string) => {
                        setValue("tole", e);
                        clearErrors("tole");
                      }}
                      value={watch("tole")}
                    />
                    <FormErrorMessage>{errors?.tole?.message}</FormErrorMessage>
                  </FormControl>
                </Grid>

                <Box mb="4">
                  <Text pt="5" mb="2" fontSize={"17px"} fontWeight={"bold"}>
                    कृषक समुहको सदस्य संख्याको विवरण
                  </Text>
                  <Box>
                    <Text pb="1" fontSize={"15px"} fontWeight={"bold"}>
                      सदस्य संख्या
                    </Text>
                    <Grid
                      gap={5}
                      templateColumns={{
                        lg: "repeat(4,1fr)",
                        md: "repeat(3,1fr)",
                        sm: "repeat(2,1fr)",
                        base: "repeat(1,1fr)",
                      }}
                    >
                      <FormControl isInvalid={errors.population?.male != null}>
                        <FormLabel>
                          पुरुष <RequireSign />
                        </FormLabel>
                        <Input
                          size="sm"
                          variant={
                            errors?.population?.male != null ? "error" : ""
                          }
                          onKeyPress={handleDigitKeyPress}
                          type="number"
                          {...register("population.male", {
                            valueAsNumber: true,
                          })}
                          placeholder={"पुरुषको संख्या लेख्नुहोस "}
                        />
                        <FormErrorMessage>
                          {errors?.population?.male?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors.population?.female != null}
                      >
                        <FormLabel>
                          महिला <RequireSign />
                        </FormLabel>
                        <Input
                          size="sm"
                          variant={
                            errors?.population?.female != null ? "error" : ""
                          }
                          onKeyPress={handleDigitKeyPress}
                          type="number"
                          {...register("population.female", {
                            valueAsNumber: true,
                          })}
                          placeholder={"महिलाको संख्या लेख्नुहोस "}
                        />
                        <FormErrorMessage>
                          {errors?.population?.female?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={errors.population?.others != null}
                      >
                        <FormLabel>
                          अन्य <RequireSign />
                        </FormLabel>
                        <Input
                          size="sm"
                          variant={
                            errors?.population?.others != null ? "error" : ""
                          }
                          onKeyPress={handleDigitKeyPress}
                          type="number"
                          {...register("population.others", {
                            valueAsNumber: true,
                          })}
                          placeholder={"अन्य संख्या लेख्नुहोस "}
                        />
                        <FormErrorMessage>
                          {errors?.population?.others?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Grid>
                  </Box>
                  <Grid
                    pt="5"
                    gap={5}
                    templateColumns={{
                      lg: "repeat(4,1fr)",
                      md: "repeat(3,1fr)",
                      sm: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    }}
                  >
                    <FormControl isInvalid={errors.purpose != null}>
                      <FormLabel>
                        समुहको उद्देश्य <RequireSign />
                      </FormLabel>
                      <NepaliInput
                        error={errors?.purpose != null}
                        placeholder={"कृषक समुहको नाम लेख्नुहोस "}
                        handleChange={(e: string) => {
                          setValue("purpose", e);
                          clearErrors("purpose");
                        }}
                        value={watch("purpose")}
                      />
                      <FormErrorMessage>
                        {errors?.purpose?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.description != null}>
                      <FormLabel>
                        समुहको विवरण <RequireSign />
                      </FormLabel>

                      <NepaliInput
                        error={errors?.description != null}
                        placeholder={" समुहको विवरण लेख्नुहोस "}
                        handleChange={(e: string) => {
                          setValue("description", e);
                        }}
                        value={watch("description")}
                      />
                      <FormErrorMessage>
                        {errors?.description?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.officeWard != null}>
                      <FormLabel>
                        कार्यालयको वडा <RequireSign />
                      </FormLabel>
                      <Input
                        size="sm"
                        variant={errors?.officeWard != null ? "error" : ""}
                        onKeyPress={handleDigitKeyPress}
                        type="number"
                        {...register("officeWard")}
                        placeholder={"कार्यालयको वडा लेख्नुहोस "}
                      />
                      <FormErrorMessage>
                        {errors?.officeWard?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.officeName != null}>
                      <FormLabel>
                        कार्यालयको नाम <RequireSign />
                      </FormLabel>

                      <NepaliInput
                        error={errors?.officeName != null}
                        placeholder={"कार्यालयको नाम लेख्नुहोस  "}
                        handleChange={async (e: string) => {
                          setValue("officeName", e);
                          clearErrors("officeName");
                        }}
                        value={watch("officeName")}
                      />
                      <FormErrorMessage>
                        {errors?.officeName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Grid>
                </Box>
              </Box>
              <Box>
                <Text pt="5" mb="2" fontSize={"17px"} fontWeight={"bold"}>
                  पदाधिकारीहरुको विवरण
                </Text>
                {fields.map((field, index) => {
                  return (
                    <Card
                      className="box"
                      p="4"
                      key={field.id}
                      sx={{
                        borderRadius: "2px",
                        boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.75)",
                      }}
                    >
                      <Text
                        mb="2"
                        fontSize={"15px"}
                        fontWeight={"bold"}
                        color={"green"}
                      >
                        पदाधिकारी नं : {index + 1}
                      </Text>
                      <Grid
                        templateColumns={{
                          lg: "repeat(4,1fr)",
                          md: "repeat(3,1fr)",
                          base: "repeat(1,1fr)",
                        }}
                        gap="5"
                      >
                        <FormControl
                          isInvalid={
                            errors?.members &&
                            errors?.members[index]?.firstName?.message != null
                          }
                        >
                          <FormLabel>
                            पहिलो नाम <RequireSign />
                          </FormLabel>

                          <NepaliInput
                            error={
                              !!(
                                errors?.members &&
                                errors?.members[index]?.firstName?.message !=
                                  null
                              )
                            }
                            placeholder={"पहिलो नाम लेख्नुहोस "}
                            handleChange={(e: string) => {
                              setValue(`members.${index}.firstName`, e);
                              clearErrors(`members.${index}.firstName`);
                            }}
                            value={watch(`members.${index}.firstName`)}
                          />

                          <FormErrorMessage>
                            {errors?.members &&
                              errors?.members[index]?.firstName?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            errors?.members &&
                            errors?.members[index]?.middleName?.message != null
                          }
                        >
                          <FormLabel>
                            बिचको नाम <RequireSign />
                          </FormLabel>
                          <NepaliInput
                            error={
                              !!(
                                errors?.members &&
                                errors?.members[index]?.middleName?.message !=
                                  null
                              )
                            }
                            placeholder={"बिचको नाम लेख्नुहोस "}
                            handleChange={(e: string) => {
                              setValue(`members.${index}.middleName`, e);
                              clearErrors(`members.${index}.middleName`);
                            }}
                            value={watch(`members.${index}.middleName`)}
                          />
                          <FormErrorMessage>
                            {errors?.members &&
                              errors?.members[index]?.middleName?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            errors?.members &&
                            errors?.members[index]?.lastName?.message != null
                          }
                        >
                          <FormLabel>
                            थर <RequireSign />
                          </FormLabel>
                          <NepaliInput
                            error={
                              !!(
                                errors?.members &&
                                errors?.members[index]?.lastName?.message !=
                                  null
                              )
                            }
                            placeholder={"बिचको नाम लेख्नुहोस "}
                            handleChange={(e: string) => {
                              setValue(`members.${index}.lastName`, e);
                              clearErrors(`members.${index}.lastName`);
                            }}
                            value={watch(`members.${index}.lastName`)}
                          />
                          <FormErrorMessage>
                            {errors?.members &&
                              errors?.members[index]?.lastName?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            errors?.members &&
                            errors?.members[index]?.position?.message != null
                          }
                        >
                          <FormLabel>
                            पद <RequireSign />
                          </FormLabel>
                          <Select
                            size="sm"
                            {...register(`members.${index}.position`)}
                            placeholder={"पद छान्नुहोस"}
                          >
                            <option value="CHAIRMAN">अध्यक्ष</option>
                            <option value="VICE_CHAIRMAN">उपाध्यक्ष</option>
                            <option value="SECRETARY">सचिव</option>
                            <option value="TREASURER">कोषाध्यक्ष</option>
                            <option value="MEMBER">सदस्य</option>
                          </Select>
                          <FormErrorMessage>
                            {errors?.members &&
                              errors?.members[index]?.position?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            errors?.members &&
                            errors?.members[index]?.age?.message != null
                          }
                        >
                          <FormLabel>
                            उमेर <RequireSign />
                          </FormLabel>
                          <Input
                            size="sm"
                            variant={
                              errors?.members &&
                              errors?.members[index]?.age?.message
                                ? "error"
                                : ""
                            }
                            type="number"
                            onKeyPress={handleDigitKeyPress}
                            {...register(`members.${index}.age`, {
                              valueAsNumber: true,
                            })}
                            placeholder={"उमेर लेख्नुहोस"}
                          />
                          <FormErrorMessage>
                            {errors?.members &&
                              errors?.members[index]?.age?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            errors?.members &&
                            errors?.members[index]?.educationQualification
                              ?.message != null
                          }
                        >
                          <FormLabel>
                            शैक्षिक योग्यता <RequireSign />
                          </FormLabel>
                          <Input
                            size="sm"
                            variant={
                              errors?.members &&
                              errors?.members[index]?.educationQualification
                                ?.message != null
                                ? "error"
                                : ""
                            }
                            type="text"
                            {...register(
                              `members.${index}.educationQualification`
                            )}
                            placeholder={"शैक्षिक योग्यता लेख्नुहोस"}
                          />
                          <FormErrorMessage>
                            {errors?.members &&
                              errors?.members[index]?.educationQualification
                                ?.message}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={
                            errors?.members &&
                            errors?.members[index]?.farmArea?.message != null
                          }
                        >
                          <FormLabel>
                            जग्गा क्षेत्रफल <RequireSign />
                          </FormLabel>
                          <Input
                            size="sm"
                            variant={
                              errors?.members &&
                              errors?.members[index]?.farmArea?.message != null
                                ? "error"
                                : ""
                            }
                            type="text"
                            {...register(`members.${index}.farmArea`)}
                            placeholder={"जग्गा क्षेत्रफल लेख्नुहोस"}
                          />
                          <FormErrorMessage>
                            {errors?.members &&
                              errors?.members[index]?.farmArea?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Grid>

                      <Flex justifyContent={"space-between"} my="2">
                        <Box>
                          {fields.length !== 1 && (
                            <Button
                              onClick={() => remove(index)}
                              size={"sm"}
                              variant={"outline"}
                              colorScheme="red"
                            >
                              {utilsLabel?.delete[lang]}
                            </Button>
                          )}
                        </Box>
                        <Box>
                          {fields.length - 1 === index && (
                            <Button
                              onClick={() =>
                                append({
                                  firstName: "",
                                  middleName: "",
                                  lastName: "",
                                  position: "",
                                  educationQualification: "",
                                  age: "",
                                  farmArea: "",
                                })
                              }
                              size={"sm"}
                              variant={"primary"}
                            >
                              {utilsLabel?.addMore[lang]}
                            </Button>
                          )}
                        </Box>
                      </Flex>
                    </Card>
                  );
                })}
              </Box>
              <Flex my="5" gap="5" justifyContent={"flex-end"}>
                <Button
                  size="sm"
                  variant={"outline"}
                  colorScheme="red"
                  onClick={() => setOpen(false)}
                >
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type="submit"
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size="sm"
                  variant={"primary"}
                >
                  {selectedValue
                    ? utilsLabel?.update[lang]
                    : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </Box>
  );
};

export default Add;
