import { plainToClass } from 'class-transformer'
import FarmerAnimalHusbandryInformation from './farmerAnimalHusbandryInformation.model'
import FarmerCropsInformation from './farmerCropsInformation.model'
import FarmerFamilyInformation from './farmerFamilyInformation.model'
import FarmerFarmsInformation from './farmerFarmsInformation.model'
import FarmerPersonalInformation from './farmerPersonalInformation.model'

class Farmer {
  id: string
  farmerPersonalInformation: FarmerPersonalInformation
  farmerFamilyInformation: FarmerFamilyInformation
  farmerFarmsInformation: FarmerFarmsInformation
  farmerCropsInformation: FarmerCropsInformation
  farmerAnimalHusbandryInformation: FarmerAnimalHusbandryInformation

  constructor(
    id: string,
    farmerPersonalInformation: FarmerPersonalInformation,
    farmerFamilyInformation: FarmerFamilyInformation,
    farmerFarmsInformation: FarmerFarmsInformation,
    farmerCropsInformation: FarmerCropsInformation,
    farmerAnimalHusbandryInformation: FarmerAnimalHusbandryInformation
  ) {
    this.id = id
    this.farmerPersonalInformation = farmerPersonalInformation
    this.farmerFamilyInformation = farmerFamilyInformation
    this.farmerFarmsInformation = farmerFarmsInformation
    this.farmerCropsInformation = farmerCropsInformation
    this.farmerAnimalHusbandryInformation = farmerAnimalHusbandryInformation
  }

  static plainToInstance(plainData: object): Farmer {
    return plainToClass(Farmer, plainData)
  }

  static plainToInstances(plainData: object[]): Farmer[] {
    return plainToClass(Farmer, plainData)
  }
}
export default Farmer
