import { Box } from '@chakra-ui/react'
import Footer from '@ui/user/organisms/Footer'
import NavBar from '@ui/user/organisms/NavBar'
import { Outlet } from 'react-router-dom'

const UserTemplate = () => {
  return (
    <Box>
      <NavBar />
      <Box py='4'>
        <Outlet />
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  )
}

export default UserTemplate
