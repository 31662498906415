import Category from '@models/news/category.model'
import { plainToClass } from 'class-transformer'

class News {
  id?: string
  title: any
  description: any
  category: Category
  media: any
  isPublished: boolean
  createdAt: Date

  constructor(
    id: string,
    title: any,
    description: any,
    category: Category,
    media: any,
    isPublished: boolean,
    createdAt: Date
  ) {
    this.id = id
    this.title = title
    this.description = description
    this.category = category
    this.media = media
    this.isPublished = isPublished
    this.createdAt = createdAt
  }

  static plainToInstance(plainData: object): News {
    return plainToClass(News, plainData)
  }

  static plainToInstances(plainData: object[]): News[] {
    return plainToClass(News, plainData)
  }
}
export default News
