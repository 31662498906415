import { Box, Heading } from '@chakra-ui/react'
import FarmerModel from '@models/farmer/farmer.model'
import React from 'react'

interface IProps {
  farmer: FarmerModel | null
}

const ShowFarmerInformation = ({ farmer }: IProps) => {
  return (
    <div>
      <Box>
        <Box pb='10'>
          <Heading fontSize={'15px'} pb='3'>
            १. व्यक्तिगत विवरण
          </Heading>
          <table>
            <thead>
              <tr>
                <th>पुरा नाम</th>
                <th>पुरा नाम</th>
                <th>जन्म मिति (बि.सं)</th>
                <th>शेक्षिक योग्यता</th>
                <th>साक्षर योग्यता</th>
                <th>वडा नम्बर</th>
                <th>गाँउको नाम</th>
                <th>नागरिकता नम्बर </th>
                <th>नागरिकता जारि जिल्ला </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {farmer?.farmerPersonalInformation?.firstName?.en}
                  {farmer?.farmerPersonalInformation?.middleName?.en
                    ? ' ' + farmer?.farmerPersonalInformation?.middleName?.en + ' '
                    : ''}
                  {farmer?.farmerPersonalInformation?.lastName?.en} - {farmer?.farmerPersonalInformation?.firstName?.ne}
                  {farmer?.farmerPersonalInformation?.middleName?.ne
                    ? ' ' + farmer?.farmerPersonalInformation?.middleName?.ne + ' '
                    : ''}
                  {farmer?.farmerPersonalInformation?.lastName?.ne}
                </td>
                <td
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {farmer?.farmerPersonalInformation?.gender?.toLowerCase()}
                </td>
                <td>{farmer?.farmerPersonalInformation?.dateOfBirth}</td>
                <td
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {farmer?.farmerPersonalInformation?.academicQualification?.toLowerCase()}
                </td>
                <td
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {' '}
                  {farmer?.farmerPersonalInformation?.literateLevel?.toLowerCase()}
                </td>
                <td>{farmer?.farmerPersonalInformation?.ward}</td>
                <td>{farmer?.farmerPersonalInformation?.village}</td>
                <td>{farmer?.farmerPersonalInformation?.citizenshipNumber}</td>
                <td>{farmer?.farmerPersonalInformation?.citizenshipIssuedDistrict}</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box pb='10'>
          <Heading fontSize={'15px'} pb='3'>
            २. कृषि विवरण
          </Heading>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>कृषि कार्य गरेको वडा नम्बर</th>
                <th rowSpan={2}>कृषि कार्य गरेको गाँउको नाम</th>
                <th rowSpan={2}>कृषि कार्यको क्षेत्र</th>
                <th rowSpan={2}>कृषि कार्यको उपक्षेत्र</th>
                <th rowSpan={2}>आफ्नो जग्गाको छ ?</th>
                <th colSpan={3}>जग्गाको क्षेत्रफल</th>
                <th colSpan={3}>औसत बार्षिक आम्दानी रु: </th>
                <th rowSpan={2}>बार्षिक औसत महिना कृषि कार्यमा संलग्न समय </th>
              </tr>
              <tr>
                <th>बिघा</th>
                <th>कठ्ठा</th>
                <th>धुर</th>
                <th>कृषि</th>
                <th>गैर कृषि</th>
                <th>जम्मा</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerPersonalInformation?.farmWard}</td>
                <td>{farmer?.farmerPersonalInformation?.farmVillage}</td>
                <td>{farmer?.farmerPersonalInformation?.agricultureArea}</td>
                <td>{farmer?.farmerPersonalInformation?.agricultureSubArea}</td>
                <td>{farmer?.farmerPersonalInformation?.isOwnLand ? 'छ' : 'छैन भुमिहिन हो ।'}</td>
                <td>{farmer?.farmerPersonalInformation?.landArea?.biggha}</td>
                <td>{farmer?.farmerPersonalInformation?.landArea?.katha}</td>
                <td>{farmer?.farmerPersonalInformation?.landArea?.dhur}</td>{' '}
                <td>{farmer?.farmerPersonalInformation?.yearlyTurnOver?.agriculture}</td>
                <td>{farmer?.farmerPersonalInformation?.yearlyTurnOver?.nonAgriculture}</td>
                <td>{farmer?.farmerPersonalInformation?.yearlyTurnOver?.total}</td>
                <td>{farmer?.farmerPersonalInformation?.averageMonthEngageOnAgriculture}</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box pb='10'>
          <Heading fontSize={'15px'} pb='3'>
            ३. पारिवारिक विवरण
          </Heading>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  पारिवारमा रहेका जम्मा सदस्य संख्या
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  कृषि कार्यमा संलग्न सदस्य संख्या
                </th>
              </tr>
              <tr>
                <th>पुरुष</th>
                <th>महिला</th>
                <th>जम्मा</th> <th>पुरुष</th>
                <th>महिला</th>
                <th>जम्मा</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerFamilyInformation?.totalFamilyMember?.male} &nbsp; &nbsp; </td>
                <td>{farmer?.farmerFamilyInformation?.totalFamilyMember?.female} &nbsp; &nbsp; </td>
                <td>{farmer?.farmerFamilyInformation?.totalFamilyMember?.total}</td>

                <td>{farmer?.farmerFamilyInformation?.involveInAgriculture?.male} &nbsp; &nbsp; </td>
                <td>{farmer?.farmerFamilyInformation?.involveInAgriculture?.female} &nbsp; &nbsp; </td>
                <td> {farmer?.farmerFamilyInformation?.involveInAgriculture?.total}</td>
              </tr>
            </tbody>
          </table>

          <Box py='3' pt='5'>
            ३.२ कृषि कार्यमा संलग्न सदस्यहरुलप बिस्तृत विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.म.
                </th>
                <th>किसान संगको नाता</th>
                <th>पुरा नाम</th>
                <th>उमेर</th>
                <th>लिङ्ग</th>
                <th>कृषि कार्यको क्षेत्र</th>
                <th>नागरिकता प्रमाण पत्र नम्बर</th>
                <th>फोन नम्बर</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerFamilyInformation?.familyDetailsWhoInvolveInAgriculture?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{item?.relation}</td>
                    <td>{item?.fullname}</td>
                    <td>{item?.age}</td>
                    <td>{item?.gender}</td>
                    <td>{item?.workingArea}</td>
                    <td>{item?.citizenshipNumber}</td>
                    <td>{item?.phoneNumber}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Box>
        <Box pb='10'>
          <Heading fontSize={'15px'} pb='3'>
            ४. फार्मको विवरण
          </Heading>
          <Box pb='3'>४.१. जमिन स्वामित्वको विवरण (स्थायी ठेगानामा रहेको)</Box>
          <table>
            <thead>
              <tr>
                <th
                  rowSpan={2}
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  जग्गाको विवरण
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  सिंचाई विवरण
                </th>
              </tr>
              <tr>
                <th>स्वामित्व (जग्गा धनी)</th>
                <th>जमिनको किसिम</th>
                <th>क्षेत्रफल</th>
                <th>सिंचाई उपलब्धता</th>
                <th>सिंचाई क्षेत्रफल</th>
                <th>सिंचाई स्रोत</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerFarmsInformation?.landOwnershipInformation?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{item?.landOwnershipInformation?.landInformation?.ownership}</td>
                    <td>{item?.landOwnershipInformation?.landInformation?.landType}</td>
                    <td>{item?.landOwnershipInformation?.landInformation?.area}</td>
                    <td>{item?.landOwnershipInformation?.irregation?.isAvaiableIrregation ? 'छ' : 'छैन'}</td>
                    <td>{item?.landOwnershipInformation?.irregation?.irrigatedArea}</td>
                    <td>{item?.landOwnershipInformation?.irregation?.irrigationSouce}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ४.२. एक भन्दा बढी स्थानीय तहमा कृषि फार्म गरिएको भए सो को विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>ठेगाना</th>
                <th>क्षेत्रफल</th>
                <th>सिंचाई उपलब्धता</th>
                <th>कृषि कार्यको क्षेत्र</th>
                <th>कृषि कार्यको उपलब्धता</th>
                <th>स्वामित्व</th>
                <th>करार भए जग्गा धनीको विवरण</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerFarmsInformation?.agricultureDetails?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{i.address}</td>
                    <td>{i.area}</td>
                    <td>{i.isAvaiableIrregation ? 'छ' : 'छैन'}</td>
                    <td>{i.categoryOfAgriculture}</td>
                    <td>{i.subCategoryOfAgriculture}</td>
                    <td>{i.ownership}</td>
                    <td>{i.ownershipInformationIfLandIsOnContract}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ४.३. अन्य व्यक्तिलाई जग्गा करार/भाडामा लगाएको भए सोको विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                  rowSpan={2}
                >
                  क्र.स.
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                  colSpan={3}
                >
                  जग्गाको विवरण
                </th>
                <th
                  style={{
                    textAlign: 'center',
                  }}
                  colSpan={3}
                >
                  सिंचाई विवरण
                </th>
              </tr>
              <tr>
                <th>स्वामित्व</th>
                <th>जमिनको किसिम</th>
                <th>क्षेत्रफल</th>
                <th>सिंचाई उपलब्धता</th>
                <th>सिंचाई क्षेत्रफल</th>
                <th>सिंचाईको स्रोत</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerFarmsInformation?.contractDetails?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.contractDetails.landInformation.ownership}</td>
                    <td>{i.contractDetails.landInformation.landType}</td>
                    <td>{i.contractDetails.landInformation.area}</td>
                    <td>{i.contractDetails.irregation.isAvaiableIrregation ? 'छ' : 'छैन'}</td>
                    <td>{i.contractDetails.irregation.irrigatedArea}</td>
                    <td>{i.contractDetails.irregation.irrigationSouce}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>{' '}
          <Box pb='3' pt='5'>
            ४.४. कृषकको विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th>कृषक दर्ता नम्बर छ </th>
                <th>दर्ता नम्बर</th>
                <th>नाम</th>
                <th>फोन नम्बर</th>
                <th>सिंचाई क्षेत्रफल</th>
                <th>ठेगाना</th>
                <th>सम्झौता मिति</th>
                <th>सम्झौता समाप्त हुने मिति</th>
                <th>हालको ठेगाना</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerFarmsInformation?.isRegistedFarmer ? 'छ' : 'छैन'}</td>
                <td>{farmer?.farmerFarmsInformation?.farmerRegistrationNumber}</td>
                <td>{farmer?.farmerFarmsInformation?.farmerDetail?.name}</td>
                <td>{farmer?.farmerFarmsInformation?.farmerDetail?.phoneNumber}</td>
                <td>{farmer?.farmerFarmsInformation?.farmerDetail?.address}</td>
                <td>{farmer?.farmerFarmsInformation?.farmerDetail?.contractDate}</td>
                <td>{farmer?.farmerFarmsInformation?.farmerDetail?.citizenshipNumber}</td>
                <td>{farmer?.farmerFarmsInformation?.farmerDetail?.currentAddress}</td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box pb='10'>
          <Heading fontSize={'15px'} pb='3'>
            ५. बाली उत्पादन विवरण
          </Heading>
          <Box pb='3'>५.१. खाधन्न बाली, तरकारि बाली, दलहनबाली,औधोगीक बाली, मसलाबाली, पुष्पखेती</Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>बालीको नाम</th>
                <th>क्षेत्रफल</th>
                <th
                  style={{
                    width: '200px',
                  }}
                >
                  उत्पादन परिमाण (के.जि) मा
                </th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerCropsInformation?.foodCrops?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{i.name}</td>
                    <td>{i.area}</td>
                    <td>{i.productionQuantity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ५.२. फलफुल बाली
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>बालीको नाम</th>
                <th>बोटको संख्या</th>
                <th>क्षेत्रफल</th>
                <th
                  style={{
                    width: '200px',
                  }}
                >
                  उत्पादन परिमाण (के.जि) मा
                </th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerCropsInformation?.fruitsCrops?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.name}</td>
                    <td>{i.plantCount}</td>
                    <td>{i.area}</td>
                    <td>{i.productionQuantity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ५.३. बिउ / बेर्ना / नर्सरी
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>बाली</th>
                <th>जात</th>
                <th>क्षेत्रफल</th>
                <th
                  style={{
                    width: '200px',
                  }}
                >
                  उत्पादन परिमाण (के.जि) मा
                </th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerCropsInformation?.seeds?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.crops}</td>
                    <td>{i.type}</td>
                    <td>{i.area}</td>
                    <td>{i.productionQuantity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ५.४. च्याउ
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>च्याउको जात</th>
                <th>टनेल संख्या</th>
                <th>प्लाष्टिकघर क्षेत्रफल</th>
                <th>उत्पादन परिमाण (के.जि) मा</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerCropsInformation?.mushroom?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.type}</td>
                    <td>{i.tunnelCount}</td>
                    <td>{i.area}</td>
                    <td>{i.productionQuantity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ५.५. रेशम
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>किम्बु लगाईएको क्षेत्रफल</th>
                <th>रेशम कीराको जात</th>
                <th>किरा संख्या</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerCropsInformation?.silk?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.area}</td>
                    <td>{i.speciesOfInsects}</td>
                    <td>{i.insectsCount}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ५.६. मौरी
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>मौरीको जात</th>
                <th>घारको संख्या</th>
                <th>उत्पादन परिमाण (के.जि) मा (मह /घार / गोला / रोयल / जेली / प्रोपेलिस आदी)</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerCropsInformation?.bee?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.type}</td>
                    <td>{i.numberOfHives}</td>
                    <td>{i.productionQuantity}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Box>
        <Box pb='5'>
          <Heading fontSize={'15px'} pb='3'>
            ६. पशुपन्छी पालन विवरण
          </Heading>
          <Box pb='3'>६.१. गाईपालन</Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  स्थानीय
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  उन्नत
                </th>
              </tr>
              <tr>
                <th>संख्या </th>
                <th>बार्षिक औसत दुध दिने महिना</th>
                <th>दैनिक दुध उत्पादन लिटर</th>
                <th>संख्या </th>
                <th>बार्षिक औसत दुध दिने महिना</th>
                <th>दैनिक दुध उत्पादन लिटर</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.cattleRearing?.local?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.cattleRearing?.local?.annualAverageMilkingMonths}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.cattleRearing?.local?.LitersOfMilkProductionPerDay}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.cattleRearing?.advance?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.cattleRearing?.advance?.annualAverageMilkingMonths}</td>
                <td>
                  {farmer?.farmerAnimalHusbandryInformation?.cattleRearing?.advance?.LitersOfMilkProductionPerDay}
                </td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.२. भैसीपालन
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  स्थानीय
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  उन्नत
                </th>
              </tr>
              <tr>
                <th>संख्या </th>
                <th>बार्षिक औसत दुध दिने महिना</th>
                <th>दैनिक दुध उत्पादन लिटर</th>
                <th>संख्या </th>
                <th>बार्षिक औसत दुध दिने महिना</th>
                <th>दैनिक दुध उत्पादन लिटर</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.buffaloRearing?.local?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.buffaloRearing?.local?.annualAverageMilkingMonths}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.buffaloRearing?.local?.LitersOfMilkProductionPerDay}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.buffaloRearing?.advance?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.buffaloRearing?.advance?.annualAverageMilkingMonths}</td>
                <td>
                  {farmer?.farmerAnimalHusbandryInformation?.buffaloRearing?.advance?.LitersOfMilkProductionPerDay}
                </td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.३. चौरी/याक पालन
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  चौरी
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  याक
                </th>
              </tr>
              <tr>
                <th>संख्या </th>
                <th>बार्षिक औसत दुध दिने महिना</th>
                <th>दैनिक दुध उत्पादन लिटर</th>
                <th>संख्या </th>
                <th>बार्षिक औसत दुध दिने महिना</th>
                <th>दैनिक दुध उत्पादन लिटर</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.yakRearing?.chauri?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.yakRearing?.chauri?.annualAverageMilkingMonths}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.yakRearing?.chauri?.LitersOfMilkProductionPerDay}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.yakRearing?.yak?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.yakRearing?.yak?.annualAverageMilkingMonths}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.yakRearing?.yak?.LitersOfMilkProductionPerDay}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.४. गोरु / रांगा पालन
          </Box>
          <table>
            <thead>
              <tr>
                <th>गोरु </th>
                <th>रांगा</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.bullOrQueue?.bull?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.bullOrQueue?.queue?.count}</td>
              </tr>
            </tbody>
          </table>{' '}
          <Box pb='3' pt='5'>
            ६.५. भेडा / बाख्रा / च्या्ङ्ग्रा पालन
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  भेडा
                </th>
                <th
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  बाख्रा
                </th>

                <th
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  च्या्ङ्ग्रा
                </th>
              </tr>
              <tr>
                <th>माउ</th>
                <th>खसि</th>
                <th>बोका</th>
                <th>पठा/पाठि</th> <th>माउ</th>
                <th>खसि</th>
                <th>बोका</th>
                <th>पठा/पाठि</th> <th>माउ</th>
                <th>खसि</th>
                <th>बोका</th>
                <th>पठा/पाठि</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.sheep?.ewe}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.sheep?.ram}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.sheep?.castratedSheep}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.sheep?.lamp}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.goat?.sheGoat}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.goat?.goat}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.goat?.billyGoat}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.goat?.kid}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.mountainGoat?.sheGoat}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.mountainGoat?.goat}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.mountainGoat?.castratedGoat}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.sheepGoatMountainGoat?.mountainGoat?.kid}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.६. बङ्गुर / सुङ्गुर / बंदेल पालन
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  बङ्गुर
                </th>
                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  सुङ्गुर
                </th>

                <th
                  colSpan={3}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  बंदेल
                </th>
              </tr>
              <tr>
                <th>माउ</th>
                <th>भाले</th>
                <th>पठा/पाठि</th>
                <th>माउ</th>
                <th>भाले</th>
                <th>पठा/पाठि</th>
                <th>माउ</th>
                <th>भाले</th>
                <th>पठा/पाठि</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.boar?.sow}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.boar?.boar}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.boar?.piglet}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.pig?.sow}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.pig?.boar}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.pig?.piglet}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.wildBoar?.sow}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.wildBoar?.boar}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.boarPigWildBoar?.wildBoar?.piglet}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.७. कुखुरापालन
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  लेयर्स कुखुरा
                </th>
                <th
                  colSpan={4}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  ब्रोईलर कुखुरा
                </th>
              </tr>
              <tr>
                <th>पालन गरिएको छ ?</th>
                <th>संख्या</th>
                <th>वार्षिक औषत अण्डा उत्पादन संख्या</th>
                <th>वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन)</th>
                <th>पालन गरिएको छ ?</th>
                <th>संख्या</th>
                <th>वार्षिक औषत अण्डा उत्पादन संख्या</th>
                <th>वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.local?.isFarming ? 'छ' : 'छैन'}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.local?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.local?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.local?.annualAverageProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.boiler?.isFarming ? 'छ' : 'छैन'}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.boiler?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.boiler?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.poultryFarming?.boiler?.annualAverageProduction}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.८. अन्य पन्छीहरु
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={8}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  संख्या
                </th>
                <th
                  colSpan={8}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  वार्षिक औषत अण्डा उत्पादन संख्या
                </th>
                <th
                  colSpan={8}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन)
                </th>
              </tr>
              <tr>
                {[1, 2, 3].map((_, index: number) => (
                  <React.Fragment key={index}>
                    <th>हांस</th>
                    <th>बट्टाई</th>
                    <th>अस्ट्रिच</th>
                    <th>कालिज</th>
                    <th>टर्की</th>
                    <th>लौकाट</th>
                    <th>परेवा</th>
                    <th>अन्य</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.duck?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.battai?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.ostrich?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.kalij?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.turkey?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.laukat?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.parrot?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.others?.count}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.duck?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.battai?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.ostrich?.annualAverageEggProduction}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.kalij?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.turkey?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.laukat?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.parrot?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.ostrich?.annualAverageEggProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.duck?.annualAverageProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.battai?.annualAverageProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.ostrich?.annualAverageProduction}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.kalij?.annualAverageProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.turkey?.annualAverageProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.laukat?.annualAverageProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.parrot?.annualAverageProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.birds?.others?.annualAverageProduction}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.९. मत्स्यपालन
          </Box>
          <table>
            <thead>
              <tr>
                <th>प्रजाति</th>
                <th>पोखरीको क्षेत्रफल</th>
                <th>संख्या</th>
                <th>उत्पादन प्रयोजन </th>
                <th>उत्पादन एकाइ </th>
                <th>उत्पादन परिमाण (के.जि) मा</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {farmer?.farmerAnimalHusbandryInformation?.fisheries?.breed?.map((item: any, index: number) => {
                    return <span key={index}>{item}, &nbsp;</span>
                  })}
                </td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.fisheries?.area}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.fisheries?.count}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.fisheries?.productionPurpose}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.fisheries?.productioUnit}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.fisheries?.productionQuantity}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.१०. अन्य पशुपालन
          </Box>
          <table>
            <thead>
              <tr>
                <th>घोडा</th>
                <th>खच्चड</th>
                <th>गधा</th>
                <th>खरायो</th>
                <th>कुकुर</th>
                <th>बिरालो</th>
                <th>अन्य</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.othersAnimalHusbandry?.horse}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.othersAnimalHusbandry?.khachhad}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.othersAnimalHusbandry?.donkey}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.othersAnimalHusbandry?.rabbit}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.othersAnimalHusbandry?.dog}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.othersAnimalHusbandry?.cat}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.othersAnimalHusbandry?.others}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.११. हुउंदे बर्षे बहुवर्षे घांसेबालीको विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  colSpan={5}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  जात
                </th>
                <th
                  colSpan={5}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  क्षेत्रफल
                </th>
                <th
                  colSpan={5}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  हरियो घांस उत्पादन (मे.टन)
                </th>
                <th
                  colSpan={5}
                  style={{
                    textAlign: 'center',
                  }}
                >
                  बिउ उत्पादन
                </th>
              </tr>
              <tr>
                {[1, 2, 3, 4].map((_, index: number) => (
                  <React.Fragment key={index}>
                    <th>हिउंदे</th>
                    <th>बर्षे</th>
                    <th>बहु बर्षे</th>
                    <th>डाले घांस</th>
                    <th>नर्सरी</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.cold?.breed}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.yearly?.breed}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.allTime?.breed}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.grass?.breed}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.nursery?.breed}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.cold?.area}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.yearly?.area}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.allTime?.area}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.grass?.area}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.nursery?.area}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.cold?.productionQuantity}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.yearly?.productionQuantity}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.allTime?.productionQuantity}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.grass?.productionQuantity}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.nursery?.productionQuantity}</td>

                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.cold?.seedProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.yearly?.seedProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.allTime?.seedProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.grass?.seedProduction}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.grassCrops?.nursery?.seedProduction}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.१२. कृषि व्यावसाय / फर्म / उधम विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th>दर्ता नम्बर</th>
                <th>दर्ता मिति</th>
                <th>नाम</th>
                <th>दर्ता भएको कार्यालय</th>
                <th>प्यान / भ्याट नं.</th>
                <th>अध्यक्ष/संचालकको नाम</th>
                <th>किसिम</th>
                <th>सदस्य संख्या</th>
                <th>बार्षिक रोजगारी सृजना संख्या</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.registrationNumber}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.registrationDate}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.name}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.registeredOffice}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.panVatNumber}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.chairmanName}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.type}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.memberCount}</td>
                <td>{farmer?.farmerAnimalHusbandryInformation?.industryDetails?.annualJobCount}</td>
              </tr>
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.१३. कृषि / पशूपन्छी / मत्स्यपालन संग सम्बन्धित मेशिनरी प्रयोग विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>मेसिनरीको नाम</th>
                <th>स्वामित्व</th>
                <th>क्षमता</th>
                <th>संख्या</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerAnimalHusbandryInformation?.machinery?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.name}</td>
                    <td>{i.ownership}</td>
                    <td>{i.capacity} </td>
                    <td>{i.count} </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Box pb='3' pt='5'>
            ६.१४. कृषि / पशुपालन / मत्स्यपालनसंग सम्बन्धित संरचनाको विवरण
          </Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>संरचनाको प्रकार</th>
                <th>संख्या</th>
                <th>क्षेत्रफल</th>
              </tr>
            </thead>
            <tbody>
              {farmer?.farmerAnimalHusbandryInformation?.building?.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.type}</td>
                    <td>{i.count}</td>
                    <td>{i.area} </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </div>
  )
}

export default ShowFarmerInformation
