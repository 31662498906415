type Label = Record<string, Record<string, string>>

const labels: Label = {
  success: {
    en: 'Success',
    ne: 'सफल भयो',
  },
  error: {
    en: 'Error',
    ne: 'त्रुटि भयो',
  },
  loginSuccessfully: {
    en: 'Login successfully',
    ne: 'सफलतापूर्वक लगइन भयो',
  },
  invalidUsernameOrPassword: {
    en: 'Invalid Username or Password',
    ne: 'अमान्य युजरनेम वा पासवर्ड',
  },
  logoutSuccessfully: {
    en: 'Logout successfully',
    ne: 'सफलतापूर्वक लगआउट भयो',
  },
  somethingWrongHappen: {
    en: 'Something wrong happen',
    ne: 'केही गलत भयो',
  },
  agricultureCategory: {
    en: 'Agriculture category',
    ne: 'कृषि श्रेणी',
  },
  agricultureType: {
    en: 'Agriculture type',
    ne: 'कृषि प्रकार',
  },
  agricultureName: {
    en: 'Agriculture name',
    ne: 'कृषिको नाम',
  },
  agricultureDetails: {
    en: 'Agriculture details',
    ne: 'कृषि विवरण',
  },
  added: {
    en: 'added',
    ne: 'थपियो',
  },
  updated: {
    en: 'updated',
    ne: 'अपडेट भयो',
  },
  delete: {
    en: 'deleted',
    ne: 'हटाईयो',
  },
  farmerGroup: {
    en: 'Farmer group',
    ne: 'कृषक समूह',
  },
  videoGallery: {
    en: 'Video gallery',
    ne: 'भिडियो ग्यालरी',
  },
  newsCategory: {
    en: 'News Category',
    ne: 'समाचार श्रेणी',
  },
  news: {
    en: 'News',
    ne: 'समाचार',
  },
  carousel: {
    en: 'Carousel',
    ne: 'क्यारोसेल',
  },
  playlist: {
    en: 'Playlist',
    ne: 'प्लेलिस्ट',
  },
  videos: {
    en: 'Videos',
    ne: 'भिडियोहरु',
  },
}
export default labels

const messages: any = {
  en: (message: string, type: string) => `${message} ${type} successfully`,
  ne: (message: string, type: string) => `${message} सफलतापूर्वक ${type}`,
}

export const generateMessage = (message: string, type: string, lang: any) => messages[lang](message, type)
