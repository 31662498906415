import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react'
import endpoint from '@config/endpoint/farmer'
import {
  familyDetailsWhoInvolveInAgricultureSchema,
  familyInformationSchema,
} from '@config/schema/farmer/Registration.schema'
import { handleDigitKeyPress } from '@functions/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import FarmerFamilyInformation from '@models/farmer/farmerFamilyInformation.model'
import RequireSign from '@ui/common/atoms/RequireSign'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiEdit } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'

interface IProps {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  farmerId: string | undefined
}

const FarmerFamilyInformationForm = ({ step, setStep, farmerId }: IProps) => {
  const { post, patch, put, getById } = useAPI<FarmerFamilyInformation>()
  const toast = useToast()
  const [selectedValue, setSelectedValue] = useState<FarmerFamilyInformation | null>(null)

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(familyInformationSchema),
    defaultValues: {
      totalFamilyMember: {
        female: '',
        male: '',
        total: '',
      },
      involveInAgriculture: {
        female: '',
        male: '',
        total: '',
      },
      familyDetailsWhoInvolveInAgriculture: {
        relation: '',
        fullname: '',
        age: '',
        gender: '',
        workingArea: '',
        citizenshipNumber: '',
        phoneNumber: '',
      },
      year: '',
    },
  })

  const onSubmit = async (data: any) => {
    try {
      let res
      if (selectedValue) {
        res = await put(endpoint?.farmerFamilyInformation, {
          id: selectedValue?.id?.toString(),
          totalFamilyMember: {
            female: +data?.totalFamilyMember?.female,
            male: +data?.totalFamilyMember?.male,
            total: +data?.totalFamilyMember?.total,
          },
          involveInAgriculture: {
            female: +data?.involveInAgriculture?.female,
            male: +data?.involveInAgriculture?.male,
            total: +data?.involveInAgriculture?.total,
          },
          familyDetailsWhoInvolveInAgriculture: familyDetailsWhoInvolveInAgriculture ?? undefined,

          year: data?.year,
        })
      } else {
        res = await post(endpoint?.farmerFamilyInformation, {
          farmerId,
          totalFamilyMember: {
            female: +data?.totalFamilyMember?.female,
            male: +data?.totalFamilyMember?.male,
            total: +data?.totalFamilyMember?.total,
          },
          involveInAgriculture: {
            female: +data?.involveInAgriculture?.female,
            male: +data?.involveInAgriculture?.male,
            total: +data?.involveInAgriculture?.total,
          },
          familyDetailsWhoInvolveInAgriculture: familyDetailsWhoInvolveInAgriculture ?? undefined,
          year: data?.year,
        })
      }
      if (res?.data) {
        toast.closeAll()
        toast({
          title: 'सफल भयो',
          description: `पारिवारिक विवरण सफलतापूर्वक ${selectedValue ? 'अपडेट' : 'सुरक्षित'} गरियो`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        setStep(step + 1)
        reset({
          totalFamilyMember: {
            female: '',
            male: '',
            total: '',
          },
          involveInAgriculture: {
            female: '',
            male: '',
            total: '',
          },
          familyDetailsWhoInvolveInAgriculture: {
            relation: '',
            fullname: '',
            age: '',
            gender: '',
            workingArea: '',
            citizenshipNumber: '',
            phoneNumber: '',
          },
          year: '',
        })
      } else {
        toast.closeAll()
        toast({
          title: 'गलत भयो',
          description: res?.error?.response?.data?.message ?? 'केहि गलत भयो',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: 'गलत भयो',
        description: err?.message ?? 'केहि गलत भयो',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const maleFamilyMember = +watch('totalFamilyMember.male')
  const femaleFamilyMember = +watch('totalFamilyMember.female')
  const totalFamilyMember = +maleFamilyMember + +femaleFamilyMember

  useEffect(() => {
    setValue('totalFamilyMember.total', totalFamilyMember?.toString())
  }, [totalFamilyMember, setValue])

  const involveInAgricultureMale = +watch('involveInAgriculture.male')
  const involveInAgricultureFemale = +watch('involveInAgriculture.female')
  const involveInAgricultureTotal = +involveInAgricultureMale + +involveInAgricultureFemale

  useEffect(() => {
    setValue('involveInAgriculture.total', involveInAgricultureTotal?.toString())
  }, [involveInAgricultureTotal, setValue])

  interface IFamilyDetailsWhoInvolveInAgriculture {
    relation: string
    fullname: string
    age: number
    gender: string
    workingArea: string
    citizenshipNumber: string
    phoneNumber: string
  }

  const [familyDetailsWhoInvolveInAgriculture, setFamilyDetailsWhoInvolveInAgriculture] = useState<
    IFamilyDetailsWhoInvolveInAgriculture[] | null
  >(null)
  const [selectedFamilyDetailsWhoInvolveInAgriculture, setSelectedFamilyDetailsWhoInvolveInAgriculture] =
    useState<IFamilyDetailsWhoInvolveInAgriculture | null>(null)

  const addFamilyDetailsWhoInvolveInAgriculture = () => {
    const newFamilyMember = {
      relation: watch('familyDetailsWhoInvolveInAgriculture.relation'),
      fullname: watch('familyDetailsWhoInvolveInAgriculture.fullname'),
      age: +watch('familyDetailsWhoInvolveInAgriculture.age'),
      gender: watch('familyDetailsWhoInvolveInAgriculture.gender'),
      workingArea: watch('familyDetailsWhoInvolveInAgriculture.workingArea'),
      citizenshipNumber: watch('familyDetailsWhoInvolveInAgriculture.citizenshipNumber'),
      phoneNumber: watch('familyDetailsWhoInvolveInAgriculture.phoneNumber'),
    }

    if (familyDetailsWhoInvolveInAgriculture)
      setFamilyDetailsWhoInvolveInAgriculture([...familyDetailsWhoInvolveInAgriculture, newFamilyMember])
    else setFamilyDetailsWhoInvolveInAgriculture([newFamilyMember])

    reset({
      familyDetailsWhoInvolveInAgriculture: {
        relation: '',
        fullname: '',
        age: '',
        gender: '',
        workingArea: '',
        citizenshipNumber: '',
        phoneNumber: '',
      },
    })
  }

  const removeFamilyDetailsWhoInvolveInAgriculture = (index: number) => {
    if (familyDetailsWhoInvolveInAgriculture) {
      const data = familyDetailsWhoInvolveInAgriculture?.filter((_, i: number) => i !== index)
      setFamilyDetailsWhoInvolveInAgriculture(data)
    }
  }

  const editFamilyDetailsWhoInvolveInAgriculture = (index: number) => {
    if (familyDetailsWhoInvolveInAgriculture) {
      removeFamilyDetailsWhoInvolveInAgriculture(index)
      const data = familyDetailsWhoInvolveInAgriculture?.find((_, i: number) => i === index)
      if (data) setSelectedFamilyDetailsWhoInvolveInAgriculture(data)
    }
  }

  useEffect(() => {
    if (selectedFamilyDetailsWhoInvolveInAgriculture) {
      reset({
        familyDetailsWhoInvolveInAgriculture: {
          relation: selectedFamilyDetailsWhoInvolveInAgriculture.relation,
          fullname: selectedFamilyDetailsWhoInvolveInAgriculture.fullname,
          age: selectedFamilyDetailsWhoInvolveInAgriculture.age?.toString(),
          gender: selectedFamilyDetailsWhoInvolveInAgriculture.gender,
          workingArea: selectedFamilyDetailsWhoInvolveInAgriculture.workingArea,
          citizenshipNumber: selectedFamilyDetailsWhoInvolveInAgriculture.citizenshipNumber,
          phoneNumber: selectedFamilyDetailsWhoInvolveInAgriculture.phoneNumber,
        },
      })
    }
  }, [selectedFamilyDetailsWhoInvolveInAgriculture])

  const getFarmerFamilyInformation = async () => {
    try {
      const res = await getById(endpoint?.farmerFamilyInformation + '/farmer', farmerId as string)
      if (res) {
        setSelectedValue(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (farmerId) {
      void getFarmerFamilyInformation()
    }
  }, [])

  useEffect(() => {
    if (selectedValue) {
      reset({
        totalFamilyMember: {
          female: selectedValue?.totalFamilyMember?.female?.toString(),
          male: selectedValue?.totalFamilyMember?.male?.toString(),
          total: selectedValue?.totalFamilyMember?.total?.toString(),
        },
        involveInAgriculture: {
          female: selectedValue?.involveInAgriculture?.female?.toString(),
          male: selectedValue?.involveInAgriculture?.male?.toString(),
          total: selectedValue?.involveInAgriculture?.total?.toString(),
        },
        year: selectedValue?.year,
      })
      if (selectedValue?.familyDetailsWhoInvolveInAgriculture)
        setFamilyDetailsWhoInvolveInAgriculture(selectedValue?.familyDetailsWhoInvolveInAgriculture)
    }
  }, [selectedValue])

  // fake filler function
  const handleFakeFiller = async () => {
    reset({
      year: '11',
      totalFamilyMember: {
        female: '4',
        male: '7',
      },
      involveInAgriculture: {
        female: '4',
        male: '7',
      },
    })
    setFamilyDetailsWhoInvolveInAgriculture([
      {
        relation: 'बाबु',
        fullname: 'बाबु राई',
        age: 45,
        gender: 'पुरुष',
        workingArea: 'बालुवाटार',
        citizenshipNumber: '123456789',
        phoneNumber: '9841234567',
      },
    ])
  }

  return (
    <Box>
      {/* remove this fake filler on production */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleFakeFiller} variant={'outline'} size='md' colorScheme='purple'>
          Fake filler
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading pb='3' size='md' mt='2'>
          पारिवारिक विवरण
        </Heading>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              lg: 'repeat(2,1fr)',
              base: 'repeat(1,1fr)',
            },
            gap: 2,
          }}
          p='2'
          border='1px'
          borderColor='gray.200'
          borderRadius={'2px'}
        >
          <Box p='4' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <FormLabel>
              १. पारिवारमा रहेका जम्मा सदस्य संख्या <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
              }}
            >
              <FormControl isInvalid={errors.totalFamilyMember?.male != null}>
                <FormLabel>
                  १.१. पुरुष <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.totalFamilyMember?.male != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('totalFamilyMember.male')}
                  placeholder={'परिवारका पुरुषको संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.totalFamilyMember?.male?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.totalFamilyMember?.female != null}>
                <FormLabel>
                  १.२. महिला <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.totalFamilyMember?.female != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('totalFamilyMember.female')}
                  placeholder={'परिवारका महिलाको संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.totalFamilyMember?.female?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.totalFamilyMember?.total != null}>
                <FormLabel>
                  १.३. जम्मा <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  readOnly
                  variant={errors?.totalFamilyMember?.total != null ? 'error' : ''}
                  type='text'
                  {...register('totalFamilyMember.total')}
                  placeholder={'परिवारका जम्मा संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.totalFamilyMember?.total?.message}</FormErrorMessage>
              </FormControl>
            </Box>
          </Box>
          <Box
            mt={{
              lg: '0',
              base: '2',
            }}
            p='4'
            border='1px'
            borderColor='gray.200'
            borderRadius={'2px'}
          >
            <FormLabel>
              २. कृषि कार्यमा संलग्न सदस्य संख्या <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 4,
              }}
            >
              <FormControl isInvalid={errors.involveInAgriculture?.male != null}>
                <FormLabel>
                  २.१. पुरुष <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.involveInAgriculture?.male != null ? 'error' : ''}
                  onKeyPress={handleDigitKeyPress}
                  type='number'
                  {...register('involveInAgriculture.male')}
                  placeholder={'कृषि कार्यमा संलग्न पुरुषको संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.involveInAgriculture?.male?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.involveInAgriculture?.female != null}>
                <FormLabel>
                  २.२. महिला <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.involveInAgriculture?.female != null ? 'error' : ''}
                  onKeyPress={handleDigitKeyPress}
                  type='number'
                  {...register('involveInAgriculture.female')}
                  placeholder={'कृषि कार्यमा संलग्न महिलाको संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.involveInAgriculture?.female?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.involveInAgriculture?.total != null}>
                <FormLabel>
                  २.३. जम्मा <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  readOnly
                  variant={errors?.involveInAgriculture?.total != null ? 'error' : ''}
                  type='text'
                  {...register('involveInAgriculture.total')}
                  placeholder={'कृषि कार्यमा संलग्न जम्मा संख्या लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.involveInAgriculture?.total?.message}</FormErrorMessage>
              </FormControl>
            </Box>
          </Box>
        </Box>
        <Box mt='5' p='2' border='1px' borderColor='gray.200' borderRadius={'2px'}>
          <FormLabel>
            ३. कृषि कार्यमा संलग्न सदस्यहरुलप बिस्तृत विवरण <RequireSign />
          </FormLabel>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '50px',
                  }}
                >
                  क्र.स.
                </th>
                <th>किसान संगको नाता</th>
                <th>पुरा नाम</th>
                <th>उमेर</th>
                <th>लिङ्ग</th>
                <th>कृषि कार्यको क्षेत्र</th>
                <th>नागरिकता प्रमाण पत्र नम्बर</th>
                <th>फोन नम्बर</th>
                <th
                  style={{
                    width: '150px',
                  }}
                >
                  कार्य
                </th>
              </tr>
            </thead>
            <tbody>
              {familyDetailsWhoInvolveInAgriculture &&
                familyDetailsWhoInvolveInAgriculture?.length > 0 &&
                familyDetailsWhoInvolveInAgriculture?.map((i, index: number) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{i.relation}</td>
                      <td>{i.fullname}</td>
                      <td>{i.age} बर्ष</td>
                      <td
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {i.gender?.toString()?.toLowerCase()}
                      </td>
                      <td
                        style={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {i.workingArea?.toString()?.toLowerCase()}
                      </td>
                      <td>{i.citizenshipNumber}</td>
                      <td>{i.phoneNumber}</td>
                      <td>
                        <Flex gap='4'>
                          <IconButton
                            colorScheme='telegram'
                            variant='outline'
                            size='xs'
                            aria-label='edit'
                            icon={<BiEdit />}
                            onClick={() => {
                              editFamilyDetailsWhoInvolveInAgriculture(index)
                            }}
                          />
                          <IconButton
                            colorScheme='red'
                            variant='outline'
                            size='xs'
                            aria-label='delete'
                            icon={<BsTrash />}
                            onClick={() => {
                              removeFamilyDetailsWhoInvolveInAgriculture(index)
                            }}
                          />
                        </Flex>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
          <Box mt='2' p='4' border='1px' borderColor='gray.200' borderRadius={'2px'}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(7, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: 3,
              }}
            >
              <FormControl isInvalid={errors.familyDetailsWhoInvolveInAgriculture?.relation != null}>
                <FormLabel>
                  ३.१. किसान संगको नाता <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.familyDetailsWhoInvolveInAgriculture?.relation != null ? 'error' : ''}
                  type='text'
                  {...register('familyDetailsWhoInvolveInAgriculture.relation')}
                  placeholder={'किसान संगको नाता लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.familyDetailsWhoInvolveInAgriculture?.relation?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.familyDetailsWhoInvolveInAgriculture?.fullname != null}>
                <FormLabel>
                  ३.२. पुरा नाम <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.familyDetailsWhoInvolveInAgriculture?.fullname != null ? 'error' : ''}
                  type='text'
                  {...register('familyDetailsWhoInvolveInAgriculture.fullname')}
                  placeholder={'पुरा नाम लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.familyDetailsWhoInvolveInAgriculture?.fullname?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.familyDetailsWhoInvolveInAgriculture?.age != null}>
                <FormLabel>
                  ३.३. उमेर <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.familyDetailsWhoInvolveInAgriculture?.age != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  {...register('familyDetailsWhoInvolveInAgriculture.age')}
                  placeholder={'उमेर लेख्नुहोस'}
                />
                <FormErrorMessage>{errors?.familyDetailsWhoInvolveInAgriculture?.age?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.familyDetailsWhoInvolveInAgriculture?.gender != null}>
                <FormLabel>
                  ३.४. लिङ्ग <RequireSign />
                </FormLabel>
                <Select
                  size='sm'
                  {...register(`familyDetailsWhoInvolveInAgriculture.gender`)}
                  placeholder='लिङ्ग छान्नुहोस'
                >
                  <option value={'पुरुष'}>पुरुष</option>
                  <option value={'महिला'}>महिला</option>
                  <option value={'अन्य'}>अन्य</option>
                </Select>
                <FormErrorMessage>{errors?.familyDetailsWhoInvolveInAgriculture?.gender?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.familyDetailsWhoInvolveInAgriculture?.workingArea != null}>
                <FormLabel>
                  ३.५. कृषि कार्यको क्षेत्र <RequireSign />
                </FormLabel>
                <Select
                  size='sm'
                  {...register(`familyDetailsWhoInvolveInAgriculture.workingArea`)}
                  placeholder='कृषि कार्यको क्षेत्र छान्नुहोस'
                >
                  <option value={'उत्पादन क्षेत्र'}>उत्पादन क्षेत्र</option>
                  <option value={'प्रशोधन क्षेत्र'}>प्रशोधन क्षेत्र</option>
                  <option value={'बजारिकरण'}>बजारिकरण</option>
                  <option value={'कृषि उधम'}>कृषि उधम</option>
                  <option value={'कृषि श्रमिक'}>कृषि श्रमिक</option>
                </Select>
                <FormErrorMessage>
                  {errors?.familyDetailsWhoInvolveInAgriculture?.workingArea?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.familyDetailsWhoInvolveInAgriculture?.citizenshipNumber != null}>
                <FormLabel>
                  ३.६. नागरिकता प्रमाण पत्र नम्बर <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.familyDetailsWhoInvolveInAgriculture?.citizenshipNumber != null ? 'error' : ''}
                  type='text'
                  {...register('familyDetailsWhoInvolveInAgriculture.citizenshipNumber')}
                  placeholder={'नागरिकता प्रमाण पत्र नम्बर लेख्नुहोस'}
                />
                <FormErrorMessage>
                  {errors?.familyDetailsWhoInvolveInAgriculture?.citizenshipNumber?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.familyDetailsWhoInvolveInAgriculture?.phoneNumber != null}>
                <FormLabel>
                  ३.७. फोन नम्बर <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.familyDetailsWhoInvolveInAgriculture?.phoneNumber != null ? 'error' : ''}
                  type='text'
                  {...register('familyDetailsWhoInvolveInAgriculture.phoneNumber')}
                  placeholder={'फोन नम्बर लेख्नुहोस'}
                />
                <FormErrorMessage>
                  {errors?.familyDetailsWhoInvolveInAgriculture?.phoneNumber?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Flex justifyContent={'flex-end'} mt='5'>
              <Button
                size='sm'
                variant={'outline'}
                colorScheme='facebook'
                onClick={async () => {
                  try {
                    await familyDetailsWhoInvolveInAgricultureSchema.validate(getValues(), { abortEarly: false })
                    void addFamilyDetailsWhoInvolveInAgriculture()
                  } catch (validationErrors: any) {
                    if (validationErrors?.inner?.length) {
                      validationErrors.inner.forEach((error: any) => {
                        setError(error.path, { type: 'manual', message: error.message })
                      })
                    } else {
                      setError(validationErrors.path, { type: 'manual', message: validationErrors.message })
                    }
                  }
                }}
              >
                सुरक्षित गर्नुहोस
              </Button>
            </Flex>
          </Box>
        </Box>
        <Box mt='5'>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                lg: 'repeat(6,1fr)',
                md: 'repeat(3,1fr)',
                base: 'repeat(1,1fr)',
              },
            }}
            p='4'
            border='1px'
            borderColor='gray.200'
            borderRadius={'2px'}
          >
            <FormControl isInvalid={errors.year != null}>
              <FormLabel>
                ४. कृषि पेशा वा व्यावसायमा संलग्न वर्ष <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.year != null ? 'error' : ''}
                type='number'
                onKeyPress={handleDigitKeyPress}
                {...register('year')}
                placeholder='१ वर्ष'
              />
              <FormErrorMessage>{errors?.year?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '4',
            mt: '5',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            size='sm'
            variant='outline'
            type='button'
            onClick={() => {
              setStep(step - 1)
            }}
            colorScheme='telegram'
          >
            पछाडि फर्कनुहोस
          </Button>
          <Button size='sm' variant='primary' type='submit' isLoading={isSubmitting} loadingText='Submitting'>
            {farmerId && selectedValue ? 'अपडेट गरि अर्को पृष्ठमा जानुहोस' : 'अर्को पृष्ठमा जानुहोस'}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default FarmerFamilyInformationForm
