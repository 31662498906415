type Label = Record<string, Record<string, string>>

const labels: Label = {
  farmer: {
    en: 'Farmer',
    ne: 'कृषक',
  },
  farmerRegistration: {
    en: 'Farmer Registration',
    ne: 'कृषक दर्ता',
  },
  personalInformation: {
    en: 'Personal Information',
    ne: 'व्यक्तिगत जानकारी',
  },
  familyInformation: {
    en: 'Family Information',
    ne: 'परिवारको जानकारी',
  },
  farmInformation: {
    en: 'Farm Information',
    ne: 'खेतको जानकारी',
  },
  cropsInformation: {
    en: 'Crops Information',
    ne: 'अन्नबालिको जानकारी',
  },
  animalHusbandry: {
    en: 'Animal Husbandry',
    ne: 'पशुपालन',
  },
  agricultureDetails: {
    en: 'Agriculture Details',
    ne: 'कृषि विवरण',
  },
  saveAndGoToNextPage: {
    en: 'Save and go to next page',
    ne: 'सुरक्षित गरि अर्को पृष्ठमा जानुहोस्',
  },
  updateAndGoToNextPage: {
    en: 'Update and go to next page',
    ne: 'अपडेट गरि अर्को पृष्ठमा जानुहोस्',
  },
}
export default labels
