import { Box, Flex, Image } from '@chakra-ui/react'
import endPoint from '@config/endpoint/media'
import useAPI from '@hooks/useApi'
import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const { forwardRef, useImperativeHandle } = React

interface ImageUploadModalProps {
  mediaGroup: any
  mediaRelated: string
}

// eslint-disable-next-line react/display-name
const ImageUploadModal = forwardRef((props: ImageUploadModalProps, ref) => {
  const { mediaGroup, mediaRelated } = props
  const { postNormal, patch } = useAPI<any>()
  const [mediaGroupId, setMediaGroupId] = useState<any>(undefined)
  const [imgListArry, setImgListArry] = useState<any>([])
  const [oldImgArry, setOldImgArry] = useState<any>([])
  const [deletedImgIdList, setDeletedImgIdList] = useState<any>([])

  useEffect(() => {
    if (mediaGroup?.id) {
      setMediaGroupId(mediaGroup?.id)
      setOldImgArry(mediaGroup?.medias)
    }
  }, [mediaGroup])

  const handleChange = (e: any) => {
    const temp = Array.from(e.target.files)
    setImgListArry(temp)
  }
  const handleDeleteFile = (index: number) => {
    let temp = []
    temp = imgListArry.filter((item: any, i: number) => i !== index)
    setImgListArry(temp)
  }
  const handleDeleteOldFile = (item: any) => {
    const deleteIdTemp = deletedImgIdList
    deleteIdTemp.push(item?.id)
    setDeletedImgIdList(deleteIdTemp)
    let stateTemp = []
    stateTemp = oldImgArry.filter((el: any) => el.id !== item.id)
    setOldImgArry(stateTemp)
  }

  useImperativeHandle(ref, () => ({
    async getMediaGroupId() {
      if (mediaGroup?.id) {
        if (imgListArry.length === 0 && deletedImgIdList.length === 0) {
          return mediaGroup?.id
        }
        if (imgListArry.length > 0 || deletedImgIdList.length > 0) {
          const formData = new FormData()
          formData.append('relatedTo', mediaRelated)
          formData.append('id', mediaGroupId)
          formData.append('deleteMediaId', deletedImgIdList)

          imgListArry.forEach((file: any) => {
            formData.append('file', file)
          })
          const mediaResponse = await patch(endPoint.media, formData)
          if (mediaResponse?.data) {
            return mediaGroup?.id
          } else {
            return null
          }
        }
      } else {
        if (imgListArry.length > 0) {
          const formData = new FormData()
          formData.append('relatedTo', mediaRelated)

          imgListArry.forEach((file: any) => {
            formData.append('file', file)
          })
          const mediaResponse = await postNormal(endPoint.media, formData)
          if (mediaResponse?.data) {
            return mediaResponse?.data?.id
          }
        }
        if (imgListArry.length === 0) {
          return null
        }
      }
    },
  }))

  return (
    <Box>
      <Flex alignItems={'center'} p={2} mr={4} justifyContent={'start'} flexWrap='wrap'>
        {oldImgArry &&
          oldImgArry?.length > 0 &&
          oldImgArry.map((item: any, index: number) => (
            <Box position={'relative'} mx='2' key={index}>
              <Image src={item.name} width={100} alt={item?.name} />
              <Flex
                justifyContent='center'
                alignItems={'center'}
                position={'absolute'}
                top='0'
                right={'0'}
                backgroundColor='gray.200'
                padding={'1'}
                opacity={'0.8'}
                borderRadius={'50%'}
                cursor='pointer'
                boxSize={8}
                color='red.500'
                onClick={() => handleDeleteOldFile(item)}
              >
                <AiOutlineCloseCircle size={'25'} />
              </Flex>
            </Box>
          ))}
        {imgListArry?.map((item: any, index: number) => (
          <Box position={'relative'} mx='2' key={index}>
            <Image src={URL.createObjectURL(item)} width={100} />
            <Flex
              justifyContent='center'
              alignItems={'center'}
              position={'absolute'}
              top='0'
              right={'0'}
              backgroundColor='gray.200'
              padding={'1'}
              opacity={'0.8'}
              borderRadius={'50%'}
              cursor='pointer'
              boxSize={8}
              color='red.500'
              onClick={() => handleDeleteFile(index)}
            >
              <AiOutlineCloseCircle size={'25'} />
            </Flex>
          </Box>
        ))}
      </Flex>

      <input type='file' multiple onChange={(e) => handleChange(e)} />
    </Box>
  )
})

export default ImageUploadModal
