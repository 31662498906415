import { Box, Container, Heading, Image, Stack, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { BiCategory } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import useAPI from "@hooks/useUserHook";
import { useParams } from "react-router-dom";

const NewsDetails = () => {
  const location = useLocation();
  const { id } = useParams();

  const { dataById, loading, getById } = useAPI<any>();

  useEffect(() => {
    getById("news/", id as string);
  }, []);

  console.log(dataById, "data");
  if (loading && !dataById) {
    return (
      <>
        <h4>Not found</h4>
      </>
    );
  }

  return (
    <Box py={4} mb="10">
      <Stack spacing={4} as={Container}>
        <Heading
          fontSize={{ base: "2xl", sm: "3xl" }}
          fontWeight={"bold"}
          textTransform="capitalize"
        >
          News
        </Heading>

        <Box pt="5">
          <Image
            width="100%"
            height={{
              lg: "400px",
              md: "300px",
              sm: "200px",
              base: "100%",
            }}
            src={dataById?.media?.medias?.[0]?.name}
          />

          <Heading pt="5" fontSize="24px">
            {dataById?.title?.ne}
          </Heading>
          <Text
            fontSize={"12px"}
            sx={{
              display: "flex",
              gap: "5",
              color: "gray.600",
              pb: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "2",
                pt: "3",
              }}
            >
              <AiOutlineClockCircle />
              {moment(dataById?.createdAt)?.format("LLL")}
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "2",
                pt: "3",
              }}
            >
              <BiCategory /> {dataById?.category?.ne}
            </Box>
          </Text>

          <Box
            pt="2"
            dangerouslySetInnerHTML={{ __html: dataById?.description?.ne }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default NewsDetails;
