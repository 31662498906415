import { Box, Container, Heading } from '@chakra-ui/react'
import label from '@data/localization/videoGallery'
import useLang from '@hooks/useLang'
import VideoList from '@ui/user/organisms/VideoGallery'

const VideoGallery = () => {
  const { lang } = useLang()

  return (
    <Box py='4'>
      <Container>
        <Heading fontSize={{ base: '2xl', sm: '3xl' }} fontWeight={'bold'} textTransform='capitalize'>
          {label?.videoGallery?.[lang]}
        </Heading>

        <Box pt='5'>
          <VideoList />
        </Box>
      </Container>
    </Box>
  )
}

export default VideoGallery
