import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import endPoint from '@config/endpoint/videoGallery'
import { videoSchema } from '@config/schema/videoGallery.schema'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import utilsLabel from '@data/localization/utils'
import videoGalleryLabel from '@data/localization/videoGallery'
import { getMessage } from '@functions/generateMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import PlaylistModel from '@models/videoGallery/playlist.model'
import VideoGalleryModel from '@models/videoGallery/videos.model'
import { IPagination } from '@type/pagination.types'
import NepaliInput from '@ui/common/atoms/NepaliInput'
import RequireSign from '@ui/common/atoms/RequireSign'
import Modal from '@ui/common/molecules/Modal'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery, useQueryClient } from 'react-query'

interface IProps {
  open: boolean
  setOpen: (data: boolean) => void
  values: IResponse<VideoGalleryModel[]> | null
  setValues: (data: IResponse<VideoGalleryModel[]>) => void
  selectedValue: VideoGalleryModel | null
  setSelectedValue: (data: VideoGalleryModel | null) => void
}
const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props
  console.log('🚀 ~ file: Add.tsx:45 ~ Add ~ selectedValue:', selectedValue?.playlist?.id)
  const { post, put } = useAPI<VideoGalleryModel>()
  const { data: playList, get } = useAPI<PlaylistModel[] | null>()

  const toast = useToast()
  const queryClient = useQueryClient()
  const [videoUrl, setVideoUrl] = useState<string | null>(null)
  const [embedUrl, setEmbedUrl] = useState<string | null>(null)
  const { lang } = useLang()
  const [pagination] = useState<IPagination>({
    currentPage: 1,
    perPage: 20,
    searchTerm: '',
    total: undefined,
    totalPages: 1,
    refreshTable: false,
  })
  const handleFetch = async () => {
    await get(endPoint.videoPlaylist, {
      perPage: pagination?.perPage,
      page: pagination?.currentPage,
      search: pagination?.searchTerm,
      queryName: 'videos',
      queryValue: 'false',
    })
  }

  useQuery('playlist', handleFetch, {
    refetchIntervalInBackground: false,
    enabled: false,
  })

  const handleFetchData = () => {
    void queryClient.prefetchQuery('myData', handleFetch)
  }

  useEffect(() => {
    if (open) void handleFetchData()
  }, [open])

  useEffect(() => {
    if (videoUrl) void makeEmbedUrl(videoUrl)
  }, [videoUrl])

  const makeEmbedUrl = async (videoId: string) => {
    try {
      const url = new URL(videoId)
      let videoID = url.pathname.split('/')[1]
      const videoId2 = url.pathname.split('/')[2]
      const vData = url.searchParams.get('v') ?? ''
      if (videoId2 === 'watch' && url.search) videoID = vData
      setEmbedUrl(`https://www.youtube.com/embed/${videoID}`)
    } catch (r) {
      setEmbedUrl(`https://www.youtube.com/embed/${videoId}`)
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(videoSchema),
    defaultValues: {
      title: {
        en: '',
        ne: '',
      },
      status: '',
      videoUrl: '',
      description: {
        en: '',
        ne: '',
      },
      playlist: '',
    },
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    if (selectedValue) {
      selectedValue?.videoUrl && setVideoUrl(selectedValue?.videoUrl)
      reset({
        title: {
          en: selectedValue?.title?.en ?? '',
          ne: selectedValue?.title?.ne ?? '',
        },
        videoUrl: selectedValue?.videoUrl ? `https://www.youtube.com/embed/${selectedValue?.videoUrl}` : '',
        description: {
          en: selectedValue?.description?.en ?? '',
          ne: selectedValue?.description?.ne ?? '',
        },
      })
    }
  }, [selectedValue])

  const onSubmit = async (data: any) => {
    console.log(data, 'submit')
    try {
      let res

      const url = data?.videoUrl
      const parts = url.split('/')
      const videoID = parts.pop()

      if (selectedValue) {
        res = await put(endPoint?.videos, {
          id: selectedValue?.id,
          title: data.title,
          status: data.status,
          videoUrl: videoID,
          description: data.description,
          playlist: +data?.playlist,
        })
      } else {
        res = await post(endPoint?.videos, {
          title: data.title,
          status: data.status,
          videoUrl: videoID,
          description: data.description,
          playlist: +data.playlist,
        })
      }
      if (res?.data) {
        await queryClient.invalidateQueries('videos')

        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.videoGallery[lang],
            selectedValue ? notificationLabel?.updated[lang] : notificationLabel?.added[lang],
            lang
          ),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else if (res?.error) {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res?.error?.response?.data?.message ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      reset({
        title: {
          en: '',
        },
        status: '',
        videoUrl: '',
        description: {
          en: '',
        },
      })
      setSelectedValue(null)
    }
  }

  useEffect(() => {
    if (selectedValue) {
      setOpen(true)
    }
  }, [selectedValue])

  return (
    <div>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        onCloseComplete={() => {
          setSelectedValue(null)
          reset({
            title: {
              en: '',
            },
            status: '',
            videoUrl: '',
            description: {
              en: '',
            },
          })
          setEmbedUrl(null)
        }}
        title={getMessage(videoGalleryLabel?.videoGallery[lang], 'add', lang)}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
              >
                <FormControl isInvalid={errors.title?.en != null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size='sm'
                    variant={errors?.title?.en != null ? 'error' : ''}
                    type='text'
                    {...register('title.en')}
                    placeholder={'Enter title name'}
                  />
                  <FormErrorMessage>{errors?.title?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={'शिर्षक लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('title.ne', e)
                    }}
                    value={watch('title.ne')}
                  />
                  <FormErrorMessage>{errors?.title?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Box pt='5'>
                <FormControl isInvalid={errors.videoUrl != null}>
                  <FormLabel>
                    {videoGalleryLabel?.videoURL[lang]} <RequireSign />
                  </FormLabel>
                  {videoUrl && embedUrl && (
                    <Box pb='3'>
                      <iframe width='100%' height='315' src={embedUrl} frameBorder='0' allowFullScreen></iframe>
                    </Box>
                  )}
                  <Input
                    size='sm'
                    variant={errors?.videoUrl != null ? 'error' : ''}
                    type='text'
                    {...register('videoUrl')}
                    onChange={(e: any) => {
                      setVideoUrl(e.target.value)
                    }}
                    placeholder={videoGalleryLabel?.enterVideoUrl[lang]}
                  />
                  <FormErrorMessage>{errors?.videoUrl?.message}</FormErrorMessage>
                  <FormHelperText>{videoGalleryLabel?.onlyAllowYoutubeVideo[lang]}</FormHelperText>
                </FormControl>
              </Box>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
                pt='5'
              >
                <FormControl isInvalid={errors.description?.en != null}>
                  <FormLabel>
                    Description - english &nbsp;
                    <RequireSign />
                  </FormLabel>
                  <Textarea
                    size='sm'
                    {...register('description.en')}
                    isInvalid={errors.description?.en != null}
                    placeholder={'Enter description here'}
                  />
                  <FormErrorMessage>{errors?.description?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.description?.ne != null}>
                  <FormLabel>
                    विवरण - नेपाली &nbsp;
                    <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.description?.ne != null}
                    placeholder={'विवरण लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('description.ne', e)
                    }}
                    multiline={true}
                    value={watch('description.ne')}
                  />

                  <FormErrorMessage>{errors?.description?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
                pt='5'
              >
                <FormControl isInvalid={errors.playlist != null}>
                  <FormLabel>
                    {videoGalleryLabel?.playlist[lang]} <RequireSign />
                    <FormErrorMessage>{errors?.playlist?.message}</FormErrorMessage>
                  </FormLabel>
                  <RadioGroup defaultValue={selectedValue?.playlist?.id?.toString()}>
                    <Flex wrap={'wrap'} gap='4'>
                      {playList?.data &&
                        playList?.data?.length > 0 &&
                        playList?.data?.map((item: PlaylistModel, index: number) => {
                          return (
                            <Radio
                              value={item?.id?.toString()}
                              {...register('playlist')}
                              colorScheme='facebook'
                              key={index}
                            >
                              {/* @ts-ignore */}
                              {item?.title?.[lang]}
                            </Radio>
                          )
                        })}
                    </Flex>
                  </RadioGroup>
                </FormControl>
                <FormControl isInvalid={errors.status != null}>
                  <FormLabel>
                    {videoGalleryLabel?.status[lang]} <RequireSign />
                    <FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
                  </FormLabel>
                  <RadioGroup defaultValue={selectedValue?.status ? '1' : '0'}>
                    <Flex wrap={'wrap'} gap='4'>
                      <Radio value={'1'} {...register('status')} colorScheme='facebook'>
                        {videoGalleryLabel?.published[lang]}
                      </Radio>
                      <Radio value={'0'} {...register('status')} colorScheme='facebook'>
                        {videoGalleryLabel?.unpublished[lang]}
                      </Radio>
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </Flex>

              <Flex my='5' gap='5' justifyContent={'flex-end'}>
                <Button size='sm' variant={'outline'} colorScheme='red' onClick={() => setOpen(false)}>
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type='submit'
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size='sm'
                  variant={'primary'}
                >
                  {selectedValue ? utilsLabel?.update[lang] : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </div>
  )
}

export default Add
