import { Box, Flex, IconButton, useToast } from "@chakra-ui/react";
import endPoint from "@config/endpoint/agriculture";
import agricultureLabel, { getMessage } from "@data/localization/agriculture";
import notificationLabel, {
  generateMessage,
} from "@data/localization/notification";
import tableLabel from "@data/localization/table";
import tableSnGenerate from "@functions/tableSnGenerate";
import useAPI from "@hooks/useApi";
import useLang from "@hooks/useLang";
import { ISimpleTable } from "@interface/global.interface";
import { IResponse } from "@interface/response.interface";
import AgricultureGroup from "@models/agricultureGroup.model";
import { createColumnHelper } from "@tanstack/react-table";
import { IPagination } from "@type/pagination.types";
import ModalBox from "@ui/common/molecules/Modal";
import Table from "@ui/common/organisms/Table";
import { useEffect, useState } from "react";
import { BiEdit, BiShowAlt } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { useQueryClient } from "react-query";
import View from "./view";
interface IProps {
  values: IResponse<AgricultureGroup[]> | null;
  setValues: (data: IResponse<AgricultureGroup[]>) => void;
  loading: boolean;
  setLoading: (data: boolean) => void;
  openDetails: boolean;
  setOpenDetails: (data: boolean) => void;
  pagination: IPagination;
  setPagination: (data: IPagination) => void;
  selectedValue: AgricultureGroup | null;
  setSelectedValue: (data: AgricultureGroup | null) => void;
  agricultureDetails: AgricultureGroup | null;
  setAgricultureDetails: (data: AgricultureGroup | null) => void;
}

const Show = (props: IProps) => {
  const { values, loading, pagination, setPagination, setSelectedValue } =
    props;
  const { remove, getById } = useAPI();
  const [open, setOpen] = useState<boolean>(false);
  const [openShow, setOpenShow] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [id, setId] = useState<string | null>(null);
  const [selectedData, setSelectedData] = useState<ISimpleTable[] | null>(null);
  const toast = useToast();
  const [submit, setSubmitting] = useState<boolean>(false);
  const { lang } = useLang();

  const queryClient = useQueryClient();

  // function to delete
  const handleDelete = async () => {
    try {
      setSubmitting(true);
      const res = await remove(`${endPoint?.AgricultureGroup}/${id}`);
      if (res === true) {
        await queryClient.invalidateQueries("myData");
        toast.closeAll();
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.farmerGroup[lang],
            notificationLabel?.delete[lang],
            lang
          ),
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast.closeAll();
        toast({
          title: notificationLabel?.error[lang],
          description: res ?? notificationLabel?.somethingWrongHappen[lang],
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      toast.closeAll();
      toast({
        title: notificationLabel?.error[lang],
        description:
          err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setOpen(false);
      setConfirm(false);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (confirm) {
      void handleDelete();
      setConfirm(false);
    }
  }, [confirm]);

  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor("SN", {
      header: () => <span>{tableLabel?.sn[lang]}</span>,
      size: 50,
      cell: (info: any) => {
        return (
          <span>
            {" "}
            {tableSnGenerate(
              info.row.index,
              pagination?.currentPage,
              pagination?.perPage
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("name", {
      id: "name",
      header: () => <span> {tableLabel?.farmerGroup_english[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.groupName?.en}</span>;
      },
    }),
    columnHelper.accessor("groupName", {
      id: "groupName",
      header: () => <span> {tableLabel?.farmerGroup_nepali[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.groupName?.ne}</span>;
      },
    }),
    columnHelper.accessor("village", {
      id: "village",
      header: () => <span>{tableLabel?.villageName[lang]} </span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.villageName}</span>;
      },
    }),
    columnHelper.accessor("ward", {
      id: "ward",
      header: () => <span>{tableLabel?.groupWard[lang]} </span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.ward}</span>;
      },
    }),

    columnHelper.accessor("createdBy", {
      id: "addedBy",
      header: () => <span>{tableLabel?.addedBy[lang]} </span>,
      size: 400,
      cell: (info: any) => {
        return (
          <span>
            {typeof info?.row?.original?.addedBy === "object"
              ? info?.row?.original?.addedBy?.firstName?.[lang] +
                " " +
                info?.row?.original?.addedBy?.lastName?.[lang]
              : info?.row?.original?.addedBy}{" "}
          </span>
        );
      },
    }),

    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {tableLabel?.action[lang]}
        </span>
      ),
      size: 100,
      cell: (info: any) => {
        return (
          <Flex gap="3" justifyContent="center">
            <IconButton
              colorScheme="green"
              variant="outline"
              aria-label="show"
              icon={<BiShowAlt />}
              onClick={() => {
                setSelectedData(info?.row?.original);
                setOpenShow(true);
              }}
            />
            <IconButton
              colorScheme="telegram"
              variant="outline"
              aria-label="edit"
              icon={<BiEdit />}
              onClick={async () => {
                const res = (await getById(
                  endPoint.AgricultureGroup,
                  info?.row?.original?.id
                )) as AgricultureGroup;
                setSelectedValue(res);
              }}
            />
            <IconButton
              colorScheme="red"
              variant="outline"
              aria-label="delete"
              icon={<BsTrash />}
              onClick={() => {
                setOpen(true);
                setId(info?.row?.original?.id);
              }}
            />
          </Flex>
        );
      },
    }),
  ];
  return (
    <Box>
      <Table
        title={getMessage(agricultureLabel?.farmerGroup[lang], "list", lang)}
        columns={columns}
        rowData={values?.data ?? []}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
      />
      {/* Delete Model */}
      <ModalBox
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(agricultureLabel?.farmerGroup[lang], "delete", lang)}
        type="delete"
        confirm={confirm}
        setConfirm={setConfirm}
        size="sm"
        submitting={submit}
      />
      {/* Show Modal */}
      <ModalBox
        size="lg"
        isOpen={openShow}
        setOpen={setOpenShow}
        title={agricultureLabel?.farmerGroup[lang]}
        data={<View data={selectedData} />}
      />
    </Box>
  );
};

export default Show;
