import { Box } from '@chakra-ui/react'
import Ping from '@ui/admin/molecules/Ping'
import Navbar from '@ui/admin/organisms/Navbar'
import Sidebar from '@ui/admin/organisms/Sidebar'
import { Outlet } from 'react-router-dom'

const AdminTemplate = () => {
  return (
    <Box
      display={{
        md: 'flex',
        base: 'block',
      }}
    >
      <Sidebar />
      <Box width={'100%'} height={{ base: '100%', md: '100vh' }} overflowY='auto'>
        <Box p='2'>
          <Navbar />
          <Ping />
          <Box
            style={{
              overflowY: 'auto',
              height: 'calc(100vh - 58px)',
            }}
            pt='2'
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AdminTemplate
