import agricultureType from '@models/agricultureType.model'
import Language from '@models/language.model'
import { plainToClass } from 'class-transformer'
import AgricultureDetails from './agricultureDetails.model'

class AgricultureName {
  id: string
  title: Language
  agricultureType: agricultureType
  media: any
  details?: AgricultureDetails

  constructor(id: string, title: Language, agricultureType: agricultureType, media: any, details: AgricultureDetails) {
    this.id = id
    this.title = title
    this.agricultureType = agricultureType
    this.media = media
    this.details = details
  }

  static plainToInstance(plainData: object): AgricultureName {
    return plainToClass(AgricultureName, plainData)
  }

  static plainToInstances(plainData: object[]): AgricultureName[] {
    return plainToClass(AgricultureName, plainData)
  }
}
export default AgricultureName
