import axios from 'axios'
import cryptoServices from '@functions/EncryptDecrypt'

export const AxiosBaseInstance = axios.create({
  baseURL: import.meta.env.VITE_AGRICULTURE_URL,
  timeout: 5000,
  headers: { Authorization: `Bearer ` },
  // headers: { Authorization: `Bearer ${token}` },
})

const PrivateAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_CENTRAL_AUTH_API,
  timeout: 5000,
  headers: { Authorization: `Bearer ` },
  // headers: { Authorization: `Bearer ${token}` },
})

PrivateAxiosInstance.interceptors.request.use((config: any) => {
  const adminToken = localStorage.getItem('service_delivery_agriculture_token' ?? '')
  const decryptAdminToken = cryptoServices.decrypt(adminToken ?? '')
  config.headers['authorization'] = `Bearer ${decryptAdminToken}`
  return config
})

PrivateAxiosInstance.interceptors.response.use((response: any) => {
  let data: any
  if ('data' in response?.data) {
    data = response.data.data
  }
  if ('auth' in response.data?.data) {
    data = JSON.parse(response.data.data.auth)
  }

  response.data = data
  return response
})

export default PrivateAxiosInstance
