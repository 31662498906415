import EncryptDecrypt from '@functions/EncryptDecrypt'
import { createContext, useEffect, useState } from 'react'

interface IProps {
  children: React.ReactNode
}
export const AuthContext = createContext<any>(null)

const AuthProvider = ({ children }: IProps) => {
  // store admin data
  const { decrypt } = EncryptDecrypt
  const [user, setUser] = useState(() => {
    let data = localStorage.getItem('service_delivery_agriculture_user')

    if (!data) {
      return null
    }
    return JSON.parse(decrypt(data))
  })

  // remember me
  const [rememberMe, setRememberMe] = useState(JSON.parse(localStorage.getItem('rememberMe') ?? '{}'))
  // const [rememberMe, setRememberMe] = useState(false)

  useEffect(() => {
    localStorage.setItem('rememberMe', JSON.stringify(rememberMe))
  }, [rememberMe])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        rememberMe,
        setRememberMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
