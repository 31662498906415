import { Box } from '@chakra-ui/react'

interface IStriper {
  step: number
  totalStep: number
  stepsName: string[]
}

const Stepper = (props: IStriper) => {
  const { step, totalStep, stepsName } = props
  return (
    <Box
      sx={{
        width: '100%',
        py: '5',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative', // make the container a reference for the child elements
          width: '100%',
          height: '2px',
        }}
      >
        {Array.from({ length: totalStep }, (_, i) => {
          return (
            <Box
              key={i}
              sx={{
                position: 'absolute', // position the bars and numbers inside the container
                top: 0,
                left: `${(100 / totalStep) * i}%`,
                width: `${100 / totalStep}%`,
                height: '100%',
                bg: i < step ? '#284d69' : 'gray.200',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                py: '0.1rem', // add padding to the container to make space for the numbers
                '@media screen and (max-width: 768px)': {
                  // styles for screens smaller than 768px
                  left: `${(100 / totalStep) * i + i * 2}%`,
                  width: `${100 / totalStep - 4}%`,
                  py: '0.3rem',
                },
              }}
            >
              <Box
                sx={{
                  fontSize: '13.5px',
                  fontWeight: 'bold',
                  color: i < step ? 'white' : '#284d69',
                  position: 'absolute',
                  top: '-15px',
                  background: i < step ? '#284d69' : 'gray.200 ',
                  paddingBottom: '5px',
                  paddingX: '15px',
                  paddingTop: '6px',
                  borderRadius: '2px',
                  '@media screen and (max-width: 768px)': {
                    // styles for screens smaller than 768px
                    fontSize: '12px',
                    top: '-20px',
                    paddingX: '10px',
                    paddingTop: '3px',
                  },
                }}
              >
                {i + 1}. &nbsp;{stepsName?.[i]}
              </Box>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default Stepper
