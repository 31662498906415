import { Box, Text } from '@chakra-ui/react'
import tableLabel from '@data/localization/table'
import useLang from '@hooks/useLang'
interface IProps {
  data: any
}

const view = (props: IProps) => {
  const { data } = props
  const { lang } = useLang()
  return (
    <Box>
      {data && (
        <Box>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '35px',
                  }}
                >
                  {tableLabel?.sn[lang]}
                </th>
                <th
                  style={{
                    width: '35%',
                  }}
                >
                  {tableLabel?.title[lang]}
                </th>
                <th>{tableLabel?.description[lang]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td> {tableLabel?.farmerGroup_english[lang]}</td>
                <td> {data?.groupName?.en}</td>
              </tr>
              <tr>
                <td>2.</td>
                <td> {tableLabel?.farmerGroup_nepali[lang]}</td>
                <td> {data?.groupName?.ne}</td>
              </tr>
              <tr>
                <td>3.</td>
                <td> {tableLabel?.groupWard[lang]}</td>
                <td> {data?.ward}</td>
              </tr>
              <tr>
                <td>4.</td>
                <td> {tableLabel?.villageName[lang]}</td>
                <td> {data?.villageName}</td>
              </tr>
              <tr>
                <td>5.</td>
                <td> {tableLabel?.toleName[lang]}</td>
                <td> {data?.tole}</td>
              </tr>
              <tr>
                <td>6.</td>
                <td> {tableLabel?.member[lang]}</td>
                <td>
                  <Box>
                    {tableLabel?.male[lang]}: {data?.population?.male ?? 'NA'}
                  </Box>
                  <Box>
                    {tableLabel?.female[lang]}: {data?.population?.female ?? 'NA'}
                  </Box>
                  <Box>
                    {tableLabel?.others[lang]}: {data?.population?.others ?? 'NA'}
                  </Box>
                  <Box>
                    {tableLabel?.total[lang]}: {data?.population?.total ?? 'NA'}
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      )}
      {data?.members?.length > 0 && (
        <Box>
          <Text fontSize='lg' fontWeight='bold' mt='5'>
            {tableLabel?.members[lang]}
          </Text>
          <table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '35px',
                  }}
                >
                  {tableLabel?.sn[lang]}
                </th>
                <th
                  style={{
                    width: '35%',
                  }}
                >
                  {tableLabel?.firstName?.[lang]}
                </th>
                <th>{tableLabel?.middleName?.[lang]}</th>
                <th>{tableLabel?.lastName?.[lang]}</th>
                <th>{tableLabel?.position?.[lang]}</th>
                <th>{tableLabel?.educationQualification?.[lang]}</th>
                <th>{tableLabel?.age[lang]}</th>
                <th>{tableLabel?.farmArea[lang]}</th>
              </tr>
            </thead>
            <tbody>
              {data?.members?.map((member: any, index: number) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{member?.firstName}</td>
                  <td>{member?.middleName}</td>
                  <td>{member?.lastName}</td>
                  <td>{member?.position}</td>
                  <td>{member?.educationQualification}</td>
                  <td>{member?.age}</td>
                  <td>{member?.farmArea}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  )
}

export default view
