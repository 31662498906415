import * as yup from 'yup'

export const agricultureDetailsSchema = yup.object({
  values: yup
    .array()
    .of(
      yup.object().shape({
        columnName: yup.string().required('Topic is required'),
        values: yup.string().required('Description is required'),
      })
    )
    .min(1, 'Please enter at least one topic and its details'),
  agricultureName: yup.string().required('Agriculture name is required'),
})
