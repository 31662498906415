const RequireSign = () => {
  return (
    <span
      style={{
        color: 'red',
      }}
    >
      *
    </span>
  )
}

export default RequireSign
