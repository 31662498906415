import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Select,
  useToast,
} from '@chakra-ui/react'
import endpoint from '@config/endpoint/farmer'
import { personalInformationSchema } from '@config/schema/farmer/Registration.schema'
import { handleDigitKeyPress, handleKeyPressForWard } from '@functions/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import FarmerPersonalInformation from '@models/farmer/farmerPersonalInformation.model'
import NepaliInput from '@ui/common/atoms/NepaliInput'
import RequireSign from '@ui/common/atoms/RequireSign'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

interface IProps {
  step: number
  setStep: React.Dispatch<React.SetStateAction<number>>
  farmerId: string | undefined
  setFarmerId: React.Dispatch<React.SetStateAction<string | undefined>>
}

const FarmerPersonalInformationForm = ({ step, setStep, farmerId, setFarmerId }: IProps) => {
  const { post, patch, put, getById } = useAPI<FarmerPersonalInformation>()
  const [selectedValue, setSelectedValue] = useState<FarmerPersonalInformation | null>(null)
  const toast = useToast()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors, isSubmitting },
    getValues,
    trigger,
  } = useForm({
    resolver: yupResolver(personalInformationSchema),
    defaultValues: {
      firstName: {
        en: '',
        ne: '',
      },
      middleName: {
        en: '',
        ne: '',
      },
      lastName: {
        en: '',
        ne: '',
      },
      gender: '',
      dateOfBirth: '',
      academicQualification: '',
      literateLevel: '',
      ward: '',
      village: '',
      farmVillage: '',
      farmWard: '',
      citizenshipNumber: '',
      citizenshipIssuedDistrict: '',
      agricultureArea: '',
      agricultureSubArea: '',
      isOwnLand: '',
      landArea: {
        biggha: '',
        katha: '',
        dhur: '',
      },
      yearlyTurnOver: {
        agriculture: '',
        nonAgriculture: '',
        total: '',
      },
      averageMonthEngageOnAgriculture: '',
    },
  })

  const onSubmit = async (data: any) => {
    try {
      let res
      if (farmerId) {
        res = await put(endpoint?.farmerPersonalInformation, {
          id: selectedValue?.id,
          firstName: {
            en: data?.firstName?.en,
            ne: data?.firstName?.ne,
          },
          middleName: data?.middleName?.en
            ? {
                en: data?.middleName?.en,
                ne: data?.middleName?.ne,
              }
            : null,
          lastName: {
            en: data?.lastName?.en,
            ne: data?.lastName?.ne,
          },
          gender: data?.gender,
          dateOfBirth: data?.dateOfBirth,
          academicQualification: data?.academicQualification,
          literateLevel: data?.literateLevel,
          ward: data?.ward,
          village: data?.village,
          farmWard: data?.farmWard,
          farmVillage: data?.farmVillage,
          yearlyTurnOver: {
            agriculture: +data?.yearlyTurnOver?.agriculture,
            nonAgriculture: +data?.yearlyTurnOver?.nonAgriculture,
            total: +data?.yearlyTurnOver?.total,
          },
          citizenshipNumber: data?.citizenshipNumber,
          citizenshipIssuedDistrict: data?.citizenshipIssuedDistrict,
          agricultureArea: data?.agricultureArea,
          agricultureSubArea: data?.agricultureSubArea,
          isOwnLand: data?.isOwnLand === 'YES',
          landArea:
            data?.isOwnLand === 'NO'
              ? undefined
              : {
                  biggha: data?.landArea?.biggha,
                  katha: data?.landArea?.katha,
                  dhur: data?.landArea?.dhur,
                },
          averageMonthEngageOnAgriculture: data?.averageMonthEngageOnAgriculture,
        })
      } else {
        res = await post(endpoint?.farmerPersonalInformation, {
          firstName: {
            en: data?.firstName?.en,
            ne: data?.firstName?.ne,
          },
          middleName: data?.middleName?.en
            ? {
                en: data?.middleName?.en,
                ne: data?.middleName?.ne,
              }
            : null,
          lastName: {
            en: data?.lastName?.en,
            ne: data?.lastName?.ne,
          },
          gender: data?.gender,
          dateOfBirth: data?.dateOfBirth,
          academicQualification: data?.academicQualification,
          literateLevel: data?.literateLevel,
          ward: data?.ward,
          village: data?.village,
          farmWard: data?.farmWard,
          farmVillage: data?.farmVillage,
          yearlyTurnOver: {
            agriculture: +data?.yearlyTurnOver?.agriculture,
            nonAgriculture: +data?.yearlyTurnOver?.nonAgriculture,
            total: +data?.yearlyTurnOver?.total,
          },
          citizenshipNumber: data?.citizenshipNumber,
          citizenshipIssuedDistrict: data?.citizenshipIssuedDistrict,
          agricultureArea: data?.agricultureArea,
          agricultureSubArea: data?.agricultureSubArea,
          isOwnLand: data?.isOwnLand === 'YES',
          landArea:
            data?.isOwnLand === 'NO'
              ? undefined
              : {
                  biggha: data?.landArea?.biggha,
                  katha: data?.landArea?.katha,
                  dhur: data?.landArea?.dhur,
                },
          averageMonthEngageOnAgriculture: data?.averageMonthEngageOnAgriculture,
        })
      }
      if (res?.data) {
        if (!farmerId) setFarmerId(res?.data?.farmerId)
        toast.closeAll()
        toast({
          title: 'सफल भयो',
          description: `व्यक्तिगत विवरण सफलतापूर्वक ${farmerId ? 'अपडेट' : 'सुरक्षित'} गरियो`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        setStep(step + 1)
        reset({
          firstName: {
            en: '',
            ne: '',
          },
          middleName: {
            en: '',
            ne: '',
          },
          lastName: {
            en: '',
            ne: '',
          },
          gender: '',
          dateOfBirth: '',
          academicQualification: '',
          literateLevel: '',
          ward: '',
          village: '',
          farmVillage: '',
          farmWard: '',
          citizenshipNumber: '',
          citizenshipIssuedDistrict: '',
          agricultureArea: '',
          agricultureSubArea: '',
          isOwnLand: '',
          landArea: {
            biggha: '',
            katha: '',
            dhur: '',
          },
          yearlyTurnOver: {
            agriculture: '',
            nonAgriculture: '',
            total: '',
          },
          averageMonthEngageOnAgriculture: '',
        })
      } else {
        toast.closeAll()
        toast({
          title: 'गलत भयो',
          description: res?.error?.response?.data?.message ?? 'केहि गलत भयो',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      console.log(err)
      toast.closeAll()
      toast({
        title: 'गलत भयो',
        description: err?.message ?? 'केहि गलत भयो',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const agriculture = watch('yearlyTurnOver.agriculture')
  const nonAgriculture = watch('yearlyTurnOver.nonAgriculture')
  const totalYearlyTurnOver = +agriculture + +nonAgriculture

  useEffect(() => {
    setValue('yearlyTurnOver.total', totalYearlyTurnOver?.toString())
  }, [totalYearlyTurnOver, setValue])

  const getFarmerPersonalInformation = async () => {
    try {
      const res = await getById(endpoint?.farmerPersonalInformation + '/farmer', farmerId as string)
      console.log(res, 'aa')
      if (res) {
        setSelectedValue(res)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (farmerId) {
      void getFarmerPersonalInformation()
    }
  }, [])

  useEffect(() => {
    if (selectedValue) {
      reset({
        firstName: {
          en: selectedValue?.firstName?.en,
          ne: selectedValue?.firstName?.ne,
        },
        middleName: {
          en: selectedValue?.firstName?.en,
          ne: selectedValue?.middleName?.ne,
        },
        lastName: {
          en: selectedValue?.firstName?.en,
          ne: selectedValue?.lastName?.ne,
        },
        gender: selectedValue?.gender,
        dateOfBirth: selectedValue?.dateOfBirth,
        academicQualification: selectedValue?.academicQualification,
        literateLevel: selectedValue?.literateLevel,
        ward: selectedValue?.ward?.toString(),
        village: selectedValue?.village,
        farmWard: selectedValue?.farmWard?.toString(),
        farmVillage: selectedValue?.farmVillage,
        yearlyTurnOver: {
          agriculture: selectedValue?.yearlyTurnOver?.agriculture?.toString(),
          nonAgriculture: selectedValue?.yearlyTurnOver?.nonAgriculture?.toString(),
          total: selectedValue?.yearlyTurnOver?.total?.toString(),
        },
        citizenshipNumber: selectedValue?.citizenshipNumber,
        citizenshipIssuedDistrict: selectedValue?.citizenshipIssuedDistrict,
        agricultureArea: selectedValue?.agricultureArea,
        agricultureSubArea: selectedValue?.agricultureSubArea,
        isOwnLand: selectedValue?.isOwnLand ? 'YES' : 'NO',
        landArea: {
          biggha: selectedValue?.landArea?.biggha,
          katha: selectedValue?.landArea?.katha,
          dhur: selectedValue?.landArea?.dhur,
        },
        averageMonthEngageOnAgriculture: selectedValue?.averageMonthEngageOnAgriculture,
      })
    }
  }, [selectedValue])

  // fake filler function
  const handleFakeFiller = async () => {
    reset({
      firstName: {
        en: 'Bijaya',
        ne: 'बिजय',
      },
      middleName: {
        en: 'Kumar',
        ne: 'कुमार',
      },
      lastName: {
        en: 'Shah',
        ne: 'साह',
      },
      gender: 'MALE',
      dateOfBirth: '2056-01-01',
      academicQualification: 'LITERATE',
      literateLevel: 'GRADUATION',
      ward: '7',
      village: '2',
      farmWard: '5',
      farmVillage: 'Ithari',
      yearlyTurnOver: {
        agriculture: '100000',
        nonAgriculture: '50000',
        total: '150000',
      },
      citizenshipNumber: '012-1234-1213',
      citizenshipIssuedDistrict: 'Morang',
      agricultureArea: 'पशुपन्छी',
      agricultureSubArea: 'परेवा',
      isOwnLand: 'YES',
      landArea: {
        biggha: '193',
        katha: '81',
        dhur: '01',
      },
      averageMonthEngageOnAgriculture: '5',
    })
  }

  return (
    <Box>
      {/* remove this fake filler on production */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={handleFakeFiller} variant={'outline'} size='md' colorScheme='purple'>
          Fake filler
        </Button>
      </Box>
      {getValues('firstName.ne')}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading pb='3' size='md' mt='2'>
          व्यक्तिगत जानकारी
        </Heading>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              lg: 'repeat(6, 1fr)',
              md: 'repeat(3, 1fr)',
              sm: 'repeat(2, 1fr)',
              base: 'repeat(1, 1fr)',
            },
            gap: '4',
          }}
          p='4'
          border='1px'
          borderColor='gray.200'
          borderRadius={'2px'}
        >
          <FormControl isInvalid={errors.firstName?.en != null}>
            <FormLabel>
              First Name <RequireSign />
            </FormLabel>
            <Input
              size='sm'
              variant={errors?.firstName?.en != null ? 'error' : ''}
              type='text'
              {...register('firstName.en')}
              placeholder={'Enter first name'}
            />
            <FormErrorMessage>{errors?.firstName?.en?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.middleName?.en != null}>
            <FormLabel>Middle Name </FormLabel>
            <Input
              size='sm'
              variant={errors?.middleName?.en != null ? 'error' : ''}
              type='text'
              {...register('middleName.en')}
              placeholder={'Enter middleName name'}
            />
            <FormErrorMessage>{errors?.middleName?.en?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.lastName?.en != null}>
            <FormLabel>
              Last Name <RequireSign />
            </FormLabel>
            <Input
              size='sm'
              variant={errors?.lastName?.en != null ? 'error' : ''}
              type='text'
              {...register('lastName.en')}
              placeholder={'Enter last name'}
            />
            <FormErrorMessage>{errors?.lastName?.en?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.firstName?.ne != null}>
            <FormLabel>
              पहिलो नाम <RequireSign />
            </FormLabel>
            <NepaliInput
              error={errors?.firstName?.ne != null}
              placeholder={'पहिलो नाम लेख्नुहोस |'}
              handleChange={(e: string) => {
                setValue('firstName.ne', e)
                void trigger('firstName.ne')
              }}
              value={watch('firstName.ne')}
            />
            <FormErrorMessage>{errors?.firstName?.ne?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.middleName?.ne != null}>
            <FormLabel>बिचको नाम</FormLabel>
            <NepaliInput
              error={errors?.middleName?.ne != null}
              placeholder={'बिचको नाम लेख्नुहोस |'}
              handleChange={(e: string) => {
                setValue('middleName.ne', e)
                void trigger('middleName.ne')
              }}
              value={watch('middleName.ne')}
            />
            <FormErrorMessage>{errors?.middleName?.ne?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.lastName?.ne != null}>
            <FormLabel>
              थर <RequireSign />
            </FormLabel>
            <NepaliInput
              error={errors?.lastName?.ne != null}
              placeholder={'थर लेख्नुहोस |'}
              handleChange={(e: string) => {
                setValue('lastName.ne', e)
                void trigger('lastName.ne')
              }}
              value={watch('lastName.ne')}
            />
            <FormErrorMessage>{errors?.lastName?.ne?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.gender != null}>
            <FormLabel>
              लिङ्ग <RequireSign />
            </FormLabel>
            <RadioGroup defaultValue={'MALE'}>
              <Radio {...register('gender')} value={'MALE'}>
                पुरुष &nbsp; &nbsp;
              </Radio>
              <Radio {...register('gender')} value={'FEMALE'}>
                महिला &nbsp; &nbsp;
              </Radio>{' '}
              <Radio {...register('gender')} value={'OTHERS'}>
                अन्य
              </Radio>
            </RadioGroup>
            <FormErrorMessage>{errors?.gender?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.dateOfBirth != null}>
            <FormLabel>
              जन्म मिति (बि.सं) / साल-महिना-गते <RequireSign />
            </FormLabel>
            <Input
              size='sm'
              variant={errors?.dateOfBirth != null ? 'error' : ''}
              type='text'
              placeholder='२०५१-१०-१२'
              {...register('dateOfBirth')}
            />
            <FormErrorMessage>{errors?.dateOfBirth?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.academicQualification != null}>
            <FormLabel>
              शेक्षिक योग्यता <RequireSign />
            </FormLabel>
            <Select size='sm' {...register(`academicQualification`)} placeholder='शैक्षिक योग्यता छान्नुहोस'>
              <option value={'ILLITERATE'}>निरक्षर</option>
              <option value={'LITERATE'}>साक्षर</option>
            </Select>
            <FormErrorMessage>{errors?.academicQualification?.message}</FormErrorMessage>
          </FormControl>
          {watch('academicQualification') === 'LITERATE' && (
            <FormControl isInvalid={errors.literateLevel != null}>
              <FormLabel>
                साक्षर योग्यता <RequireSign />
              </FormLabel>
              <Select size='sm' {...register(`literateLevel`)} placeholder='कृपया साक्षर योग्यता छान्नुहोस'>
                <option value={'GENERAL_LITERACY'}>सामान्य लेखपढ</option>
                <option value={'PRIMARY_LEVEL'}>प्राथमिक तह (८ सम्म)</option>
                <option value={'HIGHER_SECONDARY_LEVEL'}>माध्यमिक तह (८ देखि १२ सम्म)</option>
                <option value={'GRADUATION'}>स्नातक वा माथिल्लो तह</option>
              </Select>
              <FormErrorMessage>{errors?.literateLevel?.message}</FormErrorMessage>
            </FormControl>
          )}
          <FormControl isInvalid={errors.ward != null}>
            <FormLabel>
              वडा नम्बर <RequireSign />
            </FormLabel>
            <Input
              size='sm'
              variant={errors?.ward != null ? 'error' : ''}
              type='number'
              onKeyPress={handleKeyPressForWard}
              placeholder='वडा नम्बर लेख्नुहोस'
              {...register('ward')}
            />
            <FormErrorMessage>{errors?.ward?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.village != null}>
            <FormLabel>
              गाँउको नाम <RequireSign />
            </FormLabel>
            <Input
              size='sm'
              variant={errors?.village != null ? 'error' : ''}
              type='text'
              placeholder='गाँउको नाम लेख्नुहोस'
              {...register('village')}
            />
            <FormErrorMessage>{errors?.village?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.citizenshipNumber != null}>
            <FormLabel>
              नागरिकता नम्बर <RequireSign />
            </FormLabel>
            <Input
              size='sm'
              variant={errors?.citizenshipNumber != null ? 'error' : ''}
              type='text'
              placeholder='नागरिकता नम्बर लेख्नुहोस'
              {...register('citizenshipNumber')}
            />
            <FormErrorMessage>{errors?.citizenshipNumber?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.citizenshipIssuedDistrict != null}>
            <FormLabel>
              नागरिकता जारि जिल्ला <RequireSign />
            </FormLabel>
            <Input
              size='sm'
              variant={errors?.citizenshipIssuedDistrict != null ? 'error' : ''}
              type='text'
              placeholder='नागरिकता जारि जिल्ला लेख्नुहोस'
              {...register('citizenshipIssuedDistrict')}
            />
            <FormErrorMessage>{errors?.citizenshipIssuedDistrict?.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box mt='5' p='4' border='1px' borderColor='gray.200' borderRadius={'2px'}>
          <Heading pb='3' size='md'>
            कृषि विवरण
          </Heading>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                lg: 'repeat(4, 1fr)',
                md: 'repeat(4, 1fr)',
                sm: 'repeat(2, 1fr)',
                base: 'repeat(1, 1fr)',
              },
              gap: '4',
            }}
          >
            <FormControl isInvalid={errors.farmWard != null}>
              <FormLabel>
                १. कृषि कार्य गरेको वडा नम्बर <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.farmWard != null ? 'error' : ''}
                type='number'
                onKeyPress={handleKeyPressForWard}
                placeholder='वडा नम्बर लेख्नुहोस'
                {...register('farmWard')}
              />
              <FormErrorMessage>{errors?.farmWard?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.farmVillage != null}>
              <FormLabel>
                २. कृषि कार्य गरेको गाँउको नाम <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.farmVillage != null ? 'error' : ''}
                type='text'
                placeholder='गाँउको नाम लेख्नुहोस'
                {...register('farmVillage')}
              />
              <FormErrorMessage>{errors?.farmVillage?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureArea != null}>
              <FormLabel>
                ३. कृषि कार्यको क्षेत्र <RequireSign />
              </FormLabel>
              <Select size='sm' {...register(`agricultureArea`)} placeholder='कृषि कार्यको क्षेत्र छान्नुहोस'>
                <option value={'कृषि / बाली'}>कृषि / बाली</option>
                <option value={'पशुपन्छी'}>पशुपन्छी</option>
                <option value={'मिश्रित'}>मिश्रित</option>
              </Select>
              <FormErrorMessage>{errors?.agricultureArea?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.agricultureSubArea != null}>
              <FormLabel>
                ४. कृषि कार्यको उपक्षेत्र <RequireSign />
              </FormLabel>
              <Input
                size='sm'
                variant={errors?.agricultureSubArea != null ? 'error' : ''}
                type='text'
                placeholder='कृषि कार्यको उपक्षेत्र लेख्नुहोस'
                {...register('agricultureSubArea')}
              />
              <FormErrorMessage>{errors?.agricultureSubArea?.message}</FormErrorMessage>
            </FormControl>
          </Box>
          <Box mt='7'>
            <FormControl isInvalid={errors.isOwnLand != null}>
              <FormLabel>
                ५. आफ्नो जग्गाको छ ? <RequireSign />
              </FormLabel>
              <RadioGroup value={getValues('isOwnLand')}>
                <Radio {...register('isOwnLand')} value={'YES'}>
                  छ &nbsp; &nbsp;
                </Radio>
                <Radio {...register('isOwnLand')} value={'NO'}>
                  छैन भुमिहिन हो ।
                </Radio>
              </RadioGroup>
              <FormErrorMessage>{errors?.isOwnLand?.message}</FormErrorMessage>
            </FormControl>
            {watch('isOwnLand') === 'YES' && (
              <Box>
                <FormLabel mt='5'>
                  ५.१. जग्गाको क्षेत्रफल
                  <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      lg: 'repeat(6, 1fr)',
                      md: 'repeat(3, 1fr)',
                      sm: 'repeat(2, 1fr)',
                      base: 'repeat(1, 1fr)',
                    },
                  }}
                  gap='4'
                >
                  <FormControl isInvalid={errors.landArea?.biggha != null}>
                    <FormLabel>
                      ५.१.१. बिघा <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.landArea?.biggha != null ? 'error' : ''}
                      type='number'
                      onKeyPress={handleDigitKeyPress}
                      placeholder='जग्गाको क्षेत्रफल बिघा लेख्नुहोस'
                      {...register('landArea.biggha')}
                    />
                    <FormErrorMessage>{errors?.landArea?.biggha?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.landArea?.katha != null}>
                    <FormLabel>
                      ५.१.२. कठ्ठा <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.landArea?.katha != null ? 'error' : ''}
                      type='number'
                      onKeyPress={handleDigitKeyPress}
                      placeholder='जग्गाको क्षेत्रफल कठ्ठा लेख्नुहोस'
                      {...register('landArea.katha')}
                    />
                    <FormErrorMessage>{errors?.landArea?.katha?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors.landArea?.dhur != null}>
                    <FormLabel>
                      ५.१.३. धुर <RequireSign />
                    </FormLabel>
                    <Input
                      size='sm'
                      variant={errors?.landArea?.dhur != null ? 'error' : ''}
                      type='number'
                      onKeyPress={handleDigitKeyPress}
                      placeholder='जग्गाको क्षेत्रफल धुरमा लेख्नुहोस'
                      {...register('landArea.dhur')}
                    />
                    <FormErrorMessage>{errors?.landArea?.dhur?.message}</FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            )}
          </Box>
          <Box mt='7'>
            <FormLabel>
              ६. औसत बार्षिक आम्दानी रु: <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  lg: 'repeat(6, 1fr)',
                  md: 'repeat(3, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  base: 'repeat(1, 1fr)',
                },
                gap: '4',
              }}
            >
              <FormControl isInvalid={errors.yearlyTurnOver?.agriculture != null}>
                <FormLabel>
                  ६.१. कृषि पेशाबाट हुने आम्दानी रु: <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.yearlyTurnOver?.agriculture != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  placeholder='कृषि पेशाबाट हुने आम्दानी रु. मा लेख्नुहोस'
                  {...register('yearlyTurnOver.agriculture')}
                />
                <FormErrorMessage>{errors?.yearlyTurnOver?.agriculture?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.yearlyTurnOver?.nonAgriculture != null}>
                <FormLabel>
                  ६.२. गैर कृषि पेशाबाट हुने आम्दानी रु: <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  variant={errors?.yearlyTurnOver?.nonAgriculture != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  placeholder='गैर कृषि पेशाबाट हुने आम्दानी रु. मा लेख्नुहोस'
                  {...register('yearlyTurnOver.nonAgriculture')}
                />
                <FormErrorMessage>{errors?.yearlyTurnOver?.nonAgriculture?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.yearlyTurnOver?.total != null}>
                <FormLabel>
                  ६.३. जम्मा आम्दानी रु: <RequireSign />
                </FormLabel>
                <Input
                  size='sm'
                  readOnly
                  variant={errors?.yearlyTurnOver?.total != null ? 'error' : ''}
                  type='number'
                  onKeyPress={handleDigitKeyPress}
                  placeholder='जम्मा लेख्नुहोस'
                  {...register('yearlyTurnOver.total')}
                />
                <FormErrorMessage>{errors?.yearlyTurnOver?.total?.message}</FormErrorMessage>
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 7,
              display: 'grid',
              gridTemplateColumns: {
                lg: 'repeat(4, 1fr)',
                md: 'repeat(3, 1fr)',
                sm: 'repeat(2, 1fr)',
                base: 'repeat(1, 1fr)',
              },
            }}
          >
            <FormControl isInvalid={errors.averageMonthEngageOnAgriculture != null}>
              <FormLabel>
                ७. बार्षिक औसत महिना कृषि कार्यमा संलग्न समय <RequireSign />
              </FormLabel>
              <Select
                size='sm'
                {...register(`averageMonthEngageOnAgriculture`)}
                placeholder='बार्षिक औसत महिना कृषि कार्यमा संलग्न समय छान्नुहोस'
              >
                <option value={'३ महिना'}>३ महिना</option>
                <option value={'४ देखि ६ महिना'}>४ देखि ६ महिना</option>
                <option value={'७ देखि ९ महिना'}>७ देखि ९ महिना</option>
                <option value={'बर्षैभरि'}>बर्षैभरि</option>
              </Select>
              <FormErrorMessage>{errors?.averageMonthEngageOnAgriculture?.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '4',
            mt: '5',
            justifyContent: 'flex-end',
          }}
        >
          <Button size='sm' variant='primary' type='submit' isLoading={isSubmitting} loadingText='Submitting'>
            {farmerId ? 'अपडेट गरि अर्को पृष्ठमा जानुहोस्' : 'सुरक्षित गरि अर्को पृष्ठमा जानुहोस्'}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default FarmerPersonalInformationForm
