import Language from '@models/language.model'
import { plainToClass } from 'class-transformer'

class ImageAlbumModel {
  id?: string | number
  title: any
  description: Language
  status: boolean

  constructor(id: string, title: any, description: Language, status: boolean) {
    this.id = id
    this.title = title
    this.description = description
    this.status = status
  }

  static plainToInstance(plainData: object): ImageAlbumModel {
    return plainToClass(ImageAlbumModel, plainData)
  }

  static plainToInstances(plainData: object[]): ImageAlbumModel[] {
    return plainToClass(ImageAlbumModel, plainData)
  }
}
export default ImageAlbumModel
