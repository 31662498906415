import EncryptDecrypt from '@functions/EncryptDecrypt'
import axios from 'axios'

const { decrypt } = EncryptDecrypt

const getToken = () => {
  const token = localStorage.getItem('service_delivery_agriculture_token') ?? ''
  const accessToken = decrypt(token) ?? ''
  return accessToken
}

const PrivateAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_AUTH_URL,
  timeout: 10000,
})

PrivateAxiosInstance.interceptors.request.use(async (config: any) => {
  config.headers.Authorization = `Bearer ${getToken()}`
  return config
})

PrivateAxiosInstance.interceptors.response.use((response: any) => {
  let data: any
  if ('data' in response?.data) {
    data = response.data.data
  }
  if ('auth' in response.data?.data) {
    data = JSON.parse(response.data.data.auth)
  }

  response.data = data
  return response
})

export default PrivateAxiosInstance
