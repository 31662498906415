type Label = Record<string, Record<string, string>>

const labels: Label = {
  album: {
    en: 'Album',
    ne: 'एल्बम',
  },
  videoURL: {
    en: 'Video URL',
    ne: 'भिडियो यूआरएल',
  },
  images: {
    en: 'Images',
    ne: 'चित्रहरु',
  },
  playlist: {
    en: 'Playlist',
    ne: 'प्लेलिस्ट',
  },
  videos: {
    en: 'Videos',
    ne: 'भिडियोहरु',
  },
  enterVideoUrl: {
    en: 'Enter video URL',
    ne: 'भिडियो यूआरएल लेख्नुहोस्',
  },
  status: {
    en: 'Status',
    ne: 'अवस्था',
  },
  published: {
    en: 'Published',
    ne: 'प्रकाशित',
  },
  unpublished: {
    en: 'Unpublished',
    ne: 'अप्रकाशित',
  },
  onlyAllowYoutubeVideo: {
    en: 'Only allow youtube video',
    ne: 'केवल यूट्युब भिडियो हाल्न मिल्छ',
  },
}
export default labels
