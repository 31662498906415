import { useMutation } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorMode,
  useToast,
  VStack,
  WrapItem,
} from '@chakra-ui/react'
import { adminLoginSchema } from '@config/schema/Auth.schema'
import AUTH_LABELS from '@data/localization/auth'
import notificationLabel from '@data/localization/notification'
import EncryptDecrypt from '@functions/EncryptDecrypt'
import { ADMIN_LOGIN } from '@graphql/mutation'
import { yupResolver } from '@hookform/resolvers/yup'
import useAuth from '@hooks/useAuth'
import useLang from '@hooks/useLang'
import { DarkTheme, LightTheme } from '@utils/Colors'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { BiHide, BiShow } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

interface ILogin {
  userEmailPhone: string
  password: string
  rememberMe: boolean
}
const Login = () => {
  const { lang } = useLang()
  const { setUser, setRememberMe, rememberMe } = useAuth()
  const [adminLoginFn] = useMutation(ADMIN_LOGIN)
  const { encrypt, decrypt } = EncryptDecrypt
  const toast = useToast()
  const navigate = useNavigate()
  const [show, setShow] = useState<boolean>(false)
  const handleClick = () => setShow(!show)
  const { colorMode } = useColorMode()
  const [rememberMeData] = useState<any>(decrypt(rememberMe))

  // state to store remember me data
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ILogin>({
    resolver: yupResolver(adminLoginSchema),
    defaultValues: {
      userEmailPhone: rememberMeData?.userEmailPhone || '',
      password: rememberMeData?.password || '',
    },
  })

  // handle form submit
  const onSubmit = async (loginInput: ILogin) => {
    try {
      const response = await adminLoginFn({
        variables: {
          data: {
            email: loginInput.userEmailPhone,
            password: loginInput.password,
            services: 'AGRICULTURE',
          },
        },
      })
      console.log(response?.data?.loginByService?.admin, 'ahha login')
      setUser(response?.data?.loginByService?.admin)
      localStorage.setItem(
        'service_delivery_agriculture_token',
        encrypt(response?.data?.loginByService.accessToken) as string
      )
      const encryptedData = encrypt(JSON.stringify(response?.data?.loginByService?.admin))
      console.log(encryptedData, 'encrypted')
      localStorage.setItem('service_delivery_agriculture_user', encryptedData as string)

      if (loginInput.rememberMe) {
        const encryptedRememberMe = encrypt({
          userEmailPhone: loginInput?.userEmailPhone,
          password: loginInput?.password,
        })
        setRememberMe(encryptedRememberMe)
      } else {
        setRememberMe('')
      }
      navigate('/admin-dashboard', {
        replace: true,
      })
      toast.closeAll()
      toast({
        title: notificationLabel?.success[lang],
        description: notificationLabel?.loginSuccessfully[lang],
        status: 'success',
        isClosable: true,
        duration: 5000,
      })
    } catch (err: any) {
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: notificationLabel?.invalidUsernameOrPassword[lang],
        status: 'error',
        isClosable: true,
        duration: 5000,
      })
    }
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          width={{
            base: '100%',
            sm: '80vw',
            md: '80vw',
            lg: '40vw',
          }}
        >
          <Text
            fontSize='md'
            mt='7'
            fontWeight='bold'
            color={colorMode === 'light' ? LightTheme.primaryTextColor : DarkTheme.primaryTextColor}
          >
            {AUTH_LABELS?.adminLogin[lang]}
          </Text>
          <VStack>
            <FormControl pt='3' isInvalid={errors.userEmailPhone != null}>
              <FormLabel>{AUTH_LABELS?.email[lang]}</FormLabel>
              <Input
                size='sm'
                variant={errors?.userEmailPhone != null ? 'error' : ''}
                type='text'
                {...register('userEmailPhone')}
                placeholder={AUTH_LABELS?.emailPlaceholder[lang]}
              />
              <FormErrorMessage>{errors?.userEmailPhone?.message}</FormErrorMessage>
            </FormControl>
            <FormControl pt='2' mb='5' isInvalid={errors.password != null}>
              <FormLabel>{AUTH_LABELS?.password[lang]} </FormLabel>
              <InputGroup size='sm'>
                <Input
                  variant={errors?.password != null ? 'error' : ''}
                  type={show ? 'text' : 'password'}
                  {...register('password')}
                  placeholder={AUTH_LABELS?.passwordPlaceholder[lang]}
                />
                <InputRightElement onClick={handleClick} cursor='pointer'>
                  {show ? <BiShow /> : <BiHide />}
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
            </FormControl>
          </VStack>
          <Flex my='4' alignItems='center'>
            <Checkbox {...register('rememberMe')} defaultChecked>
              {AUTH_LABELS?.rememberMe[lang]}
            </Checkbox>
          </Flex>
          <WrapItem pt='2' width={'100%'}>
            <Button
              isLoading={isSubmitting}
              loadingText={AUTH_LABELS?.logging[lang]}
              size='sm'
              type='submit'
              width={'100%'}
              variant={'primary'}
            >
              {AUTH_LABELS?.login[lang]}
            </Button>
          </WrapItem>
        </Box>
      </form>
    </Box>
  )
}

export default Login
