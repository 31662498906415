import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  useToast,
} from "@chakra-ui/react";
import { FisheriesBreed } from "@config/constant/agriculture";
import endpoint from "@config/endpoint/farmer";
import {
  animalHusbandrySchema,
  buildingSchema,
  machinerySchema,
} from "@config/schema/farmer/Registration.schema";
import { handleDigitKeyPress } from "@functions/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import useAPI from "@hooks/useApi";
import FarmerAnimalHusbandryInformation from "@models/farmer/farmerAnimalHusbandryInformation.model";
import RequireSign from "@ui/common/atoms/RequireSign";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BiEdit } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

interface IProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  farmerId: string | undefined;
}

const FarmerAnimalHusbandryInformationForm = ({
  step,
  setStep,
  farmerId,
}: IProps) => {
  const { post, patch, put, getById } =
    useAPI<FarmerAnimalHusbandryInformation>();
  const toast = useToast();
  const [selectedValue, setSelectedValue] =
    useState<FarmerAnimalHusbandryInformation | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(animalHusbandrySchema),
    defaultValues: {
      cattleRearing: {
        local: {
          count: "",
          annualAverageMilkingMonths: "",
          LitersOfMilkProductionPerDay: "",
        },
        advance: {
          count: "",
          annualAverageMilkingMonths: "",
          LitersOfMilkProductionPerDay: "",
        },
      },
      buffaloRearing: {
        local: {
          count: "",
          annualAverageMilkingMonths: "",
          LitersOfMilkProductionPerDay: "",
        },
        advance: {
          count: "",
          annualAverageMilkingMonths: "",
          LitersOfMilkProductionPerDay: "",
        },
      },
      yakRearing: {
        chauri: {
          count: "",
          annualAverageMilkingMonths: "",
          LitersOfMilkProductionPerDay: "",
        },
        yak: {
          count: "",
          annualAverageMilkingMonths: "",
          LitersOfMilkProductionPerDay: "",
        },
      },
      bullOrQueue: {
        bull: {
          count: "",
        },
        queue: {
          count: "",
        },
      },
      sheepGoatMountainGoat: {
        sheep: {
          ewe: "",
          ram: "",
          castratedSheep: "",
          lamp: "",
        },
        goat: {
          sheGoat: "",
          goat: "",
          billyGoat: "",
          kid: "",
        },
        mountainGoat: {
          sheGoat: "",
          goat: "",
          castratedGoat: "",
          kid: "",
        },
      },
      boarPigWildBoar: {
        boar: {
          sow: "",
          boar: "",
          piglet: "",
        },
        pig: {
          sow: "",
          boar: "",
          piglet: "",
        },
        wildBoar: {
          sow: "",
          boar: "",
          piglet: "",
        },
      },
      poultryFarming: {
        boiler: {
          isFarming: "",
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        local: {
          isFarming: "",
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
      },
      birds: {
        duck: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        battai: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        ostrich: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        kalij: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        turkey: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        laukat: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        parrot: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
        others: {
          count: "",
          annualAverageEggProduction: "",
          annualAverageProduction: "",
        },
      },
      fisheries: {
        breed: "",
        area: "",
        count: "",
        productionPurpose: "",
        productioUnit: "",
        productionQuantity: "",
      },
      othersAnimalHusbandry: {
        horse: "",
        khachhad: "",
        donkey: "",
        rabbit: "",
        dog: "",
        cat: "",
        others: "",
      },
      grassCrops: {
        cold: {
          breed: "",
          area: "",
          productionQuantity: "",
          seedProduction: "",
        },
        yearly: {
          breed: "",
          area: "",
          productionQuantity: "",
          seedProduction: "",
        },
        allTime: {
          breed: "",
          area: "",
          productionQuantity: "",
          seedProduction: "",
        },
        grass: {
          breed: "",
          area: "",
          productionQuantity: "",
          seedProduction: "",
        },
        nursery: {
          breed: "",
          area: "",
          productionQuantity: "",
          seedProduction: "",
        },
      },
      industryDetails: {
        registrationNumber: "",
        registrationDate: "",
        name: "",
        registeredOffice: "",
        panVatNumber: "",
        chairmanName: "",
        type: "",
        memberCount: "",
        annualJobCount: "",
      },
      machinery: {
        name: "",
        ownership: "",
        capacity: "",
        count: "",
      },
      building: {
        type: "",
        count: "",
        area: "",
      },
    },
  });

  const onSubmit = async (data: any) => {
    try {
      let res;
      if (selectedValue) {
        res = await put(endpoint?.farmerAnimalHusbandryInformation, {
          id: selectedValue?.id?.toString(),
          cattleRearing: {
            local: {
              count: +data?.cattleRearing?.local?.count,
              annualAverageMilkingMonths:
                +data?.cattleRearing?.local?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.cattleRearing?.local?.LitersOfMilkProductionPerDay,
            },
            advance: {
              count: +data?.cattleRearing?.advance?.count,
              annualAverageMilkingMonths:
                +data?.cattleRearing?.advance?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.cattleRearing?.advance?.LitersOfMilkProductionPerDay,
            },
          },
          buffaloRearing: {
            local: {
              count: +data?.buffaloRearing?.local?.count,
              annualAverageMilkingMonths:
                +data?.buffaloRearing?.local?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.buffaloRearing?.local?.LitersOfMilkProductionPerDay,
            },
            advance: {
              count: +data?.buffaloRearing?.advance?.count,
              annualAverageMilkingMonths:
                +data?.buffaloRearing?.advance?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.buffaloRearing?.advance?.LitersOfMilkProductionPerDay,
            },
          },
          yakRearing: {
            chauri: {
              count: +data?.yakRearing?.chauri?.count,
              annualAverageMilkingMonths:
                +data?.yakRearing?.chauri?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.yakRearing?.chauri?.LitersOfMilkProductionPerDay,
            },
            yak: {
              count: +data?.yakRearing?.yak?.count,
              annualAverageMilkingMonths:
                +data?.yakRearing?.yak?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.yakRearing?.yak?.LitersOfMilkProductionPerDay,
            },
          },
          bullOrQueue: {
            bull: {
              count: +data?.bullOrQueue?.bull?.count,
            },
            queue: {
              count: +data?.bullOrQueue?.queue?.count,
            },
          },
          sheepGoatMountainGoat: {
            sheep: {
              ewe: +data?.sheepGoatMountainGoat?.sheep?.ewe,
              ram: +data?.sheepGoatMountainGoat?.sheep?.ram,
              castratedSheep:
                +data?.sheepGoatMountainGoat?.sheep?.castratedSheep,
              lamp: +data?.sheepGoatMountainGoat?.sheep?.lamp,
            },
            goat: {
              sheGoat: +data?.sheepGoatMountainGoat?.goat?.sheGoat,
              goat: +data?.sheepGoatMountainGoat?.goat?.goat,
              billyGoat: +data?.sheepGoatMountainGoat?.goat?.billyGoat,
              kid: +data?.sheepGoatMountainGoat?.goat?.kid,
            },
            mountainGoat: {
              sheGoat: +data?.sheepGoatMountainGoat?.mountainGoat?.sheGoat,
              goat: +data?.sheepGoatMountainGoat?.mountainGoat?.goat,
              castratedGoat:
                +data?.sheepGoatMountainGoat?.mountainGoat?.castratedGoat,
              kid: +data?.sheepGoatMountainGoat?.mountainGoat?.kid,
            },
          },
          boarPigWildBoar: {
            boar: {
              sow: +data?.boarPigWildBoar?.boar?.sow,
              boar: +data?.boarPigWildBoar?.boar?.boar,
              piglet: +data?.boarPigWildBoar?.boar?.piglet,
            },
            pig: {
              sow: +data?.boarPigWildBoar?.pig?.sow,
              boar: +data?.boarPigWildBoar?.pig?.boar,
              piglet: +data?.boarPigWildBoar?.pig?.piglet,
            },
            wildBoar: {
              sow: +data?.boarPigWildBoar?.wildBoar?.sow,
              boar: +data?.boarPigWildBoar?.wildBoar?.boar,
              piglet: +data?.boarPigWildBoar?.wildBoar?.piglet,
            },
          },
          poultryFarming: {
            boiler: {
              isFarming: data?.poultryFarming?.boiler?.isFarming === "YES",
              count: +data?.poultryFarming?.boiler?.count,
              annualAverageEggProduction:
                +data?.poultryFarming?.boiler?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.poultryFarming?.boiler?.annualAverageProduction,
            },
            local: {
              isFarming: data?.poultryFarming?.local?.isFarming === "YES",
              count: +data?.poultryFarming?.local?.count,
              annualAverageEggProduction:
                +data?.poultryFarming?.local?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.poultryFarming?.local?.annualAverageProduction,
            },
          },
          birds: {
            duck: {
              count: +data?.birds?.duck?.count,
              annualAverageEggProduction:
                +data?.birds?.duck?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.duck?.annualAverageProduction,
            },
            battai: {
              count: +data?.birds?.battai?.count,
              annualAverageEggProduction:
                +data?.birds?.battai?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.battai?.annualAverageProduction,
            },
            ostrich: {
              count: +data?.birds?.ostrich?.count,
              annualAverageEggProduction:
                +data?.birds?.ostrich?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.ostrich?.annualAverageProduction,
            },
            kalij: {
              count: +data?.birds?.kalij?.count,
              annualAverageEggProduction:
                +data?.birds?.kalij?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.kalij?.annualAverageProduction,
            },
            turkey: {
              count: +data?.birds?.turkey?.count,
              annualAverageEggProduction:
                +data?.birds?.turkey?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.turkey?.annualAverageProduction,
            },
            laukat: {
              count: +data?.birds?.laukat?.count,
              annualAverageEggProduction:
                +data?.birds?.laukat?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.laukat?.annualAverageProduction,
            },
            parrot: {
              count: +data?.birds?.parrot?.count,
              annualAverageEggProduction:
                +data?.birds?.parrot?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.parrot?.annualAverageProduction,
            },
            others: {
              count: +data?.birds?.others?.count,
              annualAverageEggProduction:
                +data?.birds?.others?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.others?.annualAverageProduction,
            },
          },
          fisheries: {
            breed: data?.fisheries?.breed,
            area: data?.fisheries?.area,
            count: +data?.fisheries?.count,
            productionPurpose: data?.fisheries?.productionPurpose,
            productioUnit: data?.fisheries?.productioUnit,
            productionQuantity: +data?.fisheries?.productionQuantity,
          },
          othersAnimalHusbandry: {
            horse: +data?.othersAnimalHusbandry?.horse,
            khachhad: +data?.othersAnimalHusbandry?.khachhad,
            donkey: +data?.othersAnimalHusbandry?.donkey,
            rabbit: +data?.othersAnimalHusbandry?.rabbit,
            dog: +data?.othersAnimalHusbandry?.dog,
            cat: +data?.othersAnimalHusbandry?.cat,
            others: +data?.othersAnimalHusbandry?.others,
          },
          grassCrops: {
            cold: {
              breed: data?.grassCrops?.cold?.breed,
              area: data?.grassCrops?.cold?.area,
              productionQuantity: +data?.grassCrops?.cold?.productionQuantity,
              seedProduction: data?.grassCrops?.cold?.seedProduction,
            },
            yearly: {
              breed: data?.grassCrops?.yearly?.breed,
              area: data?.grassCrops?.yearly?.area,
              productionQuantity: +data?.grassCrops?.yearly?.productionQuantity,
              seedProduction: data?.grassCrops?.yearly?.seedProduction,
            },
            allTime: {
              breed: data?.grassCrops?.allTime?.breed,
              area: data?.grassCrops?.allTime?.area,
              productionQuantity:
                +data?.grassCrops?.allTime?.productionQuantity,
              seedProduction: data?.grassCrops?.allTime?.seedProduction,
            },
            grass: {
              breed: data?.grassCrops?.grass?.breed,
              area: data?.grassCrops?.grass?.area,
              productionQuantity: +data?.grassCrops?.grass?.productionQuantity,
              seedProduction: data?.grassCrops?.grass?.seedProduction,
            },
            nursery: {
              breed: data?.grassCrops?.nursery?.breed,
              area: data?.grassCrops?.nursery?.area,
              productionQuantity:
                +data?.grassCrops?.nursery?.productionQuantity,
              seedProduction: data?.grassCrops?.nursery?.seedProduction,
            },
          },
          industryDetails: {
            registrationNumber: data?.industryDetails?.registrationNumber,
            registrationDate: data?.industryDetails?.registrationDate,
            name: data?.industryDetails?.name,
            registeredOffice: data?.industryDetails?.registeredOffice,
            panVatNumber: data?.industryDetails?.panVatNumber,
            chairmanName: data?.industryDetails?.chairmanName,
            type: data?.industryDetails?.type,
            memberCount: +data?.industryDetails?.memberCount,
            annualJobCount: +data?.industryDetails?.annualJobCount,
          },
          machinery: machineryDetails ?? undefined,
          building: buildingDetails ?? undefined,
        });
      } else {
        res = await post(endpoint?.farmerAnimalHusbandryInformation, {
          farmerId,
          cattleRearing: {
            local: {
              count: +data?.cattleRearing?.local?.count,
              annualAverageMilkingMonths:
                +data?.cattleRearing?.local?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.cattleRearing?.local?.LitersOfMilkProductionPerDay,
            },
            advance: {
              count: +data?.cattleRearing?.advance?.count,
              annualAverageMilkingMonths:
                +data?.cattleRearing?.advance?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.cattleRearing?.advance?.LitersOfMilkProductionPerDay,
            },
          },
          buffaloRearing: {
            local: {
              count: +data?.buffaloRearing?.local?.count,
              annualAverageMilkingMonths:
                +data?.buffaloRearing?.local?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.buffaloRearing?.local?.LitersOfMilkProductionPerDay,
            },
            advance: {
              count: +data?.buffaloRearing?.advance?.count,
              annualAverageMilkingMonths:
                +data?.buffaloRearing?.advance?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.buffaloRearing?.advance?.LitersOfMilkProductionPerDay,
            },
          },
          yakRearing: {
            chauri: {
              count: +data?.yakRearing?.chauri?.count,
              annualAverageMilkingMonths:
                +data?.yakRearing?.chauri?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.yakRearing?.chauri?.LitersOfMilkProductionPerDay,
            },
            yak: {
              count: +data?.yakRearing?.yak?.count,
              annualAverageMilkingMonths:
                +data?.yakRearing?.yak?.annualAverageMilkingMonths,
              LitersOfMilkProductionPerDay:
                +data?.yakRearing?.yak?.LitersOfMilkProductionPerDay,
            },
          },
          bullOrQueue: {
            bull: {
              count: +data?.bullOrQueue?.bull?.count,
            },
            queue: {
              count: +data?.bullOrQueue?.queue?.count,
            },
          },
          sheepGoatMountainGoat: {
            sheep: {
              ewe: +data?.sheepGoatMountainGoat?.sheep?.ewe,
              ram: +data?.sheepGoatMountainGoat?.sheep?.ram,
              castratedSheep:
                +data?.sheepGoatMountainGoat?.sheep?.castratedSheep,
              lamp: +data?.sheepGoatMountainGoat?.sheep?.lamp,
            },
            goat: {
              sheGoat: +data?.sheepGoatMountainGoat?.goat?.sheGoat,
              goat: +data?.sheepGoatMountainGoat?.goat?.goat,
              billyGoat: +data?.sheepGoatMountainGoat?.goat?.billyGoat,
              kid: +data?.sheepGoatMountainGoat?.goat?.kid,
            },
            mountainGoat: {
              sheGoat: +data?.sheepGoatMountainGoat?.mountainGoat?.sheGoat,
              goat: +data?.sheepGoatMountainGoat?.mountainGoat?.goat,
              castratedGoat:
                +data?.sheepGoatMountainGoat?.mountainGoat?.castratedGoat,
              kid: +data?.sheepGoatMountainGoat?.mountainGoat?.kid,
            },
          },
          boarPigWildBoar: {
            boar: {
              sow: +data?.boarPigWildBoar?.boar?.sow,
              boar: +data?.boarPigWildBoar?.boar?.boar,
              piglet: +data?.boarPigWildBoar?.boar?.piglet,
            },
            pig: {
              sow: +data?.boarPigWildBoar?.pig?.sow,
              boar: +data?.boarPigWildBoar?.pig?.boar,
              piglet: +data?.boarPigWildBoar?.pig?.piglet,
            },
            wildBoar: {
              sow: +data?.boarPigWildBoar?.wildBoar?.sow,
              boar: +data?.boarPigWildBoar?.wildBoar?.boar,
              piglet: +data?.boarPigWildBoar?.wildBoar?.piglet,
            },
          },
          poultryFarming: {
            boiler: {
              isFarming: data?.poultryFarming?.boiler?.isFarming === "YES",
              count: +data?.poultryFarming?.boiler?.count,
              annualAverageEggProduction:
                +data?.poultryFarming?.boiler?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.poultryFarming?.boiler?.annualAverageProduction,
            },
            local: {
              isFarming: data?.poultryFarming?.local?.isFarming === "YES",
              count: +data?.poultryFarming?.local?.count,
              annualAverageEggProduction:
                +data?.poultryFarming?.local?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.poultryFarming?.local?.annualAverageProduction,
            },
          },
          birds: {
            duck: {
              count: +data?.birds?.duck?.count,
              annualAverageEggProduction:
                +data?.birds?.duck?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.duck?.annualAverageProduction,
            },
            battai: {
              count: +data?.birds?.battai?.count,
              annualAverageEggProduction:
                +data?.birds?.battai?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.battai?.annualAverageProduction,
            },
            ostrich: {
              count: +data?.birds?.ostrich?.count,
              annualAverageEggProduction:
                +data?.birds?.ostrich?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.ostrich?.annualAverageProduction,
            },
            kalij: {
              count: +data?.birds?.kalij?.count,
              annualAverageEggProduction:
                +data?.birds?.kalij?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.kalij?.annualAverageProduction,
            },
            turkey: {
              count: +data?.birds?.turkey?.count,
              annualAverageEggProduction:
                +data?.birds?.turkey?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.turkey?.annualAverageProduction,
            },
            laukat: {
              count: +data?.birds?.laukat?.count,
              annualAverageEggProduction:
                +data?.birds?.laukat?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.laukat?.annualAverageProduction,
            },
            parrot: {
              count: +data?.birds?.parrot?.count,
              annualAverageEggProduction:
                +data?.birds?.parrot?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.parrot?.annualAverageProduction,
            },
            others: {
              count: +data?.birds?.others?.count,
              annualAverageEggProduction:
                +data?.birds?.others?.annualAverageEggProduction,
              annualAverageProduction:
                +data?.birds?.others?.annualAverageProduction,
            },
          },
          fisheries: {
            breed: data?.fisheries?.breed,
            area: data?.fisheries?.area,
            count: +data?.fisheries?.count,
            productionPurpose: data?.fisheries?.productionPurpose,
            productioUnit: data?.fisheries?.productioUnit,
            productionQuantity: +data?.fisheries?.productionQuantity,
          },
          othersAnimalHusbandry: {
            horse: +data?.othersAnimalHusbandry?.horse,
            khachhad: +data?.othersAnimalHusbandry?.khachhad,
            donkey: +data?.othersAnimalHusbandry?.donkey,
            rabbit: +data?.othersAnimalHusbandry?.rabbit,
            dog: +data?.othersAnimalHusbandry?.dog,
            cat: +data?.othersAnimalHusbandry?.cat,
            others: +data?.othersAnimalHusbandry?.others,
          },
          grassCrops: {
            cold: {
              breed: data?.grassCrops?.cold?.breed,
              area: data?.grassCrops?.cold?.area,
              productionQuantity: +data?.grassCrops?.cold?.productionQuantity,
              seedProduction: data?.grassCrops?.cold?.seedProduction,
            },
            yearly: {
              breed: data?.grassCrops?.yearly?.breed,
              area: data?.grassCrops?.yearly?.area,
              productionQuantity: +data?.grassCrops?.yearly?.productionQuantity,
              seedProduction: data?.grassCrops?.yearly?.seedProduction,
            },
            allTime: {
              breed: data?.grassCrops?.allTime?.breed,
              area: data?.grassCrops?.allTime?.area,
              productionQuantity:
                +data?.grassCrops?.allTime?.productionQuantity,
              seedProduction: data?.grassCrops?.allTime?.seedProduction,
            },
            grass: {
              breed: data?.grassCrops?.grass?.breed,
              area: data?.grassCrops?.grass?.area,
              productionQuantity: +data?.grassCrops?.grass?.productionQuantity,
              seedProduction: data?.grassCrops?.grass?.seedProduction,
            },
            nursery: {
              breed: data?.grassCrops?.nursery?.breed,
              area: data?.grassCrops?.nursery?.area,
              productionQuantity:
                +data?.grassCrops?.nursery?.productionQuantity,
              seedProduction: data?.grassCrops?.nursery?.seedProduction,
            },
          },
          industryDetails: {
            registrationNumber: data?.industryDetails?.registrationNumber,
            registrationDate: data?.industryDetails?.registrationDate,
            name: data?.industryDetails?.name,
            registeredOffice: data?.industryDetails?.registeredOffice,
            panVatNumber: data?.industryDetails?.panVatNumber,
            chairmanName: data?.industryDetails?.chairmanName,
            type: data?.industryDetails?.type,
            memberCount: +data?.industryDetails?.memberCount,
            annualJobCount: +data?.industryDetails?.annualJobCount,
          },
          machinery: machineryDetails ?? undefined,
          building: buildingDetails ?? undefined,
        });
      }
      if (res?.data) {
        toast.closeAll();
        toast({
          title: "सफल भयो",
          description: `पशुपन्छी पालन विवरण सफलतापूर्वक ${
            selectedValue ? "अपडेट" : "सुरक्षित"
          } गरियो`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setStep(step + 1);
        reset({
          cattleRearing: {
            local: {
              count: "",
              annualAverageMilkingMonths: "",
              LitersOfMilkProductionPerDay: "",
            },
            advance: {
              count: "",
              annualAverageMilkingMonths: "",
              LitersOfMilkProductionPerDay: "",
            },
          },
          buffaloRearing: {
            local: {
              count: "",
              annualAverageMilkingMonths: "",
              LitersOfMilkProductionPerDay: "",
            },
            advance: {
              count: "",
              annualAverageMilkingMonths: "",
              LitersOfMilkProductionPerDay: "",
            },
          },
          yakRearing: {
            chauri: {
              count: "",
              annualAverageMilkingMonths: "",
              LitersOfMilkProductionPerDay: "",
            },
            yak: {
              count: "",
              annualAverageMilkingMonths: "",
              LitersOfMilkProductionPerDay: "",
            },
          },
          bullOrQueue: {
            bull: {
              count: "",
            },
            queue: {
              count: "",
            },
          },
          sheepGoatMountainGoat: {
            sheep: {
              ewe: "",
              ram: "",
              castratedSheep: "",
              lamp: "",
            },
            goat: {
              sheGoat: "",
              goat: "",
              billyGoat: "",
              kid: "",
            },
            mountainGoat: {
              sheGoat: "",
              goat: "",
              castratedGoat: "",
              kid: "",
            },
          },
          boarPigWildBoar: {
            boar: {
              sow: "",
              boar: "",
              piglet: "",
            },
            pig: {
              sow: "",
              boar: "",
              piglet: "",
            },
            wildBoar: {
              sow: "",
              boar: "",
              piglet: "",
            },
          },
          poultryFarming: {
            boiler: {
              isFarming: "",
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            local: {
              isFarming: "",
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
          },
          birds: {
            duck: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            battai: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            ostrich: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            kalij: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            turkey: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            laukat: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            parrot: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
            others: {
              count: "",
              annualAverageEggProduction: "",
              annualAverageProduction: "",
            },
          },
          fisheries: {
            breed: "",
            area: "",
            count: "",
            productionPurpose: "",
            productioUnit: "",
            productionQuantity: "",
          },
          othersAnimalHusbandry: {
            horse: "",
            khachhad: "",
            donkey: "",
            rabbit: "",
            dog: "",
            cat: "",
            others: "",
          },
          grassCrops: {
            cold: {
              breed: "",
              area: "",
              productionQuantity: "",
              seedProduction: "",
            },
            yearly: {
              breed: "",
              area: "",
              productionQuantity: "",
              seedProduction: "",
            },
            allTime: {
              breed: "",
              area: "",
              productionQuantity: "",
              seedProduction: "",
            },
            grass: {
              breed: "",
              area: "",
              productionQuantity: "",
              seedProduction: "",
            },
            nursery: {
              breed: "",
              area: "",
              productionQuantity: "",
              seedProduction: "",
            },
          },
          industryDetails: {
            registrationNumber: "",
            registrationDate: "",
            name: "",
            registeredOffice: "",
            panVatNumber: "",
            chairmanName: "",
            type: "",
            memberCount: "",
            annualJobCount: "",
          },
          machinery: {
            name: "",
            ownership: "",
            capacity: "",
            count: "",
          },
          building: {
            type: "",
            count: "",
            area: "",
          },
        });
        await queryClient.invalidateQueries("farmer");
        navigate("/admin-dashboard/farmer/list", { replace: true });
      } else {
        toast.closeAll();
        toast({
          title: "गलत भयो",
          description: res?.error?.response?.data?.message ?? "केहि गलत भयो",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (err: any) {
      console.log(err);
      toast.closeAll();
      toast({
        title: "गलत भयो",
        description: err?.message ?? "केहि गलत भयो",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getFarmerAnimalHusbandryInformation = async () => {
    try {
      const res = await getById(
        endpoint?.farmerAnimalHusbandryInformation + "/farmer",
        farmerId as string
      );
      if (res) {
        setSelectedValue(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (farmerId) {
      void getFarmerAnimalHusbandryInformation();
    }
  }, []);

  useEffect(() => {
    if (selectedValue) {
      reset({
        cattleRearing: {
          local: {
            count: selectedValue?.cattleRearing?.local?.count.toString(),
            annualAverageMilkingMonths:
              selectedValue?.cattleRearing?.local?.annualAverageMilkingMonths.toString(),
            LitersOfMilkProductionPerDay:
              selectedValue?.cattleRearing?.local?.LitersOfMilkProductionPerDay.toString(),
          },
          advance: {
            count: selectedValue?.cattleRearing?.advance?.count.toString(),
            annualAverageMilkingMonths:
              selectedValue?.cattleRearing?.advance?.annualAverageMilkingMonths.toString(),
            LitersOfMilkProductionPerDay:
              selectedValue?.cattleRearing?.advance?.LitersOfMilkProductionPerDay.toString(),
          },
        },
        buffaloRearing: {
          local: {
            count: selectedValue?.buffaloRearing?.local?.count.toString(),
            annualAverageMilkingMonths:
              selectedValue?.buffaloRearing?.local?.annualAverageMilkingMonths.toString(),
            LitersOfMilkProductionPerDay:
              selectedValue?.buffaloRearing?.local?.LitersOfMilkProductionPerDay.toString(),
          },
          advance: {
            count: selectedValue?.buffaloRearing?.advance?.count.toString(),
            annualAverageMilkingMonths:
              selectedValue?.buffaloRearing?.advance?.annualAverageMilkingMonths.toString(),
            LitersOfMilkProductionPerDay:
              selectedValue?.buffaloRearing?.advance?.LitersOfMilkProductionPerDay.toString(),
          },
        },
        yakRearing: {
          chauri: {
            count: selectedValue?.yakRearing?.chauri?.count.toString(),
            annualAverageMilkingMonths:
              selectedValue?.yakRearing?.chauri?.annualAverageMilkingMonths.toString(),
            LitersOfMilkProductionPerDay:
              selectedValue?.yakRearing?.chauri?.LitersOfMilkProductionPerDay.toString(),
          },
          yak: {
            count: selectedValue?.yakRearing?.yak?.count.toString(),
            annualAverageMilkingMonths:
              selectedValue?.yakRearing?.yak?.annualAverageMilkingMonths.toString(),
            LitersOfMilkProductionPerDay:
              selectedValue?.yakRearing?.yak?.LitersOfMilkProductionPerDay.toString(),
          },
        },
        bullOrQueue: {
          bull: {
            count: selectedValue?.bullOrQueue?.bull?.count.toString(),
          },
          queue: {
            count: selectedValue?.bullOrQueue?.queue?.count.toString(),
          },
        },
        sheepGoatMountainGoat: {
          sheep: {
            ewe: selectedValue?.sheepGoatMountainGoat?.sheep?.ewe.toString(),
            ram: selectedValue?.sheepGoatMountainGoat?.sheep?.ram.toString(),
            castratedSheep:
              selectedValue?.sheepGoatMountainGoat?.sheep?.castratedSheep.toString(),
            lamp: selectedValue?.sheepGoatMountainGoat?.sheep?.lamp.toString(),
          },
          goat: {
            sheGoat:
              selectedValue?.sheepGoatMountainGoat?.goat?.sheGoat.toString(),
            goat: selectedValue?.sheepGoatMountainGoat?.goat?.goat.toString(),
            billyGoat:
              selectedValue?.sheepGoatMountainGoat?.goat?.billyGoat.toString(),
            kid: selectedValue?.sheepGoatMountainGoat?.goat?.kid.toString(),
          },
          mountainGoat: {
            sheGoat:
              selectedValue?.sheepGoatMountainGoat?.mountainGoat?.sheGoat.toString(),
            goat: selectedValue?.sheepGoatMountainGoat?.mountainGoat?.goat.toString(),
            castratedGoat:
              selectedValue?.sheepGoatMountainGoat?.mountainGoat?.castratedGoat.toString(),
            kid: selectedValue?.sheepGoatMountainGoat?.mountainGoat?.kid.toString(),
          },
        },
        boarPigWildBoar: {
          boar: {
            sow: selectedValue?.boarPigWildBoar?.boar?.sow.toString(),
            boar: selectedValue?.boarPigWildBoar?.boar?.boar.toString(),
            piglet: selectedValue?.boarPigWildBoar?.boar?.piglet.toString(),
          },
          pig: {
            sow: selectedValue?.boarPigWildBoar?.pig?.sow.toString(),
            boar: selectedValue?.boarPigWildBoar?.pig?.boar.toString(),
            piglet: selectedValue?.boarPigWildBoar?.pig?.piglet.toString(),
          },
          wildBoar: {
            sow: selectedValue?.boarPigWildBoar?.wildBoar?.sow.toString(),
            boar: selectedValue?.boarPigWildBoar?.wildBoar?.boar.toString(),
            piglet: selectedValue?.boarPigWildBoar?.wildBoar?.piglet.toString(),
          },
        },
        poultryFarming: {
          boiler: {
            isFarming: selectedValue?.poultryFarming?.boiler?.isFarming
              ? "YES"
              : "NO",
            count: selectedValue?.poultryFarming?.boiler?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.poultryFarming?.boiler?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.poultryFarming?.boiler?.annualAverageProduction.toString(),
          },
          local: {
            isFarming: selectedValue?.poultryFarming?.local?.isFarming
              ? "YES"
              : "NO",
            count: selectedValue?.poultryFarming?.local?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.poultryFarming?.local?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.poultryFarming?.local?.annualAverageProduction.toString(),
          },
        },
        birds: {
          duck: {
            count: selectedValue?.birds?.duck?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.duck?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.duck?.annualAverageProduction.toString(),
          },
          battai: {
            count: selectedValue?.birds?.battai?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.battai?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.battai?.annualAverageProduction.toString(),
          },
          ostrich: {
            count: selectedValue?.birds?.ostrich?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.ostrich?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.ostrich?.annualAverageProduction.toString(),
          },
          kalij: {
            count: selectedValue?.birds?.kalij?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.kalij?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.kalij?.annualAverageProduction.toString(),
          },
          turkey: {
            count: selectedValue?.birds?.turkey?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.turkey?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.turkey?.annualAverageProduction.toString(),
          },
          laukat: {
            count: selectedValue?.birds?.laukat?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.laukat?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.laukat?.annualAverageProduction.toString(),
          },
          parrot: {
            count: selectedValue?.birds?.parrot?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.parrot?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.parrot?.annualAverageProduction.toString(),
          },
          others: {
            count: selectedValue?.birds?.others?.count.toString(),
            annualAverageEggProduction:
              selectedValue?.birds?.others?.annualAverageEggProduction.toString(),
            annualAverageProduction:
              selectedValue?.birds?.others?.annualAverageProduction.toString(),
          },
        },
        fisheries: {
          breed: selectedValue?.fisheries?.breed,
          area: selectedValue?.fisheries?.area,
          count: selectedValue?.fisheries?.count.toString(),
          productionPurpose: selectedValue?.fisheries?.productionPurpose,
          productioUnit: selectedValue?.fisheries?.productioUnit,
          productionQuantity:
            selectedValue?.fisheries?.productionQuantity.toString(),
        },
        othersAnimalHusbandry: {
          horse: selectedValue?.othersAnimalHusbandry?.horse.toString(),
          khachhad: selectedValue?.othersAnimalHusbandry?.khachhad?.toString(),
          donkey: selectedValue?.othersAnimalHusbandry?.donkey.toString(),
          rabbit: selectedValue?.othersAnimalHusbandry?.rabbit.toString(),
          dog: selectedValue?.othersAnimalHusbandry?.dog.toString(),
          cat: selectedValue?.othersAnimalHusbandry?.cat.toString(),
          others: selectedValue?.othersAnimalHusbandry?.others.toString(),
        },
        grassCrops: {
          cold: {
            breed: selectedValue?.grassCrops?.cold?.breed,
            area: selectedValue?.grassCrops?.cold?.area,
            productionQuantity:
              selectedValue?.grassCrops?.cold?.productionQuantity?.toString(),
            seedProduction: selectedValue?.grassCrops?.cold?.seedProduction,
          },
          yearly: {
            breed: selectedValue?.grassCrops?.yearly?.breed,
            area: selectedValue?.grassCrops?.yearly?.area,
            productionQuantity:
              selectedValue?.grassCrops?.yearly?.productionQuantity?.toString(),
            seedProduction: selectedValue?.grassCrops?.yearly?.seedProduction,
          },
          allTime: {
            breed: selectedValue?.grassCrops?.allTime?.breed,
            area: selectedValue?.grassCrops?.allTime?.area,
            productionQuantity:
              selectedValue?.grassCrops?.allTime?.productionQuantity.toString(),
            seedProduction: selectedValue?.grassCrops?.allTime?.seedProduction,
          },
          grass: {
            breed: selectedValue?.grassCrops?.grass?.breed,
            area: selectedValue?.grassCrops?.grass?.area,
            productionQuantity:
              selectedValue?.grassCrops?.grass?.productionQuantity.toString(),
            seedProduction: selectedValue?.grassCrops?.grass?.seedProduction,
          },
          nursery: {
            breed: selectedValue?.grassCrops?.nursery?.breed,
            area: selectedValue?.grassCrops?.nursery?.area,
            productionQuantity:
              selectedValue?.grassCrops?.nursery?.productionQuantity.toString(),
            seedProduction: selectedValue?.grassCrops?.nursery?.seedProduction,
          },
        },
        industryDetails: {
          registrationNumber:
            selectedValue?.industryDetails?.registrationNumber,
          registrationDate: selectedValue?.industryDetails?.registrationDate,
          name: selectedValue?.industryDetails?.name,
          registeredOffice: selectedValue?.industryDetails?.registeredOffice,
          panVatNumber: selectedValue?.industryDetails?.panVatNumber,
          chairmanName: selectedValue?.industryDetails?.chairmanName,
          type: selectedValue?.industryDetails?.type,
          memberCount: selectedValue?.industryDetails?.memberCount.toString(),
          annualJobCount:
            selectedValue?.industryDetails?.annualJobCount.toString(),
        },
      });
      if (selectedValue?.machinery)
        setMachineryDetails(selectedValue?.machinery);
      if (selectedValue?.building) setBuildingDetails(selectedValue?.building);
    }
  }, [selectedValue]);

  interface IMachineryDetails {
    name: string;
    ownership: string;
    capacity: string;
    count: number;
  }

  const [machineryDetails, setMachineryDetails] = useState<
    IMachineryDetails[] | null
  >(null);
  const [selectedMachineryDetails, setSelectedMachineryDetails] =
    useState<IMachineryDetails | null>(null);

  const addMachineryDetails = () => {
    const newMachineryDetails = {
      name: watch("machinery.name"),
      ownership: watch("machinery.ownership"),
      capacity: watch("machinery.capacity"),
      count: +watch("machinery.count"),
    };

    if (machineryDetails)
      setMachineryDetails([...machineryDetails, newMachineryDetails]);
    else setMachineryDetails([newMachineryDetails]);

    reset({
      machinery: {
        name: "",
        ownership: "",
        capacity: "",
        count: "",
      },
    });
  };

  const removeMachineryDetails = (index: number) => {
    if (machineryDetails) {
      const data = machineryDetails?.filter((_, i: number) => i !== index);
      setMachineryDetails(data);
    }
  };

  const editMachineryDetails = (index: number) => {
    if (machineryDetails) {
      removeMachineryDetails(index);
      const data = machineryDetails?.find((_, i: number) => i === index);
      if (data) setSelectedMachineryDetails(data);
    }
  };

  useEffect(() => {
    if (selectedMachineryDetails) {
      reset({
        machinery: {
          name: selectedMachineryDetails?.name,
          ownership: selectedMachineryDetails?.ownership,
          capacity: selectedMachineryDetails?.capacity,
          count: selectedMachineryDetails?.count?.toString(),
        },
      });
    }
  }, [selectedMachineryDetails]);

  interface IBuildingDetails {
    type: string;
    count: number;
    area: string;
  }

  const [buildingDetails, setBuildingDetails] = useState<
    IBuildingDetails[] | null
  >(null);
  const [selectedBuildingDetails, setSelectedBuildingDetails] =
    useState<IBuildingDetails | null>(null);

  const addBuildingDetails = () => {
    const newBuildingDetails = {
      type: watch("building.type"),
      count: +watch("building.count"),
      area: watch("building.area"),
    };

    if (buildingDetails)
      setBuildingDetails([...buildingDetails, newBuildingDetails]);
    else setBuildingDetails([newBuildingDetails]);

    reset({
      building: {
        type: "",
        count: "",
        area: "",
      },
    });
  };

  const removeBuildingDetails = (index: number) => {
    if (buildingDetails) {
      const data = buildingDetails?.filter((_, i: number) => i !== index);
      setBuildingDetails(data);
    }
  };

  const editBuildingDetails = (index: number) => {
    if (buildingDetails) {
      removeBuildingDetails(index);
      const data = buildingDetails?.find((_, i: number) => i === index);
      if (data) setSelectedBuildingDetails(data);
    }
  };

  useEffect(() => {
    if (selectedBuildingDetails) {
      reset({
        building: {
          type: selectedBuildingDetails.type,
          count: selectedBuildingDetails.count?.toString(),
          area: selectedBuildingDetails.area,
        },
      });
    }
  }, [selectedBuildingDetails]);

  // fake filler function
  const handleFakeFiller = async () => {
    reset({
      cattleRearing: {
        local: {
          count: "123",
          annualAverageMilkingMonths: "988",
          LitersOfMilkProductionPerDay: "01",
        },
        advance: {
          count: "77",
          annualAverageMilkingMonths: "87",
          LitersOfMilkProductionPerDay: "12",
        },
      },
      buffaloRearing: {
        local: {
          count: "123",
          annualAverageMilkingMonths: "12",
          LitersOfMilkProductionPerDay: "12",
        },
        advance: {
          count: "99",
          annualAverageMilkingMonths: "12",
          LitersOfMilkProductionPerDay: "14",
        },
      },
      yakRearing: {
        chauri: {
          count: "44",
          annualAverageMilkingMonths: "43",
          LitersOfMilkProductionPerDay: "54",
        },
        yak: {
          count: "92",
          annualAverageMilkingMonths: "23",
          LitersOfMilkProductionPerDay: "85",
        },
      },
      bullOrQueue: {
        bull: {
          count: "23",
        },
        queue: {
          count: "12",
        },
      },
      sheepGoatMountainGoat: {
        sheep: {
          ewe: "123",
          ram: "46",
          castratedSheep: "25",
          lamp: "64",
        },
        goat: {
          sheGoat: "93",
          goat: "87",
          billyGoat: "54",
          kid: "23",
        },
        mountainGoat: {
          sheGoat: "12",
          goat: "14",
          castratedGoat: "11",
          kid: "10",
        },
      },
      boarPigWildBoar: {
        boar: {
          sow: "98",
          boar: "123",
          piglet: "1",
        },
        pig: {
          sow: "0",
          boar: "98",
          piglet: "123",
        },
        wildBoar: {
          sow: "12",
          boar: "15",
          piglet: "65",
        },
      },
      poultryFarming: {
        boiler: {
          isFarming: "YES",
          count: "123",
          annualAverageEggProduction: "234",
          annualAverageProduction: "34",
        },
        local: {
          isFarming: "YES",
          count: "12",
          annualAverageEggProduction: "54",
          annualAverageProduction: "7",
        },
      },
      birds: {
        duck: {
          count: "23",
          annualAverageEggProduction: "2",
          annualAverageProduction: "1",
        },
        battai: {
          count: "12",
          annualAverageEggProduction: "55",
          annualAverageProduction: "7",
        },
        ostrich: {
          count: "98",
          annualAverageEggProduction: "5",
          annualAverageProduction: "4",
        },
        kalij: {
          count: "2",
          annualAverageEggProduction: "33",
          annualAverageProduction: "85",
        },
        turkey: {
          count: "87",
          annualAverageEggProduction: "12",
          annualAverageProduction: "12",
        },
        laukat: {
          count: "12",
          annualAverageEggProduction: "12",
          annualAverageProduction: "123",
        },
        parrot: {
          count: "12",
          annualAverageEggProduction: "34",
          annualAverageProduction: "54",
        },
        others: {
          count: "12",
          annualAverageEggProduction: "123",
          annualAverageProduction: "43",
        },
      },
      fisheries: {
        area: "465",
        count: "1",
        productionPurpose: "मासु",
        productioUnit: "क्विन्टल",
        productionQuantity: "87",
      },
      othersAnimalHusbandry: {
        horse: "12",
        khachhad: "123",
        donkey: "5",
        rabbit: "6",
        dog: "74",
        cat: "3",
        others: "23",
      },
      grassCrops: {
        cold: {
          breed: "उन्नत",
          area: "11",
          productionQuantity: "87",
          seedProduction: "9",
        },
        yearly: {
          breed: "उन्नत",
          area: "11",
          productionQuantity: "4",
          seedProduction: "6",
        },
        allTime: {
          breed: "उन्नत",
          area: "6",
          productionQuantity: "23",
          seedProduction: "23",
        },
        grass: {
          breed: "उन्नत",
          area: "54",
          productionQuantity: "12",
          seedProduction: "12",
        },
        nursery: {
          breed: "उन्नत",
          area: "4",
          productionQuantity: "4",
          seedProduction: "56",
        },
      },
      industryDetails: {
        registrationNumber: "23",
        registrationDate: "123",
        name: "132",
        registeredOffice: "54",
        panVatNumber: "98",
        chairmanName: "9",
        type: "कृषि",
        memberCount: "23",
        annualJobCount: "11",
      },
    });
    setBuildingDetails([
      {
        type: "पक्कि",
        count: 12,
        area: "199",
      },
      {
        type: "कच्चा",
        count: 11,
        area: "877",
      },
    ]);
    setMachineryDetails([
      {
        name: "खेत खन्ने मेशिन",
        ownership: "आफ्नो",
        capacity: "1000",
        count: 199,
      },
      {
        name: "धान काट्ने मेशिन",
        ownership: "नगरपालिका",
        capacity: "9000",
        count: 10,
      },
    ]);
  };

  return (
    <Box>
      {/* remove this fake filler on production */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={handleFakeFiller}
          variant={"outline"}
          size="md"
          colorScheme="purple"
        >
          Fake filler
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading pb="3" size="md" mt="2">
          पशुपन्छी पालन विवरण
        </Heading>
        <Box>
          <Box mt="2">
            <FormLabel>
              १. गाईपालन <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(2,1fr)",
                  base: "1fr",
                },
                gap: 2,
              }}
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  १.१. स्थानीय <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(3,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.cattleRearing?.local?.count != null}
                  >
                    <FormLabel>
                      १.१.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.cattleRearing?.local?.count != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("cattleRearing.local.count")}
                      placeholder={"संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.cattleRearing?.local?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.cattleRearing?.local
                        ?.annualAverageMilkingMonths != null
                    }
                  >
                    <FormLabel>
                      १.१.२. बार्षिक औसत दुध दिने महिना <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.cattleRearing?.local
                          ?.annualAverageMilkingMonths != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "cattleRearing.local.annualAverageMilkingMonths"
                      )}
                      placeholder={"बार्षिक औसत दुध दिने महिना लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.cattleRearing?.local?.annualAverageMilkingMonths
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.cattleRearing?.local
                        ?.LitersOfMilkProductionPerDay != null
                    }
                  >
                    <FormLabel>
                      १.१.३. दैनिक दुध उत्पादन लिटर <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.cattleRearing?.local
                          ?.LitersOfMilkProductionPerDay != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "cattleRearing.local.LitersOfMilkProductionPerDay"
                      )}
                      placeholder={"दैनिक दुध उत्पादन लिटरमा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.cattleRearing?.local
                          ?.LitersOfMilkProductionPerDay?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  १.२. उन्नत <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(3,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.cattleRearing?.advance?.count != null}
                  >
                    <FormLabel>
                      १.२.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.cattleRearing?.advance?.count != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("cattleRearing.advance.count")}
                      placeholder={"संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.cattleRearing?.advance?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.cattleRearing?.advance
                        ?.annualAverageMilkingMonths != null
                    }
                  >
                    <FormLabel>
                      १.२.२. बार्षिक औसत दुध दिने महिना <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.cattleRearing?.advance
                          ?.annualAverageMilkingMonths != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "cattleRearing.advance.annualAverageMilkingMonths"
                      )}
                      placeholder={"बार्षिक औसत दुध दिने महिना लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.cattleRearing?.advance
                          ?.annualAverageMilkingMonths?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.cattleRearing?.advance
                        ?.LitersOfMilkProductionPerDay != null
                    }
                  >
                    <FormLabel>
                      १.२.३. दैनिक दुध उत्पादन लिटर <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.cattleRearing?.advance
                          ?.LitersOfMilkProductionPerDay != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "cattleRearing.advance.LitersOfMilkProductionPerDay"
                      )}
                      placeholder={"दैनिक दुध उत्पादन लिटरमा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.cattleRearing?.advance
                          ?.LitersOfMilkProductionPerDay?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              २. भैसीपालन <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(2,1fr)",
                  base: "1fr",
                },
                gap: 2,
              }}
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  २.१. स्थानीय <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(3,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.buffaloRearing?.local?.count != null}
                  >
                    <FormLabel>
                      २.१.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.buffaloRearing?.local?.count != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("buffaloRearing.local.count")}
                      placeholder={"संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.buffaloRearing?.local?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.buffaloRearing?.local
                        ?.annualAverageMilkingMonths != null
                    }
                  >
                    <FormLabel>
                      २.१.२. बार्षिक औसत दुध दिने महिना <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.buffaloRearing?.local
                          ?.annualAverageMilkingMonths != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "buffaloRearing.local.annualAverageMilkingMonths"
                      )}
                      placeholder={"बार्षिक औसत दुध दिने महिना लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.buffaloRearing?.local
                          ?.annualAverageMilkingMonths?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.buffaloRearing?.local
                        ?.LitersOfMilkProductionPerDay != null
                    }
                  >
                    <FormLabel>
                      २.१.३. दैनिक दुध उत्पादन लिटर <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.buffaloRearing?.local
                          ?.LitersOfMilkProductionPerDay != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "buffaloRearing.local.LitersOfMilkProductionPerDay"
                      )}
                      placeholder={"दैनिक दुध उत्पादन लिटरमा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.buffaloRearing?.local
                          ?.LitersOfMilkProductionPerDay?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  २.२. उन्नत <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(3,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.buffaloRearing?.advance?.count != null}
                  >
                    <FormLabel>
                      २.२.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.buffaloRearing?.advance?.count != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("buffaloRearing.advance.count")}
                      placeholder={"संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.buffaloRearing?.advance?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.buffaloRearing?.advance
                        ?.annualAverageMilkingMonths != null
                    }
                  >
                    <FormLabel>
                      २.२.२. बार्षिक औसत दुध दिने महिना <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.buffaloRearing?.advance
                          ?.annualAverageMilkingMonths != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "buffaloRearing.advance.annualAverageMilkingMonths"
                      )}
                      placeholder={"बार्षिक औसत दुध दिने महिना लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.buffaloRearing?.advance
                          ?.annualAverageMilkingMonths?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.buffaloRearing?.advance
                        ?.LitersOfMilkProductionPerDay != null
                    }
                  >
                    <FormLabel>
                      २.२.३. दैनिक दुध उत्पादन लिटर <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.buffaloRearing?.advance
                          ?.LitersOfMilkProductionPerDay != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "buffaloRearing.advance.LitersOfMilkProductionPerDay"
                      )}
                      placeholder={"दैनिक दुध उत्पादन लिटरमा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.buffaloRearing?.advance
                          ?.LitersOfMilkProductionPerDay?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ३. चौरी/याक पालन <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(2,1fr)",
                  base: "1fr",
                },
                gap: 2,
              }}
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ३.१. चौरी <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(3,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.yakRearing?.chauri?.count != null}
                  >
                    <FormLabel>
                      ३.१.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.yakRearing?.chauri?.count != null ? "error" : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("yakRearing.chauri.count")}
                      placeholder={"संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.yakRearing?.chauri?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.yakRearing?.chauri?.annualAverageMilkingMonths !=
                      null
                    }
                  >
                    <FormLabel>
                      ३.१.२. बार्षिक औसत दुध दिने महिना <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.yakRearing?.chauri
                          ?.annualAverageMilkingMonths != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "yakRearing.chauri.annualAverageMilkingMonths"
                      )}
                      placeholder={"बार्षिक औसत दुध दिने महिना लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.yakRearing?.chauri?.annualAverageMilkingMonths
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.yakRearing?.chauri
                        ?.LitersOfMilkProductionPerDay != null
                    }
                  >
                    <FormLabel>
                      ३.१.३. दैनिक दुध उत्पादन लिटर <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.yakRearing?.chauri
                          ?.LitersOfMilkProductionPerDay != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "yakRearing.chauri.LitersOfMilkProductionPerDay"
                      )}
                      placeholder={"दैनिक दुध उत्पादन लिटरमा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.yakRearing?.chauri?.LitersOfMilkProductionPerDay
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ३.२. याक <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(3,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.yakRearing?.yak?.count != null}
                  >
                    <FormLabel>
                      ३.२.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.yakRearing?.yak?.count != null ? "error" : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("yakRearing.yak.count")}
                      placeholder={"संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.yakRearing?.yak?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.yakRearing?.yak?.annualAverageMilkingMonths !=
                      null
                    }
                  >
                    <FormLabel>
                      ३.२.२. बार्षिक औसत दुध दिने महिना <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.yakRearing?.yak?.annualAverageMilkingMonths !=
                        null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("yakRearing.yak.annualAverageMilkingMonths")}
                      placeholder={"बार्षिक औसत दुध दिने महिना लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.yakRearing?.yak?.annualAverageMilkingMonths
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.yakRearing?.yak?.LitersOfMilkProductionPerDay !=
                      null
                    }
                  >
                    <FormLabel>
                      ३.२.३. दैनिक दुध उत्पादन लिटर <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.yakRearing?.yak?.LitersOfMilkProductionPerDay !=
                        null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "yakRearing.yak.LitersOfMilkProductionPerDay"
                      )}
                      placeholder={"दैनिक दुध उत्पादन लिटरमा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.yakRearing?.yak?.LitersOfMilkProductionPerDay
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ४. गोरु / रांगा पालन <RequireSign />
            </FormLabel>
            <Box
              p="4"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(6,1fr)",
                  md: "repeat(3,1fr)",
                  base: "1fr",
                },
                gap: 4,
              }}
            >
              <Box>
                <FormLabel>
                  ४.१. गोरु <RequireSign />
                </FormLabel>
                <Box>
                  <FormControl
                    isInvalid={errors?.bullOrQueue?.bull?.count != null}
                  >
                    <Input
                      size="sm"
                      variant={
                        errors?.bullOrQueue?.bull?.count != null ? "error" : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("bullOrQueue.bull.count")}
                      placeholder={"गोरुको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.bullOrQueue?.bull?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box>
                <FormLabel>
                  ४.२. रांगा <RequireSign />
                </FormLabel>
                <Box>
                  <FormControl
                    isInvalid={errors?.bullOrQueue?.queue?.count != null}
                  >
                    <Input
                      size="sm"
                      variant={
                        errors?.bullOrQueue?.queue?.count != null ? "error" : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("bullOrQueue.queue.count")}
                      placeholder={"रांगाको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.bullOrQueue?.queue?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ५. भेडा / बाख्रा / च्या्ङ्ग्रा पालन <RequireSign />
            </FormLabel>
            <Box
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(3,1fr)",
                  base: "1fr",
                },
                gap: 2,
              }}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ५.१. भेडा <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      lg: "repeat(2,1fr)",
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.sheep?.ewe != null
                    }
                  >
                    <FormLabel>
                      ५.१.१. माउको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.sheep?.ewe != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("sheepGoatMountainGoat.sheep.ewe")}
                      placeholder={"माउको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.sheepGoatMountainGoat?.sheep?.ewe?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.sheep?.ram != null
                    }
                  >
                    <FormLabel>
                      ५.१.२. खसिको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.sheep?.ram != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("sheepGoatMountainGoat.sheep.ram")}
                      placeholder={"खसिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.sheepGoatMountainGoat?.sheep?.ram?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.sheep?.castratedSheep !=
                      null
                    }
                  >
                    <FormLabel>
                      ५.१.३. बोकाको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.sheep?.castratedSheep !=
                        null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "sheepGoatMountainGoat.sheep.castratedSheep"
                      )}
                      placeholder={"बोकाको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.sheepGoatMountainGoat?.sheep?.castratedSheep
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.sheep?.lamp != null
                    }
                  >
                    <FormLabel>
                      ५.१.४. पठा/पाठि संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.sheep?.lamp != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("sheepGoatMountainGoat.sheep.lamp")}
                      placeholder={"पठा/पाठिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.sheepGoatMountainGoat?.sheep?.lamp?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ५.२. बाख्रा <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      lg: "repeat(2,1fr)",
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.goat?.sheGoat != null
                    }
                  >
                    <FormLabel>
                      ५.२.१. माउको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.goat?.sheGoat != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("sheepGoatMountainGoat.goat.sheGoat")}
                      placeholder={"माउको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.sheepGoatMountainGoat?.goat?.sheGoat?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.goat?.goat != null
                    }
                  >
                    <FormLabel>
                      ५.२.२. खसिको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.goat?.goat != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("sheepGoatMountainGoat.goat.goat")}
                      placeholder={"खसिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.sheepGoatMountainGoat?.goat?.goat?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.goat?.billyGoat != null
                    }
                  >
                    <FormLabel>
                      ५.२.३. बोकाको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.goat?.billyGoat != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("sheepGoatMountainGoat.goat.billyGoat")}
                      placeholder={"बोकाको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.sheepGoatMountainGoat?.goat?.billyGoat?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.sheepGoatMountainGoat?.goat?.kid != null}
                  >
                    <FormLabel>
                      ५.२.४. पठा/पाठि संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.goat?.kid != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("sheepGoatMountainGoat.goat.kid")}
                      placeholder={"पठा/पाठिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.sheepGoatMountainGoat?.goat?.kid?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ५.३. च्या्ङ्ग्रा <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      lg: "repeat(2,1fr)",
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.mountainGoat?.sheGoat !=
                      null
                    }
                  >
                    <FormLabel>
                      ५.३.१. माउको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.mountainGoat?.sheGoat !=
                        null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register(
                        "sheepGoatMountainGoat.mountainGoat.sheGoat"
                      )}
                      placeholder={"माउको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.sheepGoatMountainGoat?.mountainGoat?.sheGoat
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.mountainGoat?.goat != null
                    }
                  >
                    <FormLabel>
                      ५.३.२. खसिको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.mountainGoat?.goat !=
                        null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("sheepGoatMountainGoat.mountainGoat.goat")}
                      placeholder={"खसिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.sheepGoatMountainGoat?.mountainGoat?.goat
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.mountainGoat
                        ?.castratedGoat != null
                    }
                  >
                    <FormLabel>
                      ५.३.३. बोकाको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.mountainGoat
                          ?.castratedGoat != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register(
                        "sheepGoatMountainGoat.mountainGoat.castratedGoat"
                      )}
                      placeholder={"बोकाको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.sheepGoatMountainGoat?.mountainGoat
                          ?.castratedGoat?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.sheepGoatMountainGoat?.mountainGoat?.kid != null
                    }
                  >
                    <FormLabel>
                      ५.३.४. पठा/पाठि संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.sheepGoatMountainGoat?.mountainGoat?.kid != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("sheepGoatMountainGoat.mountainGoat.kid")}
                      placeholder={"पठा/पाठिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.sheepGoatMountainGoat?.mountainGoat?.kid
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ६. बङ्गुर / सुङ्गुर / बंदेल पालन <RequireSign />
            </FormLabel>
            <Box
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(3,1fr)",
                  base: "1fr",
                },
                gap: 2,
              }}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ६.१. बङ्गुर <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      lg: "repeat(2,1fr)",
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.boar?.sow != null}
                  >
                    <FormLabel>
                      ६.१.१. माउको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.boar?.sow != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.boar.sow")}
                      placeholder={"माउको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.boar?.sow?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.boar?.boar != null}
                  >
                    <FormLabel>
                      ६.१.२. भालेको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.boar?.boar != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.boar.boar")}
                      placeholder={"खसिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.boar?.boar?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.boar?.piglet != null}
                  >
                    <FormLabel>
                      ६.१.३. पठा/पाठि संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.boar?.piglet != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.boar.piglet")}
                      placeholder={"पठा/पाठिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.boar?.piglet?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ६.२. सुङ्गुर <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      lg: "repeat(2,1fr)",
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.pig?.sow != null}
                  >
                    <FormLabel>
                      ६.२.१. माउको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.pig?.sow != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.pig.sow")}
                      placeholder={"माउको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.pig?.sow?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.pig?.boar != null}
                  >
                    <FormLabel>
                      ६.२.२. भालेको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.pig?.boar != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.pig.boar")}
                      placeholder={"खसिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.pig?.boar?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.pig?.piglet != null}
                  >
                    <FormLabel>
                      ६.२.३. पठा/पाठि संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.pig?.piglet != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.pig.piglet")}
                      placeholder={"पठा/पाठिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.pig?.piglet?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ६.३. बंदेल <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      lg: "repeat(2,1fr)",
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.wildBoar?.sow != null}
                  >
                    <FormLabel>
                      ६.३.१. माउको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.wildBoar?.sow != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.wildBoar.sow")}
                      placeholder={"माउको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.wildBoar?.sow?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.boarPigWildBoar?.wildBoar?.boar != null}
                  >
                    <FormLabel>
                      ६.३.२. भालेको संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.wildBoar?.boar != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.wildBoar.boar")}
                      placeholder={"खसिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.wildBoar?.boar?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.boarPigWildBoar?.wildBoar?.piglet != null
                    }
                  >
                    <FormLabel>
                      ६.३.३. पठा/पाठि संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.boarPigWildBoar?.wildBoar?.piglet != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("boarPigWildBoar.wildBoar.piglet")}
                      placeholder={"पठा/पाठिको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.boarPigWildBoar?.wildBoar?.piglet?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ७. कुखुरापालन <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(2,1fr)",
                  base: "1fr",
                },
                gap: 2,
              }}
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ७.१. लेयर्स कुखुरा <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.poultryFarming?.local?.isFarming != null}
                  >
                    <FormLabel>
                      ७.१.१. पालन गरिएको छ ? <RequireSign />
                    </FormLabel>
                    <RadioGroup>
                      <Radio
                        {...register("poultryFarming.local.isFarming")}
                        value={"YES"}
                      >
                        छ &nbsp; &nbsp;
                      </Radio>
                      <Radio
                        {...register("poultryFarming.local.isFarming")}
                        value={"NO"}
                      >
                        छैन
                      </Radio>
                    </RadioGroup>
                    <FormErrorMessage>
                      {errors?.poultryFarming?.local?.isFarming?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.poultryFarming?.local?.count != null}
                  >
                    <FormLabel>
                      ७.१.२. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.poultryFarming?.local?.count != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("poultryFarming.local.count")}
                      placeholder={"कुखुराको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.poultryFarming?.local?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.poultryFarming?.local
                        ?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ७.१.३. वार्षिक औषत अण्डा उत्पादन संख्या
                      <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.poultryFarming?.local
                          ?.annualAverageEggProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register(
                        "poultryFarming.local.annualAverageEggProduction"
                      )}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.poultryFarming?.local
                          ?.annualAverageEggProduction?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.poultryFarming?.local?.annualAverageProduction !=
                      null
                    }
                  >
                    <FormLabel>
                      ७.१.४. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.poultryFarming?.local
                          ?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register(
                        "poultryFarming.local.annualAverageProduction"
                      )}
                      placeholder={
                        "वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {
                        errors?.poultryFarming?.local?.annualAverageProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ७.२. ब्रोईलर कुखुरा <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "1fr",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={
                      errors?.poultryFarming?.boiler?.isFarming != null
                    }
                  >
                    <FormLabel>
                      ७.२.१. पालन गरिएको छ ? <RequireSign />
                    </FormLabel>
                    <RadioGroup>
                      <Radio
                        {...register("poultryFarming.boiler.isFarming")}
                        value={"YES"}
                      >
                        छ &nbsp; &nbsp;
                      </Radio>
                      <Radio
                        {...register("poultryFarming.boiler.isFarming")}
                        value={"NO"}
                      >
                        छैन
                      </Radio>
                    </RadioGroup>
                    <FormErrorMessage>
                      {errors?.poultryFarming?.boiler?.isFarming?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.poultryFarming?.boiler?.count != null}
                  >
                    <FormLabel>
                      ७.२.२. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.poultryFarming?.boiler?.count != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("poultryFarming.boiler.count")}
                      placeholder={"कुखुराको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.poultryFarming?.boiler?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.poultryFarming?.boiler
                        ?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ७.२.३. वार्षिक औषत अण्डा उत्पादन संख्या
                      <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.poultryFarming?.boiler
                          ?.annualAverageEggProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register(
                        "poultryFarming.boiler.annualAverageEggProduction"
                      )}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.poultryFarming?.boiler
                          ?.annualAverageEggProduction?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.poultryFarming?.boiler?.annualAverageProduction !=
                      null
                    }
                  >
                    <FormLabel>
                      ७.२.४. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.poultryFarming?.boiler
                          ?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register(
                        "poultryFarming.boiler.annualAverageProduction"
                      )}
                      placeholder={
                        "वार्षिक औषत कुखुरा  उत्पादन संख्या लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {
                        errors?.poultryFarming?.boiler?.annualAverageProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ८. अन्य पन्छीहरु <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(2,1fr)",
                  base: "repeat(1,1fr)",
                },
                gap: 2,
              }}
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.१. हांस <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={errors?.birds?.duck?.count != null}>
                    <FormLabel>
                      ८.१.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.duck?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.duck.count")}
                      placeholder={"हांसको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.duck?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.duck?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.१.२. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.duck?.annualAverageEggProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.duck.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.duck?.annualAverageEggProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.duck?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.१.३. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.duck?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.duck.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.duck?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.२. बट्टाई <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={errors?.birds?.battai?.count != null}>
                    <FormLabel>
                      ८.२.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.battai?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.battai.count")}
                      placeholder={"बट्टाईको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.battai?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.battai?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.२.२. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.battai?.annualAverageEggProduction !=
                        null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.battai.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.birds?.battai?.annualAverageEggProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.battai?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.२.३. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.battai?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.battai.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.battai?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.३. अस्ट्रिच <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.birds?.ostrich?.count != null}
                  >
                    <FormLabel>
                      ८.३.२. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.ostrich?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.ostrich.count")}
                      placeholder={"अस्ट्रिचको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.ostrich?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.ostrich?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.३.१. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.ostrich?.annualAverageEggProduction !=
                        null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.ostrich.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.birds?.ostrich?.annualAverageEggProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.ostrich?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.३.३. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.ostrich?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.ostrich.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.ostrich?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.४. कालिज <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={errors?.birds?.kalij?.count != null}>
                    <FormLabel>
                      ८.४.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.kalij?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.kalij.count")}
                      placeholder={"कालिजको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.kalij?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.kalij?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.४.२. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.kalij?.annualAverageEggProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.kalij.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.birds?.kalij?.annualAverageEggProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.kalij?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.४.३. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.kalij?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.kalij.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.kalij?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.५. टर्की <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={errors?.birds?.turkey?.count != null}>
                    <FormLabel>
                      ८.५.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.turkey?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.turkey.count")}
                      placeholder={"टर्कीको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.turkey?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.turkey?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.५.२. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.turkey?.annualAverageEggProduction !=
                        null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.turkey.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.birds?.turkey?.annualAverageEggProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.turkey?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.५.३. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.turkey?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.turkey.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.turkey?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.६. लौकाट <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={errors?.birds?.laukat?.count != null}>
                    <FormLabel>
                      ८.६.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.laukat?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.laukat.count")}
                      placeholder={"लौकाटको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.laukat?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.laukat?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.६.२. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.laukat?.annualAverageEggProduction !=
                        null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.laukat.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.birds?.laukat?.annualAverageEggProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.laukat?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.६.२. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.laukat?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.laukat.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.laukat?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.७. परेवा <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={errors?.birds?.parrot?.count != null}>
                    <FormLabel>
                      ८.७.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.parrot?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.parrot.count")}
                      placeholder={"परेवाको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.parrot?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.parrot?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.७.२. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.parrot?.annualAverageEggProduction !=
                        null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.parrot.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.birds?.parrot?.annualAverageEggProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.parrot?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.७.३. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.parrot?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.parrot.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.parrot?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ८.८. अन्य <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl isInvalid={errors?.birds?.others?.count != null}>
                    <FormLabel>
                      ८.८.१. संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.others?.count != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.others.count")}
                      placeholder={"अन्यको संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.birds?.others?.count?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.others?.annualAverageEggProduction != null
                    }
                  >
                    <FormLabel>
                      ८.८.२. वार्षिक औषत अण्डा उत्पादन संख्या <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.others?.annualAverageEggProduction !=
                        null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.others.annualAverageEggProduction")}
                      placeholder={"वार्षिक औषत अण्डा उत्पादन संख्या लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {
                        errors?.birds?.others?.annualAverageEggProduction
                          ?.message
                      }
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.birds?.others?.annualAverageProduction != null
                    }
                  >
                    <FormLabel>
                      ८.८.३. वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि
                      प्रायोजन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.birds?.others?.annualAverageProduction != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("birds.others.annualAverageProduction")}
                      placeholder={
                        "वार्षिक औषत कुखुरा उत्पादन संख्या (ब्रिक्रि प्रायोजन) लेख्नुहोस"
                      }
                    />
                    <FormErrorMessage>
                      {errors?.birds?.others?.annualAverageProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ९. मत्स्यपालन <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(6,1fr)",
                  md: "repeat(3,1fr)",
                  base: "repeat(1,1fr)",
                },
                gap: 4,
              }}
              p="4"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <FormControl
                isInvalid={errors?.fisheries?.breed != null}
                gridColumn={{ md: "span 3", base: "span 1" }}
              >
                <FormLabel>
                  ९.१. प्रजाति <RequireSign />
                </FormLabel>
                <CheckboxGroup colorScheme="green">
                  <Box display={"flex"} flexWrap={"wrap"} gap="4">
                    {Object.values(FisheriesBreed).map((breed) => (
                      <Checkbox
                        key={breed}
                        {...register("fisheries.breed")}
                        value={breed}
                      >
                        {breed}
                      </Checkbox>
                    ))}
                  </Box>
                </CheckboxGroup>
                <FormErrorMessage>
                  {errors?.fisheries?.breed?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.fisheries?.area != null}>
                <FormLabel>
                  ९.२. पोखरीको क्षेत्रफल <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={errors?.fisheries?.area != null ? "error" : ""}
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("fisheries.area")}
                  placeholder={"पोखरीको क्षेत्रफल लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.fisheries?.area?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.fisheries?.count != null}>
                <FormLabel>
                  ९.३. संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={errors?.fisheries?.count != null ? "error" : ""}
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("fisheries.count")}
                  placeholder={"संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.fisheries?.count?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.fisheries?.productionPurpose != null}
              >
                <FormLabel>
                  ९.४. उत्पादन प्रयोजन <RequireSign />
                </FormLabel>
                <Select
                  size="sm"
                  {...register(`fisheries.productionPurpose`)}
                  placeholder="उत्पादन प्रयोजन छान्नुहोस"
                >
                  <option value={"मासु"}>मासु</option>
                  <option value={"जिउंदो"}>जिउंदो</option>
                  <option value={"बिक्री"}>बिक्री</option>
                  <option value={"भुरा"}>भुरा</option>
                  <option value={"उत्पादन"}>उत्पादन</option>
                </Select>
                <FormErrorMessage>
                  {errors?.fisheries?.productionPurpose?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.fisheries?.productioUnit != null}>
                <FormLabel>
                  ९.५. उत्पादन एकाइ <RequireSign />
                </FormLabel>
                <Select
                  size="sm"
                  {...register(`fisheries.productioUnit`)}
                  placeholder="उत्पादन एकाइ छान्नुहोस"
                >
                  <option value={"केजि"}>के.जि</option>
                  <option value={"क्विन्टल"}>क्विन्टल</option>
                  <option value={"संख्या"}>संख्या</option>
                </Select>
                <FormErrorMessage>
                  {errors?.fisheries?.productioUnit?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.fisheries?.productionQuantity != null}
              >
                <FormLabel>
                  ९.६. उत्पादन परिमाण (के.जि) मा <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.fisheries?.productionQuantity != null ? "error" : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("fisheries.productionQuantity")}
                  placeholder={"उत्पादन परिमाण (के.जि) मा लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.fisheries?.productionQuantity?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              १०. अन्य पशुपालन <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(6,1fr)",
                  md: "repeat(3,1fr)",
                  base: "repeat(1,1fr)",
                },
                gap: 4,
              }}
              p="4"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <FormControl
                isInvalid={errors?.othersAnimalHusbandry?.horse != null}
              >
                <FormLabel>
                  १०.१. घोडाको संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.othersAnimalHusbandry?.horse != null ? "error" : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("othersAnimalHusbandry.horse")}
                  placeholder={"घोडाको संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.othersAnimalHusbandry?.horse?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.othersAnimalHusbandry?.khachhad != null}
              >
                <FormLabel>
                  १०.२. खच्चडको संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.othersAnimalHusbandry?.khachhad != null
                      ? "error"
                      : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("othersAnimalHusbandry.khachhad")}
                  placeholder={"खच्चडको संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.othersAnimalHusbandry?.khachhad?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.othersAnimalHusbandry?.donkey != null}
              >
                <FormLabel>
                  १०.३. गधाको संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.othersAnimalHusbandry?.donkey != null ? "error" : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("othersAnimalHusbandry.donkey")}
                  placeholder={"गधाको संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.othersAnimalHusbandry?.donkey?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.othersAnimalHusbandry?.rabbit != null}
              >
                <FormLabel>
                  १०.४. खरायोको संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.othersAnimalHusbandry?.rabbit != null ? "error" : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("othersAnimalHusbandry.rabbit")}
                  placeholder={"खरायोको संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.othersAnimalHusbandry?.rabbit?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.othersAnimalHusbandry?.dog != null}
              >
                <FormLabel>
                  १०.५. कुकुरको संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.othersAnimalHusbandry?.dog != null ? "error" : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("othersAnimalHusbandry.dog")}
                  placeholder={"कुकुरको संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.othersAnimalHusbandry?.dog?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.othersAnimalHusbandry?.cat != null}
              >
                <FormLabel>
                  १०.५. बिरालोको संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.othersAnimalHusbandry?.cat != null ? "error" : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("othersAnimalHusbandry.cat")}
                  placeholder={"बिरालोको संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.othersAnimalHusbandry?.cat?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.othersAnimalHusbandry?.others != null}
              >
                <FormLabel>
                  १०.६. अन्य <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.othersAnimalHusbandry?.others != null ? "error" : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("othersAnimalHusbandry.others")}
                  placeholder={"अन्यको संख्या लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.othersAnimalHusbandry?.others?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              ११. हुउंदे बर्षे बहुवर्षे घांसेबालीको विवरण <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(3,1fr)",
                  base: "repeat(1,1fr)",
                },
                gap: 2,
              }}
              p="2"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ११.१. हिउंदे <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.grassCrops?.cold?.breed != null}
                  >
                    <FormLabel>
                      ११.१.१. जात <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.cold?.breed != null ? "error" : ""
                      }
                      type="text"
                      {...register("grassCrops.cold.breed")}
                      placeholder={"जात लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.cold?.breed?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.grassCrops?.cold?.area != null}
                  >
                    <FormLabel>
                      ११.१.२. क्षेत्रफल <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.cold?.area != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("grassCrops.cold.area")}
                      placeholder={"क्षेत्रफल लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.cold?.area?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.cold?.productionQuantity != null
                    }
                  >
                    <FormLabel>
                      ११.१.३. हरियो घांस उत्पादन (मे.टन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.cold?.productionQuantity != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.cold.productionQuantity")}
                      placeholder={"हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.cold?.productionQuantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.grassCrops?.cold?.seedProduction != null}
                  >
                    <FormLabel>
                      ११.१.४. बिउ उत्पादन <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.cold?.seedProduction != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.cold.seedProduction")}
                      placeholder={"बिउ उत्पादन लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.cold?.seedProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ११.२. बर्षे <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.grassCrops?.yearly?.breed != null}
                  >
                    <FormLabel>
                      ११.२.१. जात <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.yearly?.breed != null ? "error" : ""
                      }
                      type="text"
                      {...register("grassCrops.yearly.breed")}
                      placeholder={"जात लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.yearly?.breed?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.grassCrops?.yearly?.area != null}
                  >
                    <FormLabel>
                      ११.२.२. क्षेत्रफल <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.yearly?.area != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("grassCrops.yearly.area")}
                      placeholder={"क्षेत्रफल लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.yearly?.area?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.yearly?.productionQuantity != null
                    }
                  >
                    <FormLabel>
                      ११.२.३. हरियो घांस उत्पादन (मे.टन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.yearly?.productionQuantity != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.yearly.productionQuantity")}
                      placeholder={"हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.yearly?.productionQuantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.yearly?.seedProduction != null
                    }
                  >
                    <FormLabel>
                      ११.२.४. बिउ उत्पादन <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.yearly?.seedProduction != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.yearly.seedProduction")}
                      placeholder={"बिउ उत्पादन लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.yearly?.seedProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ११.३. बहु बर्षे <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.grassCrops?.allTime?.breed != null}
                  >
                    <FormLabel>
                      ११.३.१. जात <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.allTime?.breed != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.allTime.breed")}
                      placeholder={"जात लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.allTime?.breed?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.grassCrops?.allTime?.area != null}
                  >
                    <FormLabel>
                      ११.३.२. क्षेत्रफल <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.allTime?.area != null ? "error" : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("grassCrops.allTime.area")}
                      placeholder={"क्षेत्रफल लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.allTime?.area?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.allTime?.productionQuantity != null
                    }
                  >
                    <FormLabel>
                      ११.३.३. हरियो घांस उत्पादन (मे.टन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.allTime?.productionQuantity != null
                          ? "error"
                          : ""
                      }
                      onKeyPress={handleDigitKeyPress}
                      type="number"
                      {...register("grassCrops.allTime.productionQuantity")}
                      placeholder={"हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.allTime?.productionQuantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.allTime?.seedProduction != null
                    }
                  >
                    <FormLabel>
                      ११.३.४. बिउ उत्पादन <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.allTime?.seedProduction != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.allTime.seedProduction")}
                      placeholder={"बिउ उत्पादन लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.allTime?.seedProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ११.४. डाले घांस <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.grassCrops?.grass?.breed != null}
                  >
                    <FormLabel>
                      ११.४.१. जात <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.grass?.breed != null ? "error" : ""
                      }
                      type="text"
                      {...register("grassCrops.grass.breed")}
                      placeholder={"जात लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.grass?.breed?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.grassCrops?.grass?.area != null}
                  >
                    <FormLabel>
                      ११.४.२. क्षेत्रफल <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.grass?.area != null ? "error" : ""
                      }
                      type="text"
                      {...register("grassCrops.grass.area")}
                      placeholder={"क्षेत्रफल लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.grass?.area?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.grass?.productionQuantity != null
                    }
                  >
                    <FormLabel>
                      ११.४.३. हरियो घांस उत्पादन (मे.टन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.grass?.productionQuantity != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("grassCrops.grass.productionQuantity")}
                      placeholder={"हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.grass?.productionQuantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.grass?.seedProduction != null
                    }
                  >
                    <FormLabel>
                      ११.४.४. बिउ उत्पादन <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.grass?.seedProduction != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.grass.seedProduction")}
                      placeholder={"बिउ उत्पादन लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.grass?.seedProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
              <Box
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
              >
                <FormLabel>
                  ११.५. नर्सरी <RequireSign />
                </FormLabel>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: {
                      md: "repeat(2,1fr)",
                      base: "repeat(1,1fr)",
                    },
                    gap: 4,
                  }}
                >
                  <FormControl
                    isInvalid={errors?.grassCrops?.nursery?.breed != null}
                  >
                    <FormLabel>
                      ११.५.१. जात <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.nursery?.breed != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.nursery.breed")}
                      placeholder={"जात लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.nursery?.breed?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={errors?.grassCrops?.nursery?.area != null}
                  >
                    <FormLabel>
                      ११.५.२. क्षेत्रफल <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.nursery?.area != null ? "error" : ""
                      }
                      type="text"
                      {...register("grassCrops.nursery.area")}
                      placeholder={"क्षेत्रफल लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.nursery?.area?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.nursery?.productionQuantity != null
                    }
                  >
                    <FormLabel>
                      ११.५.३. हरियो घांस उत्पादन (मे.टन) <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.nursery?.productionQuantity != null
                          ? "error"
                          : ""
                      }
                      type="number"
                      onKeyPress={handleDigitKeyPress}
                      {...register("grassCrops.nursery.productionQuantity")}
                      placeholder={"हरियो घांस उत्पादन (मे.टन) मा लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.nursery?.productionQuantity?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      errors?.grassCrops?.nursery?.seedProduction != null
                    }
                  >
                    <FormLabel>
                      ११.५.४. बिउ उत्पादन <RequireSign />
                    </FormLabel>
                    <Input
                      size="sm"
                      variant={
                        errors?.grassCrops?.nursery?.seedProduction != null
                          ? "error"
                          : ""
                      }
                      type="text"
                      {...register("grassCrops.nursery.seedProduction")}
                      placeholder={"बिउ उत्पादन लेख्नुहोस"}
                    />
                    <FormErrorMessage>
                      {errors?.grassCrops?.nursery?.seedProduction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              १२. कृषि व्यावसाय / फर्म / उधम विवरण <RequireSign />
            </FormLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  lg: "repeat(6,1fr)",
                  md: "repeat(3,1fr)",
                  base: "repeat(1,1fr)",
                },
                gap: 4,
              }}
              p="4"
              border="1px"
              borderColor="gray.200"
              borderRadius={"2px"}
            >
              <FormControl
                isInvalid={errors?.industryDetails?.registrationNumber != null}
              >
                <FormLabel>
                  १२.१. व्यावसाय / फर्म / उधम दर्ता नम्बर <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.industryDetails?.registrationNumber != null
                      ? "error"
                      : ""
                  }
                  type="text"
                  {...register("industryDetails.registrationNumber")}
                  placeholder={"दर्ता नम्बर लेख्नुहोस"}
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.registrationNumber?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.industryDetails?.registrationDate != null}
              >
                <FormLabel>
                  १२.२. दर्ता मिति <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.industryDetails?.registrationDate != null
                      ? "error"
                      : ""
                  }
                  type="date"
                  {...register("industryDetails.registrationDate")}
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.registrationDate?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.industryDetails?.name != null}>
                <FormLabel>
                  १२.३. व्यावसाय / फर्म / उधमको नाम <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={errors?.industryDetails?.name != null ? "error" : ""}
                  type="text"
                  {...register("industryDetails.name")}
                  placeholder="व्यावसाय / फर्म / उधमको नाम लेख्नुहोस"
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.name?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.industryDetails?.registeredOffice != null}
              >
                <FormLabel>
                  १२.४. दर्ता भएको कार्यालय <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.industryDetails?.registeredOffice != null
                      ? "error"
                      : ""
                  }
                  type="text"
                  {...register("industryDetails.registeredOffice")}
                  placeholder="दर्ता भएको कार्यालय लेख्नुहोस"
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.registeredOffice?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.industryDetails?.panVatNumber != null}
              >
                <FormLabel>
                  १२.५. प्यान / भ्याट नं. <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.industryDetails?.panVatNumber != null ? "error" : ""
                  }
                  type="text"
                  {...register("industryDetails.panVatNumber")}
                  placeholder="प्यान / भ्याट नं. लेख्नुहोस"
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.panVatNumber?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.industryDetails?.chairmanName != null}
              >
                <FormLabel>
                  १२.६. अध्यक्ष/संचालकको नाम <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.industryDetails?.chairmanName != null ? "error" : ""
                  }
                  type="text"
                  {...register("industryDetails.chairmanName")}
                  placeholder="अध्यक्ष/संचालकको नाम लेख्नुहोस"
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.chairmanName?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors?.industryDetails?.type != null}>
                <FormLabel>
                  १२.७. किसिम <RequireSign />
                </FormLabel>
                <Select
                  size="sm"
                  {...register(`industryDetails.type`)}
                  placeholder="किसिम छान्नुहोस"
                >
                  <option value={"कृषि"}>कृषि</option>
                  <option value={"पशुपालन"}>पशुपालन</option>
                  <option value={"मिश्रित"}>मिश्रित</option> s
                </Select>
                {/* <FormErrorMessage>{errors?.industryDetails?.type?.message as string}</FormErrorMessage> */}
              </FormControl>
              <FormControl
                isInvalid={errors?.industryDetails?.memberCount != null}
              >
                <FormLabel>
                  १२.८. सदस्य संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.industryDetails?.memberCount != null ? "error" : ""
                  }
                  type="number"
                  onKeyPress={handleDigitKeyPress}
                  {...register("industryDetails.memberCount")}
                  placeholder="सदस्य संख्या लेख्नुहोस"
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.memberCount?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={errors?.industryDetails?.annualJobCount != null}
              >
                <FormLabel>
                  १२.९. बार्षिक रोजगारी सृजना संख्या <RequireSign />
                </FormLabel>
                <Input
                  size="sm"
                  variant={
                    errors?.industryDetails?.annualJobCount != null
                      ? "error"
                      : ""
                  }
                  onKeyPress={handleDigitKeyPress}
                  type="number"
                  {...register("industryDetails.annualJobCount")}
                  placeholder="बार्षिक रोजगारी सृजना संख्या लेख्नुहोस"
                />
                <FormErrorMessage>
                  {errors?.industryDetails?.annualJobCount?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              १३. कृषि / पशूपन्छी / मत्स्यपालन संग सम्बन्धित मेशिनरी प्रयोग
              विवरण <RequireSign />
            </FormLabel>
            <Box p="2" border="1px" borderColor="gray.200" borderRadius={"2px"}>
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "50px",
                      }}
                    >
                      क्र.स.
                    </th>
                    <th>मेसिनरीको नाम</th>
                    <th>स्वामित्व</th>
                    <th>क्षमता</th>
                    <th>संख्या</th>
                    <th
                      style={{
                        width: "150px",
                      }}
                    >
                      कार्य
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {machineryDetails &&
                    machineryDetails?.length > 0 &&
                    machineryDetails?.map((i, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{i.name}</td>
                          <td>{i.ownership}</td>
                          <td>{i.capacity} </td>
                          <td>{i.count} </td>
                          <td>
                            <Flex gap="4">
                              <IconButton
                                colorScheme="telegram"
                                variant="outline"
                                size="xs"
                                aria-label="edit"
                                icon={<BiEdit />}
                                onClick={() => {
                                  editMachineryDetails(index);
                                }}
                              />
                              <IconButton
                                colorScheme="red"
                                variant="outline"
                                size="xs"
                                aria-label="delete"
                                icon={<BsTrash />}
                                onClick={() => {
                                  removeMachineryDetails(index);
                                }}
                              />
                            </Flex>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Box
                mt="2"
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    lg: "repeat(5,1fr)",
                    md: "repeat(3,1fr)",
                    base: "1fr",
                  },
                  gap: 2,
                }}
              >
                <FormControl isInvalid={errors?.machinery?.name != null}>
                  <FormLabel>
                    १३.१. मेसिनरीको नाम <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.machinery?.name != null ? "error" : ""}
                    type="text"
                    {...register("machinery.name")}
                    placeholder={"मेसिनरीको नाम लेख्नुहोस"}
                  />
                  <FormErrorMessage>
                    {errors?.machinery?.name?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors?.machinery?.ownership != null}>
                  <FormLabel>
                    १३.२. स्वामित्व <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={
                      errors?.machinery?.ownership != null ? "error" : ""
                    }
                    type="text"
                    {...register("machinery.ownership")}
                    placeholder={"स्वामित्व लेख्नुहोस"}
                  />
                  <FormErrorMessage>
                    {errors?.machinery?.ownership?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors?.machinery?.capacity != null}>
                  <FormLabel>
                    १३.३. क्षमता <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.machinery?.capacity != null ? "error" : ""}
                    type="number"
                    onKeyPress={handleDigitKeyPress}
                    {...register("machinery.capacity")}
                    placeholder={"क्षमता लेख्नुहोस"}
                  />
                  <FormErrorMessage>
                    {errors?.machinery?.capacity?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors?.machinery?.count != null}>
                  <FormLabel>
                    १३.४. संख्या <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.machinery?.count != null ? "error" : ""}
                    type="number"
                    onKeyPress={handleDigitKeyPress}
                    {...register("machinery.count")}
                    placeholder={"संख्या लेख्नुहोस"}
                  />
                  <FormErrorMessage>
                    {errors?.machinery?.count?.message}
                  </FormErrorMessage>
                </FormControl>
                <Box mt="8">
                  <Button
                    size="sm"
                    variant={"outline"}
                    colorScheme="facebook"
                    onClick={async () => {
                      try {
                        await machinerySchema.validate(getValues(), {
                          abortEarly: false,
                        });
                        void addMachineryDetails();
                      } catch (validationErrors: any) {
                        if (validationErrors?.inner?.length) {
                          validationErrors.inner.forEach((error: any) => {
                            setError(error.path, {
                              type: "manual",
                              message: error.message,
                            });
                          });
                        } else {
                          setError(validationErrors.path, {
                            type: "manual",
                            message: validationErrors.message,
                          });
                        }
                      }
                    }}
                  >
                    सुरक्षित गर्नुहोस
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="5">
            <FormLabel>
              १४. कृषि / पशुपालन / मत्स्यपालनसंग सम्बन्धित संरचनाको विवरण{" "}
              <RequireSign />
            </FormLabel>
            <Box p="2" border="1px" borderColor="gray.200" borderRadius={"2px"}>
              <table>
                <thead>
                  <tr>
                    <th
                      style={{
                        width: "50px",
                      }}
                    >
                      क्र.स.
                    </th>
                    <th>संरचनाको प्रकार</th>
                    <th>संख्या</th>
                    <th>क्षेत्रफल</th>
                    <th
                      style={{
                        width: "150px",
                      }}
                    >
                      कार्य
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {buildingDetails &&
                    buildingDetails?.length > 0 &&
                    buildingDetails?.map((i, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{i.type}</td>
                          <td>{i.count}</td>
                          <td>{i.area} </td>
                          <td>
                            <Flex gap="4">
                              <IconButton
                                colorScheme="telegram"
                                variant="outline"
                                size="xs"
                                aria-label="edit"
                                icon={<BiEdit />}
                                onClick={() => {
                                  editBuildingDetails(index);
                                }}
                              />
                              <IconButton
                                colorScheme="red"
                                variant="outline"
                                size="xs"
                                aria-label="delete"
                                icon={<BsTrash />}
                                onClick={() => {
                                  removeBuildingDetails(index);
                                }}
                              />
                            </Flex>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <Box
                mt="2"
                p="4"
                border="1px"
                borderColor="gray.200"
                borderRadius={"2px"}
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    lg: "repeat(5,1fr)",
                    md: "repeat(3,1fr)",
                    base: "1fr",
                  },
                  gap: 2,
                }}
              >
                <FormControl isInvalid={errors?.building?.type != null}>
                  <FormLabel>
                    १४.१. संरचनाको प्रकार <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.building?.type != null ? "error" : ""}
                    type="text"
                    {...register("building.type")}
                    placeholder={"संरचनाको प्रकार लेख्नुहोस"}
                  />
                  {/* <FormErrorMessage>{errors?.building?.type?.message}</FormErrorMessage> */}
                </FormControl>
                <FormControl isInvalid={errors?.building?.count != null}>
                  <FormLabel>
                    १४.२. संख्या <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.building?.count != null ? "error" : ""}
                    type="number"
                    {...register("building.count")}
                    placeholder={"संख्या लेख्नुहोस"}
                  />
                  <FormErrorMessage>
                    {errors?.building?.count?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors?.building?.area != null}>
                  <FormLabel>
                    १४.३. क्षेत्रफल <RequireSign />
                  </FormLabel>
                  <Input
                    size="sm"
                    variant={errors?.building?.area != null ? "error" : ""}
                    type="text"
                    {...register("building.area")}
                    placeholder={"क्षेत्रफल लेख्नुहोस"}
                  />
                  <FormErrorMessage>
                    {errors?.building?.area?.message}
                  </FormErrorMessage>
                </FormControl>

                <Box mt="8">
                  <Button
                    size="sm"
                    variant={"outline"}
                    colorScheme="facebook"
                    onClick={async () => {
                      try {
                        await buildingSchema.validate(getValues(), {
                          abortEarly: false,
                        });
                        void addBuildingDetails();
                      } catch (validationErrors: any) {
                        if (validationErrors?.inner?.length) {
                          validationErrors.inner.forEach((error: any) => {
                            setError(error.path, {
                              type: "manual",
                              message: error.message,
                            });
                          });
                        } else {
                          setError(validationErrors.path, {
                            type: "manual",
                            message: validationErrors.message,
                          });
                        }
                      }
                    }}
                  >
                    सुरक्षित गर्नुहोस
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "4",
            mt: "5",
            justifyContent: "flex-end",
          }}
        >
          <Button
            size="sm"
            variant="outline"
            type="button"
            onClick={() => {
              setStep(step - 1);
            }}
            colorScheme="telegram"
          >
            पछाडि फर्कनुहोस
          </Button>
          <Button
            size="sm"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            loadingText="Submitting"
          >
            {farmerId && selectedValue ? "अपडेट गर्नुहोस" : "सुरक्षित गर्नुहोस"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FarmerAnimalHusbandryInformationForm;
