import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Link,
  List,
  ListItem,
} from '@chakra-ui/react'
import EncryptDecrypt from '@functions/EncryptDecrypt'
import useAuth from '@hooks/useAuth'
import useLang from '@hooks/useLang'
import { Colors } from '@utils/Colors'
import { Link as ReactLink, useLocation } from 'react-router-dom'
interface IProps {
  minimize: boolean
  title: string
  link: string
  icon: any
  roleLevel?: string[] | string
  subMenu?: Array<{
    title: string
    link: string
    icon: any
  }>
}
const SidebarItem = (props: IProps) => {
  const location = useLocation().pathname
  const { lang } = useLang()
  const { title, minimize, link, icon, subMenu, roleLevel } = props
  const { user } = useAuth()

  const adminRoleLevel = user?.role

  return (
    <div>
      {roleLevel === undefined ||
        (roleLevel?.includes(adminRoleLevel) && (
          <Accordion allowMultiple width={'100%'} bg={location === link ? Colors?.dashboardHoverColor : ''}>
            <AccordionItem border={'0'} my='1'>
              <Link
                as={ReactLink}
                to={link}
                sx={{
                  '&:hover': {
                    textDecoration: 'none',
                    color: 'white',
                  },
                }}
              >
                <Box>
                  <AccordionButton
                    pb='2'
                    sx={{
                      '&:hover': {
                        background: Colors?.dashboardHoverColor,
                      },
                    }}
                    _expanded={{ bg: Colors?.dashboardHoverColor }}
                  >
                    <Flex
                      as='span'
                      flex='1'
                      textAlign='left'
                      alignItems='center'
                      gap='2'
                      color='white'
                      sx={{
                        svg: {
                          mb: '4px',
                        },
                      }}
                    >
                      {icon ?? <Icon />}
                      {!minimize && title?.[lang]}
                    </Flex>
                    {
                      // check if subMenu is present than only show the icon
                      !minimize && subMenu != null && subMenu?.length > 0 && <AccordionIcon color='white' />
                    }
                  </AccordionButton>
                </Box>
              </Link>
              {
                // check if subMenu is present than only show the subMenu
                subMenu != null && subMenu?.length > 0 && (
                  <AccordionPanel
                    pb={4}
                    color='white'
                    // check if path end same as link than change background
                  >
                    <List display='flex' flexDirection='column' gap='4'>
                      {subMenu?.length > 0 &&
                        subMenu.map((item: any, index: number) => {
                          return (
                            <Link
                              key={index}
                              as={ReactLink}
                              to={item?.link}
                              sx={{
                                '&:hover': {
                                  textDecoration: 'none',
                                  color: 'white',
                                },
                              }}
                            >
                              <ListItem
                                key={index}
                                display='flex'
                                alignItems='center'
                                gap='2'
                                ms={minimize ? 0 : 5}
                                sx={{
                                  '&:hover': {
                                    color: 'gray',
                                  },
                                  svg: {
                                    mb: '4px',
                                  },
                                }}
                                color={location?.endsWith(item?.link) ? 'gray' : ''}
                              >
                                {item?.icon ?? <Icon />}
                                {!minimize && item?.title?.[lang]}
                              </ListItem>
                            </Link>
                          )
                        })}
                    </List>
                  </AccordionPanel>
                )
              }
            </AccordionItem>
          </Accordion>
        ))}
    </div>
  )
}

export default SidebarItem
