type Label = Record<string, Record<string, string>>

const labels: Label = {
  carousel: {
    en: 'Carousel',
    ne: 'क्यारोसेल',
  },
  selectCoverImage: {
    en: 'Select cover image',
    ne: 'कवर तस्बिर छान्नुहोस्',
  },
  isPublished: {
    en: 'Is published',
    ne: 'प्रकाशित छ ?',
  },
}
export default labels
