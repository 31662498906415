import * as yup from 'yup'
import { NEPALI_REGEX } from '../../regex/index'

export const imageAlbumSchema = yup.object({
  title: yup.object().shape({
    en: yup.string().required('Title is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'शिर्षक देवानगरिमा लेख्नुहोस').required('शिर्षक आवश्यक छ ।'),
  }),
  status: yup.boolean().required().typeError('Status must be publish or unpublished'),
  description: yup.object().shape({
    en: yup.string().required('Description is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'विवरण  देवानगरिमा लेख्नुहोस').required('विवरण आवश्यक छ ।'),
  }),
})

export const imageGallerySchema = yup.object({
  title: yup.object().shape({
    en: yup.string().required('Title is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'शिर्षक देवानगरिमा लेख्नुहोस').required('शिर्षक आवश्यक छ ।'),
  }),
  status: yup.boolean().required().typeError('Status must be publish or unpublished'),
  album: yup.string().required('Please select playlist').typeError('Please select playlist'),
  images: yup.array().of(yup.mixed()).min(1, 'Minimum 1 image is required'),
})
