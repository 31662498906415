const TABLE_LABELS = {
  profile: {
    en: "Profile",
    ne: "प्रोफाइल",
  },
  print: {
    en: "Print",
    ne: "प्रिन्ट गर्नुहोस्",
  },
  refresh: {
    en: "Refresh",
    ne: "रिफ्रेश गर्नुहोस्",
  },
  exportFile: {
    en: "Export",
    ne: "निर्यात गर्नुहोस्",
  },
  search: {
    en: "Search ...",
    ne: "खोज्नुहोस् ....",
  },
  totalData: {
    en: "Total Data",
    ne: "जम्मा डाटा",
  },
  first: {
    en: "First",
    ne: "पहिलो",
  },
  last: {
    en: "Last",
    ne: "अन्तिम",
  },
  previous: {
    en: "Previous",
    ne: "अघिल्लो",
  },
  next: {
    en: "Next",
    ne: "अर्को",
  },
  rowPerPage: {
    en: "Rows per page",
    ne: "प्रति पृष्ठ पङ्क्ति",
  },
  resetData: {
    en: "Reset ",
    ne: "डाटा रिसेट गर्नुहोस्",
  },
  edit: {
    en: "Edit",
    ne: "सम्पादन गर्नुहोस्",
  },
  gotoPage: {
    en: "Go to page",
    ne: "पृष्ठमा जानुहोस्",
  },
  sn: {
    en: "SN",
    ne: "क्र.सं.",
  },
  title: {
    en: "Title",
    ne: "शीर्षक",
  },
  titleEn: {
    en: "Title - english",
    ne: "शीर्षक - अंग्रेजी",
  },
  titleNp: {
    en: "Title - nepali",
    ne: "शीर्षक - नेपाली",
  },
  playlist: {
    en: "Playlist",
    ne: "प्लेलिस्ट",
  },
  description: {
    en: "Description",
    ne: "विवरण",
  },
  name_english: {
    en: "Name - english",
    ne: "नाम - अंग्रेजी",
  },
  name_nepali: {
    en: "Name - nepali",
    ne: "नाम - नेपाली",
  },
  action: {
    en: "Action",
    ne: "कार्य",
  },
  category: {
    en: "Category",
    ne: "श्रेणी",
  },
  agricultureType: {
    en: "Agriculture type",
    ne: "कृषि प्रकार",
  },
  addDetails: {
    en: "Add Details",
    ne: "विवरण थप्नुहोस्",
  },
  topic: {
    en: "Topic",
    ne: "शीर्षक",
  },
  farmerGroup_english: {
    en: "Farmer group - english",
    ne: "कृषक समूह - अंग्रेजी",
  },
  farmerGroup_nepali: {
    en: "Farmer group - nepali",
    ne: "कृषक समूह - नेपाली",
  },
  villageName: {
    en: "Farmer group village name",
    ne: "कृषक समुहको गाउँपालिकाको नाम",
  },
  groupWard: {
    en: "Group Ward no.",
    ne: "समूहको वार्ड नं.",
  },
  toleName: {
    en: "Tole Name",
    ne: "टोलको नाम",
  },
  member: {
    en: "Member",
    ne: "सदस्य",
  },
  male: {
    en: "Male",
    ne: "पुरुष",
  },
  female: {
    en: "Female",
    ne: "महिला",
  },
  others: {
    en: "Others",
    ne: "अन्य",
  },
  total: {
    en: "Total",
    ne: "जम्मा",
  },
  videoUrl: {
    en: "Video URL",
    ne: "भिडियो यूआरएल",
  },
  status: {
    en: "Status",
    ne: "स्थिति",
  },
  publishedAt: {
    en: "Published at",
    ne: "प्रकाशित मिति",
  },
  date: {
    en: "Date",
    ne: "मिति",
  },
  isPublished: {
    en: "Is Published",
    ne: "प्रकाशित छ ?",
  },
  yes: {
    en: "Yes",
    ne: "छ",
  },
  no: {
    en: "No",
    ne: "छैन",
  },
  farmerName: {
    en: "Farmer Name",
    ne: "कृषकको नाम",
  },
  citizenshipNumber: {
    en: "Citizenship Number",
    ne: "नागरिकता नं.",
  },
  gender: {
    en: "Gender",
    ne: "लिङ्ग",
  },
  wardNo: {
    en: "Ward No.",
    ne: "वार्ड नं.",
  },
  tollName: {
    en: "Toll Name",
    ne: "टोलको नाम",
  },
  album: {
    en: "Album",
    ne: "एल्बम",
  },
  addedBy: {
    en: "Added By",
    ne: "थपिएको",
  },
  members: {
    en: "Members",
    ne: "सदस्यहरु",
  },
  firstName: {
    en: "First Name",
    ne: "पहिलो नाम",
  },
  middleName: {
    en: "Middle Name",
    ne: "बीचको नाम",
  },
  lastName: {
    en: "Last Name",
    ne: "थर",
  },
  position: {
    en: "Position",
    ne: "पद",
  },
  educationQualification: {
    en: "Education Qualification",
    ne: "शैक्षिक योग्यता",
  },
  age: {
    en: "Age",
    ne: "उमेर",
  },
  farmArea: {
    en: "Farm Area",
    ne: "कृषि क्षेत्र",
  },
};
export default TABLE_LABELS as any;
