import { Box, Flex, IconButton, useToast } from '@chakra-ui/react'
import endpoint from '@config/endpoint/agriculture'
import agricultureLabel, { getMessage } from '@data/localization/agriculture'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import tableLabel from '@data/localization/table'
import tableSnGenerate from '@functions/tableSnGenerate'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { ISimpleTable } from '@interface/global.interface'
import { IResponse } from '@interface/response.interface'
import AgricultureType from '@models/agricultureType.model'
import { createColumnHelper } from '@tanstack/react-table'
import { IPagination } from '@type/pagination.types'
import ModalBox from '@ui/common/molecules/Modal'
import SimpleTable from '@ui/common/molecules/SimpleTable'
import Table from '@ui/common/organisms/Table'
import { useEffect, useState } from 'react'
import { BiEdit, BiShowAlt } from 'react-icons/bi'
import { BsTrash } from 'react-icons/bs'
import { useQueryClient } from 'react-query'

interface IProps {
  values: IResponse<AgricultureType[]> | null
  setValues: (data: IResponse<AgricultureType[]>) => void
  loading: boolean
  setLoading: (data: boolean) => void
  pagination: IPagination
  setPagination: (data: IPagination) => void
  selectedValue: AgricultureType | null
  setSelectedValue: (data: AgricultureType | null) => void
}

const Show = (props: IProps) => {
  const { values, pagination, setPagination, loading, setSelectedValue } = props
  const { remove } = useAPI()
  const toast = useToast()
  const [open, setOpen] = useState<boolean>(false)
  const [openShow, setOpenShow] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)
  const [id, setId] = useState<string | null>(null)
  const [selectedData, setSelectedData] = useState<ISimpleTable[] | null>(null)
  const [submit, setSubmitting] = useState<boolean>(false)
  const { lang } = useLang()
  const queryClient = useQueryClient()

  // function to delete
  const handleDelete = async () => {
    try {
      setSubmitting(true)
      const res = await remove(`${endpoint?.agricultureType}/${id}`)
      if (res === true) {
        await queryClient.invalidateQueries('agricultureType')
        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(notificationLabel?.agricultureType[lang], notificationLabel?.delete[lang], lang),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      setConfirm(false)
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (confirm) {
      void handleDelete()
      setConfirm(false)
    }
  }, [confirm])

  const columnHelper = createColumnHelper<any>()

  const columns = [
    columnHelper.accessor('SN', {
      header: () => <span>{tableLabel?.sn[lang]}</span>,
      size: 50,
      cell: (info: any) => {
        return <span>{tableSnGenerate(info.row.index, pagination?.currentPage, pagination?.perPage)}</span>
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>{tableLabel?.name_english[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.title?.en}</span>
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => <span>{tableLabel?.name_nepali[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return <span>{info?.row?.original?.title?.ne}</span>
      },
    }),
    columnHelper.accessor('category', {
      id: 'name',
      header: () => <span>{tableLabel?.category[lang]}</span>,
      size: 400,
      cell: (info: any) => {
        return (
          <span>
            {info?.row?.original?.category?.title?.en} {info?.row?.original?.category?.title?.en && '/'}{' '}
            {info?.row?.original?.category?.title?.ne}
          </span>
        )
      },
    }),

    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {tableLabel?.action[lang]}
        </span>
      ),
      size: 100,
      cell: (info: any) => {
        return (
          <Flex gap='3' justifyContent={'center'}>
            <IconButton
              colorScheme='green'
              variant='outline'
              aria-label='show'
              icon={<BiShowAlt />}
              onClick={() => {
                setOpenShow(true)
                console.log(info?.row?.original)
                setSelectedData([
                  {
                    title: 'Type name',
                    description: `${info?.row?.original?.title?.en} - ${info?.row?.original?.title?.ne}`,
                  },
                  {
                    title: 'Category name',
                    description: `${info?.row?.original?.category?.title?.en} - ${info?.row?.original?.category?.title?.ne}`,
                  },
                  {
                    title: 'Cover image',
                    type: 'image',
                    description: `${info?.row?.original?.media?.medias?.[0]?.name}`,
                  },
                ])
              }}
            />
            <IconButton
              colorScheme='telegram'
              variant='outline'
              aria-label='edit'
              icon={<BiEdit />}
              onClick={() => {
                setSelectedValue(info?.row?.original)
              }}
            />
            <IconButton
              colorScheme='red'
              variant='outline'
              aria-label='delete'
              icon={<BsTrash />}
              onClick={() => {
                setOpen(true)
                setId(info?.row?.original?.id)
              }}
            />
          </Flex>
        )
      },
    }),
  ]

  return (
    <Box>
      <Table
        title={getMessage(agricultureLabel?.agricultureType[lang], 'list', lang)}
        columns={columns}
        rowData={values?.data ?? []}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
      />
      {/* Delete Model */}
      <ModalBox
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(agricultureLabel?.agricultureType[lang], 'delete', lang)}
        type='delete'
        confirm={confirm}
        setConfirm={setConfirm}
        submitting={submit}
        size='sm'
      />
      {/* Show Modal */}
      <ModalBox
        isOpen={openShow}
        setOpen={setOpenShow}
        title={agricultureLabel?.agricultureType[lang]}
        data={<SimpleTable data={selectedData ?? []} />}
      />
    </Box>
  )
}

export default Show
