type Label = Record<string, Record<string, string>>

const labels: Label = {
  newsCategory: {
    en: 'News Category',
    ne: 'समाचार श्रेणी',
  },
  news: {
    en: 'News',
    ne: 'समाचार',
  },
  selectCoverImage: {
    en: 'Select cover image',
    ne: 'कवर तस्बिर छान्नुहोस्',
  },
  isPublished: {
    en: 'Is Published',
    ne: 'प्रकाशित छ',
  },
  yes: {
    en: 'Yes',
    ne: 'छ',
  },
  no: {
    en: 'No',
    ne: 'छैन',
  },
  selectNewsCategory: {
    en: 'Select news category',
    ne: 'समाचार श्रेणी छान्नुहोस्',
  },
}
export default labels
