import { client } from '@api/graphql'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import { Box, Center, Container, Flex, useColorModeValue } from '@chakra-ui/react'
import Logo from '@ui/admin/molecules/Logo'
import LanguageToggle from '@ui/common/molecules/LanguageToggle'
import { DarkTheme, LightTheme } from '@utils/Colors'
import { Outlet } from 'react-router-dom'

const AuthTemplate = () => {
  return (
    <Container maxW={'100%'}>
      <Center h='100vh'>
        <Box
          className='auth-template-section'
          bgColor={useColorModeValue(LightTheme?.secondaryBackground, DarkTheme?.secondaryBackground)}
          p='6'
          borderRadius={4}
        >
          <Flex pb='6' justify={'end'}>
            <LanguageToggle />
          </Flex>

          <Logo />
          <ApolloProvider client={client as ApolloClient<any>}>
            <Outlet />
          </ApolloProvider>
        </Box>
      </Center>
    </Container>
  )
}

export default AuthTemplate
