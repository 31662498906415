export enum FisheriesBreed {
  रहु = 'रहु',
  नैनी = 'नैनी',
  सिल्भरकार्प = 'सिल्भर कार्प',
  बिगहेडकार्प = 'नैबिगहेड कार्पनी',
  ग्रासकार्प = 'ग्रास कार्प',
  कमनकार्प = 'कमन कार्पनी',
  ट्राउट = 'ट्राउट',
  छडी = 'छडी',
  टिलापिया = 'टिलापिया',
  पंगास = 'पंगास',
  स्थानीय = 'स्थानीय',
  अन्य = 'अन्य',
}
