import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import endPoint from '@config/endpoint/imageGallery'
import { playlistSchema } from '@config/schema/videoGallery.schema'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import utilsLabel from '@data/localization/utils'
import imageAlbumLabel from '@data/localization/imageAlbum'
import { getMessage } from '@functions/generateMessage'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import NepaliInput from '@ui/common/atoms/NepaliInput'
import RequireSign from '@ui/common/atoms/RequireSign'
import Modal from '@ui/common/molecules/Modal'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import ImageAlbumModel from '@models/imageGallery/imageAlbum.model'

interface IProps {
  open: boolean
  setOpen: (data: boolean) => void
  values: IResponse<ImageAlbumModel[]> | null
  setValues: (data: IResponse<ImageAlbumModel[]>) => void
  selectedValue: ImageAlbumModel | null
  setSelectedValue: (data: ImageAlbumModel | null) => void
}
const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props
  const { post, put } = useAPI<ImageAlbumModel>()
  const toast = useToast()
  const queryClient = useQueryClient()

  const { lang } = useLang()

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(playlistSchema),
    defaultValues: {
      title: {
        en: '',
        ne: '',
      },
      status: '',
      description: {
        en: '',
        ne: '',
      },
    },
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    if (selectedValue) {
      reset({
        title: {
          en: selectedValue?.title?.en ?? '',
          ne: selectedValue?.title?.ne ?? '',
        },
        description: {
          en: selectedValue?.description?.en ?? '',
          ne: selectedValue?.description?.ne ?? '',
        },
      })
    }
  }, [selectedValue])

  const onSubmit = async (data: any) => {
    try {
      let res
      if (selectedValue) {
        res = await put(endPoint?.album, {
          id: selectedValue?.id,
          title: data.title,
          status: data.status,
          description: data.description,
        })
      } else {
        res = await post(endPoint?.album, {
          title: data.title,
          status: data.status,
          description: data.description,
        })
      }
      if (res?.data) {
        await queryClient.invalidateQueries('playlist')
        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.playlist[lang],
            selectedValue ? notificationLabel?.updated[lang] : notificationLabel?.added[lang],
            lang
          ),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      } else if (res?.error) {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res?.error?.response?.data?.message ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
      reset({
        title: {
          en: '',
        },
        status: '',
        description: {
          en: '',
        },
      })
      setSelectedValue(null)
    }
  }

  useEffect(() => {
    if (selectedValue) {
      setOpen(true)
    }
  }, [selectedValue])

  return (
    <div>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(imageAlbumLabel?.album[lang], 'add', lang)}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
              >
                <FormControl isInvalid={errors.title?.en != null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size='sm'
                    variant={errors?.title?.en != null ? 'error' : ''}
                    type='text'
                    {...register('title.en')}
                    placeholder={'Enter title name'}
                  />
                  <FormErrorMessage>{errors?.title?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={'शिर्षक लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('title.ne', e)
                    }}
                    value={watch('title.ne')}
                  />
                  <FormErrorMessage>{errors?.title?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
                pt='5'
              >
                <FormControl isInvalid={errors.description?.en != null}>
                  <FormLabel>
                    Description - english &nbsp;
                    <RequireSign />
                  </FormLabel>
                  <Textarea
                    size='sm'
                    {...register('description.en')}
                    isInvalid={errors.description?.en != null}
                    placeholder={'Enter description here'}
                  />
                  <FormErrorMessage>{errors?.description?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.description?.ne != null}>
                  <FormLabel>
                    विवरण - नेपाली &nbsp;
                    <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.description?.ne != null}
                    placeholder={'विवरण लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('description.ne', e)
                    }}
                    multiline={true}
                    value={watch('description.ne')}
                  />

                  <FormErrorMessage>{errors?.description?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              <Box pt='5'>
                <FormControl isInvalid={errors.status != null}>
                  <FormLabel>
                    {imageAlbumLabel?.status[lang]} <RequireSign />
                    <FormErrorMessage>{errors?.status?.message}</FormErrorMessage>
                  </FormLabel>
                  <RadioGroup defaultValue={selectedValue?.status ? '1' : '0'}>
                    <Flex wrap={'wrap'} gap='4'>
                      <Radio value={'1'} {...register('status')} colorScheme='facebook'>
                        {imageAlbumLabel?.published[lang]}
                      </Radio>
                      <Radio value={'0'} {...register('status')} colorScheme='facebook'>
                        {imageAlbumLabel?.unpublished[lang]}
                      </Radio>
                    </Flex>
                  </RadioGroup>
                </FormControl>
              </Box>
              <Flex my='5' gap='5' justifyContent={'flex-end'}>
                <Button size='sm' variant={'outline'} colorScheme='red' onClick={() => setOpen(false)}>
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type='submit'
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size='sm'
                  variant={'primary'}
                >
                  {selectedValue ? utilsLabel?.update[lang] : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </div>
  )
}

export default Add
