import React from 'react'
import ErrorPage from './ErrorPage'

class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(_error: any, errorInfo: any) {
    console.log(_error, 'error')
    console.log(errorInfo, 'errorINfo')
  }

  render() {
    console.log(this.state, 'erro boundary')
    // @ts-expect-error
    if (this.state.hasError) {
      return <ErrorPage />
    }

    // @ts-expect-error
    // eslint-disable-next-line react/prop-types
    return this.props.children
  }
}

export default ErrorBoundary
