import { Box, Flex, Text } from '@chakra-ui/react'
import { TbDeviceDesktopAnalytics } from 'react-icons/tb'

interface IProps {
  title: string
  count: number
  color: string
}

const AnalyticsCard = (props: IProps) => {
  const { title, count, color } = props
  return (
    <Box
      sx={{
        background: color,
        px: '4',
        pt: '4',
        borderRadius: '3px',
      }}
    >
      <Flex justifyContent={'space-between'}>
        <Box
          sx={{
            svg: {
              fontSize: '42px',
              color: 'white',
            },
          }}
        >
          <TbDeviceDesktopAnalytics />
        </Box>
        <Box>
          <Text fontSize={'14px'} fontWeight='500' textTransform={'capitalize'} color='white'>
            {title?.toString()?.toLowerCase()}
          </Text>
          <Text textAlign={'end'} fontSize={'28px'} fontWeight='800' color='white'>
            {count}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default AnalyticsCard
