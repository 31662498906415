import { AxiosBaseInstance as AxiosInstance } from '@api/index'
const useUpload = () => {
  const uploadMedia = async (files: any, type: string = 'ImageGallery') => {
    const formData = new FormData()
    formData.append('relatedTo', type)
    console.log(Array.isArray(files), 'jahhaha ')
    if (Array.isArray(files)) {
      files.map((file) => {
        return formData.append('file', file)
      })
    } else {
      formData.append('file', files)
    }

    return await AxiosInstance.post('/media', formData)
  }
  const updateMedia = async (files: any) => {
    const formData = new FormData()
    if (Array.isArray(files)) {
      files.map((file) => {
        return formData.append('file', file)
      })
    } else {
      formData.append('file', files)
    }
    formData.append('relatedTo', 'ImageGallery')

    return await AxiosInstance.put('/media', formData)
  }
  return {
    uploadMedia,
    updateMedia,
  }
}

export default useUpload
