import { Box, Image, Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import tableLabel from '@data/localization/table'
import useLang from '@hooks/useLang'
interface IProps {
  data: Array<{
    title: string
    description?: string
    type?: 'image' | 'text'
    multipleImage?: boolean
    images?: Array<{
      name: string
      path: string
    }>
  }>
  tableCaption?: string
}

const SimpleTable = (props: IProps) => {
  const { data, tableCaption } = props
  const { lang } = useLang()
  console.log(data, ':ahah')
  return (
    <TableContainer>
      <Table
        size='sm'
        sx={{
          mb: '4',
          border: '1px',
          borderColor: 'gray.100',
          thead: {
            tr: {
              background: 'gray.200',
              th: {
                paddingTop: '12px',
                paddingBottom: '7px',
                color: 'black',
                textTransform: 'capitalize',
              },
            },
          },
          tbody: {
            tr: {
              border: '1px',
              borderColor: 'gray.100',
              td: {
                paddingY: '3',
              },
            },
          },
        }}
      >
        {tableCaption && <TableCaption>Imperial to metric conversion factors</TableCaption>}
        <Thead>
          <Tr
            sx={{
              th: {
                fontSize: '15px',
              },
            }}
          >
            <Th width={'35px'}>{tableLabel?.sn[lang]}</Th>
            <Th width={'35%'}>{tableLabel?.title[lang]}</Th>
            <Th>{tableLabel?.description[lang]}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.length > 0 &&
            data?.map((el, index: number) => {
              return (
                <Tr key={index}>
                  <Td>{index + 1}</Td>
                  <Td>{el?.title}</Td>
                  <Td>
                    {el?.multipleImage ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '3',
                        }}
                      >
                        {el?.images &&
                          el?.images?.length > 0 &&
                          el?.images?.map((e, i: number) => {
                            return (
                              <Image
                                key={i}
                                src={e?.path}
                                alt={e?.name}
                                width={'100px'}
                                height={'100px'}
                                objectFit={'cover'}
                              />
                            )
                          })}
                      </Box>
                    ) : el?.type === 'image' ? (
                      <Image
                        src={el?.description}
                        alt={el?.title}
                        width={'100px'}
                        height={'100px'}
                        objectFit={'cover'}
                      />
                    ) : (
                      el?.description && <div dangerouslySetInnerHTML={{ __html: el?.description }} />
                    )}
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

SimpleTable.defaultProps = {
  data: {
    type: 'text',
  },
}
export default SimpleTable
