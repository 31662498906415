interface IType {
  add: 'add'
  delete: 'delete'
  list: 'list'
}

const messages: any = {
  en: {
    add: (message: string) => `Add ${message}`,
    delete: (message: string) => `Delete ${message}`,
    list: (message: string) => `List ${message}`,
  },
  ne: {
    add: (message: string) => `${message} थप्नुहोस`,
    delete: (message: string) => `${message} मेटाउनुहोस`,
    list: (message: string) => `${message} सूची`,
  },
}

export const getMessage = (message: string, type: keyof IType, lang: any) => {
  return messages[lang][type](message)
}
