import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import endPoint from '@config/endpoint/news'
import useAPI from '@hooks/useUserHook'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import NewsModel from '@models/news/news.model'
import { IPagination } from '@type/pagination.types'
import NewsCard from '@ui/user/organisms/news/NewsCard'
import NewsCategory from '@ui/user/organisms/news/NewsCategory'
import { useEffect, useState } from 'react'
import { IoSearchSharp } from 'react-icons/io5'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

const News = () => {
  let [searchParams] = useSearchParams()

  const { data, loading, get } = useAPI<NewsModel[] | null>()
  const [values, setValues] = useState<IResponse<NewsModel[]> | null>(null)
  const [mounted, setMounted] = useState(false)
  const { lang } = useLang()

  const [pagination, setPagination] = useState<IPagination>({
    currentPage: 1,
    perPage: 10,
    searchTerm: searchParams.get('q') ?? '',
    total: undefined,
    totalPages: 1,
    refreshTable: false,
  })

  useEffect(() => {
    if (data?.data) {
      setValues({
        ...data,
        data: NewsModel?.plainToInstances(data?.data),
        pagination: data?.pagination,
      })
      if (data?.pagination)
        setPagination({
          ...pagination,
          currentPage: data?.pagination?.currentPage ?? 1,
          perPage: data?.pagination?.perPage ?? 20,
          total: data?.pagination?.total ?? undefined,
          totalPages: data?.pagination?.totalPages ?? undefined,
        })
    }
  }, [data])

  const handleFetch = async () => {
    await get(endPoint?.news, {
      perPage: pagination?.perPage,
      page: pagination?.currentPage,
      search: pagination?.searchTerm,
    })
  }
  useQuery('news', handleFetch, {
    refetchIntervalInBackground: false,
  })

  const handleNextPage = () => {
    setPagination({
      ...pagination,
      currentPage: pagination?.currentPage + 1,
    })
  }

  const handlePreviousPage = () => {
    setPagination({
      ...pagination,
      currentPage: pagination?.currentPage - 1,
    })
  }

  useEffect(() => {
    if (mounted) {
      if (pagination?.currentPage > 1 && pagination?.currentPage <= pagination?.totalPages) void handleFetch()
    } else {
      setMounted(true)
    }
  }, [pagination?.currentPage])

  useEffect(() => {
    setPagination({
      ...pagination,
      total: data?.pagination?.total,
      totalPages: data?.pagination?.totalPages ?? 1,
    })
  }, [data])

  let timer: any
  // search
  useEffect(() => {
    if (mounted) {
      if (pagination?.searchTerm === values?.pagination?.searchTerm) return
      timer = setTimeout(() => {
        void handleFetch()
      }, 500)
      return () => clearTimeout(timer)
    } else {
      setMounted(true)
    }
  }, [pagination?.searchTerm])

  return (
    <Box py={4}>
      <Stack spacing={4} as={Container}>
        <Heading fontSize={{ base: '2xl', sm: '3xl' }} fontWeight={'bold'} textTransform='capitalize'>
          News
        </Heading>
      </Stack>

      <Container>
        {pagination?.searchTerm?.length > 0 && (
          <Text pt='4' display={'flex'} gap='2' alignItems={'center'}>
            <IoSearchSharp size={'18px'} />
            <Text pt='2px' display={'flex'} gap='3'>
              Search for
              <Text color='green' textDecoration={'underline'}>
                {pagination?.searchTerm}{' '}
              </Text>
            </Text>
          </Text>
        )}
        <Grid templateColumns={{ lg: 'repeat(4,1fr)', md: 'repeat(3,1fr)', base: 'repeat(1,1fr)' }} gap='5' mt='5'>
          <GridItem colSpan={3}>
            {loading ? (
              'Loading'
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                {values?.data && values?.data?.length > 0 ? (
                  values?.data?.map((item, index) => (
                    <NewsCard
                      key={index}
                      id={item?.id as string}
                      title={item?.title?.[lang]}
                      description={item?.description?.[lang]}
                      createdAt={item?.createdAt}
                      image={item?.media?.medias[0]?.name}
                      category={item?.category?.title?.[lang]}
                    />
                  ))
                ) : (
                  <Text color='red'>No News found </Text>
                )}
                <Flex justifyContent={'space-between'} mt='5'>
                  {pagination?.currentPage !== 1 && (
                    <Button
                      variant='primary'
                      size='sm'
                      onClick={() => {
                        handlePreviousPage()
                      }}
                    >
                      Previous
                    </Button>
                  )}
                  {pagination?.currentPage !== pagination?.totalPages && pagination?.totalPages > 1 && (
                    <Button
                      variant='primary'
                      size='sm'
                      onClick={() => {
                        handleNextPage()
                      }}
                    >
                      Next
                    </Button>
                  )}
                </Flex>
              </Box>
            )}
          </GridItem>
          <Box>
            <Box
              sx={{
                position: 'sticky',
                top: '20px',
              }}
            >
              <Box mb='10'>
                <Text pb='2' fontSize={'18px'} fontWeight='600'>
                  Search
                </Text>

                <FormControl pt='3'>
                  <FormLabel>Search news here</FormLabel>
                  <Input
                    size='sm'
                    type='text'
                    onChange={(e) => {
                      setPagination({
                        ...pagination,
                        searchTerm: e.target.value,
                      })
                    }}
                    placeholder={'Enter search text here..'}
                  />
                </FormControl>
              </Box>
              <Text pb='2' pt='5' fontSize={'18px'} fontWeight='600'>
                Category
              </Text>
              <NewsCategory />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default News
