import { Box, Image } from '@chakra-ui/react'
import endPoint from '@config/endpoint/media'
import useAPI from '@hooks/useApi'
import useUpload from '@hooks/useUpload'
import React, { ChangeEvent, useEffect, useState } from 'react'

interface ImageUploadProps {
  onChange: (data: string) => void
  selectFormat: string
  relatedTo: string
  selectedFile?: string // path of the file
  selectedId?: string // id of the file
}

const FileUpload: React.FC<ImageUploadProps> = ({ onChange, selectFormat, relatedTo, selectedFile, selectedId }) => {
  const { post } = useAPI<any>()
  const { uploadMedia } = useUpload()
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)
  const [fileName, setFileName] = useState<File | null>(null)

  useEffect(() => {
    if (selectedFile && selectedFile?.length > 0) {
      setPreviewUrl(selectedFile)
      if (selectedId) onChange(selectedId)
    }
  }, [selectedFile])

  const handleFileInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.files?.[0] as File)
    const file = event.target.files?.[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string)
      }
      reader.readAsDataURL(file)
    } else {
      setPreviewUrl(null)
    }
  }

  useEffect(() => {
    void handleSubmit()
  }, [fileName])

  const handleSubmit = async () => {
    if (fileName) {
      const formData = new FormData()
      formData.append('relatedTo', relatedTo)
      formData.append('file', fileName)
      // const res = await useU(endPoint.media, formData)
      const res = await uploadMedia(fileName)
      if (res?.data) {
        onChange(res.data?.data?.id)
      }
    }
  }

  return (
    <Box>
      {previewUrl && (
        <Image
          sx={{
            width: '300px',
            height: 'auto',
            objectFit: 'contain',
            mb: 2,
            borderRadius: 'md',
          }}
          src={previewUrl}
          alt='Preview'
        />
      )}
      <input type='file' name='file' onChange={handleFileInputChange} accept={selectFormat} />
    </Box>
  )
}

export default FileUpload
