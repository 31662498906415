import Category from '@models/agricultureCategory.model'
import Language from '@models/language.model'
import { plainToClass } from 'class-transformer'

class AgricultureType {
  id: string
  title: Language
  category: Category
  media: any

  constructor(id: string, title: Language, category: Category, Media: any) {
    this.id = id
    this.title = title
    this.category = category
    this.media = Media
  }

  static plainToInstance(plainData: object): AgricultureType {
    return plainToClass(AgricultureType, plainData)
  }

  static plainToInstances(plainData: object[]): AgricultureType[] {
    return plainToClass(AgricultureType, plainData)
  }
}
export default AgricultureType
