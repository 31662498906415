import axios from "@api/PrivateAxios";
import { HTTPMethod, Service } from "@config/constant/global";
import pagination from "@config/constant/pagination";
import { graphQLCall, graphqlBodyParser } from "@functions/graphqlParser";
import { centralAuthQ } from "@graphql/query";
import { IPagination } from "@type/pagination.types";
import { useState } from "react";
import axiosForMedia from "axios";
import { AxiosBaseInstance } from "@api/index";

interface IGetParameter {
  perPage?: number;
  page?: number;
  search?: string;
  queryName?: string;
  queryValue?: string;
}

interface IPaginatedResponse<T> {
  data: T;
  pagination: IPagination;
}

const useAPI = <T>() => {
  const [data, setData] = useState<IPaginatedResponse<T> | null>(null);
  const [error, setError] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const get = async (endPoint: string, args?: IGetParameter): Promise<T> => {
    setLoading(true);
    let response;
    try {
      if (args)
        endPoint = `${endPoint}/?search=${args?.search}&perPage=${
          args?.perPage ?? pagination?.page
        }&page=${args?.page}&${args?.queryName}=${args?.queryValue}`;
      else endPoint = `${endPoint}`;

      response = await graphQLCall(
        {
          data: graphqlBodyParser(
            Service.AGRICULTURE,
            endPoint,
            HTTPMethod.GET
          ),
        },
        centralAuthQ
      );

      setData(response?.data?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

    return response?.data?.data;
  };

  const getById = async (endPoint: string, id: string): Promise<T> => {
    setLoading(true);
    let response;
    try {
      endPoint = `${endPoint}/${id}`;

      response = await graphQLCall(
        {
          data: graphqlBodyParser(
            Service.AGRICULTURE,
            endPoint,
            HTTPMethod.GET
          ),
        },
        centralAuthQ
      );
      setData(response?.data?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }

    return response?.data?.data;
  };

  const post = async (endPoint: string, data: Partial<T>) => {
    let response;
    try {
      response = await graphQLCall(
        {
          data: graphqlBodyParser(
            Service.AGRICULTURE,
            endPoint,
            HTTPMethod.POST,
            JSON.stringify(data)
          ),
        },
        centralAuthQ
      );

      return {
        data: response.data.data ?? response.data,
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error,
      };
    }
  };

  const postNormal = async (endPoint: string, data: Partial<T>) => {
    let response;
    try {
      response = await AxiosBaseInstance.post(endPoint, data);
      return {
        data: response.data.data,
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error,
      };
    }
  };

  const put = async (endPoint: string, data: Partial<T>) => {
    let response;
    try {
      response = await graphQLCall(
        {
          data: graphqlBodyParser(
            Service.AGRICULTURE,
            endPoint,
            HTTPMethod.PUT,
            JSON.stringify(data)
          ),
        },
        centralAuthQ
      );
      return {
        data: response.data.data,
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error,
      };
    }
  };

  const patch = async (endPoint: string, data: Partial<T>) => {
    let response;
    try {
      response = await graphQLCall(
        {
          data: graphqlBodyParser(
            Service.AGRICULTURE,
            endPoint,
            HTTPMethod.PUT,
            JSON.stringify(data)
          ),
        },
        centralAuthQ
      );
      return {
        data: response.data.data,
        error: null,
      };
    } catch (error: any) {
      return {
        data: null,
        error,
      };
    }
  };

  const remove = async (endPoint: string): Promise<boolean | string> => {
    setLoading(true);
    let response;
    try {
      endPoint = `${endPoint}`;
      response = await graphQLCall(
        {
          data: graphqlBodyParser(
            Service.AGRICULTURE,
            endPoint,
            HTTPMethod.DELETE
          ),
        },
        centralAuthQ
      );
      response = response?.data?.status;
    } catch (error: any) {
      console.log(error);
      response = error?.response?.data?.message ?? "error";
    } finally {
      setLoading(false);
    }
    return response;
  };

  return {
    data,
    error,
    loading,
    setLoading,
    get,
    getById,
    post,
    patch,
    remove,
    put,
    postNormal,
  };
};

export default useAPI;
