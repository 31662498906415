import { gql } from '@apollo/client'

export const ADMIN_LOGIN = gql`
  mutation LoginByService($data: AdminLoginInputByService!) {
    loginByService(data: $data) {
      accessToken
      refreshToken
      admin {
        id
        email
        role
        services {
          name
          permissions
          role
          roleLevel
          parentAdminId
        }
        detail {
          firstName {
            en
            ne
          }
          middleName {
            ne
            en
          }
          lastName {
            en
            ne
          }
          id
        }
      }
    }
  }
`
