export const loginQuery = `mutation Login($data: AdminLoginInputByService!) {
    loginByService(data: $data) {
      accessToken
      refreshToken
      admin {
        id
        email
        role
        services {
          name
          permissions
          role
          roleLevel
          parentAdminId
        }
        detail {
          firstName {
            en
            ne
          }
          middleName {
            ne
            en
          }
          lastName {
            en
            ne
          }
          id
       
        }
      }
    }
  }`

export const centralAuthQ = `mutation Auth($data: CentralAuthenticationInput!) {
    auth(data: $data)
  }`

export const getCoordinatorQuery = `query GetAdminsByService($service: Service!, $roleLevel: RoleLevel) {
    getAdminsByService(service: $service, roleLevel: $roleLevel) {
      adminWardNumber
      email
      id
      detail {
        middleName {
          en
          ne
        }
        lastName {
          en
          ne
        }
        firstName {
          en
          ne
        }
      }
    }
  }`

export const getFiscalYearQuery = `
  query FiscalYear {
  fiscalYears {
    id
    isCurrent
    startDate
    endDate
    year
  }
}
  `

export const getFiscalYears = `
  query{
    fiscalYears {
      id
      year
    }
  }
  `
