import PrivateAxiosInstance from '@api/PrivateAxios'
import { HTTPMethod, Service } from '@config/constant/global'
export const graphQLCall = (data: object, query: string) => {
  return PrivateAxiosInstance.post('', {
    query: query,
    variables: data,
  })
}

export const graphqlBodyParser = (service: Service, url: string, method: HTTPMethod, body?: any) => {
  return {
    service,
    method,
    url,
    body: body ?? null,
  }
}

export const graphQLCallDirect = (query: string, data: object) => {
  return PrivateAxiosInstance.post('', {
    query: query,
    variables: data,
  })
}
