import * as Yup from 'yup'
import Text, { getRequiredText } from '../constant/text'

export const adminLoginSchema = Yup.object({
  userEmailPhone: Yup.string()
    .required('Email/Phone Number is required')
    .test('test-name', 'Enter Valid Phone/Email', function (value: any) {
      // eslint-disable-next-line
      const emailRegex = /([a-zA-Z0-9]+)([\_\.\-{1}])?([a-zA-Z0-9]+)\@([a-zA-Z0-9]+)([\.])([a-zA-Z\.]+)/g
      const phoneRegex = /^([\s\\(\\)\\-]*\d[\s\\(\\)\\-]*){10}$/
      const isValidEmail = emailRegex.test(value)
      const isValidPhone = phoneRegex.test(value)
      if (!isValidEmail && !isValidPhone) {
        return false
      }
      return true
    }),
  password: Yup.string()
    .min(8)
    .max(30)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/, Text.PASSWORD_SUGGESTION)
    .required(getRequiredText('Password')),
})
