import { Grid, Text } from '@chakra-ui/react'
import endPoint from '@config/endpoint/agriculture'
import useAPI from '@hooks/useApi'
import AnalyticsCard from '@ui/common/molecules/AnalyticsCard'
import { useEffect, useState } from 'react'

const index = () => {
  const { get } = useAPI()
  const [data, setData] = useState<any>()

  const handleFetch = async () => {
    const res = await get(endPoint.AgricultureAnalytics)
    if (res) {
      setData(res)
    }
  }
  useEffect(() => {
    void handleFetch()
  }, [])

  // create array of color code
  const color = ['#1d3557', '#540b0e', '#22223b', '#15616d', '#003566']

  return (
    <Grid
      pt='2'
      templateColumns={{
        base: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(4, 1fr)',
        lg: 'repeat(5, 1fr)',
      }}
      gap={4}
    >
      {data && data?.length > 0 ? (
        data?.map((item: any, index: number) => (
          <AnalyticsCard key={index} count={item?.count} color={color?.[index]} title={item?.title} />
        ))
      ) : (
        <Text color={'red'}>No records found for analytics</Text>
      )}
    </Grid>
  )
}

export default index
