import EncryptDecrypt from '@functions/EncryptDecrypt'
import useAuth from '@hooks/useAuth'
import { Navigate, useLocation } from 'react-router-dom'

const AdminProtectedRoute = ({ children }: any) => {
  const { user } = useAuth()

  const { decrypt } = EncryptDecrypt
  // const admin = decrypt(user)?.admin

  const location = useLocation()

  if (!user) {
    return <Navigate to='/auth/admin' state={{ from: location }} replace />
  }
  return children
}

export { AdminProtectedRoute }
