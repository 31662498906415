import { AxiosBaseInstance } from '@api/index';
import {
  Box,
  Container,
  IconButton,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import endPoint from '@config/endpoint/carousel';
import useAPI from '@hooks/useApi';
import useLang from '@hooks/useLang';
import CarouselModel from '@models/carousel/carousel.model';
import React, { useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { useQuery } from 'react-query';
import Slider from 'react-slick';

const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function CaptionCarousel() {
  const [slider, setSlider] = React.useState<Slider | null>(null);
  const { get } = useAPI();
  const { lang } = useLang();
  const [value, setValues] = useState<CarouselModel | null>(null);

  // fetch data
  const handleFetch = async () => {
    const data = (await AxiosBaseInstance.get(endPoint?.allCarousel)) as any;
    if (data?.data?.data?.data?.[0]) {
      setValues(data?.data?.data?.data?.[0]);
    }
  };

  useQuery('agricultureType', handleFetch, {
    refetchIntervalInBackground: false,
  });

  // breakpoints which changes the position of the
  const top = useBreakpointValue({ base: '90%', md: '50%' });
  const side = useBreakpointValue({ base: '30%', md: '40px' });

  return (
    <Box>
      {value != null && (
        <Box
          position={'relative'}
          height={'600px'}
          width={'full'}
          overflow={'hidden'}
        >
          {/* CSS files for react-slick */}
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          {/* Left Icon */}
          <IconButton
            aria-label="left-arrow"
            variant="ghost"
            position="absolute"
            left={side}
            top={top}
            transform={'translate(0%, -50%)'}
            zIndex={2}
            onClick={() => slider?.slickPrev()}
          >
            <BiLeftArrowAlt size="40px" />
          </IconButton>
          {/* Right Icon */}
          <IconButton
            aria-label="right-arrow"
            variant="ghost"
            position="absolute"
            right={side}
            top={top}
            transform={'translate(0%, -50%)'}
            zIndex={2}
            onClick={() => slider?.slickNext()}
          >
            <BiRightArrowAlt size="40px" />
          </IconButton>
          {/* Slider */}
          <Slider {...settings} ref={(slider) => setSlider(slider)}>
            {value?.media?.medias?.map((card: any, index: number) => (
              <Box
                key={index}
                height={'6xl'}
                position="relative"
                backgroundPosition="bottom"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundImage={`url(${card?.name})`}
              >
                {/* This is the block you need to change, to customize the caption */}
                <Container
                  size="container.lg"
                  height="600px"
                  position="relative"
                >
                  <Stack
                    w={'full'}
                    position="absolute"
                    bottom="20px"
                    transform="translate(-50%, -50%)"
                    left={'50%'}
                    right={'50%'}
                  >
                    <Box
                      sx={{
                        background: 'gray.100',
                        paddingX: '1rem',
                        paddingBottom: '.21rem',
                        paddingTop: '.5rem',
                      }}
                    >
                      <Text fontSize={'15px'} color="black">
                        {value?.title[lang]}
                      </Text>
                    </Box>
                  </Stack>
                </Container>
              </Box>
            ))}
          </Slider>
        </Box>
      )}
    </Box>
  );
}
