import hero from '@assets/image/hero.jpg'
import AppStore from '@assets/image/logo/downloadonAppStore.png'
import Nepal_flag from '@assets/image/logo/Flag_Nepal.png'
import USA_flag from '@assets/image/logo/Flag_USA.png'
import logo from '@assets/image/logo/logo.png'
import npFlag from '@assets/image/logo/np_flag.gif'

const image = {
  logo,
  Nepal_flag,
  USA_flag,
  npFlag,
  AppStore,
  hero,
}
export default image
