import * as yup from 'yup'
import { NEPALI_REGEX } from '../../regex/index'

// id: string
//   groupName: Language
//   establishDate: Date
//   villageName: string
//   description: string
//   ward: number
//   tole: string
//   purpose: string
//   population: {
//     male: number
//     female: number
//     others: number
//   }

//   officeWard: number
//   officeName: string

export const agricultureGroupSchema = yup.object({
  groupName: yup.object().shape({
    en: yup.string().required('Name is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'देवानगरिमा लेख्नुहोस').required('नाम आवश्यक छ ।'),
  }),
  establishDate: yup.date().required('Establish date is required').typeError('Establish date must be a date'),
  villageName: yup.string().required('Village name is required'),
  description: yup.string().required('Description is required'),
  ward: yup.number().required('Ward is required').typeError('Ward Must be a number'),
  tole: yup.string().required('Tole is required'),
  population: yup.object().shape({
    male: yup.number().required('Male Count is required').typeError('Male Count Must be a number'),
    female: yup.number().required('Female Count is required').typeError('Female Count Must be a number'),
    others: yup.number().required('Others Count is required').typeError('Others Count Must be a number'),
  }),
  purpose: yup.string().required('Purpose is required'),
  officeWard: yup.number().required('Office Ward is required').typeError('Office Ward Must be a number'),
  officeName: yup.string().required('Office Name is required'),
  members: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required('First Name is required'),
      middleName: yup.string().optional(),
      lastName: yup.string().required('Last Name is required'),
      position: yup.string().required('Position is required'),
      educationQualification: yup.string().required('Education Qualification is required'),
      age: yup.number().required('Age is required').typeError('Age Must be a number'),
      farmArea: yup.string().required('Farm Area is required'),
    })
  ),
})
