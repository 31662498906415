import { NEPALI_REGEX } from '@regex/index'
import * as yup from 'yup'

export const newsSchema = yup.object({
  title: yup.object().shape({
    en: yup.string().required('Name is required'),
    ne: yup.string().matches(NEPALI_REGEX, 'देवानगरिमा लेख्नुहोस').required('नाम आवश्यक छ ।'),
  }),
  description: yup.object().shape({
    en: yup.string().required('Description is required'),
    ne: yup.string().required('विवरण आवश्यक छ ।'),
  }),
  media: yup.string().required('Please upload cover image'),
  category: yup.string().required('Please select category')?.typeError('Please select category'),
})
