export enum Service {
  WADAPATRA = 'WADAPATRA',
  PROFILING = 'PROFILING',
  MISCELLANEOUS = 'MISCELLANEOUS',
  PLANNING = 'PLANNING',
  IJALAS = 'IJALAS',
  SIFARISH = 'SIFARISH',
  REGISTRATION = 'REGISTRATION',
  PAPER_LESS_DOCUMENT = 'PAPER_LESS_DOCUMENT',
  AGRICULTURE = 'AGRICULTURE',
}

export enum HTTPMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}
