import { ChakraProvider } from '@chakra-ui/react'
import ContextProvider from '@contexts/index'
import theme from '@utils/theme'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import './assets/scss/main.scss'
import ErrorBoundary from './Error.Boundary'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ErrorBoundary>
    <ChakraProvider theme={theme}>
      <Router>
        <ContextProvider>
          <QueryClientProvider client={queryClient}>
            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            <App />
          </QueryClientProvider>
        </ContextProvider>
      </Router>
    </ChakraProvider>
  </ErrorBoundary>
)
