import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, useToast } from '@chakra-ui/react'
import endPoint from '@config/endpoint/agriculture'
import { categorySchema } from '@config/schema/category.schema'
import agricultureLabel, { getMessage } from '@data/localization/agriculture'
import notificationLabel, { generateMessage } from '@data/localization/notification'
import utilsLabel from '@data/localization/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import useAPI from '@hooks/useApi'
import useLang from '@hooks/useLang'
import { IResponse } from '@interface/response.interface'
import AgricultureCategory from '@models/agricultureCategory.model'
import NepaliInput from '@ui/common/atoms/NepaliInput'
import RequireSign from '@ui/common/atoms/RequireSign'
import Modal from '@ui/common/molecules/Modal'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'

interface IProps {
  open: boolean
  setOpen: (data: boolean) => void
  values: IResponse<AgricultureCategory[]> | null
  setValues: (data: IResponse<AgricultureCategory[]>) => void
  selectedValue: AgricultureCategory | null
  setSelectedValue: (data: AgricultureCategory | null) => void
}

const Add = (props: IProps) => {
  const { open, setOpen, selectedValue, setSelectedValue } = props
  const { post, patch, put } = useAPI<AgricultureCategory>()
  const toast = useToast()
  const queryClient = useQueryClient()
  const { lang } = useLang()

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    resolver: yupResolver(categorySchema),
    defaultValues: {
      title: {
        en: '',
        ne: '',
      },
    },
    reValidateMode: 'onSubmit',
  })

  useEffect(() => {
    if (selectedValue) {
      reset({
        title: {
          en: selectedValue?.title?.en ?? '',
          ne: selectedValue?.title?.ne ?? '',
        },
      })
    }
  }, [selectedValue])

  const onSubmit = async (data: any) => {
    try {
      let res
      if (selectedValue) {
        res = await put(endPoint?.agricultureCategory, {
          id: selectedValue?.id?.toString(),
          title: data.title,
        })
      } else {
        res = await post(endPoint?.agricultureCategory, {
          title: data.title,
        })
      }
      if (res?.data) {
        await queryClient.invalidateQueries('myData')

        toast.closeAll()
        toast({
          title: notificationLabel?.success[lang],
          description: generateMessage(
            notificationLabel?.agricultureCategory[lang],
            selectedValue ? notificationLabel?.updated[lang] : notificationLabel?.added[lang],
            lang
          ),
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        reset({
          title: {
            en: '',
            ne: '',
          },
        })
      } else if (res?.error) {
        toast.closeAll()
        toast({
          title: notificationLabel?.error[lang],
          description: res?.error?.response?.data?.message ?? notificationLabel?.somethingWrongHappen[lang],
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (err: any) {
      toast.closeAll()
      toast({
        title: notificationLabel?.error[lang],
        description: err?.message ?? notificationLabel?.somethingWrongHappen[lang],
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setOpen(false)
    }
  }

  useEffect(() => {
    if (selectedValue) {
      setOpen(true)
    }
  }, [selectedValue])

  useEffect(() => {
    if (!open) {
      setSelectedValue(null)
      reset({
        title: {
          en: '',
        },
      })
    }
  }, [open])

  return (
    <Box>
      <Modal
        isOpen={open}
        setOpen={setOpen}
        title={getMessage(agricultureLabel?.agricultureCategory[lang], 'add', lang)}
        data={
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                direction={{
                  md: 'row',
                  base: 'column',
                }}
                justifyContent='space-between'
                gap='5'
              >
                <FormControl isInvalid={errors.title?.en != null}>
                  <FormLabel>
                    Title - english <RequireSign />
                  </FormLabel>
                  <Input
                    size='sm'
                    variant={errors?.title?.en != null ? 'error' : ''}
                    type='text'
                    {...register('title.en')}
                    placeholder={'Enter title name'}
                  />
                  <FormErrorMessage>{errors?.title?.en?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.title?.ne != null}>
                  <FormLabel>
                    शिर्षक - नेपाली <RequireSign />
                  </FormLabel>
                  <NepaliInput
                    error={errors?.title?.ne != null}
                    placeholder={'शिर्षक लेख्नुहोस |'}
                    handleChange={(e: string) => {
                      setValue('title.ne', e)
                    }}
                    value={watch('title.ne')}
                  />
                  <FormErrorMessage>{errors?.title?.ne?.message}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex my='5' gap='5' justifyContent={'flex-end'}>
                <Button size='sm' variant={'outline'} colorScheme='red' onClick={() => setOpen(false)}>
                  {utilsLabel?.cancel[lang]}
                </Button>
                <Button
                  type='submit'
                  isLoading={isSubmitting}
                  loadingText={utilsLabel?.submitting[lang]}
                  size='sm'
                  variant={'primary'}
                >
                  {selectedValue ? utilsLabel?.update[lang] : utilsLabel?.submit[lang]}
                </Button>
              </Flex>
            </form>
          </Box>
        }
      />
    </Box>
  )
}

export default Add
